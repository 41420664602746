
import name from 'lib/name';
import { get, isEmpty } from 'lodash';
import * as moment from 'moment';
import posthog from 'posthog-js';
import { parse, stringify } from 'query-string';
import { RaygunPayload, RaygunV2 } from 'raygun4js';
import Cookies from 'universal-cookie';
import history from '../routing/history';
import config from '../runtime-config';
import { memoizeDebounce } from './memoize-debounce';
import { initTracing } from './tracing';

interface UserOptions {
  email: string;
  emailHash: string;
  firstName: string;
  lastName: string;
  preferredName?: string;
  organization: string;
  userID: string;
  activatedAt?: string;
  lastLoginDate?: string;
  firstAccess: boolean;
  cohort?: string;
  orgType?: string;
  currentUserCan?: string[];
}

// Official typedefs do not exist yet: https://docs.appcues.com/en_US/dev-installing-appcues/installation-overview-for-developers#a-note-on-using-typescript-7
interface Appcues {
  page: () => void;
  track: (event: string, eventOptions?: object) => void;
  group: (organization: string, options: object) => void;
  identify: (userID: string, traits: object) => void;
}

/**
 * Remove email from params sent to GA
 * @param pathname
 * @param hash
 */
export function cleanHash(pathname: string, hash: string) {
  const [route, query] = hash.split('?');
  let params = {};
  if (query) {
    params = parse(query);
    delete params['email'];
  }

  const tokens = [] as string[];
  if (pathname === '/') {
    tokens.push(route.substring(1));
  } else {
    tokens.push(pathname + route.substring(1));
  }
  if (!isEmpty(params)) {
    tokens.push(stringify(params));
  }

  return tokens.join('?');
}

declare global {
  interface Window {
    analytics: SegmentAnalytics.AnalyticsJS;
    rg4js: RaygunV2 | undefined;
    Appcues: Appcues | undefined;
    Vitally: any;
    mixpanel: any;
  }
}

const analytics = {
  initialized: false,
  user: undefined as UserOptions | undefined,
  userID: '',
  organization: '',
  organizationId: '',
  organizationType: '',
  surveyCount: 0,
  surveyVolume: 0,
  identify(user: UserOptions | undefined, traits: object) {
    // cache the UserOptions object
    if (user) {
      this.user = user;
      this.userID = user.userID;
    }
    if (traits['organization']) {
      this.organization = traits['organization'];
    }
    if (traits['orgId']) {
      this.organizationId = traits['orgId'];
    }
    if (traits['orgType']) {
      this.organizationType = traits['orgType'];
    }
    if (traits['surveyCount']) {
      this.surveyCount = traits['surveyCount'];
    }
    if (traits['surveyVolume']) {
      this.surveyVolume = traits['surveyVolume'];
    }

    if (this.initialized) {

      // default traits we expect
      let traitsDictionary = {};
      let createdAtString = '';
      if (this.user) {

        // turn the activated at into a string
        if (this.user.activatedAt) {
          createdAtString = moment(this.user.activatedAt).format('YYYY-MM-DD');
        }

        traitsDictionary = {
          userID: this.userID,
          orgID: this.organizationId,
          cohort: this.user.cohort,
          activatedAt: this.user.activatedAt,
          createdAt: createdAtString,
          lastLoginDate: this.user.lastLoginDate
        };
      }
      // now if we were asked to provide other traits: do so now
      if (traits) {
        Object.assign(traitsDictionary, traits);
      }

      window.Appcues?.identify(this.userID, {
        email: this.user?.email,
        firstName: this.user?.firstName,
        createdAt: createdAtString,
        organization: this.organizationId
      });
      window.Appcues?.group(this.organizationId, {
        companyName: this.organization,
        location: config.region
      });

      window.analytics.identify(this.userID, traitsDictionary);
      window.analytics.group(this.organization, {
        region: config.region
      });

      if (this.userID) {
        posthog.identify(this.userID, traitsDictionary);
      }

      // set the relevant cookie
      this.setOrgInfoCookie();

      // identify with vitally
      if (this.user && window.Vitally) {
        const {
          firstName,
          lastName,
          preferredName,
          email,
          organization,
          userID,
          orgType,
        } = this.user;
        // make vitally calls in a separate thread to stop them crashing
        setTimeout(() => {
          try {
            // send account info
            window.Vitally.account({
              accountId: organization,
              traits: {
                name: organization,
                orgType: orgType
              }
            });
            // send the user identifier to Vitally
            let vitallyTraitsDictionary = {
              name: name({ firstName, lastName, preferredName }),
              email: email,
              createdAt: createdAtString
            };
            Object.assign(vitallyTraitsDictionary, traits);
            window.Vitally.user({
              userId: userID,
              accountId: organization,
              traits: vitallyTraitsDictionary
            });
          } catch (e) {
            // Swallow error, do nothing since it happens inside Vitally
          }
        }, 100);
      }

      // set user on raygun
      if (this.user && window.rg4js) {
        const { rg4js } = window;
        rg4js('setUser', {
          identifier: this.userID,
          email: this.userID,
          firstName: this.user.firstName
        });
      }
    }
  },
  init() {
    const { rg4js } = window;
    analytics.initialized = true;
    // setup segment
    if (config.segmentID) {
      window.analytics.load(config.segmentID);
    }

    if (config.posthogID) {
      posthog.init(config.posthogID, { api_host: 'https://app.posthog.com', disable_session_recording: true });
    }

    if (config.honeycombID) {
      initTracing(config.honeycombID, config.honeycombDataset);
    }

    if (config.raygunID === '') {
      // Prevent raygun from overriding console.log in development
      rg4js?.('disableAutoBreadcrumbsConsole');
    }

    history.listen((location) => {
      const { hash, pathname } = location;
      const cleaned = cleanHash(pathname, hash);
      window.analytics.page(cleaned);

      if (window.Appcues) {
        window.Appcues.page();
      }
    });

    // setup raygun
    if (rg4js && config.raygunID) {
      rg4js('apiKey', config.raygunID);
      rg4js('enableCrashReporting', true);
      rg4js('options', {
        ignore3rdPartyErrors: true,
      });
      // @ts-expect-error typedef appears to be incomplete
      rg4js('setAutoBreadcrumbsXHRIgnoredHosts', [
        'embedwistia-a.akamaihd.net',
        'distillery.wistia.com',
        'pipedream.wistia.com',
        'api.vitally.io',
        'api-js.mixpanel.com',
        'api.mixpanel.com',
        'api.segment.io',
        'cdn.segment.com'
      ]);
      rg4js('onBeforeSend', function (payload: RaygunPayload): RaygunPayload | boolean {
        const headers = get(payload, 'Details.Request.QueryString', {});
        delete headers.Authorization;

        if (!navigator.onLine) {
          return false;
        }

        return payload;
      });
    }
  },
  track: memoizeDebounce((event: string, eventOptions?: object) => {
    analytics._track(event, eventOptions);
  }, 50),
  _track(event: string, eventOptions?: object) {
    if (analytics.initialized && window.analytics) {
      window.analytics.track(event, eventOptions);
    }
    if (window.Appcues) {
      window.Appcues.track(event, eventOptions);
    }
  },
  lastPage: null,
  setOrgInfoCookie() {
    /*
    This function sets a cookie representing the current logged in user at the domain level
    This enables any non-authenticated part of the site to know who the last logged in user was
    */
    const cookieInfo = {
      org: this.organization,
      orgId: this.organizationId,
      orgType: this.organizationType,
      orgSurveys: this.surveyCount,
      orgVolume: this.surveyVolume,
      userEmail: '',
      userFirstName: '',
      userLastName: ''
    };

    if (this.user) {
      cookieInfo.userEmail = this.user.email;
      cookieInfo.userFirstName = this.user.firstName;
      cookieInfo.userLastName = this.user.lastName;
    }
    const cookies = new Cookies();
    cookies.set('currentOrganization', JSON.stringify(cookieInfo), { domain: 'getthematic.com' });
  },
  reset() {
    if (config.posthogID && analytics.initialized) {
      // posthog will error if posthog.reset() is called before posthog.init()
      posthog.reset();
    }
    window.analytics.reset();
    if (window.mixpanel && window.mixpanel.cookie) {
      window.mixpanel.cookie.clear();
    }
  },
  startRecording(includeThematicUsers: boolean) {
    // check if we are a thematic user that shouldn't be included in the recordings
    if (!includeThematicUsers && this.user && this.user.email.endsWith('getthematic.com')) {
      return;
    }
    if (config.posthogID) {
      posthog.startSessionRecording();
    }
  },
  stopRecording() {
    if (config.posthogID) {
      posthog.stopSessionRecording();
    }
  }
};

export function onProfileReady(opts: UserOptions) {
  if (analytics.initialized) {
    analytics.identify(opts, {});

    analytics.track('Login', { category: 'Login' });
  }
}

export function onLogout() {
  analytics.reset();
}

/*
This block is a simple and indicative capture of screen shot events. It is not a comprehensive solution.
Keyup is used because this is the only event that fires on windows print screen.
*/
const capturePrintScreen = (e) => {
  // test for mac
  if ((e.key === 'Shift' && e.metaKey) || (e.key === 'Meta' && e.shiftKey)) {
    analytics.track('System: Print Screen');
  }
  // test for win
  if (e.key === '44') {
    analytics.track('System: Print Screen');
  }
};
document.addEventListener('keyup', capturePrintScreen, false);

export default analytics;
