<template>
  <score-overtime-component
    :values="values"
    :labels="labels"
    :current-period="currentPeriod"
    :can-navigate="canNavigate"
    :error="error"
    :loading="loading"
    :score-name="scoreName"
    :period-resolution="periodResolution"
    :title="config.title"
    :source="source"
    :panel-order="panelOrder"
    :widget-order="widgetOrder"
  />
</template>

<script>
import { get, map, slice } from 'lodash';

import CanNavigateMixin from 'vue/dashboards/Dashboard/MixIns/CanNavigateMixin';

import ScoreOvertimeComponent from './Components/ScoreOvertimeComponent.vue';

export default {
  name: 'SCORE_OVERTIME',
  components: { ScoreOvertimeComponent },
  mixins: [CanNavigateMixin('over-time')],
  props: {
    config: { default: undefined, type: Object },
    context: { type: Object },
    data: { default: undefined, type: Object },
    dateFilter: { default: undefined, type: Object },
    error: { default: undefined, type: String },
    limit: { default: 5, type: Number },
    loading: { default: false, type: Boolean },
    source: { default: undefined, type: String },
    panelOrder: { type: Number },
    widgetOrder: { type: Number },
  },
  // called as a static function from WidgetChooser#widgetType to determine what type to use
  isComparison(config) {
    return config.compareToOverall;
  },
  computed: {
    labels() {
      return map(this.scores, 'label');
    },
    periodResolution() {
      return get(this.data, 'periodResolution');
    },
    scoreConfig() {
      return get(this.data, 'scoreConfig', get(this.config, 'score'));
    },
    scoreName() {
      const compareFilterName = get(this, 'context.compareFilterName', '');
      const scoreConfigName = get(this.config, 'score.name', get(this, 'scoreConfig.name'));
      return compareFilterName ? compareFilterName : scoreConfigName;
    },
    scores() {
      return slice(get(this, 'data.scores', []), 0, 5);
    },
    values() {
      return map(this.scores, 'score.score');
    },
    currentPeriod() {
      return get(this, 'data.currentPeriodName');
    },
  },
};
</script>

<style lang="scss" scoped></style>
