import thematicData from 'vue/libs/thematicData';
import queryBuilder from 'vue/libs/queryBuilder';
import { getBaselineCount } from './fetch-themes.util';
import { mapThemeObject } from 'vue/explore/theme-data-functions';
import { AvailableDateRange, Category, DateOptions, FetchOptions } from './types';
import { getDateLabelAndQuery } from './export-assistant-date.util';

const getDimensions = async (selectedDimension: string, fetchOptions: FetchOptions) => {
  const { commentColumns, requestOptions, selectionQueries } = fetchOptions;

  if (!requestOptions) {
    throw new Error('No request options');
  }

  const selectionsQueryWithoutTheme = queryBuilder.appendToFilter(
    selectionQueries.baseline.query,
    'theme!=empty_string'
  );

  return await thematicData.getStatistics(
    selectionsQueryWithoutTheme,
    commentColumns,
    requestOptions as any,
    selectedDimension,
  );
};

const getDimensionsByDate = async (
  selectedDimension: string,
  category: Category,
  fetchOptions: FetchOptions,
  availableDateRange: AvailableDateRange
) => {
  const { id, dateOptions } = category;

  if (!dateOptions) {
    throw new Error('No date options');
  }

  const timePeriod = dateOptions.timePeriod;
  const repeatFrequency = parseInt(dateOptions.repeatFrequency, 10);

  const dates = Array.from({ length: repeatFrequency }).map((_, index) => {
    return getDateLabelAndQuery(timePeriod, index, id, availableDateRange);
  });

  const fetchAllDimensions = dates.map((date) => {
    if (!date?.query) {
      return;
    }

    const selectionsQueryWithDate = queryBuilder.appendToFilter(
      fetchOptions.selectionQueries.baseline.queryWithoutDate,
      date.query
    );

    const updatedFetchOptions = {
      ...fetchOptions,
      selectionQueries: {
        ...fetchOptions.selectionQueries,
        baseline: {
          ...fetchOptions.selectionQueries.baseline,
          query: selectionsQueryWithDate
        },
      }
    }

    return getDimensions(selectedDimension, updatedFetchOptions);
  });

  const dimensions =  await Promise.all(fetchAllDimensions);
  const dateLabels = dates.map((date) => date?.dateLabel);

  return {
    dateLabels,
    dimensions
  }
};

const getThemes = async (fetchOptions: FetchOptions) => {
  const { commentColumns, requestOptions, selectionQueries, segmentQueryStrings } = fetchOptions;

  if (!requestOptions) {
    throw new Error('No request options');
  }

  const [baseline, comparison] = await Promise.all([
    thematicData.getThemes(
      selectionQueries.baseline.query,
      commentColumns,
      {
        ...requestOptions,
        themesSegmentFilterString: segmentQueryStrings?.baseline || null
      }
    ),
    thematicData.getThemes(
      selectionQueries.comparison.query,
      commentColumns,
      {
        ...requestOptions,
        themesSegmentFilterString: segmentQueryStrings?.comparison || null
      }
    )
  ]);

  const baselineCount = getBaselineCount(baseline.counts);
  const baselineScores = baseline.scoreAllRows;
  const baselineThemes = baseline.themes;
  const comparisonCount = comparison.counts;
  const comparisonScores = comparison.scoreAllRows;
  const comparisonThemes = comparison.themes;

  const themes = mapThemeObject(
    baselineScores,
    comparisonScores,
    requestOptions.scoreType,
    requestOptions?.scoreOptions?.range,
    baselineThemes,
    comparisonThemes,
    baselineCount,
    comparisonCount,
    'difference',
    'volume',
    ''
  );

  return {
    baselineCount,
    themes
  };
}

const getThemesByDate = async (dateOptions: DateOptions, fetchOptions: FetchOptions) => {
  const { commentColumns, requestOptions, selectionQueries } = fetchOptions;

  const requestOptionsWithDateResolution = {
    ...requestOptions,
    dateMaxPast: parseInt(dateOptions?.repeatFrequency || '2', 10),
    dateResolution: dateOptions?.timePeriod,
  };

  if (!requestOptions) {
    throw new Error('Request options not found');
  }

  return await thematicData.getThemesByDate(
    selectionQueries.baseline.queryWithoutDate,
    commentColumns,
    requestOptionsWithDateResolution
  );
};


export { getDimensions, getDimensionsByDate, getThemes, getThemesByDate };
