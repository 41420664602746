import { Modal } from 'components/Shared/Modal';
import * as React from 'react';
import { copyText } from 'lib/clipboard';
import { Button } from 'components/Shared/Button';
import { Checkbox, Input, Loader } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FeatureFlagManager, FlagKeys } from 'lib/feature-flag';
import analytics from 'lib/analytics';
import './sharing-modal.scss';
import EmailLogo from 'images/email-logo.svg';
import SlackLogo from 'images/slack-logo.svg';

interface SharingModalProps {
  isVisible: boolean;
  orgName: string;
  currentSharingLink: string;
  isSharingAllowed: boolean;
  isUpdatingIsSharingAllowed: boolean;
  toggleSharing: () => void;
  onClose: () => void;
  onUnmount?: () => void;
  onOpenEmailModal: () => void;
}

interface SharingItemProps {
  children: React.ReactElement;
  title: string;
  description: string;
  buttonText: string;
  onButtonClick?: () => void;
}

const SharingModal = (props: SharingModalProps) => {
  const [hasClickedCopy, setHasClickedCopy] = React.useState(false);
  const timeoutId = React.useRef<ReturnType<typeof setTimeout>>();

  React.useEffect(() => {
    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
    };
  }, []);

  const {
    onClose,
    onUnmount,
    isVisible,
    orgName,
    currentSharingLink,
    isUpdatingIsSharingAllowed,
    isSharingAllowed
  } = props;

  function onMouseUp() {
    const selectedText = window.getSelection() as Selection;
    const trimmedText = selectedText.toString().trim();
    if (trimmedText.length > 0 && trimmedText === currentSharingLink) {
      analytics.track('Answers: Copy share link', {});
    }
  }

  function onCopyLink() {
    copyText(currentSharingLink)
    analytics.track('Answers: Copy share link', {});
    setHasClickedCopy(true);

    timeoutId.current = setTimeout(() => {
      setHasClickedCopy(false);
    }, 3000);
  }

  function SharingItem({
    children,
    title,
    description,
    buttonText,
    onButtonClick
  }: SharingItemProps) {
    return (
      <div className="sharing-modal__email-slack-item">
        <div className="sharing-modal__email-slack-content">
          {children}
          <div className="sharing-modal__email-slack-text">
            <p className="sharing-modal__email-slack-title">{title}</p>
            <p className="sharing-modal__email-slack-description">{description}</p>
          </div>
        </div>
        <Button
          variant="secondary"
          size="medium"
          onClick={onButtonClick}
        >
          {buttonText}
        </Button>
      </div>
    );
  }

  const canShowSharingLink = isSharingAllowed && !isUpdatingIsSharingAllowed;
  const isPersistingAsShared = isSharingAllowed && isUpdatingIsSharingAllowed;
  const isPersistingAsPrivate = !isSharingAllowed && isUpdatingIsSharingAllowed;
  const canSeeEmailSharing = FeatureFlagManager.checkFlag(FlagKeys.CAN_SEE_EMAIL_SHARING);
  const canSeeSlackSharing = FeatureFlagManager.checkFlag(FlagKeys.CAN_SEE_SLACK_SHARING);

  return (
    <Modal
      open={isVisible}
      isSizeMedium={true}
      onClose={() => onClose?.()}
      onUnmount={onUnmount}
    >
      <div
        className='answers-sharing-modal'
      >
        <header className='sharing-modal__header'>
          Share answer
        </header>
        <div className="sharing-modal__content">
          <section className="sharing-modal__toggle-text-container">
            <div className="sharing-modal__text">
              <p className="sharing-modal__title">Share in {orgName}</p>
              {!isSharingAllowed && <p className="sharing-modal__description">Currently private to you</p>}
              {isSharingAllowed && <p className="sharing-modal__description">Now anyone in {orgName} with this link can view this answer</p>}
            </div>
            <Checkbox
              toggle={true}
              checked={isSharingAllowed}
              onChange={() =>props.toggleSharing()}
            />
          </section>
          {isPersistingAsShared && (
            <div className='sharing-modal__loader'>
              <Loader size="small" inline active/>
              <em>Generating link</em>
            </div>
          )}
          {isPersistingAsPrivate && (
            <div className='sharing-modal__loader'>
              <Loader size="small" inline active/>
              <em>Making it private</em>
            </div>
          )}
          {canShowSharingLink &&
            <section className="sharing-modal__copy-link">
              <Input
                value={currentSharingLink}
                readOnly={true}
                fluid={true}
                onMouseUp={onMouseUp}
              />
              <Button
                variant="primary"
                icon={hasClickedCopy ? <FontAwesomeIcon icon="check"/> : <FontAwesomeIcon icon="copy"/>}
                disabled={hasClickedCopy}
                onClick={() => onCopyLink()}
              >
                {hasClickedCopy ? 'Copied' : 'Copy Link'}
              </Button>
            </section>
          }
          <section className="sharing-modal__email-slack">
            {canSeeEmailSharing &&
              <SharingItem
                title="Send report directly via email"
                description="Send it directly to specific recipients"
                buttonText="Finalize & send"
                onButtonClick={props.onOpenEmailModal}
              >
                <EmailLogo className="email-logo" />
              </SharingItem>
            }
            {canSeeSlackSharing &&
              <SharingItem
                title="Share on slack"
                description="Post it on specific slack channel"
                buttonText="Set and share"
              >
                <SlackLogo className="slack-logo" />
              </SharingItem>
            }
          </section>
        </div>
      </div>
    </Modal>
  );
};

export { SharingModal };
