enum FlagKeys {
  // used to determine who can see 'labs'
  QA = 'QA',

  AGGREGATE_VIEW_ANALYSIS_TOOL = 'aggregate-view-analysis-tool',
  CAN_ASSUME_USER = 'can-assume-user',
  CAN_REFRESH_ANSWER = 'can-refresh-answer',
  CAN_SEE_THEMES_SUMMARIZATION = 'can-see-themes-summarization',
  CAN_SEE_DATASET_SUMMARIZATION = 'can-see-dataset-summarization',
  CAN_SEE_SCORE_CHANGE_SUMMARIZATION = 'can-see-score-change-summarization',
  CAN_SEE_SUMMARIZE_CATEGORIES = 'summarize-categories',
  CAN_SEE_SUMMARIES_ON_DASHBOARD = 'can-see-summaries-on-dashboard',
  CAN_USE_PROTOTYPE_EXPORT = 'prototype-export',
  CAN_SEE_QUICK_PHRASE_SELECTION = 'quick-phrase-selection',
  USE_CLAUDE_SUMMARIZER = 'use-claude-summarizer',
  USE_CLUSTERING_TEXT_TYPE = 'use-cluster-text-type',
  USE_REACT_DASHBOARD_COMMENT_COMPONENT = 'use-react-dashboard-comment-component',
  USE_PARALLEL_SENTENCE_REQUESTS = 'parallel-sentence-requests',
  CAN_SEE_EMAIL_SHARING = 'can-see-email-sharing',
  CAN_SEE_SLACK_SHARING = 'can-see-slack-sharing',
  CAN_CLICK_DRAWER_THEMES = 'can-click-drawer-themes',
  CAN_SEE_SEMANTIC_POPUP_FILTERS = 'can-see-semantic-popup-filters',
  CAN_SEE_PINNED_QUOTES = 'can-see-pinned-quotes',

  // permissions
  CAN_SEE_DEV_ONLY_TOOLS = 'can-see-dev-only-tools',
  CAN_SEE_THEME_DISCOVERY_WORKFLOW = 'can-see-theme-discovery-workflow',
  CAN_SEE_BETA_CLUSTERING = 'beta-clustering',


  // posthog implemented flags

}

export default FlagKeys;
