import { render, staticRenderFns } from "./OverviewGroup.vue?vue&type=template&id=46ec16ac"
import script from "./OverviewGroup.vue?vue&type=script&lang=ts"
export * from "./OverviewGroup.vue?vue&type=script&lang=ts"
import style0 from "./OverviewGroup.vue?vue&type=style&index=0&id=46ec16ac&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports