<template>
  <widget-wrapper
    :title="config.title"
    :loading="loading"
    :error="error"
    :warning="warning"
  >
    <div
      slot="subtitle"
      :class="{ 'widget-subtitle-clickable': canNavigate }"
      class="widget-subtitle"
      @click="doNavigate()"
    >
      <div class="widget-subtitle-text">
        <span class="key-text">
          {{ widgetSubtitle }}
          <font-awesome-icon
            v-if="canNavigate"
            class="link-icon"
            icon="chart-bar"
          />
        </span>
      </div>
      <div class="widget-display-categories">
        Volume
      </div>
    </div>
    <div
      :class="{ empty: empty }"
      class="themes-body widget-body"
    >
      <div class="legend">
        <svg
          class="key-square"
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="7"
            cy="7"
            r="7"
            width="14"
            height="14"
            :fill="colors.primary500"
          />
        </svg>
        <span class="key-text">{{ baselineName }}</span>
        <svg
          class="key-square"
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="7"
            cy="7"
            r="7"
            width="14"
            height="14"
            :fill="colors.orange500"
          />
        </svg>
        <span class="key-text">{{ comparisonName }}</span>
      </div>
      <div
        v-for="(theme, index) in changedThemes"
        :key="index + theme.name"
        class="theme-row"
      >
        <div
          :class="{ 'clickable-theme': canClickTheme }"
          class="theme-name"
          @click="doNavigate({code: theme.basethemeCode, title: theme.basetheme},
                             theme.subtheme ? {code: theme.subthemeCode, title: theme.subtheme} : undefined)"
        >
          {{ theme.name }}
        </div>
        <bar-difference-normalized
          :baseline-width="theme.volumeBaselineNormalized"
          :comparison-width="theme.volumeComparisonNormalized"
          :baseline="theme.volumeBaseline"
          :comparison="theme.volumeComparison"
          suffix="%"
          dp="0"
        />
      </div>
      <div
        v-if="empty"
        class="empty-change-list"
      >
        No changes found
      </div>
    </div>
    <summary-component
      v-if="isSummaryEnabled"
      :filters="filters"
      :theme="selectedTheme"
      :source="source"
      :comparison-themes="comparisonThemes"
      :can-navigate="canNavigate"
      @doNavigate="
        () =>
          doNavigate(
            selectedTheme.theme,
            selectedTheme.subtheme,
            selectedTheme.volumeBy,
            true
          )
      "
    />
    <comment-component
      v-if="isCommentsEnabled"
      :filter-string="filters ? filters.baseline : undefined"
      :theme="selectedTheme"
      :source="source"
      :can-navigate="canNavigate"
      @doNavigate="
        () =>
          doNavigate(
            selectedTheme.theme,
            selectedTheme.subtheme,
            selectedTheme.volumeBy,
            true
          )
      "
    />
  </widget-wrapper>
</template>

<script>
import { ReactInVue } from 'vuera';
import { get, slice } from 'lodash';

import { CommentComponent as ReactCommentComponent } from 'components/Dashboard/Widgets/CommentComponent';
import SummaryComponent from './Components/SummaryComponent';
import BarDifferenceNormalized from './Components/BarDifferenceNormalized.vue';

import CanNavigateMixin from 'vue/dashboards/Dashboard/MixIns/CanNavigateMixin';
import SentimentMixin from 'vue/dashboards/Dashboard/MixIns/SentimentMixin';

import WidgetWrapper from './WidgetWrapper';
import colors from 'vue/styles/element-variables.scss';

export default {
  name: 'THEMES_COMPARE_TWO',
  components: {
    BarDifferenceNormalized,
    WidgetWrapper,
    SummaryComponent,
    CommentComponent: ReactInVue(ReactCommentComponent),
  },
  mixins: [CanNavigateMixin('explore'), SentimentMixin],
  props: {
    config: { default: undefined, type: Object },
    filters: { default: undefined, type: Object },
    context: { default: undefined, type: Object },
    data: { default: undefined, type: Object },
    warning: { default: undefined, type: String },
    error: { default: undefined, type: String },
    limit: { default: 5, type: Number },
    loading: { default: false, type: Boolean },
    source: { default: undefined, type: String },
  },
  data() {
    return {
      colors: colors,
    };
  },
  computed: {
    comparisonThemes() {
      return get(this, 'data.comparison');
    },
    baselineName() {
      return get(this, 'data.baseline.name');
    },
    changedThemes() {
      const themes = get(this, 'data.themes', []);
      return slice(themes, 0, this.limit);
    },
    comparisonName() {
      return get(this, 'data.comparison.name');
    },
    empty() {
      return this.changedThemes.length === 0;
    },
  },
  watch: {
    config: {
      handler (config) {
        this.isCommentsEnabled = config.showComments;
        this.isSummaryEnabled = config.showSummary;
      },
      immediate: true
    },
  },
};
</script>


<style lang="scss" scoped>
.theme-row {
  align-content: center;
  display: grid;
  grid:
    'name-area bar-area' 32px
    / 150px auto;
  grid-gap: 0 10px;
}
.widget.dashboard-widget .widget-subtitle-clickable.widget-subtitle {
  &:hover {
    .link-icon {
      width: 12px;
    }
  }
  .link-icon {
    width: 0;
    transition-property: width, opacity;
  }
}

.key-square {
  height: 17px;
  display: inline-flex;
  margin-right: 5px;
  width: 17px;
}
.key-text {
  position: relative;
  margin-right: 10px;
}

.percentage-bar {
  grid-area: bar-area;
}
</style>
