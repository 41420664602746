import * as React from 'react';

const OnboardingBeacon = ({ className, delay = 0 }) => {
  const [identifier, setIdentifier] = React.useState('');

  React.useEffect(() => {
    setTimeout(() => {
      setIdentifier(className);
    }, delay);
  }, []);

  return <div style={{ visibility: 'hidden' }} className={identifier} />;
};

export default OnboardingBeacon;
