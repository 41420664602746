<template>
  <div
    :class="{narrow: narrow}"
    class="percentage-bar"
  >
    <div
      :class="{ negative: isNegative, 'right-bar': isNegative && hastLastItem , 'left-bar': !isNegative && hastLastItem, 'force-height': lastItem}"
      class="percentage-bar-container"
    >
      <svg
        class="percentage-bar-indicator"
        preserveAspectRatio="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <linearGradient id="overall">
            <stop
              offset="0%"
              :stop-color="colors.primary400"
            />
            <stop
              offset="100%"
              :stop-color="colors.primary300"
            />
          </linearGradient>
          <linearGradient id="negative-overall">
            <stop
              offset="0%"
              :stop-color="colors.primary300"
            />
            <stop
              offset="100%"
              :stop-color="colors.primary400"
            />
          </linearGradient>
        </defs>

        <rect
          :x="x"
          :width="width + '%'"
          :fill="`url(#${isNegative ? 'negative-' : ''}overall)`"
          y="0"
          rx="0"
          ry="0"
          :height="narrow ? 10 : 20"
        />
      </svg>
    </div>
    <div class="bar-label">
      <span class="value-label">{{ valueLabel }}{{ suffixLabel }}</span>
      <span
        v-if="displayPercentage"
        class="percentage-label"
      > {{ percent }}</span>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import toFixed from 'vue/libs/to-fixed';
import colors from 'vue/styles/element-variables.scss';
export default {
  name: 'Bar',
  props: {
    value: { default: 0, type: Number },
    maxValue: { default: 0, type: Number },
    dp: { default: 1, type: Number },
    narrow: { default: false, type: Boolean },
    displayPercentage: { default: 0, type: Number },
    range: { default: () => undefined, type: Array },
    suffix: { default: undefined, type: String },
    lastItem: { default: false, type: Boolean }
  },
  data() {
    return {
      colors: colors,
      suffixLabel: ''
    };
  },
  computed: {
    valueLabel() {
      const { precision, proportion } = this;

      let value;
      if (precision == 0) {
        value = toFixed(Math.round(proportion), 0);
      } else {
        value = toFixed(proportion, precision);
      }
      return value;
    },
    percent() {
      const { displayPercentage, precision } = this;
      if (!displayPercentage) {
        return '';
      }
      if (precision == 0) {
        return toFixed(Math.round(displayPercentage), 0) + '%';
      }
      return toFixed(displayPercentage, precision) + '%';
    },
    width() {
      const { maxValue, proportion, range } = this;

      // also need to scale maxValue
      let max;
      if (range) {
        const [low, high] = range;
        max = maxValue / (high - low) * 100.0;
      } else {
        max = maxValue;
      }
      return Math.abs(0.9 * (proportion / max * 100.0));
    },
    hastLastItem() {
      return _.isBoolean(this.lastItem);
    },
    isNegative() {
      return this.value < 0;
    },
    precision() {
      return parseInt(this.dp, 10) >= 0 ? Number(this.dp) : 1;
    },
    proportion() {
      if (this.range) {
        const [low, high] = this.range;
        return this.value / (high - low) * 100.0;
      } else {
        return this.value;
      }
    },
    x() {
      if (this.value > 0) {
        return 0;
      } else {
        return 100 - this.width + '%';
      }
    }
  },
  mounted() {
    this.suffixLabel = this.suffix;
    if (this.range && !this.suffix) {
      this.suffixLabel = '%';
    }
  }
};
</script>


<style lang="scss" scoped>
.percentage-bar {
  display: grid;
  grid:
    'bar-inner bar-percent' 20px
    / 1fr auto;
}
.percentage-bar-indicator {
  height: 20px;
  display: block;
  width: 100%;
  grid-area: bar-inner;
}
.left-bar {
  height: 40px;
}
.force-height {
  height: 20px !important;
}
.right-bar {
  height: 40px;
}
.bar-label {
  display: inline-flex;
  grid-area: bar-percent;
  line-height: 20px;
  text-align: right;
  .value-label,
  .percentage-label {
    display: inline-block;
    font-size: 12px;
    width: 60px;
  }
}
.narrow {
  .percentage-bar-container, .percentage-bar-container {
    padding-top: 5px;
    height: 10px;
  }

  .bar-label .value-label,
  .bar-label .percentage-label {
    width: 40px;
  }
}
</style>
