<template>
  <div class="selection-score-breakdown-container">
    <div
      v-if="showFadeLeft"
      ref="fadeLeft"
      :style="fadeStyle"
      class="selection-score-breakdown-container__fade-left"
    />
    <div
      v-if="showFadeRight"
      ref="fadeRight"
      :style="fadeStyle"
      class="selection-score-breakdown-container__fade-right"
    />
    <selector-wrapper
      ref="scoreBreakdown"
      class="selection-score-breakdown-widget"
      :loading="loading"
      :error="error"
      :warning="warning"
    >
      <div
        v-if="activeDashboardUIStore.isEditing"
        class="settings"
      >
        <settings-menu
          :show-delete="true"
          @onDelete="deleteSelectionWidget()"
        />
      </div>
      <div
        class="selection-score-breakdown"
        :style="breakdownStyle"
      >
        <div
          v-if="widgetData"
          class="selection-score-breakdown__details"
        >
          <ul>
            <li>
              <score-breakdown-headline
                v-if="headline"
                v-bind="headline"
                :label="scoreLabel"
                :compare-filter="compareFilter"
                @clearSelection="clearSelection"
              />
              <div class="selection-score-breakdown__notch" />
              <ul class="selection-score-breakdown__hierarchy">
                <li
                  v-for="filter in filters"
                  :key="filter.name"
                >
                  <score-breakdown
                    class="selection-score-breakdown__hierarchy-item"
                    v-bind="filter"
                    :compare-filter="compareFilter"
                    :filter-id="widgetData.id"
                    :filter-name="widgetData.name"
                    :total-filters="totalFilters"
                    @selectionChanged="selectionChanged"
                  />
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div
          v-if="loading && !widgetData"
          class="dashboard-widget empty-score-rank"
        >
          Loading&hellip;
        </div>
      </div>
    </selector-wrapper>
  </div>
</template>

<script>
import { find, get, reject, map } from 'lodash';
import SelectorWrapper from './SelectorWrapper.vue';
import WidgetDataMixin from 'vue/components/mixins/WidgetDataMixin';
import queryBuilder from 'vue/libs/queryBuilder';
import analytics from 'lib/analytics';
import { asAverage, asNps, asThreshold } from 'vue/libs/score-to-segments';
import ScoreBreakdownHeadline from './Components/ScoreBreakdownHeadline.vue';
import ScoreBreakdown from './Components/ScoreBreakdown.vue';
import { matchesFilter } from 'vue/libs/filter-watcher';
import { getActiveDashboardUIStore } from 'stores/RootStore';
import SettingsMenu from 'vue/dashboards/DashboardEditable/SettingsMenu.vue';

export default {
  name: 'SelectionScoreBreakdown',
  components: {
    ScoreBreakdown,
    ScoreBreakdownHeadline,
    SelectorWrapper,
    SettingsMenu,
  },
  mixins: [WidgetDataMixin],
  props: {
    compareFilter: { type: String },
    compareFilterName: { type: String },
    source: { type: String },
  },
  data() {
    return {
      ignoreCompare: true,
      showFadeRight: false,
      showFadeLeft: false,
      widgetDataUpdated: false,
      activeDashboardUIStore: getActiveDashboardUIStore(),
    };
  },
  computed: {
    filters() {
      return reject(this.cuts, { id: 'all_' });
    },
    filterId() {
      return get(this, 'widgetData.id');
    },
    filterName() {
      return get(this, 'widgetData.name');
    },
    headline() {
      return find(this.cuts, { id: 'all_' });
    },
    breakdownStyle() {
      return {
        '--totalFilters': `${this.totalFilters}`,
      };
    },
    fadeStyle() {
      const height = this.scoreBreakdownElement ? this.scoreBreakdownElement.offsetHeight : 0;
      return {
        '--height': `${height}px`,
      };
    },
    totalFilters() {
      return this.filters.length;
    },
    scoreBreakdownElement() {
      return this.$refs.scoreBreakdown ? this.$refs.scoreBreakdown.$el : undefined;
    },
    scoreConfig() {
      return get(this.widgetData, 'scoreConfig', get(this.activeDashboardUIStore.selectionWidget, 'score'));
    },
    scoreLabel() {
      return (
        get(this.activeDashboardUIStore, 'selectionWidget.score.name') ||
        get(this, 'scoreConfig.name') ||
        get(this, 'scoreConfig.type') ||
        'Score'
      );
    },
    cuts() {
      const defaultScore = get(this, 'widgetData.score.score');
      const scoreConfig = this.scoreConfig;

      if (scoreConfig.type === 'average') {
        const low = get(scoreConfig, 'options.range.0', 0);
        const high = get(scoreConfig, 'options.range.1', 100);
        let segmentNames = get(scoreConfig, 'options.range_thresholds');
        segmentNames = map(segmentNames, (s) => get(s, 'name'));
        return asAverage(this.widgetData, defaultScore, low, high, segmentNames);
      } else if (scoreConfig.type === 'threshold') {
        return asThreshold(this.widgetData, defaultScore);
      } else {
        return asNps(this.widgetData, defaultScore);
      }
    },
  },
  watch: {
    $route(to) {
      if (!to || !this.filterId) {
        return;
      }
      this.matchRoute(to);
    },
    filterId(filterId) {
      if (filterId) {
        this.matchRoute(this.$route);
      }
    },
    widgetData() {
      if (this.widgetData) {
        this.widgetDataUpdated = true;
      }
    },
  },
  updated() {
    // This makes sure the div is scrolled into view only when widget data changes
    if (this.widgetDataUpdated) {
      this.scrollIntoView();
      this.widgetDataUpdated = false;
    }
  },
  mounted() {
    const unwatch = this.$watch('widgetData', (data) => {
      if (data) {
        this.$emit('onReady');
        this.scoreBreakdownElement.addEventListener('scroll', this.handleScroll);
        unwatch();
      }
    });
  },
  destroyed() {
    if (this.scoreBreakdownElement) {
      this.scoreBreakdownElement.removeEventListener('scroll', this.handleScroll);
    }
  },
  methods: {
    scrollIntoView() {
      const { scrollWidth, clientWidth } = this.scoreBreakdownElement;
      const scrollBy = (scrollWidth - clientWidth) / 2;
      this.scoreBreakdownElement.scrollLeft = scrollBy;
    },
    handleScroll() {
      this.showFadeLeft = false;
      this.showFadeRight = false;

      const { scrollLeft, scrollWidth, clientWidth } = this.scoreBreakdownElement;
      const maxScrollBy = scrollWidth - clientWidth;

      if (scrollLeft > 0) {
        this.showFadeLeft = true;
      }
      if (scrollLeft < maxScrollBy) {
        this.showFadeRight = true;
      }
    },
    matchRoute(to) {
      const { filterId } = this;
      const selected = matchesFilter(to.query.compare, filterId);
      if (selected) {
        this.setFromIds(selected);
      }
    },
    setFromIds(ids) {
      const [id] = ids;
      const { cuts } = this;
      const cut = find(cuts, { id });
      if (cut) {
        const { id, label, rql } = cut;
        const { filterId, filterName } = this;

        // fake clicking on the row
        const queryFilter = {
          filterId,
          filterName,
          selected: [{ id, label, name: label, rql }],
        };
        this.selectionChanged(queryFilter, label);
      } else {
        this.clearSelection();
      }
    },
    clearSelection() {
      const { filterId, filterName } = this;
      this.selectionChanged({
        filterId,
        filterName,
        selected: [],
      });
    },
    selectionChanged(queryFilter, title) {
      const filter = { compareTo: queryFilter };
      const compareRql = queryBuilder.build(filter);
      const compareParam = queryBuilder.getSelectedIds(filter);
      this.$emit('updateCompareTo', compareRql, title, compareParam);

      analytics.track('Dashboard: Update CompareTo', {
        category: 'Dashboard',
        type: 'SCORE_BREAKDOWN',
        compareRql,
      });
    },
    deleteSelectionWidget() {
      this.activeDashboardUIStore.deleteSelectionWidget();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../styles/element-variables.scss';
.selection-score-breakdown-container__fade-left {
  position: absolute;
  border-radius: 4px;
  background-image: linear-gradient(to left, rgba(255, 255, 255, 0), white 85%);
  z-index: var(--z-index-dashboard-widgets);
  width: 200px;
  left: 30px;
  height: var(--height);
}
.selection-score-breakdown-container__fade-right {
  position: absolute;
  border-radius: 4px;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0), white 85%);
  z-index: var(--z-index-dashboard-widgets);
  width: 200px;
  right: 30px;
  height: var(--height);
}

.selection-score-breakdown-widget {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  overflow-x: scroll;
  -ms-overflow-style: none;
  margin: 1.4em auto;
  &::-webkit-scrollbar {
    display: none;
  }
  .settings {
    display: flex;
    justify-content: flex-end;
    padding: 8px 20px;

    .settings-dropdown.el-dropdown {
      padding: 0;
    }
  }
}
.selection-score-breakdown {
  background-color: $--color-white;
  padding: 10px 0;
  min-width: calc(230px * (var(--totalFilters)));
  position: relative;

  .empty-score-rank {
    margin: 20px;
    padding: 25px 5px;
    text-align: center;
  }

  ul {
    padding: 0;
  }

  .selection-score-breakdown__details {
    position: fixed;
    display: contents;

    // Top notch styling
    .selection-score-breakdown__notch {
      &:after {
        outline: 1px solid $--neutral-200;
        content: '';
        position: absolute;
        height: 1.3em;
        left: 50%;
        margin-top: -1.3rem;
      }
    }
  }

  // Grid and hierarchy lines styling
  .selection-score-breakdown__hierarchy {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-gap: 5px;
    padding: 0;

    li:first-child:before {
      outline: 1px solid $--neutral-200;
      content: '';
      position: absolute;
      width: calc(100% - (100% / (var(--totalFilters))));
      left: calc((100% / (var(--totalFilters) * 2)));
    }

    .selection-score-breakdown__hierarchy-item:before {
      outline: 1px solid $--neutral-200;
      content: '';
      position: absolute;
      height: 1.4em;
      margin-top: -20px;
    }
  }
}
</style>
