<template>
  <impact-component
    :count="count"
    :error="error"
    :loading="loading"
    :max-value="maxValue"
    :range="range"
    :score-name="scoreName"
    :sort="sort"
    :filters="filters"
    :title="config.title"
    :themes="themes"
    :source="source"
    :show-comments="config.showComments"
    :show-summary="config.showSummary"
    :theme-sources="themeSources"
    :is-weighted="isWeighted"
    :panel-order="panelOrder"
    :widget-order="widgetOrder"
  />
</template>

<script>
import { filter, get, map, max, slice } from 'lodash';

import ImpactComponent from './Components/ImpactComponent.vue';

export default {
  name: 'IMPACT_SIMPLE',
  components: { ImpactComponent },
  props: {
    config: { default: undefined, type: Object },
    filters: { default: undefined, type: Object },
    error: { default: undefined, type: String },
    data: { default: undefined, type: Object },
    limit: { default: 5, type: Number },
    loading: { default: false, type: Boolean },
    source: { default: undefined, type: String },
    isWeighted: { default: false, type: Boolean },
    panelOrder: { type: Number },
    widgetOrder: { type: Number }
  },
  // called as a static function from WidgetChooser#widgetType to determine what type to use
  isComparison(config) {
    return config.compareToOverall;
  },
  computed: {
    count() {
      return get(this, 'data.count', 0);
    },
    maxValue() {
      const impacts = map(this.themes, theme => Math.abs(theme.impact));
      return max(impacts) || 0;
    },
    themeSources() {
      return get(this, 'data.metadata.sources', undefined);
    },
    scoreConfig() {
      return get(this.data, 'scoreConfig', get(this.config, 'score'));
    },
    scoreName() {
      return get(this.config, 'score.name', get(this, 'scoreConfig.name'));
    },
    scoreType() {
      return get(this, 'scoreConfig.type', 'nps');
    },
    sort() {
      return get(this, 'config.sort', 'desc');
    },
    themes() {
      const asc = this.sort === 'asc';
      const themes = filter(get(this, 'data.themes', []), theme => {
        const impact = this.comparisonThemes
          ? theme.impactBaseline
          : theme.impact;
        if (asc) {
          return impact <= 0;
        } else {
          return impact >= 0;
        }
      });
      return slice(themes, 0, this.limit);
    },
    range() {
      return get(this, 'scoreConfig.options.range');
    }
  }
};
</script>

<style lang="scss" scoped></style>
