import * as React from 'react';
import { AnalysisFilter } from 'api/interfaces';
import { Select } from 'semantic-ui-react';
import { Category } from './types';

interface Props {
  enabledIDs: string[];
  filters: AnalysisFilter[];
  category: Category | null;
  setCategory: (category: Category) => void;
}

export const ExportAssistantCategorySelect = ({ enabledIDs, filters, category, setCategory }: Props) => {
  const handleChange = (filterId: string) => {
    const selectedFilter = filters.find(({ id }) => id === filterId);

    if (!selectedFilter) {
      setCategory({
        id: 'none',
        name: 'None',
        columns: [],
        type: 'none',
      });

      return;
    }

    setCategory({
      id: selectedFilter.id,
      name: selectedFilter.name,
      columns: [],
      type: selectedFilter.type || selectedFilter.id,
    });
  }

  const enabledOptions = filters
    .filter(({ id }) => enabledIDs.includes(id))
    .map(({ id, name }) => ({
      key: id,
      value: id,
      text: name,
    }));

  const filterOptions = [
    {
      key: 'none',
      value: 'none',
      text: 'None',
    },
    ...enabledOptions,
  ];

  return (
    <Select
      onChange={(_, data) => handleChange(data.value as string)}
      options={filterOptions}
      placeholder='None'
      value={category?.id || 'none'}
    />
  );
};
