import {
  difference,
  filter,
  forEach,
  has,
  includes,
  intersection,
  isArray,
  keys,
  map,
  reduce,
  startsWith,
  toPairs,
  values,
  without
} from 'lodash';
import { ThemesJson, TitleJson, isGroupKey } from 'lib/theme-file-parser';

export enum LookupResults {
  BASETHEME = '__basetheme__',
  DELETED = '__deleted__',
  DUPLICATE_SUBTHEME = '__subtheme_is_in_two_basethemes__',
  MERGED = '__merged__',
  MERGE_CHAIN = '__merge_chain__',
  MERGE_MISS = '__merge_miss__',
  MERGED_SUBTHEME = '__merged_subtheme__',
  NO_BASETHEME = '__no_basetheme__',
  SUBTHEME_NO_ENTRY = '__subtheme_no_entry__',
  SUBTHEME_IS_BASETHEME = '__subtheme_is_basetheme__',
}

export interface ThemeLookup {
  [groupid: string]: {
    [themeId: string]: string
  };
}

export function validate(json?: ThemesJson, lite?: boolean) {
  const errors = [] as string[];
  const lookups = {} as ThemeLookup;

  if (!json) {
    return { errors: ['Missing themes file.'], lookups };
  }

  if (!lite) {
    errors.push(...validateColumnGrouping(json));
    errors.push(...validateMap(json));
    errors.push(...validateTitles(json));
  }

  forEach(json, (group: object, key: string) => {
    if (isGroupKey(key)) {
      const lookup = buildLookup(group);
      errors.push(...validateGroup(group, lookup));

      if (!lite) {
        errors.push(...validateGroupTitles(group, json.titles));
      }

      lookups[key] = lookup;
    }
  });

  return { errors, lookups };
}

function getGroup(json: ThemesJson, key: string) {
  if (isGroupKey(key) && json[key]) {
    return json[key];
  }
  return undefined;
}
export function repair(
  json: ThemesJson | undefined,
  lookups: ThemeLookup
) {
  if (!json) {
    return undefined;
  }
  forEach(lookups, (lookup, groupId) => {
    const pairs = toPairs(lookup);
    forEach(pairs, ([id, result]) => {
      if (result === LookupResults.NO_BASETHEME) {
        // implement
      } else if (result === LookupResults.DUPLICATE_SUBTHEME) {
        // implement
      } else if (startsWith(result, LookupResults.MERGE_CHAIN)) {
        const [, themeId] = result.split('::');
        const [, targetMergeId] = (lookup[themeId] || '').split('::');
        const group = getGroup(json, groupId);

        if (group) {
          group[id].merged = targetMergeId;
        }
        // implement
      } else if (startsWith(result, LookupResults.MERGE_MISS)) {
        const [, themeId] = result.split('::');
        const group = getGroup(json, groupId);
        if (group) {
          group[themeId] = { freq: 0, sub_themes: [] };
        }
      } else if (startsWith(result, LookupResults.MERGED_SUBTHEME)) {
        const [, , themeId] = result.split('::');
        const group = getGroup(json, groupId);
        if (group && group[themeId]) {
          const basetheme = group[themeId];
          basetheme.sub_themes = without(basetheme.sub_themes, id);
        }
      } else if (startsWith(result, LookupResults.SUBTHEME_NO_ENTRY)) {
        const group = getGroup(json, groupId);
        if (group) {
          group[id] = { freq: 0 };
        }
      } else if (startsWith(result, LookupResults.SUBTHEME_IS_BASETHEME)) {
        const [, themeId] = result.split('::');
        const group = getGroup(json, groupId);
        if (group && group[themeId]) {
          const basetheme = group[themeId];
          basetheme.sub_themes = without(basetheme.sub_themes, id);
        }
      }
    });
  });
  return json;
}

export function validateGroup(group: object, lookup: object) {
  const errors = [] as string[];
  const pairs = toPairs(lookup);
  forEach(pairs, pair => {
    const [id, result] = pair;
    if (result === LookupResults.NO_BASETHEME) {
      errors.push(`"${id}" is a subtheme with no base theme`);
    } else if (result ===  LookupResults.DUPLICATE_SUBTHEME) {
      errors.push(`"${id}" is a subtheme duplicated into two basethemes`);
    } else if (startsWith(result, LookupResults.MERGE_CHAIN)) {
      const [, themeId] = result.split('::');
      const [, targetMergeId] = (lookup[themeId] || '').split('::');
      errors.push(
        `"${id}" is merged into "${themeId}" but this is itself merged into "${targetMergeId}"`
      );
    } else if (startsWith(result, LookupResults.MERGE_MISS)) {
      const [, themeId] = result.split('::');
      errors.push(
        `"${id}" is merged into "${themeId}" but this doesn't have an entry`
      );
    } else if (startsWith(result, LookupResults.MERGED_SUBTHEME)) {
      const [, mergeTarget, subthemeTarget] = result.split('::');
      errors.push(
        `"${id}" is merged into "${mergeTarget}" but is also a subtheme of "${subthemeTarget}"`
      );
    } else if (startsWith(result, LookupResults.SUBTHEME_NO_ENTRY)) {
      const [, themeId] = result.split('::');
      errors.push(
        `"${id}" is referenced as a subtheme of "${themeId}" but doesn't have its own entry`
      );
    } else if (startsWith(result, LookupResults.SUBTHEME_IS_BASETHEME)) {
      const [, themeId] = result.split('::');
      errors.push(
        `"${id}" is a base theme but is also a subtheme of "${themeId}"`
      );
    }
  });
  return errors;
}

export function validateGroupTitles(group: object, titles: TitleJson) {
  return reduce(
    group,
    (errors, theme, themeId) => {
      if (!has(titles, themeId)) {
        errors.push(`"${themeId}" does not have a title`);
      }
      return errors;
    },
    [] as string[]
  );
}

export function validateColumnGrouping(json: ThemesJson) {
  const errors: string[] = [];
  const groupings = json['column_groupings'];
  if (groupings) {
    const groups = values(groupings);
    forEach(groups, num => {
      const key = `themes${num}`;
      if (!json[key]) {
        errors.push(`"${key}" referenced in column grouping but is missing`);
      }
    });
  }
  return errors;
}

export function validateMap(json: ThemesJson) {
  const codes = values(json.map);
  const groupCodes = reduce(
    json,
    (result, group, key) => {
      if (isGroupKey(key)) {
        result.push(...keys(group));
      }
      return result;
    },
    [] as string[]
  );

  const missing = difference(codes, groupCodes);
  return map(missing, m => `"${m}" is in map but not in a themes group`);
}
export function validateTitles(json: ThemesJson) {
  const codes = keys(json.titles);
  const groupCodes = reduce(
    json,
    (result, group, key) => {
      if (isGroupKey(key)) {
        result.push(...keys(group));
      }
      return result;
    },
    [] as string[]
  );

  const missing = difference(codes, groupCodes);
  return map(missing, m => `"${m}" is in titles but not in a themes group`);
}

export function buildLookup(group: object) {
  /*
  This function builds up a set of look up mechanisms that help to identify issues in the file
  */
  const result = {} as { [themeId: string]: string };
  const pairs = toPairs(group);
  const themeLookups = {} as { [theme: string]: boolean };
  const mergedKeys = [] as string[];
  const deletedKeys = [] as string[];
  const subthemeLookups = {} as { [subtheme: string]: string };
  const mergedLookups = {} as { [theme: string]: string };
  const referencedSubthemes = [] as string[];
  const subthemeKeys = [] as string[];
  forEach(pairs, pair => {
    const [key, theme] = pair;
    let handled = false;
    if (isArray(theme.sub_themes)) {
      themeLookups[key] = true;
      result[key] = LookupResults.BASETHEME;
      forEach(theme.sub_themes, subtheme => {
        subthemeLookups[subtheme] = key;
        referencedSubthemes.push(subtheme);
      });
      handled = true;
    }
    if (theme.merged) {
      mergedKeys.push(key);
      mergedLookups[key] = theme.merged;
      result[key] = `${LookupResults.MERGED}::${theme.merged}`;
      handled = true;
    }
    if (theme.deleted) {
      deletedKeys.push(key);
      result[key] = LookupResults.DELETED;
      handled = true;
    }
    if (!handled) {
      subthemeKeys.push(key);
    }
  });

  // find unreferenced subthemes by eliminating all referenced keys from subtheme keys
  const unreferenced = difference(subthemeKeys, referencedSubthemes);
  forEach(unreferenced, key => {
    result[key] = LookupResults.NO_BASETHEME;
  });

  // find double up subthemes
  const duplicates = filter(referencedSubthemes, (val, i, iteratee) => includes(iteratee, val, i + 1));
  forEach(duplicates, key => {
    // delete subthemeLookups[key];
    result[key] = LookupResults.DUPLICATE_SUBTHEME;
  });

  // find danging subtheme keys
  const dangling = difference(referencedSubthemes, subthemeKeys);
  forEach(dangling, key => {
    // it might be a basetheme
    let msg;
    const lookupKey = subthemeLookups[key];
    if (themeLookups[lookupKey] && themeLookups[key]) {
      msg = `${LookupResults.SUBTHEME_IS_BASETHEME}::${lookupKey}`;
    } else {
      msg = `${LookupResults.SUBTHEME_NO_ENTRY}::${lookupKey}`;
    }
    result[key] = msg;
  });

  // find merge chains, merge misses & rewrite the result
  forEach(mergedLookups, (mergeTarget, themeId) => {
    if (mergedLookups[mergeTarget]) {
      result[themeId] = `${LookupResults.MERGE_CHAIN}::${mergeTarget}`;
    } else if (!group[mergeTarget]) {
      result[themeId] = `${LookupResults.MERGE_MISS}::${mergeTarget}`;
    } else if (subthemeLookups[themeId]) {
      const subthemeTarget = subthemeLookups[themeId];
      result[themeId] = `${
        LookupResults.MERGED_SUBTHEME
      }::${mergeTarget}::${subthemeTarget}`;
    }
  });

  // populate referenced keys
  const referenced = intersection(
    difference( difference(referencedSubthemes, dangling), duplicates),
    subthemeKeys
  );
  forEach(referenced, key => {
    result[key] = subthemeLookups[key];
  });

  return result;
}
