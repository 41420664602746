<template>
  <widget-wrapper
    :loading="loading"
    :error="error && error.message"
    :warning="warning"
  >
    <div
      slot="subtitle"
      class="widget-subtitle"
    >
      <div class="widget-subtitle-text">
        {{ byVolume ? 'Volume of ' : '' }}
        {{ config.title || 'Comparison Over Time' }}
      </div>
    </div>
    <div
      :class="{ empty: empty }"
      class="score-graph-container widget-body"
    >
      <div class="legend">
        <div
          v-for="(filter, i) in config.filterGroups"
          :key="i"
          :class="`legend-item legend-item-${i+1}`"
        >
          {{ filter.name }}
        </div>
      </div>
      <multi-line-graph
        v-if="!empty"
        :values="values"
        :labels="labels"
        :by-volume="byVolume"
        class="multi-line-graph"
      />

      <div v-else>
        No data found
      </div>
    </div>
  </widget-wrapper>
</template>

<script>
import _ from 'lodash';

import { watchAll } from 'vue/libs/vueExt';

import restrictionMixin from './Utils/restrictionMixin.js';
import WidgetWrapper from './WidgetWrapper';
import MultiLineGraph from './Components/MultiLineGraph.vue';

import thematicData from 'vue/libs/thematicData';
import queryBuilder from 'vue/libs/queryBuilder';
import {
  utcRangeToResolution,
  expandUtcRange,
  expandToCompletePeriod
} from 'lib/date';

export default {
  name: 'GROUPS_OVERTIME',
  components: {
    'multi-line-graph': MultiLineGraph,
    'widget-wrapper': WidgetWrapper
  },
  mixins: [restrictionMixin],
  props: {
    config: { default: undefined, type: Object },
    source: { default: undefined, type: String },
    warning: { default: undefined, type: String },
    dateFilter: { default: undefined, type: Object }
  },
  data() {
    return {
      error: undefined,
      lastPromises: undefined,
      loading: true,
      series: undefined
    };
  },
  computed: {
    byVolume() {
      return this.config && this.config.byVolume;
    },
    empty() {
      return !(_.isArray(this.series) && this.series.length);
    },
    dateRange() {
      // first expand the selected range to twice in either direction (to try to get 5 data points)
      const { selected, validRange } = this.dateFilter;
      const { startDate: start, endDate: end } = selected;
      const expanded = expandUtcRange({ start, end }, 5, validRange);
      // now figure out what the display resolution should be
      const dateResolution = utcRangeToResolution(expanded);
      // and expand the range to meet the ends of the resolution
      const range = expandToCompletePeriod(expanded, dateResolution);
      return { range, dateResolution };
    },
    labels() {
      if (this.empty) {
        return [];
      } else {
        const first = _.first(this.series);
        return first.dateLabels;
      }
    },
    requestOptions() {
      const { dateResolution } = this.dateRange;
      return {
        dataSource: this.source,
        scoreType: _.get(this.config, 'score.type', 'nps'),
        scoreColumn: _.get(this.config, 'score.column', null),
        scoreOptions: _.get(this.config, 'score.options', null),
        dateColumn: this.dateFilter.column,
        dateOptions: { fullOnly: true },
        dateResolution,
        dateMaxPast: 5
      };
    },
    validDateFilter() {
      return this.dateFilter && this.dateFilter.validRange;
    },
    values() {
      if (this.empty) {
        return [];
      } else {
        const { byVolume, labels, series } = this;

        return _.map(series, s => {
          const byLabel = _.zipObject(s.dateLabels, s.scores);
          return _.map(labels, label => {
            const entry = byLabel[label];
            if (entry) {
              const { score, hist: counts } = entry.score;
              if (byVolume) {
                return _.sum(counts);
              } else {
                return score;
              }
            } else {
              return undefined;
            }
          });
        });
      }
    }
  },
  mounted() {
    watchAll(this, ['config', 'source', 'dateFilter'], this.fetchData);
    this.fetchData();
  },
  methods: {
    fetchData: async function() {
      if (!this.source || !this.config || !this.validDateFilter) {
        return;
      }

      this.loading = true;

      const { range } = this.dateRange;
      const { filterGroups } = this.config;

      const dateFilterString = this.restrictCustomDateRange(
        queryBuilder.buildDateQuery(this.dateFilter.id, null, range.end)
      );
      const promises = [];

      // if byVolume we need to know the overall volume so another request needed
      if (this.byVolume) {
        promises.push(
          thematicData.getScoreByDate(
            dateFilterString,
            null,
            this.requestOptions
          )
        );
      }
      promises.push(
        ..._.map(filterGroups, filter => {
          const filterString = queryBuilder.appendToFilter(
            dateFilterString,
            filter.filterString
          );
          return thematicData.getScoreByDate(
            filterString,
            null,
            this.requestOptions
          );
        })
      );

      this.lastPromises = promises;

      try {
        const series = await Promise.all(promises);
        if (promises !== this.lastPromises) {
          return;
        }
        this.series = series;
      } catch (e) {
        this.error = e;
      } finally {
        if (promises === this.lastPromises) {
          this.loading = false;
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>

