<template>
  <div class="percentage-bar">
    <svg
      class="percentage-bar-indicator"
      preserveAspectRatio="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 32"
    >
      <defs>
        <linearGradient id="overall">
          <stop
            offset="0%"
            :stop-color="colors.primary400"
          />
          <stop
            offset="100%"
            :stop-color="colors.primary300"
          />
        </linearGradient>
        <linearGradient id="comparison">
          <stop
            offset="0%"
            :stop-color="colors.orange400"
          />
          <stop
            offset="100%"
            :stop-color="colors.orange200"
          />
        </linearGradient>
      </defs>
      <rect
        :width="baselineWidth + '%'"
        x="0"
        y="5"
        rx="0"
        ry="0"
        height="10"
        fill="url(#overall)"
      />
      <rect
        :width="comparisonWidth + '%'"
        x="
        0"
        y="17"
        rx="0"
        ry="0"
        height="10"
        fill="url(#comparison)"
      />
    </svg>
    <div class="percentage-label baseline">
      {{ baselineLabel }}
    </div>
    <div class="percentage-label comparison">
      {{ comparisonLabel }}
    </div>
  </div>
</template>

<script>
import { isString } from 'lodash';
import toFixed from 'vue/libs/to-fixed';
import colors from 'vue/styles/element-variables.scss';
function format(value, precision, suffix) {
  if (!isString(precision)) {
    precision = 1;
  } else {
    precision = parseInt(precision, 10);
  }
  if (precision === 0) {
    return toFixed(Math.round(value), 0) + suffix;
  }
  return toFixed(value, precision) + suffix;
}
export default {
  name: 'BarDifferenceN',
  props: {
    baseline: { type: Number },
    baselineWidth: { type: Number },
    comparison: { type: Number },
    comparisonWidth: { type: Number },
    suffix: { type: String },
    dp: { type: String }
  },
  data() {
    return {
      colors: colors
    }
  },
  computed: {
    baselineLabel() {
      return format(this.baseline, this.dp, this.suffix);
    },
    comparisonLabel() {
      return format(this.comparison, this.dp, this.suffix);
    }
  }
};
</script>


<style lang="scss" scoped>
.percentage-bar {
  align-items: center;
  display: grid;
  grid-gap: 0 8px;
  grid:
    'bar-inner baseline' 16px
    'bar-inner comparison' 16px
    / 1fr 60px;
}
.percentage-bar-indicator {
  height: 32px;
  display: block;
  width: 100%;
  grid-area: bar-inner;
}
.percentage-label {
  font-size: 12px;
  line-height: 16px;
  padding: 0;
  text-align: right;
}
.baseline {
  grid-area: baseline;
}
.comparison {
  grid-area: comparison;
}
</style>
