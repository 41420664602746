import FlagKeys from 'Auth/flag-keys';
import {
  DashboardDateResolution, DashboardWidgetType, SelectionWidgetType,
  SignificantChangeType,
  WidgetOrderingValue,
  WidgetSortValue,
  WidgetSummaryType,
  WidgetThemeLevel
} from 'api/enums';
import { ConfigWidgetSize, CustomDashboardWidgetType } from './enums';
import { ConfigFieldType } from './types';

export const CONFIG_FIELDS = {
  [DashboardWidgetType.KEY_TAKEAWAYS]: [
    { target: 'score', type: ConfigFieldType.SCORE, isRequired: true },
    { target: 'title', type: ConfigFieldType.TITLE },
  ],
  [DashboardWidgetType.SCORE]: [
    { target: 'score', type: ConfigFieldType.SCORE, isRequired: true },
    { target: 'title', type: ConfigFieldType.TITLE },
  ],
  [DashboardWidgetType.SCORE_DETAIL]: [
    { target: 'score', type: ConfigFieldType.SCORE, isRequired: true },
    { target: 'title', type: ConfigFieldType.TITLE },
  ],
  [DashboardWidgetType.SCORE_OVERTIME]: [
    { target: 'score', type: ConfigFieldType.SCORE, isRequired: true },
    { target: 'title', type: ConfigFieldType.TITLE },
  ],
  [DashboardWidgetType.SCORE_CHANGES]: [
    { target: 'score', type: ConfigFieldType.SCORE, isRequired: true },
    { target: 'title', type: ConfigFieldType.TITLE },
  ],
  [DashboardWidgetType.SUMMARY]: [
    { target: 'title', type: ConfigFieldType.TITLE },
    { target: 'summaryType', type: ConfigFieldType.SUMMARY_TYPE },
  ],
  [DashboardWidgetType.IMPACT_CHANGES]: [
    { target: 'score', type: ConfigFieldType.SCORE, isRequired: true },
    { target: 'title', type: ConfigFieldType.TITLE },
    { target: 'themeLevel', type: ConfigFieldType.THEME_LEVEL },
    { target: 'ordering', type: ConfigFieldType.ORDERING },
    { target: 'sort', type: ConfigFieldType.SORT },
    { target: 'showSummary', type: ConfigFieldType.SHOW_SUMMARY },
    { target: 'showComments', type: ConfigFieldType.SHOW_COMMENTS },
  ],
  [DashboardWidgetType.METADATA]: [
    { target: 'title', type: ConfigFieldType.TITLE },
  ],
  [DashboardWidgetType.IMPACT_SIMPLE]: [
    { target: 'score', type: ConfigFieldType.SCORE, isRequired: true },
    { target: 'title', type: ConfigFieldType.TITLE },
    { target: 'themeLevel', type: ConfigFieldType.THEME_LEVEL },
    { target: 'showSummary', type: ConfigFieldType.SHOW_SUMMARY },
    { target: 'showComments', type: ConfigFieldType.SHOW_COMMENTS },
  ],
  [DashboardWidgetType.THEMES_SIMPLE]: [
    { target: 'title', type: ConfigFieldType.TITLE },
    { target: 'size', type: ConfigFieldType.SIZE },
    { target: 'themeLevel', type: ConfigFieldType.THEME_LEVEL },
    { target: 'showSummary', type: ConfigFieldType.SHOW_SUMMARY },
    { target: 'showComments', type: ConfigFieldType.SHOW_COMMENTS },
  ],
  [DashboardWidgetType.THEMES1]: [
    { target: 'title', type: ConfigFieldType.TITLE },
    { target: 'size', type: ConfigFieldType.SIZE },
    { target: 'showSummary', type: ConfigFieldType.SHOW_SUMMARY },
    { target: 'showComments', type: ConfigFieldType.SHOW_COMMENTS },
  ],
  [DashboardWidgetType.THEMES_CHANGES]: [
    { target: 'title', type: ConfigFieldType.TITLE },
    { target: 'showSummary', type: ConfigFieldType.SHOW_SUMMARY },
    { target: 'showComments', type: ConfigFieldType.SHOW_COMMENTS },
  ],
  [DashboardWidgetType.SIGNIFICANT_CHANGES]: [
    { target: 'title', type: ConfigFieldType.TITLE },
    { target: 'showSummary', type: ConfigFieldType.SHOW_SUMMARY },
    { target: 'showComments', type: ConfigFieldType.SHOW_COMMENTS },
  ],
  [DashboardWidgetType.SIGNIFICANT_SCORE_CHANGES]: [
    { target: 'score', type: ConfigFieldType.SCORE, isRequired: true },
    { target: 'title', type: ConfigFieldType.TITLE },
    { target: 'showSummary', type: ConfigFieldType.SHOW_SUMMARY },
    { target: 'showComments', type: ConfigFieldType.SHOW_COMMENTS },
  ],
};

export const DELETABLE_WIDGETS = [
  DashboardWidgetType.KEY_TAKEAWAYS,
  DashboardWidgetType.SCORE,
  DashboardWidgetType.SCORE_DETAIL,
  DashboardWidgetType.SCORE_OVERTIME,
  DashboardWidgetType.SCORE_CHANGES,
  DashboardWidgetType.SUMMARY,
  DashboardWidgetType.IMPACT_SIMPLE,
  DashboardWidgetType.IMPACT_CHANGES,
  DashboardWidgetType.THEMES_CHANGES,
  DashboardWidgetType.THEMES1,
  DashboardWidgetType.THEMES_SIMPLE,
  DashboardWidgetType.THEMES_NEW,
  DashboardWidgetType.METADATA,
  DashboardWidgetType.EMPTY,
  DashboardWidgetType.SIGNIFICANT_CHANGES,
  DashboardWidgetType.SIGNIFICANT_SCORE_CHANGES
];

export const WIDGET_TYPE_OPTIONS = [
  {
    value: DashboardWidgetType.KEY_TAKEAWAYS,
    label: 'Key Takeaways',
    description: 'Bullet points of the most important things',
    icon: 'list-ol'
  },
  {
    value: DashboardWidgetType.SCORE,
    label: 'Score',
    description: 'Score, previous period change, and number of responses',
    icon: 'tachometer-alt'
  },
  {
    value: DashboardWidgetType.SCORE_OVERTIME,
    label: 'Score Over Time',
    description: 'Score shown charted over time',
    icon: 'chart-line'
  },
  {
    value: DashboardWidgetType.SCORE_CHANGES,
    label: 'Why did score change',
    description: 'Score changes explained by themes for the current and previous time periods',
    icon: 'map'
  },
  {
    value: DashboardWidgetType.SUMMARY,
    label: 'Summary',
    description: 'Summary of responses',
    icon: 'sparkles',
    requiresFlag: FlagKeys.CAN_SEE_SUMMARIES_ON_DASHBOARD
  },
  {
    value: DashboardWidgetType.METADATA,
    label: 'Responses',
    description: 'Number of responses',
    icon: 'comment'
  },
  {
    value: CustomDashboardWidgetType.UNIFIED_THEMES,
    label: 'Top themes by volume',
    description: 'Highest volume themes',
    icon: 'chart-bar'
  },
  {
    value: DashboardWidgetType.THEMES_CHANGES,
    label: 'Changes in Most Common Themes',
    description: 'Volume in current and previous time periods',
    icon: 'chart-bar'
  },
  {
    value: CustomDashboardWidgetType.IMPACT_SIMPLE_ASC,
    label: 'Key negative drivers of score',
    description: 'Themes with negative impact',
    defaultConfig: { sort: WidgetSortValue.ASC },
    icon: 'sort-amount-down'
  },
  {
    value: CustomDashboardWidgetType.IMPACT_SIMPLE_DESC,
    label: 'Key positive drivers of score',
    description: 'Themes with positive impact',
    defaultConfig: { sort: WidgetSortValue.DESC },
    icon: 'sort-amount-up'
  },
  {
    value: DashboardWidgetType.SCORE_DETAIL,
    label: 'Score breakdown',
    description: 'E.g. negative/neutral/positive, detractor/passive/promoter',
    icon: 'tasks-alt'
  },
  {
    value: CustomDashboardWidgetType.SIGNIFICANT_CHANGES_EMERGING,
    label: 'Recently emerged rare themes',
    description: 'Very low volume themes that surged recently',
    defaultConfig: { changeType: SignificantChangeType.EMERGING },
    icon: 'siren-on'
  },
  {
    value: CustomDashboardWidgetType.SIGNIFICANT_CHANGES_VOLUME,
    label: 'Largest changes in theme mentions',
    description: 'Themes where volume has spiked',
    defaultConfig: { changeType: SignificantChangeType.VOLUME },
    icon: 'wave-triangle'
  },
  {
    value: DashboardWidgetType.SIGNIFICANT_SCORE_CHANGES,
    label: 'Largest changes in score',
    description: 'Themes where score has spiked',
    icon: 'wave-triangle'
  },
];

export const RESOLUTION_OPTIONS = [
  {
    value: DashboardDateResolution.WEEKLY,
    label: 'Weekly'
  },
  {
    value: DashboardDateResolution.MONTHLY,
    label: 'Monthly'
  },
  {
    value: DashboardDateResolution.QUARTERLY,
    label: 'Quarterly'
  }
];

export const REQUIRED_COLUMNS = {
  [DashboardWidgetType.SCORE]: ['scores'],
  [DashboardWidgetType.SCORE_DETAIL]: ['scores'],
  [DashboardWidgetType.SCORE_OVERTIME]: ['scores', 'date_column'],
  [DashboardWidgetType.SCORE_CHANGES]: ['scores', 'date_column'],
  [DashboardWidgetType.THEMES_CHANGES]: ['date_column'],
  [DashboardWidgetType.IMPACT_CHANGES]: ['scores', 'date_column'],
  [DashboardWidgetType.SIGNIFICANT_SCORE_CHANGES]: ['scores'],
};

export const WIDGET_SORT_OPTIONS = [
  {
    value: WidgetSortValue.ASC,
    label: 'Low to high',
  },
  {
    value: WidgetSortValue.DESC,
    label: 'High to low',
  },
  {
    value: WidgetSortValue.ASC_ABS,
    label: 'Low to high (absolute value)',
  },
  {
    value: WidgetSortValue.DESC_ABS,
    label: 'High to low (absolute value)',
  },
];

export const WIDGET_ORDERING_OPTIONS = [
  {
    value: WidgetOrderingValue.BASELINE,
    label: 'Baseline',
  },
  {
    value: WidgetOrderingValue.COMPARISON,
    label: 'Comparison',
  },
  {
    value: WidgetOrderingValue.DIFFERENCE,
    label: 'Difference',
  },
];

export const WIDGET_THEME_LEVEL_OPTIONS = [
  {
    value: WidgetThemeLevel.BASETHEME,
    label: 'Base themes',
  },
  {
    value: WidgetThemeLevel.SUBTHEME,
    label: 'Subthemes',
  },
];

export const WIDGET_SIZE_OPTIONS = [
  {
    value: ConfigWidgetSize.FULL,
    label: 'Full width',
  },
  {
    value: ConfigWidgetSize.HALF,
    label: 'Half width',
  },
];

export const SUMMARY_WIDGET_OPTIONS = [
  {
    value: WidgetSummaryType.ALL,
    label: 'all feedback'
  },
  {
    value: WidgetSummaryType.ISSUE,
    label: 'issues'
  },
  {
    value: WidgetSummaryType.REQUEST,
    label: 'requests'
  },
  {
    value: WidgetSummaryType.QUESTION,
    label: 'questions'
  },
  {
    value: WidgetSummaryType.NEGATIVE,
    label: 'negative feedback'
  },
  {
    value: WidgetSummaryType.POSITIVE,
    label: 'positive feedback'
  },
];

export const SELECTION_WIDGET_OPTIONS = [
  {
    value: SelectionWidgetType.SELECTION_DROPDOWN,
    label: 'Dropdown',
  }
];

export const SELECTION_SCORE_WIDGET_OPTIONS = [
  {
    value: SelectionWidgetType.SELECTION_SCORERANK,
    label: 'Benchmark table',
  },
  {
    value: SelectionWidgetType.SELECTION_SCORE_BREAKDOWN,
    label: 'Score breakdown',
  },
];
