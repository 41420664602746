
































































































































































































import CopyToClipboard from "../components/CopyToClipboard/CopyToClipboard.vue";
import analytics from 'lib/analytics';
import ReviewControls from 'vue/components/Summary/ReviewControls.vue';
import {
  getAnalysisToolsUIStore,
} from 'stores/RootStore';

export default {
  name: 'OverviewGroup',
  components: {
    CopyToClipboard,
    ReviewControls
  },
  props: {
    hasSummaryError: { type: Boolean },
    isBaselineEmpty: { type: Boolean },
    isComparisonEmpty: { type: Boolean },
    hasComparison: { type: Boolean },
    hasSummary: { type: Boolean },
    hasSentiment: { type: Boolean },
    hasKeyTakeaways: { type: Boolean },
    baselineSummary: { type: String },
    comparisonSummary: { type: String },
    themeName: {type: String}
  },
  data() {
    return {
      analysisToolsUIStore: getAnalysisToolsUIStore(),
    };

  },
  computed: {
    layoutClassName() {
      const { hasSummary, hasSentiment, hasKeyTakeaways, hasComparison } = this;
      switch(true) {
        case hasComparison && hasSummary && hasSentiment && hasKeyTakeaways : return 'layout_1--compare has-horizontal-keyline';
        case hasComparison &&               hasSentiment && hasKeyTakeaways : return 'layout_2--compare has-vertical-keyline';
        case hasComparison && hasSummary && hasSentiment                    : return 'layout_3--compare has-horizontal-keyline';
        case hasComparison && hasSummary &&                 hasKeyTakeaways : return 'layout_4--compare has-horizontal-keyline';
        case hasComparison &&                               hasKeyTakeaways : return 'layout_5--compare has-vertical-keyline';
        case hasComparison && hasSummary                                    : return 'layout_6--compare has-horizontal-keyline';
        case hasComparison              && !hasSentiment                    : return 'layout_7--compare has-vertical-keyline';
        case hasComparison &&               hasSentiment                    : return 'layout_8--compare has-vertical-keyline';
        case                  hasSummary && hasSentiment && hasKeyTakeaways : return 'layout_1';
        case                                hasSentiment && hasKeyTakeaways : return 'layout_2';
        case                  hasSummary && hasSentiment                    : return 'layout_3';
        case                  hasSummary &&                 hasKeyTakeaways : return 'layout_4';
        case                                                hasKeyTakeaways : return 'layout_5';
        case                  hasSummary && !hasSentiment                   : return 'layout_6';
        case                                hasSentiment                    : return 'layout_7';
        default                                                             : return 'layout_8';
      }
    }
  },
  methods: {
    getLocationForFeedback() {
      const surveyId = this.analysisToolsUIStore.currentSurveyId ? this.analysisToolsUIStore.currentSurveyId : '';

      return `analysis:${surveyId}:themes`;
    },
    scrollToChart(value) {
      const selector = `[data-id="${value}-over-time"]`;
      const el = this.$root.$el.querySelector(selector);
      if (!el) {
        return;
      }
      el.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    },
    onTextCopied() {
      analytics.track('Analysis: Copy Summary',
        {
          'Location': 'Theme',
        }
      );
    }
  }
};
