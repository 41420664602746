<template>
  <div class="theme-pill">
    <div class="theme__name">
      {{ theme.name }}
    </div>
    <div class="theme__percentage">
      {{ percentage }}
    </div>
  </div>
</template>


<script>
import toFixed from 'vue/libs/to-fixed';
export default {
  name: 'ThemePill',
  props: { theme: { default: () => ({}), type: Object } },
  computed: {
    percentage() {
      return toFixed(this.theme.percentage, 0) + '%';
    }
  }
};
</script>


<style lang="scss" scoped>
@import '../../../../styles/element-variables';
.theme-pill {
  display: inline-flex;
  line-height: 20px;
  margin: 0 10px 4px 0;
  font-size: 12px;
  -webkit-print-color-adjust: exact !important;
  @media print {
    line-height: 15px;
    margin: 0 8px 4px 0;
    font-size: 12px;
  }
}
.theme__name {
  background: $--primary-100;
  border-radius: $--border-radius-small 0 0 $--border-radius-small;
  flex: 0 0 auto;
  padding: 0 8px;
  -webkit-print-color-adjust: exact !important;
  @media print {
    padding: 0 6px;
    background: $--primary-100 !important;
  }
}
.theme__percentage {
  background: $--primary-100;
  border-radius: 0 $--border-radius-small $--border-radius-small 0;
  color: $--neutral-800;
  flex: 0 0 auto;
  padding: 0 8px;
  -webkit-print-color-adjust: exact !important;
  @media print {
    padding: 0 6px;
    background: $--primary-100 !important;
  }
}
</style>
