<template>
  <div
    class="analysis-menu"
    mode="horizontal"
  >
    <div
      v-for="v in analysisToolsUIStore.filteredVisualizations"
      :key="v.name"
      :class="{ 'active': isActive(v) }"
      class="analysis-menu__item"
      @click="navigateTo(toRoute(v.urlSlug))"
    >
      <font-awesome-icon
        v-if="!!v.icon"
        :icon="getIcon(v)"
      />
      {{ v.name }}
    </div>
  </div>
</template>

<script>

import { getAnalysisConfigStore, getAnalysisToolsUIStore, getFilterStore, getThemesStore } from 'stores/RootStore';
import { toRoute } from 'vue/libs/routeUtils';

export default {
  name: 'AnalysisToolsMenu',
  data() {
    return {
      analysisConfigStore: getAnalysisConfigStore(),
      analysisToolsUIStore: getAnalysisToolsUIStore(),
      themesStore: getThemesStore(),
      filterStore: getFilterStore()
    };
  },
  computed: {
    isFeedbackTool() {
      return this.analysisToolsUIStore.isFeedbackTool;
    }
  },
  watch: {
    isFeedbackTool: {
      handler() {
        if (this.analysisToolsUIStore.toolUrlSlug !== 'feedback') {
          const themes = this.themesStore.themesHierarchy || {};
          this.filterStore.removeUntaggedCommentOption(themes);
          this.filterStore.updateSelections();
        }
      },
      immediate: true,
    }
  },
  methods: {
    toRoute(name) {
      return toRoute(this, name);
    },
    getIcon(vis) {
      if (this.analysisConfigStore.isThematicAdmin) {
        return vis.icon;
      }
      if (this.analysisToolsUIStore.surveyNeedsOnboarding && vis.needsOnboarding) {
        return 'lock';
      }
      if (this.analysisToolsUIStore.surveyNeedsUpgrade && vis.needsUpgrade) {
        return 'gem';
      }
      return vis.icon;
    },
    isActive(visualisation) {
      return this.analysisToolsUIStore.visConfig.urlSlug === visualisation.urlSlug;
    },
    navigateTo(path) {
      window.location = `/#${path}`;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../styles/element-variables.scss';

.analysis-menu {
  border-bottom: none;
  display: flex;
  margin: 20px 0 10px;

  .analysis-menu__item {
    align-items: center;
    border-radius: 20px;
    background-color: $--neutral-100;
    cursor: pointer;
    display: flex;
    height: 35px;
    padding: 0 20px;
    margin-right: 10px;

    &.active {
      background-color: $--primary-500;
      color: $--color-white;
    }

    svg {
      font-size: 18px;
      margin-right: 5px;
    }
  }

  .gem-icon {
    margin-top: -3px;
  }
}
</style>
