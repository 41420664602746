import * as React from 'react';
import { Modal } from 'components/Shared/Modal';
import './email-modal.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'components/Shared/Button';
import { Checkbox, Form, Input, Loader, TextArea } from 'semantic-ui-react';
import { EmailManager, EmailInputState } from 'components/Shared/EmailManager';
import { debounce, isEqual } from 'lodash';
import { AnswersEmailModalState } from 'types/custom';
import { EmailPreviewSkeleton } from './EmailPreviewSkeleton';
import { InlineNotification } from 'components/Shared/InlineNotification';
import analytics from 'lib/analytics';
import { useCallback } from 'react';

interface EmailModalProps {
  answerTitle: string;
  existingEmailModalState: AnswersEmailModalState;
  isFetchingEmailPreview: boolean;
  emailPreviewHtml: string | null;
  emailPreviewError: Error | null;
  sendEmailError: Error | null;
  isSendingEmail: boolean;
  onBack: () => void;
  onClose: () => void;
  onCancel: () => void;
  onUpdateEmailState: (emailState: Partial<AnswersEmailModalState>) => void;
  onRetry: () => void;
  onSendEmail: (emails: string[], emailSubject: string, emailBody: string) => void;
}

export const EmailModal = ({
  answerTitle,
  existingEmailModalState,
  isFetchingEmailPreview,
  emailPreviewHtml,
  emailPreviewError,
  sendEmailError,
  isSendingEmail,
  onBack,
  onClose,
  onCancel,
  onUpdateEmailState,
  onRetry,
  onSendEmail
}: EmailModalProps) => {
  const iframeRef = React.useRef<HTMLIFrameElement>(null);
  const textAreaRef = React.useRef<TextArea>(null);
  const [inputValue, setInputValue] = React.useState<string>(
    existingEmailModalState.subject ? existingEmailModalState.subject : ''
  );
  const [isEmailSafeToSend, setIsEmailSafeToSend] = React.useState<boolean>(false);
  const [enteredEmailValueValidation, setEnteredEmailValueValidation] = React.useState<boolean>(false);
  const [enteredEmailValue, setEnteredEmailValue] = React.useState<string>('');
  const [hasUserTriedToSubmitForm, setHasUserTriedToSubmitForm] = React.useState<boolean>(false);
  const [bodyInputValue, setBodyInputValue] = React.useState<string>('');

  const updateHtmlPreviewContent = useCallback((value: string) => {
    const writeHtml = (html: string | null) => {
      if (iframeRef.current && iframeRef.current.contentWindow && html && !isFetchingEmailPreview) {
        iframeRef.current.contentWindow.document.close();
        iframeRef.current.contentWindow.document.write(html);
      }
    };

    if (value.length > 0) {
      const emailBodyElementWithEnteredText = `<p class="static-answer-email-body">${value}</p>`;
      if (emailPreviewHtml) {
        const updatedHtml = emailPreviewHtml.replace(
          '<h1 class="static-answer-question">', `${emailBodyElementWithEnteredText}<h1 class="static-answer-question">`
        );
        writeHtml(updatedHtml);
      }
    } else {
      writeHtml(emailPreviewHtml);
    }
  }, [emailPreviewHtml, isFetchingEmailPreview]);

  React.useEffect(() => {
    updateHtmlPreviewContent(bodyInputValue);
  }, [bodyInputValue, emailPreviewHtml, isFetchingEmailPreview, updateHtmlPreviewContent]);

  React.useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current['ref'].current.style.height = 'auto';
      textAreaRef.current['ref'].current.style.height = `${textAreaRef.current['ref'].current.scrollHeight}px`;
    }
  }, [bodyInputValue]);

  const handleChange = (_e, { value }) => {
    setInputValue(value);
    onUpdateEmailState({ subject: value });
  };

  const debouncedBodyInputChange = debounce((value) => {
      updateHtmlPreviewContent(value);
    }, 300)

  const handleBodyInputChange = (_e, { value }) => {
    setBodyInputValue(value);
    debouncedBodyInputChange(value);
  };

  function handleEnteredEmails(emailInputState: EmailInputState) {
    if (!isEqual(emailInputState.emails, existingEmailModalState.emails)) {
      onUpdateEmailState({ emails: emailInputState.emails });
    }
    setEnteredEmailValue(emailInputState.enteredValue)
    setEnteredEmailValueValidation(emailInputState.enteredValueValidation);
  }

  function updateEmails() {
    const existingEmails = existingEmailModalState.emails;
    // if the email is not in the list and left in the input by the user, we need to make sure to add it
    const isEmailValueLeftInsideInput = enteredEmailValue.length > 0 && enteredEmailValueValidation;

    const canAddEmail = isEmailValueLeftInsideInput
      && enteredEmailValueValidation
      && !existingEmails.includes(enteredEmailValue);

    const finalizedEmails = canAddEmail
      ? [...existingEmails, enteredEmailValue]
      : existingEmails;

    if (canAddEmail) {
      onUpdateEmailState({ emails: finalizedEmails });
    }
    return finalizedEmails;
  }

  function onConfirm() {
    setHasUserTriedToSubmitForm(true);
    const updatedEmails = updateEmails();
    const canSendEmail = isEmailSafeToSend && updatedEmails.length > 0;
    const emailSubject = inputValue.trim().length > 0 ? inputValue.trim() : answerTitle;
    const emailBody = bodyInputValue.trim();
    if (canSendEmail) {
      onSendEmail(updatedEmails, emailSubject, emailBody);
      analytics.track('Answers: Share email');
    }
  }

  function getErrorView() {
    // order of error display is important
    if (sendEmailError) {
      return (
        <div className="answers-email-modal__error">
          <InlineNotification
            title='An error occurred while sending an email. Please try again later.'
            type='error'
            isHighlighted={true}
          />
        </div>
      );
    }
    if (emailPreviewError) {
      return (
        <div className="answers-email-modal__error">
          <InlineNotification
            title='Something went wrong previewing the email.'
            type='error'
            isHighlighted={true}
            actionText='Try again'
            onActionClick={onRetry}
          />
        </div>
      );
    }
    return null;
  }

  return (
    <Modal
      open={true}
      size="large"
      closeOnEscape={false}
      onClose={onClose}
    >
      <div
        className="answers-email-modal"
      >
        <header className="answers-email-modal__header">
          <h1 className="answers-email-modal__heading">
            <Button
              variant="tertiary"
              size="medium"
              onClick={onBack}
            >
              <FontAwesomeIcon
                icon="arrow-left"
                className="answers-email-modal__back-icon"
              />
            </Button>
            Finalize & send email
          </h1>
          {getErrorView()}
        </header>
        <div className="answers-email-modal__content">
          <section className="answers-email-modal__form">
            <div className="answers-email-modal__input">
              <h3 className="answers-email-modal__input-title">
                Email subject
              </h3>
              <Input
                placeholder={answerTitle}
                className="answers-email-modal__input-field"
                autoFocus={true}
                autoComplete="off"
                fluid={true}
                maxLength={72}
                value={inputValue}
                onChange={handleChange}
              />
              <p className="answers-email-modal__input-description">
                {inputValue.length}/72 characters
              </p>
            </div>
            <div className="answers-email-modal__textarea">
              <h3 className="answers-email-modal__textarea-title">
                Email body
              </h3>
              <Form>
                <TextArea
                  placeholder='Enter the body message here'
                  className="answers-email-modal__textarea-field"
                  rows={1}
                  autoFocus={false}
                  ref={textAreaRef}
                  autoComplete="off"
                  maxLength={200}
                  value={bodyInputValue}
                  onChange={handleBodyInputChange}
                />
              </Form>
              <p className="answers-email-modal__textarea-description">
                {bodyInputValue.length}/200 characters
              </p>
            </div>
            <EmailManager
              title="Recipients"
              inputPlaceholder="Type email addresses and press enter"
              hasUserTriedToSubmitForm={hasUserTriedToSubmitForm}
              existingEmails={existingEmailModalState.emails}
              onEmailsChange={handleEnteredEmails}
            />
            <div className="answers-email-modal__checkbox">
              <Checkbox
                label="I have reviewed the content to ensure it does not contain anything confidential or sensitive."
                checked={isEmailSafeToSend}
                onChange={() => setIsEmailSafeToSend(!isEmailSafeToSend)}
              />
              {hasUserTriedToSubmitForm && !isEmailSafeToSend &&
                <InlineNotification
                  title="Select checkbox if email is safe to send"
                  type='error'
                />
              }
            </div>
          </section>
          <section className="answers-email-modal__preview">
            <h3 className="answers-email-modal__preview-title">
              Preview
            </h3>
            {isFetchingEmailPreview ?
              <EmailPreviewSkeleton />
            :
              <div className="answers-email-modal__html-preview">
                <iframe
                  className="answers-email-modal__iframe"
                  ref={iframeRef}
                  title="Email Preview"
                />
              </div>
            }
          </section>
        </div>
        <div className="answers-email-modal__footer">
          <Button
            variant="secondary"
            size="medium"
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            size="medium"
            onClick={isSendingEmail ? null : onConfirm}
          >
            {isSendingEmail ?
              <>
                <Loader active inline size='mini' />
                Sending email
              </>
            :
              'Send email'
            }
          </Button>
        </div>
      </div>
    </Modal>
  );
};
