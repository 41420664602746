<template>
  <div
    id="theme-dropdown-popover"
    :class="{
      'theme-dropdown': openThemeDropdown && !verticalPosition,
      'theme-dropdown-from-comment': verticalPosition && openThemeDropdown
    }"
  >
    <el-popover
      :value="openThemeDropdown"
      :style="{
        position: 'absolute',
        top: verticalPosition + 'px'
      }"
      :visible-arrow="false"
      :append-to-body="false"
      placement="bottom-start"
      width="100%"
      trigger="click"
      @hide="onHide"
    >
      <div class="popover-content">
        <el-input
          ref="inputElement"
          v-model="filterText"
          placeholder="Search themes"
          size="small"
          clearable
        />
        <el-tree
          ref="filterTree"
          :data="allThemes"
          :expand-on-click-node="false"
          class="theme-options"
          icon-class="el-icon-arrow-right"
          :filter-node-method="filterNode"
          @node-click="handleNodeClick"
        />
        <el-button
          v-if="!filterText || (isFilteredListEmpty && filterText)"
          class="new-theme-button"
          @click="openCreateTheme"
        >
          {{ !filterText ? 'New theme' : `New theme "${filterText}"` }}
        </el-button>
      </div>
    </el-popover>
    <select-phrase
      v-if="!!selectedTheme"
      :conversations="conversations"
      :plain-blocks="plainBlocks"
      :comment-id="commentId"
      :selected-theme="selectedTheme"
      :pre-selected-phrase="preSelectedPhrase"
      @onAction="onAction"
    />
    <new-theme
      v-if="openCreateThemeDialog"
      :all-themes="allThemes"
      :theme-name="filterText"
      @onThemeCreation="onThemeCreation"
      @onAction="openCreateThemeDialog = false"
    />
  </div>
</template>

<script>
import { isEmpty, reduce, sortBy, find } from 'lodash';
import { getThemesStore } from 'stores/RootStore';
import SelectPhrase from './SelectPhrase.vue';
import NewTheme from './NewTheme.vue';

export default {
  name: 'ThemeDropdown',
  components: { SelectPhrase, NewTheme },
  props: {
    openThemeDropdown: {default: false, type: Boolean},
    verticalPosition: {default: undefined, type: Number},
    plainBlocks: { type: [Array, null] },
    conversations: { type: [Array, null] },
    preSelectedPhrase: {default: '', type: String},
    commentId: { default: '', type: [String, Number] },
  },
  data() {
    return {
      themesStore: getThemesStore(),

      currentItem: 0,
      filterText: '',
      selectedTheme: undefined,
      openCreateThemeDialog: false,
      isFilteredListEmpty: false
    };
  },
  computed: {
    themesHierarchy() {
      return this.themesStore.themesHierarchy;
    },
    allThemes() {
      if (isEmpty(this.themesHierarchy)) {
        return [];
      }
      // updating themesHierarchy with newly added themes
      const updatedThemesHierarchy = this.updateThemesHierarchy(this.themesHierarchy);
      return this.mapThemes(updatedThemesHierarchy);
    },
  },
  watch: {
    filterText(val) {
      this.$refs.filterTree.filter(val);
      this.isFilteredListEmpty = this.$refs.filterTree.isEmpty;
    },
  },
  mounted() {
    document.addEventListener('click', this.onDocumentClick);
    this.focusInput();
  },
  beforeDestroy() {
    document.removeEventListener('click', this.onDocumentClick);
  },
  methods: {
    onDocumentClick(e) {
      const popupRoot = document.getElementById('theme-dropdown-popover');
      const appRoot = document.getElementById('root');
      const popupButton = document.getElementById('inline-themes-add-theme-button' + '-' + this.commentId);
      const addThemeToCommentButton = document.getElementById('add-theme-to-comment');
      // we will only get vertical position when user tries to add theme by directly selecting the text from comment
      const isOpenedFromComment = this.verticalPosition ? addThemeToCommentButton : popupButton;

      if (
        this.openThemeDropdown &&
        e.target instanceof Node &&
        !!appRoot &&
        appRoot.contains(e.target) &&
        !!popupRoot &&
        !popupRoot.contains(e.target) &&
        isOpenedFromComment &&
        !isOpenedFromComment.contains(e.target)
      ) {
        this.onHide();
      }
    },
    onHide() {
      this.$emit('closeThemeDropdown');
    },
    openCreateTheme() {
      this.openCreateThemeDialog = true;
    },
    onAction() {
      this.selectedTheme = undefined;
      this.onHide();
    },
    mapThemes: function (themeObject) {
      const themes = reduce(
        themeObject,
        (result, theme, themeName) => {
          result.push({
            id: theme.id,
            baseTitle: themeName,
            base: theme.id,
            label: themeName,
            children: reduce(theme.subthemes, (result, subtheme, subthemeName) => {
              if(theme.id !== subtheme.id) {
                result.push({
                  id: `${theme.id}:${subtheme.id}`,
                  baseTitle: themeName,
                  base: theme.id,
                  sub: subtheme.id,
                  subTitle: `${subthemeName} subTitle`,
                  label:  `${subthemeName} label`,
                });
              }
              return result;
            }, []),
          });
          return result;
        },
        [],
      );
      return sortBy(themes, 'title');
    },
    updateThemesHierarchy(themesHierarchy) {
      const newThemeTransforms = this.themesStore.transforms.filter(transform => transform.type === 'AddTheme');
      let updatedThemesHierarchy = themesHierarchy;
      newThemeTransforms.forEach(transform => {
        const theme = {
          [transform.title]: {
            id: transform.themeId
          }
        };
        if (transform.parentThemeId) {
          find(updatedThemesHierarchy, (value, key) => {
            if (value.id === transform.parentThemeId) {
              updatedThemesHierarchy[key].subthemes = {...updatedThemesHierarchy[key].subthemes, ...theme};
            }
          })
        } else {
          updatedThemesHierarchy = {...updatedThemesHierarchy, ...theme};
        }
      })
      return updatedThemesHierarchy;
    },
    handleNodeClick(data) {
      this.selectedTheme = data;
    },
    focusInput() {
      this.$nextTick(() => {
        if (this.$refs.inputElement) {
          this.$refs.inputElement.$refs.input.focus();
        }
      });
    },
    filterNode(value, data) {
      if (!value) {
        return true;
      }
      return data.label.toLowerCase().indexOf(value.toLowerCase()) !== -1;
    },
    onThemeCreation(newTheme) {
      this.selectedTheme = newTheme;
      this.openCreateThemeDialog = false;
    }
  },
};
</script>

<style lang="scss" scoped>
@import '../../styles/element-variables.scss';
@import '../../styles/custom-button-types.scss';

.theme-dropdown ::v-deep .el-popover {
  // need to give important here because parent component popover padding is 0
  padding: 11px !important;
  margin-top: 0 !important;
}

.theme-dropdown-from-comment ::v-deep .el-popover {
  padding: 11px !important;
  margin-top: 1em !important;
}

.theme-dropdown-from-comment ::v-deep .el-input__inner {
  transition: none;
  margin-bottom: 0.6em;
}

.theme-dropdown ::v-deep .el-input__inner {
  transition: none;
  margin-bottom: 0.6em;
}
.theme-dropdown ::v-deep .el-input__suffix {
  .el-input__icon {
    padding-bottom: 10px;
  }
}

.popover-content {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-width: 300px;
  max-width: 400px;

  &.disabled {
    pointer-events: none;
  }
}

.theme-options {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  max-height: 200px;
  min-height: 0;
  overflow-y: auto;
  font-size: 14px;
  ::v-deep .el-tree-node__content {
    padding: 18px 0;
    &:hover {
      background: $--primary-100;
    }
  }
  ::v-deep .el-tree-node__content > .el-tree-node__expand-icon {
    font-size: 14px;
    color: $--color-text-primary;
    font-weight: bold;
    &.is-leaf {
      color: transparent;
      cursor: default;
    }
  }
  ::v-deep .el-tree-node:focus > .el-tree-node__content {
    background: $--primary-100;
  }
  ::v-deep .el-tree__empty-block {
    display: none;
  }
}

.new-theme-button {
  margin-top: 10px;
}
</style>
