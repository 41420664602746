import FlagKeys from 'Auth/flag-keys';
import posthog from 'posthog-js';
import { getUserStore } from 'stores/RootStore';

const QA_KEY = 'allow-wye-switch-qa';
class FeatureFlagManager {
  static _flagJsonDev: Record<string, string> = {};

    static checkFlag(flagKey: string): boolean {
        const {flags, flagUser} = getUserStore();
        const flagStatus = flags.isEnabled(
            flagKey,
            flagUser
        );
        return flagStatus;
    }

    static async prepareDevFlags(): Promise<void> {
      // IFF we are in dev mode load the dev flags
      if (process.env.NODE_ENV === 'development') {
        const flagsUrl = require('feature-flags.json').default;
        try {
          const result = await fetch(flagsUrl);
          const { ok } = result;
          if (ok) {
            // replace flagJson
            this._flagJsonDev = await result.json();
          }
        } catch (e) {
          // do nothing
        }
      }
    }


    static _checkDevFlag(flagKey: string): boolean | string {
      // Used only when we are in dev mode
      let flagValue = false as boolean | string;
        if (this._flagJsonDev) {
          flagValue = this._flagJsonDev[flagKey];
          // undefined means the flag is definitely off
          if (flagValue === undefined) {
            return false;
          }
          if (flagValue == 'QA') {
            return !!sessionStorage.getItem(QA_KEY);
          }
        }
        return flagValue;
    }

    static checkPHFlag(flagKey: string): boolean | string {
      // if we are in dev mode, use the local flags
      if (process.env.NODE_ENV === 'development') {
        return this._checkDevFlag(flagKey);
      }
      // retrieve the default value of the flag for the currently identified user
      let flagValue = posthog.getFeatureFlag(flagKey);
      // undefined means the flag is definitely off
      if (flagValue === undefined) {
        return false;
      }
      // even if true, we need to check the state of being in labs
      if (flagValue) {
        const payload = posthog.getFeatureFlagPayload(flagKey);
        if (payload == 'QA') {
          flagValue = !!sessionStorage.getItem(QA_KEY);
        }
      }
      return flagValue; // could be a string with a value like A/B
    }
}

export { FeatureFlagManager, FlagKeys, QA_KEY };

