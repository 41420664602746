<template>
  <widget-wrapper
    :title="config.title"
    :loading="loading"
    :error="error"
    :warning="warning"
    class="widget-width-2 themes-1"
    :panel-order="panelOrder"
    :widget-order="widgetOrder"
  >
    <div
      slot="subtitle"
      :class="{ 'widget-subtitle-clickable': canNavigate }"
      class="widget-subtitle"
      @click="doNavigate()"
    >
      <div class="widget-subtitle-text">
        {{ widgetSubtitle }}
        <font-awesome-icon
          v-if="canNavigate"
          class="link-icon"
          icon="chart-bar"
        />
      </div>
    </div>
    <div
      :class="{ empty: themes.length === 0 }"
      class="themes-body widget-body"
    >
      <div
        v-if="hasComparisonThemes"
        class="legend"
      >
        <svg
          class="key-square"
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="7"
            cy="7"
            r="7"
            width="14"
            height="14"
            :fill="colors.primary500"
          />
        </svg>
        <span class="key-text">{{ data.baseline.name }}</span>
        <svg
          class="key-square"
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="7"
            cy="7"
            r="7"
            width="14"
            height="14"
            :fill="colors.orange500"
          />
        </svg>
        <span class="key-text">{{ data.comparison.name }}</span>
      </div>
      <div
        v-for="(theme, index) in themes"
        :key="index + theme.name"
        class="theme-container"
      >
        <div
          class="theme-volume__theme-name"
          :class="{ 'clickable-theme': canClickTheme }"
          @click="doNavigate({code: theme.code, title: theme.name}, null, { volumeBy })"
        >
          <span>{{ theme.name }}</span>
        </div>
        <bar-difference
          v-if="hasComparisonThemes"
          class="theme-volume__bar"
          :value="theme.volumeBaseline"
          :old-value="theme.volumeComparison"
          :max-value="maxPercentage"
          suffix="%"
          dp="0"
        />
        <bar
          v-else
          class="theme-volume__bar"
          :value="theme.volume"
          :max-value="maxPercentage"
          :dp="0"
          suffix="%"
        />
        <div
          v-if="hasComparisonThemes"
          class="theme-volume__subthemes"
        >
          <theme-percentage-difference-pill
            v-for="(subtheme, i) in theme.subthemes"
            :key="i + theme"
            :theme="subtheme"
          />
        </div>
        <div
          v-else
          class="theme-volume__subthemes"
        >
          <theme-percentage-pill
            v-for="(subtheme, i) in theme.subthemes"
            :key="i + theme"
            :theme="subtheme"
          />
        </div>
      </div>
      <div
        v-if="themes.length === 0"
        class="empty-theme-list"
      >
        No themes found
      </div>
    </div>
    <summary-component
      v-if="isSummaryEnabled"
      :filters="filters"
      :theme="selectedTheme"
      :source="source"
      :comparison-themes="comparisonThemes"
      :can-navigate="canNavigate"
      @doNavigate="
        () =>
          doNavigate(
            selectedTheme.theme,
            selectedTheme.subtheme,
            selectedTheme.volumeBy,
            true
          )
      "
    />
    <comment-component
      v-if="isCommentsEnabled"
      :filter-string="filters ? filters.baseline : undefined"
      :theme="selectedTheme"
      :source="source"
      :can-navigate="canNavigate"
      @doNavigate="
        () =>
          doNavigate(
            selectedTheme.theme,
            selectedTheme.subtheme,
            selectedTheme.volumeBy,
            true
          )
      "
    />
  </widget-wrapper>
</template>

<script>
import { CommentComponent as ReactCommentComponent } from 'components/Dashboard/Widgets/CommentComponent';
import { assign, each, get, map, reduce } from 'lodash';
import CanNavigateMixin from 'vue/dashboards/Dashboard/MixIns/CanNavigateMixin';
import SentimentMixin from 'vue/dashboards/Dashboard/MixIns/SentimentMixin';
import colors from 'vue/styles/element-variables.scss';
import { ReactInVue } from 'vuera';
import Bar from './Components/Bar.vue';
import BarDifference from './Components/BarDifference.vue';
import SummaryComponent from './Components/SummaryComponent';
import ThemePercentageDifferencePill from './Components/ThemePercentageDifferencePill.vue';
import ThemePercentagePill from './Components/ThemePercentagePill.vue';
import WidgetWrapper from './WidgetWrapper';

export default {
  name: 'THEMES1',
  components: {
    BarDifference,
    Bar,
    ThemePercentagePill,
    ThemePercentageDifferencePill,
    WidgetWrapper,
    SummaryComponent,
    CommentComponent: ReactInVue(ReactCommentComponent),
  },
  mixins: [CanNavigateMixin('explore'), SentimentMixin],
  props: {
    config: { default: undefined, type: Object },
    filters: { default: undefined, type: Object },
    context: { default: undefined, type: Object },
    data: { default: undefined, type: Object },
    warning: { default: undefined, type: String },
    error: { default: undefined, type: String },
    limit: { default: 5, type: Number },
    loading: { default: false, type: Boolean },
    source: { default: undefined, type: String },
    panelOrder: { type: Number },
    widgetOrder: { type: Number },
  },
  data() {
    return {
      colors: colors,
    };
  },
  computed: {
    comparisonThemes() {
      return get(this, 'data.comparison');
    },
    compareToOverall() {
      return get(this.config, 'compareToOverall', false);
    },
    hasComparisonThemes() {
      return this.compareToOverall && this.themes.length > 0 && this.themes[0].volumeComparison !== undefined;
    },
    themes() {
      const themes = get(this, 'data.themes', []);

      return reduce(
        themes,
        (result, theme) => {
          if (result.length < 5) {
            const subthemes = map(theme.subthemes, (subtheme) => {
              if (subtheme.volume) {
                const { name, volume: percentage } = subtheme;
                return { name, percentage };
              } else if (subtheme.volumeBaseline) {
                return subtheme;
              }
            });
            result.push(assign({}, theme, { subthemes }));
          }
          return result;
        },
        [],
      );
    },
    maxPercentage() {
      let curr = 0;
      if (this.themes)
        each(this.themes, (theme) => {
          if (theme.volume) {
            curr = Math.max(theme.volume, curr);
          } else if (theme.volumeBaseline) {
            curr = Math.max(Math.max(theme.volumeBaseline, theme.volumeComparison), curr);
          }
        });
      return curr;
    },
  },
  watch: {
    config: {
      handler (config) {
        this.isCommentsEnabled = config.showComments;
        this.isSummaryEnabled = config.showSummary;
      },
      immediate: true
    },
  },
};
</script>

<style lang="scss" scoped>
.theme-container {
  display: grid;
  grid-gap: 5px;
  grid:
    'theme-name bar' 30px
    'theme-name subthemes' 24px
    / 200px auto;
  padding: 0 20px;
  margin: 10px 0 10px;
  @media print {
    overflow: wrap;
    grid:
      'theme-name bar' 40px
      'theme-name subthemes' 34px
      / 200px auto;
  }
  .theme-volume {
    &__theme-name {
      grid-area: theme-name;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      overflow: hidden;
      padding: 0;
    }
    &__bar {
      grid-area: bar;
    }
    &__subthemes {
      grid-area: subthemes;
      display: flex;
      flex-wrap: wrap;
      margin-right: 15px;
      overflow: hidden;
      @media print {
        overflow: wrap;
      }
    }
  }
}
</style>
