import * as React from 'react';
import { SegmentLocation, Summary, SummarySegment } from 'types/custom';
import './conversation-list.scss';
import { ConversationItem } from './ConversationItem';
import { combineReferences } from 'lib/combine-references';
import { Button } from 'components/Shared/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown } from 'semantic-ui-react';
import { CONVERSATION_DRAWER_SLIDE_DURATION, FILTER_OPTIONS, FilterOption } from './constants';
import { ConversationLens } from './ConversationLens';
import analytics from 'lib/analytics';
import getActorsClassNames from 'lib/get-actors-class-names';

interface Props {
  allReferences: number[];
  conversation: Summary['conversation'];
  selectedSegments: SummarySegment[];
}

const ConversationList = ({ allReferences, conversation, selectedSegments }: Props) => {
  const conversationItemRefs = conversation.parts.map(() => React.useRef<HTMLDivElement | null>(null));
  const conversationListRef = React.useRef<HTMLUListElement | null>(null);
  const [filter, setFilter] = React.useState<FilterOption>(FILTER_OPTIONS.FULL_CONVERSATION);
  const [isAnimatingScroll, setIsAnimatingScroll] = React.useState<boolean>(false);
  const [conversationScrollTop, setConversationScrollTop] = React.useState<number>(0);
  const [showFilterDropdown, setShowFilterDropdown] = React.useState(false);

  const collapseItems = (event: React.MouseEvent) => {
    event.stopPropagation();

    setFilter(FILTER_OPTIONS.REFERENCES);
  };

  const selectedSegmentsReferences = selectedSegments
    .flatMap(segment => combineReferences(segment.conversationReferences));
  const selectedReferenceIndexes = selectedSegmentsReferences.map((reference) => reference.index);

  const getLocations = (index: number): SegmentLocation[] => {
    return selectedSegments.flatMap((segment: SummarySegment) => {
      const combinedReferences = combineReferences(segment.conversationReferences);
      return combinedReferences.find((reference) => reference.index === index)?.locations ?? []
    });
  };

  const handleLensUpdate = (lensScrollTop: number) => {
    setConversationScrollTop(lensScrollTop);
  };

  const handleScroll = () => {
    const conversationList = conversationListRef.current;

    if (conversationList) {
      setConversationScrollTop(conversationList.scrollTop);
    }
  };

  const isReference = (itemIndex: number) => {
    return allReferences.includes(itemIndex);
  };

  const isSelected = (itemIndex: number) => {
    return selectedReferenceIndexes.includes(itemIndex);
  };

  const scrollToReference = (itemIndex: number) => {
    let scrollTimeout: NodeJS.Timeout;
    const itemRef = conversationItemRefs[itemIndex]?.current;

    const handleScroll = () => {
      clearTimeout(scrollTimeout);
      scrollTimeout = setTimeout(() => {
          setIsAnimatingScroll(false);
          conversationListRef?.current?.removeEventListener('scrollend', handleScroll);
      }, 100);
    }

    conversationListRef?.current?.addEventListener('scrollend', handleScroll);

    if (itemRef) {
      setIsAnimatingScroll(true);
      itemRef.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'center' });
    }
  };

  const shouldDisplayItem = (itemIndex: number) => {
    if (filter === FILTER_OPTIONS.FULL_CONVERSATION) {
      return true;
    }

    return isReference(itemIndex);
  };

  const toggleFilterDropdown = () => {
    setShowFilterDropdown(!showFilterDropdown);
  };

  const handleFilterChange = (option: FilterOption) => {
    analytics.track("Analysis: Changed Conversation Filter", {
      view: option
    });
    setFilter(option);
  };

  React.useEffect(() => {
    const timer = setTimeout(() => {
      scrollToReference(selectedReferenceIndexes[0]);
    }, CONVERSATION_DRAWER_SLIDE_DURATION)
    return () => clearTimeout(timer);
  }, []);

  React.useEffect(() => {
    if (isAnimatingScroll || !conversationListRef.current) {
      return;
    }

    conversationListRef.current.scrollTop = conversationScrollTop;
  }, [conversationScrollTop]);

  React.useEffect(() => {
    if (selectedReferenceIndexes[0]) {
      scrollToReference(selectedReferenceIndexes[0]);
    }
  }, [selectedReferenceIndexes[0]]);

  const actorsClassNames = getActorsClassNames(conversation.parts);

  const hasReferences = allReferences.length > 0;

  return (
    <div>
      {hasReferences && (
        <Dropdown
          className="conversation-list__dropdown"
          data-testid="conversation-list__dropdown"
          icon={null}
          onClose={() => setShowFilterDropdown(false)}
          open={showFilterDropdown}
          trigger={
            <Button
              onClick={toggleFilterDropdown}
              subvariant="white"
              variant="secondary"
            >
              {filter}
              <span className="conversation-list__dropdown-icon">
                <FontAwesomeIcon icon="chevron-down" />
              </span>
            </Button>
          }
        >
          <Dropdown.Menu className="conversation-list__dropdown-menu">
            <Dropdown.Item
              onClick={() => handleFilterChange(FILTER_OPTIONS.REFERENCES)}
              active={filter === FILTER_OPTIONS.REFERENCES}
            >
              <h4 className="conversation-list__item-title">References</h4>
              <p className="conversation-list__item-description">Only comments related to summaries</p>
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => handleFilterChange(FILTER_OPTIONS.FULL_CONVERSATION)}
              active={filter === FILTER_OPTIONS.FULL_CONVERSATION}
            >
              <h4 className="conversation-list__item-title">Full conversation</h4>
              <p className="conversation-list__item-description">All comments from the conversation</p>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      )}
      <ul
        className="conversation-list"
        data-testid="conversation-list"
        onScroll={handleScroll}
        ref={conversationListRef}
      >
        {conversation.parts.map((part, index) => {
          return (
            <li key={`${index}-${part.text}`}>
              {shouldDisplayItem(index) && part.text && (
                <div ref={conversationItemRefs[index]}>
                  <ConversationItem
                    collapseItems={collapseItems}
                    conversationPart={part}
                    actorClassName={actorsClassNames[part.author]}
                    locations={getLocations(index)}
                    isReference={isReference(index)}
                    isSelected={isSelected(index)}
                  />
                </div>
              )}
            </li>
          );
        })}
      </ul>
      <ConversationLens
        conversationItemRefs={conversationItemRefs}
        conversationListRef={conversationListRef}
        conversationScrollTop={conversationScrollTop}
        onLensUpdate={handleLensUpdate}
        selectedFilter={filter}
        selectedSegments={selectedSegments}
      />
    </div>
  );
};

export { ConversationList };
