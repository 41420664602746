import Auth from 'Auth/Auth';
import AuthenticatedRoute from 'components/AuthenticatedRoute';
import Home from 'components/Home/Home';
import LoadingHome from 'components/Home/LoadingHome';
import IntegrationRedirects from 'components/Integrations/IntegrationRedirects';
import Logout from 'components/LoginScreen/Logout';
import SsoError from 'components/LoginScreen/SsoError';
import NotFound from 'components/NotFound/NotFound';
import Org from 'components/Org/Org';
import ManageThematicAdmins from 'components/ThematicAdmin/ManageThematicAdmins';
import Unsubscribed from 'components/Unsubscribed/Unsubscribed';
import UserProfile from 'components/User/UserProfile';
import { omit } from 'lodash';
import { parse, stringify } from 'query-string';
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { getUserStore } from 'stores/RootStore';

function locationToken() {
  const search = parse(window.location.search);

  return search.sso_token;
}
function hashToken() {
  const { hash } = window.location;
  const hashSearch = hash.substring(hash.indexOf('?'));
  const search = parse(hashSearch);
  return search.sso_token;
}
function ssoToken() {
  return locationToken() || hashToken();
}

function strippedLocation(): string {
  const a = document.createElement('a');

  a.href = window.location.href;
  const search = parse(window.location.search);
  // if we find sso_token, strip it
  if (search.sso_token) {
    a.search = stringify(omit(search, 'sso_token'));
  }

  const [hashHead, hashTail] = window.location.hash.split('?');
  const hashSearch = parse(hashTail);
  // if we find sso_token on hash query, strip it
  if (hashSearch.sso_token) {
    const hashSearchString = stringify(omit(hashSearch, 'sso_token'));
    if (hashSearchString) {
      a.hash = `${ hashHead }?${ hashSearchString }`;
    } else {
      a.hash = hashHead;
    }
  }

  return a.href;
}
let redirectRequest: number | undefined;

export default () => {
  const token = ssoToken();
  if (typeof token === 'string' && redirectRequest === undefined) {
    Auth.setSession({
      accessToken: token,
      idToken: token
    });
    redirectRequest = setTimeout(() => {
      getUserStore().unsetUser();
      window.location.href = strippedLocation();
      window.location.reload();
    });
    return null;
  }

  return (
    <Switch>
      <AuthenticatedRoute
        exact={true}
        path="/callback"
        component={Home}
      />
      <AuthenticatedRoute exact={true} path="/" component={Home} />
      <AuthenticatedRoute path="/thematic-admin" component={ManageThematicAdmins} />
      <AuthenticatedRoute path="/c/:orgId" component={Org} />
      <AuthenticatedRoute
        exact={true}
        path="/profile"
        component={UserProfile}
      />
      <AuthenticatedRoute path="/redirects" component={IntegrationRedirects} />
      <Route path="/access_token*" component={LoadingHome} />
      <Route path="/unsubscribe" component={Unsubscribed} />
      <Route path="/sso/error" component={SsoError} />
      <Route path="/logout" component={Logout} />
      <Route path="/error*" component={Logout} />
      <Route component={NotFound} />
    </Switch>
  );
};
