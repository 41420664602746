import { library } from '@fortawesome/fontawesome-svg-core';
import { faBan, faStar as faEmptyStar } from '@fortawesome/pro-light-svg-icons';
import {
  faAngleDown,
  faAngleRight,
  faAngleUp,
  faArrowAltCircleLeft,
  faArrowAltToLeft,
  faArrowAltToRight,
  faArrowRight,
  faBadge,
  faBadgeCheck,
  faBalanceScale,
  faBolt,
  faCalendar,
  faChartBar,
  faChartLineDown,
  faChartPie,
  faCheck,
  faCheckCircle,
  faChevronDoubleDown,
  faChevronDoubleLeft,
  faChevronDoubleRight,
  faChevronDoubleUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCloudUpload,
  faCloudUploadAlt,
  faComment,
  faComments,
  faCompressAlt,
  faCopy,
  faDatabase,
  faEdit,
  faEllipsisV,
  faEnvelope,
  faExclamation,
  faExclamationTriangle,
  faExpandAlt,
  faEye,
  faEyeSlash,
  faFilter,
  faFlask,
  faGem,
  faHashtag,
  faHome,
  faInfoCircle,
  faLaugh,
  faLightbulb,
  faListOl,
  faLock,
  faMagic,
  faMap,
  faMeteor,
  faMicroscope,
  faMinus, faPause, faPencil,
  faPhone,
  faPlay,
  faPlug,
  faPlus,
  faProjector,
  faQuestion,
  faQuoteRight,
  faRedo,
  faSave,
  faSearch,
  faShareSquare,
  faSignOut,
  faSirenOn,
  faSortAmountDown,
  faSortAmountUp,
  faSparkles,
  faSpinner,
  faStar,
  faStarExclamation,
  faSyncAlt,
  faTable,
  faTachometerAlt,
  faTag,
  faTally,
  faTasksAlt,
  faText,
  faThumbsDown,
  faThumbsUp,
  faTimes,
  faTimesCircle,
  faTrash,
  faTrashAlt,
  faTypewriter,
  faUndo,
  faUpload,
  faUser,
  faUserCog,
  faUsers,
  faVideo,
  faWaveTriangle,
  faWrench,
  faArrowLeft,
  faList
} from '@fortawesome/pro-solid-svg-icons';

export default function registerIcons() {
  library.add(
    faAngleDown,
    faAngleRight,
    faAngleUp,
    faArrowAltCircleLeft,
    faArrowAltToLeft,
    faArrowAltToRight,
    faArrowRight,
    faBadge,
    faBadgeCheck,
    faBalanceScale,
    faBolt,
    faBan,
    faCalendar,
    faChartBar,
    faChartLineDown,
    faChartPie,
    faCheck,
    faCheckCircle,
    faChevronDoubleDown,
    faChevronDoubleLeft,
    faChevronDoubleRight,
    faChevronDoubleUp,
    faChevronDown,
    faChevronUp,
    faChevronLeft,
    faChevronRight,
    faCloudUpload,
    faCloudUploadAlt,
    faComment,
    faComments,
    faCompressAlt,
    faCopy,
    faDatabase,
    faEdit,
    faEmptyStar,
    faEnvelope,
    faEllipsisV,
    faExclamation,
    faExclamationTriangle,
    faExpandAlt,
    faEye,
    faEyeSlash,
    faFilter,
    faFlask,
    faGem,
    faHashtag,
    faHome,
    faInfoCircle,
    faLaugh,
    faLightbulb,
    faListOl,
    faLock,
    faMagic,
    faMap,
    faMeteor,
    faMicroscope,
    faMinus,
    faPencil,
    faPhone,
    faPlay,
    faPlug,
    faPlus,
    faPause,
    faProjector,
    faQuoteRight,
    faQuestion,
    faRedo,
    faSave,
    faSearch,
    faSignOut,
    faSirenOn,
    faShareSquare,
    faSortAmountDown,
    faSortAmountUp,
    faSparkles,
    faSpinner,
    faStar,
    faStarExclamation,
    faSyncAlt,
    faTachometerAlt,
    faTable,
    faTag,
    faTally,
    faTasksAlt,
    faTimesCircle,
    faTimes,
    faText,
    faThumbsDown,
    faThumbsUp,
    faTrash,
    faTrashAlt,
    faTypewriter,
    faUndo,
    faUndo,
    faUpload,
    faUser,
    faUserCog,
    faUsers,
    faVideo,
    faWaveTriangle,
    faWrench,
    faArrowLeft,
    faList
  );
}
