<template>
  <widget-wrapper
    :title="config.title"
    :loading="loading"
    :error="error"
    :warning="warning"
    :panel-order="panelOrder"
    :widget-order="widgetOrder"
  >
    <div
      slot="subtitle"
      :class="{ 'widget-subtitle-clickable': canNavigate }"
      class="widget-subtitle"
      @click="doNavigate()"
    >
      <div class="widget-subtitle-text">
        <span class="key-text">
          {{ subtitle }}
          <font-awesome-icon
            v-if="canNavigate"
            class="link-icon"
            icon="chart-bar"
          />
        </span>
      </div>
      <div class="widget-display-categories">
        Volume
      </div>
    </div>
    <div
      :class="{ empty: empty }"
      class="themes-body widget-body"
    >
      <div class="legend">
        <svg
          class="key-square"
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="7"
            cy="7"
            r="7"
            width="14"
            height="14"
            :fill="colors.primary500"
          />
        </svg>
        <span class="key-text">{{ baselineName }}</span>
        <svg
          class="key-square"
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="7"
            cy="7"
            r="7"
            width="14"
            height="14"
            :fill="colors.orange500"
          />
        </svg>
        <span class="key-text">{{ comparisonName }}</span>
      </div>
      <div
        v-for="(theme, index) in changedThemes"
        :key="index + theme.name"
        class="theme-row"
      >
        <div
          :class="{ 'clickable-theme': canClickTheme }"
          class="theme-name"
          @click="doNavigate({code: theme.code, title: theme.name},
                             theme.subtheme ? {code: theme.subthemeCode, title: theme.subtheme} : undefined)"
        >
          <span>{{ theme.name }}</span>
        </div>
        <bar-difference-normalized
          :baseline-width="theme.volumeBaselineNormalized"
          :comparison-width="theme.volumeComparisonNormalized"
          :baseline="theme.volumeBaseline"
          :comparison="theme.volumeComparison"
          suffix="%"
          dp="0"
        />
      </div>
      <div
        v-if="empty"
        class="empty-change-list"
      >
        No changes found
      </div>
    </div>
    <summary-component
      v-if="isSummaryEnabled"
      :filters="filters"
      :theme="selectedTheme"
      :source="source"
      :can-navigate="canNavigate"
      :comparison-themes="comparisonThemes"
      @doNavigate="
        () =>
          doNavigate(
            selectedTheme.theme,
            selectedTheme.subtheme,
            selectedTheme.volumeBy,
            true
          )
      "
    />
    <comment-component
      v-if="isCommentsEnabled"
      :filter-string="filters ? filters.baseline : undefined"
      :theme="selectedTheme"
      :source="source"
      :can-navigate="canNavigate"
      @doNavigate="
        () =>
          doNavigate(
            selectedTheme.theme,
            selectedTheme.subtheme,
            selectedTheme.volumeBy,
            true
          )
      "
    />
  </widget-wrapper>
</template>

<script>
import { get, slice, sortBy } from 'lodash';
import { ReactInVue } from 'vuera';

import { CommentComponent as ReactCommentComponent } from 'components/Dashboard/Widgets/CommentComponent';
import SummaryComponent from './Components/SummaryComponent';
import BarDifferenceNormalized from './Components/BarDifferenceNormalized.vue';
import CanNavigateMixin from 'vue/dashboards/Dashboard/MixIns/CanNavigateMixin';
import WidgetWrapper from './WidgetWrapper';
import colors from 'vue/styles/element-variables.scss';

export default {
  name: 'THEMES_CHANGES',
  components: {
    BarDifferenceNormalized,
    WidgetWrapper,
    SummaryComponent,
    CommentComponent: ReactInVue(ReactCommentComponent),
  },
  mixins: [CanNavigateMixin('explore')],
  props: {
    config: { default: undefined, type: Object },
    filters: { default: undefined, type: Object },
    context: { default: undefined, type: Object },
    data: { default: undefined, type: Object },
    warning: { default: undefined, type: String },
    error: { default: undefined, type: String },
    limit: { default: 5, type: Number },
    loading: { default: false, type: Boolean },
    source: { default: undefined, type: String },
    panelOrder: { type: Number },
    widgetOrder: { type: Number },
  },
  data() {
    return {
      colors: colors,
    };
  },
  computed: {
    baselineName() {
      return get(this, 'data.baseline.name');
    },
    comparisonName() {
      return get(this, 'data.comparison.name');
    },
    comparisonThemes() {
      return get(this, 'data.comparison');
    },
    empty() {
      return this.changedThemes.length === 0;
    },
    subtitle() {
      const title = `Biggest changes in volumes per theme between ${this.baselineName} and ${this.comparisonName}`;
      return title;
    },
    sortedThemes() {
      const themes = get(this, 'data.themes', []);
      return sortBy(themes, (theme) => {
        const { volumeBaselineNormalized: baseline, volumeComparisonNormalized: comparison } = theme;
        return -Math.abs(baseline - comparison);
      });
    },
    changedThemes() {
      return slice(this.sortedThemes, 0, this.limit);
    },
    count() {
      return get(this, 'data.baseline.count');
    },
    previousCount() {
      return get(this, 'data.baseline.count');
    },
  },
  watch: {
    config: {
      handler (config) {
        this.isCommentsEnabled = config.showComments;
        this.isSummaryEnabled = config.showSummary;
      },
      immediate: true
    },
  },
  methods: {},
};
</script>


<style lang="scss" scoped></style>
