import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FilterType, IntegrationType, WorkflowActionType } from 'api/enums';
import { AnalysisFilterCut, AnalysisViewSource, Segment as SavedFilter, SegmentFilters } from 'api/interfaces';
import { WorkflowConfiguration } from 'api/workflow-interfaces';
import { filterPopUpId } from 'components/Filters/types';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Button, Dropdown, Form, Segment } from 'semantic-ui-react';
import { getThemesStore } from 'stores/RootStore';
import EmailAction, { EmailsObject } from '../components/EmailAction';
import SlackAction from '../components/SlackAction';
import { ACTION_OPTIONS } from '../constants';
import SingleFilter from 'components/Filters/SingleFilter';
import { TemplateProps } from './WorkflowTemplateBase';
import './route-template.scss';
import { DateShortcutMap, getSelectedCuts } from 'lib/filters/saved-filter-helper';
import { createTagFilter } from 'lib/filters/tag-filter-helper';
import { createThemeFilter } from 'lib/filters/theme-filter-helper';
import { createCategoryFilter } from 'lib/filters/category-filter-helper';
import { createSentimentFilter } from 'lib/filters/sentiment-filter-helper';
import { DatasetVis } from 'stores/data-structure/datasets';
import { flatten, parseAsNested } from 'lib/dataset-tool-parser';
import { getIdFromDatasetVis } from 'lib/dataset-picker-helper';
import { initializeFilters } from 'components/Filters/filters.service';
import { QueryFilter } from 'stores/types';
import { queryFiltersToSegmentFilters } from 'lib/query-filters-to-segment-filters';
import { getSlackIntegrationId } from '../workflow-helper';

export interface RouteTemplateState {
  enteredEmailValidation: boolean;
  enteredEmailValue: string;
  selectedRole: string;
  selectedSurveyId: string | undefined;
  selectedVisualizationId: string | undefined;
  selectedViewId: string | undefined;
  selectedNotifyType: string | undefined;
  fetchingData: boolean;
  templateTitle: string;
  enteredEmails: string[];
  selectedSlackIntegration?: string;
  selectedSlackChannel?: string;
  configCorrect: boolean;
  selectedFilterRql: string;
  selectedFilters: SegmentFilters;
}
@inject(
  'analysisConfigStore',
  'workflowsStore',
  'organizationStore',
  'roleStore',
  'surveyStore',
  'filterStore',
  'integrationsStore'
)
@observer
class RouteTemplate extends React.Component<TemplateProps, RouteTemplateState> {
  state = {
    selectedRole: '',
    selectedSurveyId: undefined,
    selectedVisualizationId: undefined,
    selectedViewId: undefined,
    selectedNotifyType: this.props.configuration ? '' : ACTION_OPTIONS[0].value,
    fetchingData: false,
    templateTitle: '',
    enteredEmails: [],
    configCorrect: false,
    selectedFilterRql: '',
    selectedFilters: {},
    selectedSlackChannel: '',
    enteredEmailValidation: false,
    enteredEmailValue: ''
  } as RouteTemplateState;

  get roleStore() {
    return this.props.roleStore!;
  }

  get surveyStore() {
    return this.props.surveyStore!;
  }

  get filterStore() {
    return this.props.filterStore!;
  }

  get integrationsStore() {
    return this.props.integrationsStore!;
  }

  static configurationMatches(configuration: WorkflowConfiguration): { dimension: string } | null {
    if (configuration.name === 'Route Template' || configuration.templateId === 'route') {
      return { dimension: 'route' };
    }
    return null;
  }

  componentDidMount() {
    this.initialize();
  }

  initialize = async () => {
    this.setState({ fetchingData: true });
    this.suggestName();
    // fetch surveys and roles if necessary
    const promises = [] as Promise<void>[];
    if (this.roleStore.roles.length === 0) {
      promises.push(this.roleStore.fetchRoles());
    }
    if (!this.surveyStore.surveysJson) {
      promises.push(this.surveyStore.fetchSurveys());
    }
    await Promise.all(promises);
    if (this.props.configuration) {
      this.initializeConfig();
    }
    this.preselectDefaults();
    await this.initializeFilters();
    this.setState({ fetchingData: false });
    this.determineConfigCorrect();
  };

  initializeConfig = () => {
    const { configuration: config } = this.props;
    if (config) {
      const matchDetectionParams = config.stateMachine.States.MatchDetection.Parameters;
      const notify = config.stateMachine.States.Notify;
      const currentIntegration = this.integrationsStore.getConnectedIntegrationsOfType(IntegrationType.SLACK);
      let notifyType: WorkflowActionType | undefined;
      let selectedSlackIntegration = '';
      let selectedSlackChannel = '';
      let enteredEmails: string[] = [];
      if (notify.Resource === 'local:notify_email') {
        notifyType = WorkflowActionType.EMAIL;
        enteredEmails = notify.Parameters.emailList.split(',');
      } else if (notify.Resource === 'local:notify_slack') {
        notifyType = WorkflowActionType.SLACK;
        selectedSlackIntegration = getSlackIntegrationId(config, currentIntegration);
        selectedSlackChannel = notify.Parameters.channel;
      }
      this.setState({
        selectedNotifyType: notifyType,
        selectedSlackIntegration,
        selectedSlackChannel,
        enteredEmails,
        selectedFilterRql: matchDetectionParams.filter,
        selectedFilters: matchDetectionParams.filterMetadata,
      });
    }
  };

  initializeFilters = async () => {
    const {
      selectedSurveyId,
      selectedViewId,
      selectedVisualizationId,
    } = this.state;

    if (!selectedSurveyId) {
      return;
    }
    const selectedFilters = this.state.selectedFilters;
    const source: AnalysisViewSource = {
      survey: selectedSurveyId,
      visualization: selectedVisualizationId || '_',
      view: selectedViewId
    };

    initializeFilters(
      source,
      [FilterType.DATE],
      selectedFilters
    );
  }

  preselectDefaults = () => {
    const { selectedRole, configuration: config } = this.props;
    const defaultRole = this.roleStore.roles.find((role) => role.name === 'Dataset Administration');
    if (defaultRole) {
      this.selectedRole(null, { value: defaultRole.id });
    } else {
      this.selectedRole(null, { value: this.roleStore.roles[0].id });
    }
    const flattenedDatasets = this.getFlattenedDatasets();

    if (flattenedDatasets[0]) {
      this.handleSurveySelection(null, { value: getIdFromDatasetVis(flattenedDatasets[0]) });
    }

    if (config) {
      const matchDetectionParams = config.stateMachine.States.MatchDetection.Parameters;
      if (selectedRole) {
        this.selectedRole(null, { value: `${ selectedRole }` });
      }
      if (matchDetectionParams.surveyId) {
        this.setState({
          selectedSurveyId: matchDetectionParams.surveyId,
          selectedVisualizationId: matchDetectionParams.visualizationId,
          selectedViewId: matchDetectionParams.viewId,
        });
      }
    }
  };

  suggestName = () => {
    const selectedDatasetName = this.getSelectedDataset()?.fullTitle;

    let suggestedTitle = 'Comments that match';
    if (selectedDatasetName) {
      suggestedTitle += ` in ${ selectedDatasetName }`;
    }
    const selectedFiltersTitle = this.getSelectedFiltersTitle();
    if (!!selectedFiltersTitle && selectedFiltersTitle.length > 0 && selectedFiltersTitle !== 'All') {
      suggestedTitle += `: ${ selectedFiltersTitle }`;
    }
    this.props.onSuggestName(suggestedTitle);
  };

  getSelectedFiltersTitle = () => {
    const { selectedFilters } = this.state;
    let selectedFiltersTitleString = this.filterStore.queryTitles.baseline;
    if (Object.keys(selectedFilters).length === 0) {
      // empty title if no filters are selected
      selectedFiltersTitleString = '';
    }
    if (selectedFiltersTitleString !== 'All') {
      return selectedFiltersTitleString;
    }
    return;
  }

  selectedRole = (_e: React.SyntheticEvent<HTMLElement> | null, { value }) => {
    this.setState({ selectedRole: value }, () => {
      this.determineConfigCorrect();
    });
  };

  handleSurveySelection = (_e: React.SyntheticEvent<HTMLElement> | null, { value }) => {
    const flattenedDatasets = this.getFlattenedDatasets();

    if (flattenedDatasets.length === 0) {
      return;
    }

    const selectedDataset = flattenedDatasets.find((dataset) => getIdFromDatasetVis(dataset) === value);
    const selectedSurveyId = selectedDataset?.surveyId;
    const selectedViewId = selectedDataset?.viewId;
    const selectedVisualizationId = selectedDataset?.visualizationId;

    this.setState(
      {
        selectedSurveyId,
        selectedVisualizationId,
        selectedViewId
      },
      () => {
        this.determineConfigCorrect();
        this.suggestName();
        this.initializeFilters();
      },
    );

    getThemesStore().resetHierarchy();
  };

  selectedNotifyTypeChange = (_e: React.SyntheticEvent<HTMLElement>, { value }) => {
    this.setState({ selectedNotifyType: value, enteredEmails: [], selectedSlackChannel: undefined }, () => {
      this.determineConfigCorrect();
    });
  };

  handleEnteredEmails = (emailsObject: EmailsObject) => {
    this.setState(
      {
        enteredEmails: emailsObject.emails,
        enteredEmailValidation: !!emailsObject.enteredValueValidation
      }, () => {
        if (!!emailsObject.enteredValueValidation && !!emailsObject.enteredValue) {
          this.setState({ enteredEmailValue: emailsObject.enteredValue });
        } else {
          this.setState({ enteredEmailValue: '' });
        }
        this.determineConfigCorrect();
      }
    );
  }

  handleSelectedChannel = (integrationId?: string, channel?: string) => {
    this.setState(
      {
        selectedSlackIntegration: integrationId,
        selectedSlackChannel: channel,
      },
      () => {
        this.determineConfigCorrect();
      },
    );
  };

  onFilterChange = (filters: Record<string, QueryFilter>) => {
    const rql = this.filterStore.selections.baseline.query;
    const dateShortcuts = this.filterStore.dateShortcutMaps['baseline'];
    const segmentFilters = queryFiltersToSegmentFilters(filters, dateShortcuts);

    this.setState({
      selectedFilters: segmentFilters,
      selectedFilterRql: rql
    }, () => { this.suggestName(); });
  };

  onSavedFilterSelected = (savedFilter: SavedFilter) => {
    const segmentFilters: SegmentFilters = savedFilter.configuration.filters;
    const queryFilters = this.filterStore.filters.baseline || {};
    const analysisFilters = this.props.analysisConfigStore?.filters ?? [];

    const combinedFilters = [
      ...analysisFilters,
      createTagFilter(),
      createThemeFilter(),
      createCategoryFilter(),
      createSentimentFilter()
    ];

    const dateShortcuts: DateShortcutMap = Object
      .keys(segmentFilters)
      .reduce((result, filterId) => {
        return {
          ...result,
          [filterId]: this.filterStore.dateShortcuts(filterId)
        };
      }, {});

    const selectedCuts = getSelectedCuts(
      segmentFilters,
      queryFilters,
      combinedFilters,
      dateShortcuts
    );

    const selectedFilterRql: string = Object
      .values(selectedCuts)
      .reduce((result: string[], cuts: AnalysisFilterCut[]) => {
        const rql = cuts.map(cut => cut.rql).join(';');
        if (rql.length === 0) {
          return result;
        }
        return [...result, `(${rql})`];
      }, []).join(';');

    this.setState({
      selectedFilterRql,
      selectedFilters: savedFilter.configuration.filters
    }, () => {
      this.suggestName();
    });
  };

  determineConfigCorrect = () => {
    const {
      selectedSurveyId,
      selectedRole,
      selectedNotifyType,
      enteredEmails,
      selectedSlackIntegration,
      enteredEmailValidation,
      selectedSlackChannel,
    } = this.state;
    let configCorrect = !!selectedSurveyId && !!selectedRole;

    if (selectedNotifyType === WorkflowActionType.EMAIL) {
      configCorrect = configCorrect && (enteredEmails.length > 0 || enteredEmailValidation);
    } else if (selectedNotifyType === WorkflowActionType.SLACK) {
      configCorrect = configCorrect && !!selectedSlackIntegration && !!selectedSlackChannel;
    } else {
      configCorrect = false;
    }
    this.setState({ configCorrect });
  };

  onSave = async () => {
    const { workflowId } = this.props;
    const {
      selectedSurveyId,
      selectedViewId,
      selectedVisualizationId,
      selectedNotifyType,
      selectedSlackIntegration,
      selectedSlackChannel,
      enteredEmails,
      selectedRole,
      selectedFilterRql,
      selectedFilters,
      enteredEmailValue
    } = this.state;

    const selectedFiltersTitle = this.getSelectedFiltersTitle();
    let notifyBlock = {};

    const updatedEmailsList = enteredEmailValue.length > 0 ? [...enteredEmails, enteredEmailValue] : enteredEmails;

    if (selectedNotifyType === WorkflowActionType.EMAIL) {
      notifyBlock = {
        Resource: 'local:notify_email',
        Parameters: {
          emailList: updatedEmailsList.join(','),
          'templateData': {
            'filtersTitle': selectedFiltersTitle,
          },
          template: '5B4TG9MZ9X47ZXKJ1B73QN1CNJEA',
          'data.$': '$.matches',
          'workflowId.$': '$.workflowId',
          'runId.$': '$.runId',
          'workflowName.$': '$.workflowName',
          'accessToken.$': '$.accessToken',
          'clientUrl.$': '$.clientUrl',
        },
        ResultPath: '$.result',
        Type: 'Task',
        Next: 'End',
      };
    } else if (selectedNotifyType === WorkflowActionType.SLACK) {
      notifyBlock = {
        Resource: 'local:notify_slack',
        Parameters: {
          integrationId: `${ selectedSlackIntegration }`,
          channel: selectedSlackChannel,
          'templateData': {
            'filtersTitle': selectedFiltersTitle,
          },
          template: '5B4TG9MZ9X47ZXKJ1B73QN1CNJEA',
          'data.$': '$.matches',
          'accessToken.$': '$.accessToken',
          'clientUrl.$': '$.clientUrl',
          'workflowId.$': '$.workflowId',
          'runId.$': '$.runId',
          'workflowName.$': '$.workflowName',
        },
        ResultPath: '$.result',
        Type: 'Task',
        Next: 'End',
      };
    }

    let config = {
      stateMachine: {
        StartAt: 'MatchDetection',
        States: {
          MatchDetection: {
            Resource: 'local:process_match',
            Parameters: {
              surveyId: selectedSurveyId,
              visualizationId: selectedVisualizationId,
              viewId: selectedViewId,
              source: { 'uploadId.$': '$.input.uploadId' },
              filter: selectedFilterRql,
              filterMetadata: selectedFilters,
              'accessToken.$': '$.accessToken',
              'clientUrl.$': '$.clientUrl',
              'artifactsDestination.$': '$.artifactsDestination',
            },
            InputPath: '$.input',
            ResultPath: '$.matches',
            Type: 'Task',
            Next: 'Choices',
          },
          Choices: {
            Type: 'Choice',
            Choices: [{ Variable: '$.matches.itemCount', NumericGreaterThan: 0, Next: 'Notify' }],
            Default: 'End',
          },
          Notify: notifyBlock,
          End: { Type: 'Pass', End: true },
        },
      },
      templateId: 'route',
    };
    let trigger = {
      calledAfterProcessing: [selectedSurveyId],
    };

    const workflowAnalyticsEvent = workflowId === 'create' ? 'Workflows: Save Workflow' : 'Workflows: Update Workflow';
    analytics.track(workflowAnalyticsEvent, {
      category: 'Workflows',
      template: 'Route Template',
      notify: selectedNotifyType,
    });

    await this.props.onSave(selectedRole, trigger, config);
    this.resetTemplateScroll();
  };

  resetTemplateScroll = () => {
    const { createWorkflowErroredMessage, updateWorkflowErroredMessage } = this.props.workflowsStore!;

    const anyErrorsExist = !!createWorkflowErroredMessage || !!updateWorkflowErroredMessage;

    if (anyErrorsExist) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  };

  getFlattenedDatasets():DatasetVis[] {
    const { surveyStore, organizationStore } = this.props;
    if (!organizationStore || !surveyStore) {
      return [];
    }
    const { surveysJson, getSurveyCanAction } = surveyStore;
    const { orgId, getCanAction } = organizationStore;

    const canThematicAdmin = getCanAction('manage:internalResource');
    const nested = parseAsNested(
      orgId,
      surveysJson || [],
      getSurveyCanAction,
      canThematicAdmin,
      'read:survey'
    );
    return flatten(nested);
  }

  getSelectedDatasetId() {
    const {
      selectedSurveyId,
      selectedViewId,
      selectedVisualizationId,
    } = this.state;
    return `${selectedSurveyId}-${selectedViewId || ''}-${selectedVisualizationId || '_'}`;
  }

  getSelectedDataset(): DatasetVis | undefined {
    const flattenedDatasets = this.getFlattenedDatasets();
    const selectedDataSetId = this.getSelectedDatasetId();
    return flattenedDatasets.find((dataset) => getIdFromDatasetVis(dataset) === selectedDataSetId);
  }

  render(): JSX.Element | null {
    const {
      fetchingData,
      configCorrect,
      selectedRole,
      selectedSurveyId,
      selectedNotifyType,
      enteredEmails,
      selectedSlackChannel,
    } = this.state;

    const { performingEvent, loading, workflowId, onCancel } = this.props;

    const flattened = this.getFlattenedDatasets();
    const selectedDataSetId = this.getSelectedDatasetId();

    const datasetOptions = flattened.map((vis: DatasetVis) => {
      return {
        key: getIdFromDatasetVis(vis),
        text: vis.fullTitle,
        value: getIdFromDatasetVis(vis)
      };
    });

    return (
      <Form className="route-template">
        <Form.Field className="route-template-role">
          <h2>Conditions</h2>
          <label>
            Run workflow using the&nbsp;
            <Dropdown
              inline={true}
              className="route-template-inline-dropdown"
              placeholder="Select role"
              loading={fetchingData}
              options={(this.roleStore.roles.filter((role) => !role.isUserSpecific) || []).map((value) => {
                return {
                  key: value.id,
                  text: value.name,
                  value: value.id,
                };
              })}
              value={selectedRole}
              onChange={this.selectedRole}
            />
            &nbsp;role
          </label>
        </Form.Field>
        <Form.Field className="route-template-dataset">
          <h2>Analyze</h2>
          <div className="route-template-dataset-options">
            <Dropdown
              placeholder="Select dataset"
              icon={<FontAwesomeIcon icon="chevron-down" />}
              fluid={true}
              selection={true}
              loading={fetchingData}
              options={datasetOptions}
              value={selectedDataSetId}
              onChange={this.handleSurveySelection}
            />
            {selectedSurveyId && (
              <span className="route-template-dataset__filter">
                <SingleFilter
                  testId="route-template-filter"
                  onSavedFilterSelected={savedFilter => this.onSavedFilterSelected(savedFilter)}
                  filterPopUpId={filterPopUpId.RouteTemplate}
                  surveyId={selectedSurveyId}
                  disabledFilterTypes={[FilterType.DATE]}
                  onChange={this.onFilterChange}
                  buttonSize="medium"
                />
              </span>
            )}
          </div>
        </Form.Field>
        <Form.Field>
          <Segment className="email-action">
            <label>Action</label>
            <Dropdown
              fluid={true}
              selection={true}
              options={ACTION_OPTIONS}
              value={selectedNotifyType}
              onChange={this.selectedNotifyTypeChange}
            />
            {selectedNotifyType === WorkflowActionType.EMAIL && (
              <EmailAction
                existingEmails={enteredEmails}
                enteredEmails={this.handleEnteredEmails}
              />
            )}
            {selectedNotifyType === WorkflowActionType.SLACK && (
              <SlackAction
                existingSlackChannel={selectedSlackChannel}
                selectedSlackChannel={this.handleSelectedChannel}
              />
            )}
          </Segment>
        </Form.Field>
        <Button type="button" disabled={performingEvent || fetchingData} onClick={onCancel}>
          Cancel
        </Button>
        <Button
          type="button"
          color="blue"
          loading={loading || fetchingData}
          disabled={performingEvent || fetchingData || !configCorrect}
          onClick={this.onSave}
        >
          {workflowId === 'create' ? 'Save workflow' : 'Update'}
        </Button>
      </Form>
    );
  }
}

export default RouteTemplate;
