import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import ThemeFrequency from 'components/ThemeEditor/ThemeFrequency';
import { XYCoord } from 'dnd-core';
import { isEmpty, trim } from 'lodash';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { DropTargetMonitor, useDrag, useDrop } from 'react-dnd';
import { Button, Form, Input, Label, Modal } from 'semantic-ui-react';
import { ThemesStoreInterface } from 'stores/ThemesStore';
import './theme-card.scss';
import { HoverHint, ThemeCardInterface } from './ThemeTree';

export enum ThemeNodeTypes {
  THEME_CARD = 'theme-card'
}

interface ThemeCardStoreProps {
  themesStore?: ThemesStoreInterface;
}

export interface ThemeCardProps extends ThemeCardStoreProps {
  isTail?: boolean;
  hasChildren?: boolean;
  hasMerged: boolean;
  hasMergedNew: boolean;
  isNew: boolean;
  toReview: boolean;
  expanded?: boolean;
  hoverHint?: HoverHint;
  id: string;
  parentId?: string;
  selected: boolean;
  title: string;
  activateNode: (id: string) => void;
  addSubtheme?: (title: string, id: string) => void;
  deleteTheme: (id: string, parentId?: string) => void;
  executeHint: (item: ThemeCardInterface) => void;
  moveHint: (hoverHint?: HoverHint) => void;
  toggleNode?: (id: string) => void;
}

interface DragItem extends ThemeCardInterface {
  type: string;
}

export function calculatePosition(
  ref: React.RefObject<HTMLDivElement>,
  monitor: DropTargetMonitor
) {
  if (!ref.current) {
    return { topHalf: false };
  }
  const hoverBoundingRect = ref.current.getBoundingClientRect();
  const { bottom, top } = hoverBoundingRect;

  // Get vertical middle
  const hoverMiddleY = (bottom - top) / 2;

  // Determine mouse position
  const clientOffset = monitor.getClientOffset();

  // Get pixels to the top
  const hoverClientY = (clientOffset as XYCoord).y - top;

  const topHalf = hoverClientY < hoverMiddleY;
  return { topHalf };
}

const ThemeCard: React.FC<ThemeCardProps> = props => {
  const {
    activateNode,
    deleteTheme,
    expanded,
    hasChildren,
    hasMerged,
    hasMergedNew,
    isNew,
    toReview,
    hoverHint,
    isTail,
    id,
    parentId,
    title,
    executeHint,
    moveHint,
    selected,
    addSubtheme,
    themesStore,
    toggleNode
  } = props as ThemeCardProps;

  const ref = React.useRef<HTMLDivElement>(null);
  const mergeRef = React.useRef<HTMLDivElement>(null);
  const promoteRef = React.useRef<HTMLDivElement>(null);
  const childRef = React.useRef<HTMLDivElement>(null);

  const [isOpen, setOpen] = React.useState(false);
  const [newTitle, setTitle] = React.useState('');
  const [startingDrag, setStartingDrag] = React.useState(false);
  const [{ isOverCurrent }, drop] = useDrop({
    accept: ThemeNodeTypes.THEME_CARD,
    collect: monitor => ({
      isOverCurrent: monitor.isOver({ shallow: true })
    }),
    drop(item: DragItem) {
      if (!ref.current || !isOverCurrent) {
        return;
      }
      executeHint(item);
    },

    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current || !isOverCurrent) {
        return;
      }
      const position = calculatePosition(ref, monitor);

      const notParent = item.id !== parentId;
      if (notParent) {
        moveHint({ id, parentId, ...position });
      } else {
        moveHint(undefined);
      }
    }
  });
  const [, mergeDrop] = useDrop({
    accept: ThemeNodeTypes.THEME_CARD,
    drop(item: DragItem) {
      if (!ref.current) {
        return;
      }
      executeHint(item);
    },
    hover(item: DragItem) {
      if (!mergeRef.current) {
        return;
      }
      const notSelf = item.id !== id;
      const notParent = item.id !== parentId;

      if (notSelf && notParent) {
        moveHint({
          id,
          parentId,
          merge: notSelf && notParent,
          overMerge: notParent
        });
      } else {
        moveHint(undefined);
      }
    }
  });
  const [, promoteDrop] = useDrop({
    accept: ThemeNodeTypes.THEME_CARD,
    drop(item: DragItem) {
      if (!ref.current) {
        return;
      }
      executeHint(item);
    },

    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (!promoteRef.current) {
        return;
      }
      const isSelf = item.id === id && item.id === parentId;
      if (isSelf) {
        moveHint(undefined);
      } else {
        const position = calculatePosition(ref, monitor);

        moveHint({ id, parentId, ...position, promote: true });
      }
    }
  });
  const [, childDrop] = useDrop({
    accept: ThemeNodeTypes.THEME_CARD,
    collect: monitor => ({
      isOverCurrent: monitor.isOver({ shallow: true })
    }),
    drop(item: DragItem) {
      if (!childRef.current) {
        return;
      }
      executeHint(item);
    },

    hover(item: DragItem) {
      if (!childRef.current) {
        return;
      }
      const notSelf = item.id !== id;
      moveHint({
        id,
        parentId,
        child: notSelf && !parentId
      });
    }
  });

  const [{ isDragging }, drag, preview] = useDrag({
    collect: monitor => ({
      isDragging: monitor.isDragging()
    }),
    item: { type: ThemeNodeTypes.THEME_CARD, id, parentId },
    begin: () => {
      setStartingDrag(true);
      setTimeout(() => setStartingDrag(false));
    },
    end: () => {
      moveHint(undefined);
    }
  });

  const topHalf = hoverHint && hoverHint.topHalf;
  const bottomHalf = hoverHint && hoverHint.topHalf === false;
  const promote = hoverHint && hoverHint.promote;

  const mergeable = hoverHint && hoverHint.merge;
  const overMerge = hoverHint && hoverHint.overMerge;
  const makeChild = hoverHint && hoverHint.child;

  drag(drop(ref));
  mergeDrop(mergeRef);
  promoteDrop(promoteRef);
  childDrop(childRef);

  function addChild() {
    if (addSubtheme) {
      addSubtheme(newTitle, id);
    }
    setTitle('');
    setOpen(false);
  }

  return (
    <>
      <div
        ref={ref}
        className={classnames('theme-card ob-theme-card', {
          'hover-top': topHalf,
          'hover-bottom': bottomHalf,
          'is-tail': isTail,
          'is-dragging': isDragging,
          'is-subtheme': parentId,
          'make-child': !overMerge && makeChild,
          'starting-drag': startingDrag,
          mergeable: mergeable,
          promote: promote,
          selected: selected
        })}
        onClick={() => {
          activateNode(id);
          themesStore?.toggleThemeInfo(true);
        }}
      >
        <div className="theme-card__button-area" ref={promoteRef}>
          {!parentId && (
            <Button
              circular={true}
              disabled={!hasChildren}
              onClick={(event) => {
                event.stopPropagation();
                if (toggleNode) {
                  toggleNode(id);
                }
              }}
              size="small"
            >
              <FontAwesomeIcon icon={expanded ? 'chevron-down' : 'chevron-right'} />
            </Button>
          )}
        </div>

        <div className="theme-card__title" ref={mergeRef}>
          <div className="headline" ref={preview}>
            {title}
          </div>
          <div className="subtitle">
            {hasMerged ? <Label size="small">Merged</Label> : null}
            {toReview ? (
              <FontAwesomeIcon
                className="icon"
                icon="exclamation"
                fixedWidth={true}
              />
            ) : null}
            {isNew ? (
              <FontAwesomeIcon className="icon" icon="star" fixedWidth={true} />
            ) : null}
            {hasMergedNew ? (
              <FontAwesomeIcon
                className="icon"
                icon={['fal', 'star']}
                fixedWidth={true}
              />
            ) : null}
          </div>
          <Label size="small">Merge</Label>
        </div>
        <div className="theme-card__actions">
          {themesStore!.trackCommentCounts && <ThemeFrequency nodeId={id} />}
          {!parentId && (
            <Button
              basic={true}
              key="add"
              onClick={() => setOpen(true)}
              size="tiny"
              className="nw-theme-add"
              icon={
                <FontAwesomeIcon
                  className="icon"
                  icon="plus"
                  fixedWidth={true}
                />
              }
            />
          )}
          <Button
            basic={true}
            onClick={e => {
              e.stopPropagation();
              deleteTheme(id, parentId);
            }}
            key="delete"
            size="tiny"
            className="nw-theme-delete ob-theme-delete"
            icon={
              <FontAwesomeIcon
                className="icon"
                icon="trash"
                fixedWidth={true}
              />
            }
          />
        </div>

        {!parentId && <div className="theme-card__child-area" ref={childRef} />}
        {isTail && <div className="theme-card__subtheme-tail" />}
      </div>
      <Modal
        dimmer="blurring"
        open={isOpen}
        onClose={() => setOpen(false)}
        size="mini"
        style={{ minWidth: '400px' }}
      >
        <Modal.Header>Add new theme</Modal.Header>
        <Modal.Content>
          <Form noValidate="novalidate" onSubmit={addChild}>
            <Form.Field>
              <Input
                autoFocus={true}
                fluid={true}
                placeholder="Theme name"
                onChange={(e: React.FormEvent<HTMLInputElement>) =>
                  setTitle(e.currentTarget.value)
                }
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button
            color="blue"
            disabled={isEmpty(trim(newTitle))}
            onClick={addChild}
          >
            OK
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default inject('themesStore')(observer(ThemeCard, {}));
