<template>
  <widget-wrapper
    :title="config.title"
    :loading="loading"
    :error="error"
    :warning="warning"
    :panel-order="panelOrder"
    :widget-order="widgetOrder"
  >
    <div
      slot="subtitle"
      :class="{ 'widget-subtitle-clickable': canNavigate }"
      class="widget-subtitle"
      @click="doNavigate()"
    >
      <div class="widget-subtitle-text">
        {{ widgetSubtitle }}
        <font-awesome-icon
          v-if="canNavigate"
          class="link-icon"
          icon="chart-bar"
        />
      </div>
      <div class="widget-display-categories">
        <div class="category">
          Score
        </div>
        <div class="category large-category">
          Change
        </div>
      </div>
    </div>
    <div
      v-if="themes.length"
      class="themes-body widget-body"
    >
      <div
        v-for="(theme, index) in themes"
        :key="index + theme.name"
        class="theme-row theme-row-spacing"
      >
        <div
          v-if="!isWeighted"
          :class="{ 'clickable-theme': canClickTheme }"
          class="theme-name"
          @click="doNavigate({code: theme.basethemeCode, title: theme.basetheme},
                             theme.subthemeCode ? {code: theme.subthemeCode, title: theme.subtheme} : undefined)"
        >
          <span>{{ theme.name }}</span>
        </div>
        <theme-name-with-sources
          v-else
          :source="source"
          tool="explore"
          :theme="theme.name"
          :theme-components="theme.components"
          :theme-name="theme.name"
          :theme-sources="themeSources"
        />
        <div class="value">
          {{ theme.displayValue }}
        </div>
        <div class="change-value">
          <change-value
            :value="theme.value"
            :previous-value="theme.previousValue"
          />
        </div>
        <div class="spark-line">
          <spark-line :values="theme.history" />
        </div>
      </div>
    </div>
    <div
      v-else
      class="empty widget-body"
    >
      No changes found
    </div>
    <summary-component
      v-if="isSummaryEnabled"
      :filters="filters"
      :theme="selectedTheme"
      :source="source"
      :comparison-themes="comparisonThemes"
      :can-navigate="canNavigate"
      @doNavigate="
        () =>
          doNavigate(
            selectedTheme.theme,
            selectedTheme.subtheme,
            selectedTheme.volumeBy,
            true
          )
      "
    />
    <comment-component
      v-if="isCommentsEnabled"
      :filter-string="filters ? filters.baseline : undefined"
      :theme="selectedTheme"
      :source="source"
      :can-navigate="canNavigate"
      @doNavigate="
        () =>
          doNavigate(
            selectedTheme.theme,
            selectedTheme.subtheme,
            selectedTheme.volumeBy,
            true
          )
      "
    />
  </widget-wrapper>
</template>

<script>
import { get, slice, map } from 'lodash';
import { ReactInVue } from 'vuera';

import toFixed from 'vue/libs/to-fixed';

import { CommentComponent as ReactCommentComponent } from 'components/Dashboard/Widgets/CommentComponent';
import SummaryComponent from './Components/SummaryComponent';
import SparkLine from './Components/SparkLine.vue';
import ChangeValue from './Components/ChangeValue.vue';
import ThemeNameWithSources from './Components/ThemeNameWithSources';

import CanNavigateMixin from 'vue/dashboards/Dashboard/MixIns/CanNavigateMixin';

import WidgetWrapper from './WidgetWrapper';
import colors from 'vue/styles/element-variables.scss';

export default {
  name: 'SIGNIFICANT_SCORE_CHANGES',
  components: {
    ChangeValue,
    ThemeNameWithSources,
    SparkLine,
    WidgetWrapper,
    SummaryComponent,
    CommentComponent: ReactInVue(ReactCommentComponent),
  },
  mixins: [CanNavigateMixin('over-time')],
  props: {
    config: { default: undefined, type: Object },
    filters: { default: undefined, type: Object },
    context: { default: undefined, type: Object },
    data: { default: undefined, type: Object },
    warning: { default: undefined, type: String },
    error: { default: undefined, type: String },
    limit: { default: 5, type: Number },
    loading: { default: false, type: Boolean },
    source: { default: undefined, type: String },
    panelOrder: { type: Number },
    widgetOrder: { type: Number },
  },
  data() {
    return {
      colors: colors,
    };
  },
  computed: {
    comparisonThemes() {
      return get(this, 'data.comparison');
    },
    themeSources() {
      return get(this, 'data.metadata.sources', undefined);
    },
    themes() {
      if (!this.data) {
        return [];
      }
      const data = this.data;
      let themes = slice(data.result, 0, this.limit);
      themes = map(themes, (theme) => {
        let value = theme.scoreTrend;
        let score = value.history[value.history.length - 1];
        let fixedValue = Number(toFixed(score, this.decimalPoints(score)));
        let average = Number(toFixed(value.avg, this.decimalPoints(value.avg)));
        return {
          name: theme.subtheme ? theme.subtheme : theme.theme,
          basetheme: theme.theme,
          basethemeCode: theme.theme_code,
          subtheme: theme.subtheme ? theme.subtheme : undefined,
          subthemeCode: theme.subtheme ? theme.subtheme_code : undefined,
          previousValue: average,
          displayValue: fixedValue,
          value: fixedValue,
          history: value.history,
        };
      });

      return themes;
    },
    changeType() {
      return this.config.changeType;
    },
    widgetSubtitle() {
      return 'Largest changes in score';
    },
  },
  watch: {
    config: {
      handler (config) {
        this.isCommentsEnabled = config.showComments;
        this.isSummaryEnabled = config.showSummary;
      },
      immediate: true
    },
  },
  methods: {
    decimalPoints(value) {
      const score = Math.abs(value);
      if (score < 0.2) {
        return 2;
      } else if (score >= 0.2 && score < 2) {
        return 1;
      } else {
        return 0;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.key-square {
  height: 14px;
  display: inline-flex;
  margin-right: 5px;
  width: 14px;
}
.key-text {
  margin-right: 10px;
}
.theme-row-spacing {
  display: grid;
  grid-template-columns: auto 100px 125px 50px;
}

.theme-row .clickable-theme span {
  max-width: 350px;
}

.clickable-theme > span {
  width: auto;
}

.value {
  text-align: right;
}

.spark-line {
  height: 30px;
}
</style>
