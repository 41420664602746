export const SELECTION_STATUS = {
  Invalid: 'INVALID',
  InvalidCrossesBoundary: 'INVALID_CROSSES_BOUNDARY',
  InvalidTooManyWords: 'INVALID_TOO_MANY_WORDS',
  None: 'NONE',
  Valid: 'VALID',
} as const;

export interface SelectedPhrasePosition {
  x: number;
  y: number;
}

export type SelectionStatus = typeof SELECTION_STATUS[keyof typeof SELECTION_STATUS];

export const MAX_SELECTED_WORDS = 6;

interface PhraseSegmentItem {
  base: string;
  baseTitle: string;
  id: string;
  label: string;
}

export interface PhraseSegmentSubItem extends PhraseSegmentItem {
  sub: string;
  subTitle: string;
}

export interface PhraseSegmentBaseItem extends PhraseSegmentItem {
  children: PhraseSegmentSubItem[];
}
