import * as React from 'react';
import { AnswerResponse, AnswersDataSet, isNonComparisonResponse } from 'api/interfaces';
import fuzzNum from 'vue/libs/fuzz-num';
import { observer } from 'mobx-react-lite';
import './context.scss';
import { useComparisonFilters } from '../hooks/useComparisonFilters';
import { useSingleFilters } from '../hooks/useSingleFilters';
import { Button } from 'components/Shared/Button';
import { Tooltip } from 'components/Shared/Tooltip';
import { Metadata } from './Metadata';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ContextMetadataTooltip } from './ContextMetadataTooltip';
import { ContextComparison } from './ContextComparison';
import classNames from 'classnames';

export interface Props {
  answer: AnswerResponse;
  dataSets: AnswersDataSet[];
}

export const Context = observer(({ answer, dataSets }: Props) => {
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const [showMetaData, setShowMetaData] = React.useState(false);

  const translatedComparisons = useComparisonFilters(answer, dataSets);
  const translatedFilters = useSingleFilters(answer.filters, dataSets);

  const hasComparisonFilters = translatedComparisons && translatedComparisons.length > 0;

  return (
    <section className="context">
      <Tooltip
        position="bottom center"
        inverted={true}
        content={showMetaData ? 'Hide used data' : 'Show used data'}
        on={['hover', 'focus']}
        trigger={
          <span className="context__show-data-used">
            <Button
              inline={true}
              variant="tertiary"
              subvariant="neutral-text"
              onClick={() => setShowMetaData(!showMetaData)}
              size="small"
              ref={buttonRef}
            >
              <span className="context__chevron">
                <FontAwesomeIcon
                  icon={showMetaData ? 'chevron-up' : 'chevron-down'}
                  fixedWidth={true}
                />
              </span>
              <span>Used data</span>
            </Button>
          </span>
        }
      />
      {dataSets.length > 0 && !showMetaData && (
        <div className="context__metadata">
          {isNonComparisonResponse(answer) && (
            <>
              <span className="context__label">Comments</span>
              <span className="context__values ob-answers-comment-count">
                {fuzzNum(answer.metadata.count)} of {fuzzNum(answer.metadata.totalCount)}
              </span>
              <ContextMetadataTooltip
                totalCommentsCount={answer.metadata.totalCount}
                dataSets={dataSets}
                filters={translatedFilters}
              />
              <span className="context__bullet">•</span>
            </>
          )}
          <span className="context__label">Datasets</span>
          {dataSets.length > 0 && (
            <span className="context__data-set">
              <span>{dataSets[0].title}</span>
              {dataSets.length > 1 && <span className="context__data-set-count">+{dataSets.length - 1}</span>}
            </span>
          )}
          {translatedFilters.length > 0 &&
            <>
              <span className="context__bullet">•</span>
              <span className="context__label">{translatedFilters[0].filterName}</span>
              <span className="context__values">{translatedFilters[0].filterValues}</span>
              {translatedFilters.length > 1 && <span className="context__filter-count">+{translatedFilters.length - 1}</span>}
            </>
          }
        </div>
      )}
      <div className={classNames({
        'context__data': hasComparisonFilters || showMetaData
      })}>
        {hasComparisonFilters && (
          <ContextComparison
            translatedComparisons={translatedComparisons}
            dataSets={dataSets}
            showFilters={showMetaData}
          />
        )}
        {showMetaData && <Metadata answer={answer} dataSets={dataSets} orgId='' />}
      </div>
    </section>
  );
});
