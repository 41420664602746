<template>
  <div
    class="dashboard-header"
    :class="{ 'edit-mode': activeDashboardUIStore.isEditing }"
  >
    <el-alert
      v-if="requestPowerpointError"
      :title="`Uh oh! Something went wrong with this download.`"
      type="error"
      show-icon
    />
    <el-alert
      v-if="toggleFavError"
      :title="`Uh oh! Something went wrong favoriting this dashboard.`"
      type="error"
      show-icon
    />
    <el-alert
      v-if="activeDashboardUIStore.savingDashboardError"
      :title="`Uh oh! Something went wrong saving this dashboard`"
      type="error"
      show-icon
    />
    <div class="dashboard-title">
      <template v-if="!activeDashboardUIStore.isEditing">
        <h1>
          <el-tooltip
            v-if="canFavDashboards"
            :open-delay="300"
            :popper-options="popperOptions"
            placement="bottom-start"
          >
            <div
              v-if="favDashboard"
              slot="content"
            >
              Default dashboard
            </div>
            <div
              v-else
              slot="content"
            >
              Set as default dashboard
            </div>
            <div
              v-if="favDashboard"
              class="fav-star"
            >
              <font-awesome-icon
                fixed-width
                icon="star"
              />
            </div>
            <el-button
              v-else
              type="plain-text"
              @click="setFav"
            >
              <span
                v-if="favoritingDashboard"
                key="spin"
              >
                <font-awesome-icon
                  fixed-width
                  spin
                  icon="spinner"
                />
              </span>
              <span
                v-else
                key="empty"
              >
                <font-awesome-icon
                  fixed-width
                  :icon="['fal', 'star']"
                />
              </span>
            </el-button>
          </el-tooltip>
          {{ title }}
        </h1>
        <div class="dashboard-buttons">
          <el-button
            v-if="editable && canEditDashboard"
            class="ob-editable-dashboard"
            type="primary"
            title="Edit Dashboard"
            size="small"
            @click="toggleEditMode"
          >
            <font-awesome-icon icon="pencil" /> Edit
          </el-button>
          <el-button
            type="primary"
            size="small"
            @click="openSubscriptionWindow"
          >
            <font-awesome-icon icon="envelope" /> Share
          </el-button>
          <el-button
            ref="powerpoint-download"
            type="primary"
            :loading="requestingPowerpoint"
            size="small"
            @click="downloadPowerpoint"
          >
            <span v-if="!requestingPowerpoint"> <font-awesome-icon icon="file-powerpoint" /> Powerpoint </span>
          </el-button>
          <el-button
            v-if="canSeeDevOnlyTools"
            type="primary"
            size="small"
            title="Only visible to Thematic admins"
            @click="downloadJSON"
          >
            <font-awesome-icon icon="file-code" /> JSON
          </el-button>
        </div>
      </template>
      <template v-else>
        <dashboard-editable-string target="title" />
        <div class="dashboard-buttons">
          <el-button
            v-if="canEditDashboard"
            size="small"
            class="cancel-dashboard-edits"
            @click="activeDashboardUIStore.cancelEditingDashboard()"
          >
            Cancel
          </el-button>
          <el-button
            v-if="activeDashboardUIStore.isEditing"
            type="baseline"
            size="small"
            title="Settings"
            @click="showDashboardConfigModal"
          >
            <font-awesome-icon icon="cog" />
            Settings
          </el-button>
          <el-button
            v-if="canEditDashboard"
            type="baseline"
            size="small"
            :loading="activeDashboardUIStore.savingDashboard"
            @click="toggleEditMode"
          >
            <font-awesome-icon icon="save" />
            Save
          </el-button>
        </div>
      </template>
    </div>
    <div v-if="isDashboardConfigModalVisible">
      <configure-dashboard
        @onDone="hideDashboardConfigModal"
        @onCancel="hideDashboardConfigModal"
      />
    </div>
  </div>
</template>

<script>
import { DashboardWidgetType } from "api/enums";
import { get } from 'lodash';
import FileSaver from 'file-saver';
import { FeatureFlagManager, FlagKeys } from 'lib/feature-flag';
import DashboardEditableString from 'vue/dashboards/Dashboard/Components/DashboardEditableString.vue';

import ConfigureDashboard from 'vue/dashboards/DashboardEditable/ConfigureDashboard.vue';
import Auth from 'Auth/Auth';
import analytics from 'lib/analytics';
import {
  getActiveDashboardUIStore,
  getSubscriptionStore,
  getUserStore,
  getAnalysisConfigStore,
} from 'stores/RootStore';

export default {
  name: 'DashboardHeader',
  components: {
    DashboardEditableString,
    ConfigureDashboard,
  },
  props: {
    config: { default: undefined, type: Object },
    version: { default: undefined, type: String },
    dateString: { type: String },
    dashboardId: { type: String },
    title: { type: String },
    context: { default: undefined, type: Object },
    editable: { default: false, type: Boolean }
  },
  data() {
    return {
      activeDashboardUIStore: getActiveDashboardUIStore(),
      analysisConfigStore: getAnalysisConfigStore(),
      subscriptionStore: getSubscriptionStore(),
      userStore: getUserStore(),

      popperOptions: { boundariesElement: this.$parent._uid },
      requestingPowerpoint: false,
      favoritingDashboard: false,
      requestPowerpointError: undefined,
      toggleFavError: undefined,
      isDashboardConfigModalVisible: false,
    };
  },
  computed: {
    configLocation() {
      return this.activeDashboardUIStore.configLocation;
    },
    canEditDashboard() {
      return this.activeDashboardUIStore.getCanAction('manage:report');
    },
    canFavDashboards() {
      return this.dashboardId;
    },
    canSeeDevOnlyTools() {
      return FeatureFlagManager.checkFlag(FlagKeys.CAN_SEE_DEV_ONLY_TOOLS);
    },
    favDashboard() {
      const { defaultDashboard } = this;
      const dashboard = this.userStore.defaultDashboard || defaultDashboard;
      return this.dashboardId === dashboard;
    },
  },
  methods: {
    setFav: async function () {
      this.favoritingDashboard = true;
      const defaultDashboard = this.dashboardId;
      try {
        await this.userStore.setPreferences({ defaultDashboard });
      } catch (e) {
        this.toggleFavError = e;
      } finally {
        this.favoritingDashboard = false;
      }
    },
    toggleEditMode: async function () {
      if (!this.activeDashboardUIStore.isEditing) {
        analytics.track('Dashboard: Edit Dashboard', { category: 'Dashboard' });
        this.activeDashboardUIStore.startEditingDashboard();
        // Fetch configs for editing dashboard
        const sources = Object.values(this.activeDashboardUIStore.sources);
        const dashboardId = this.activeDashboardUIStore.currentDashboardId;
        await this.analysisConfigStore.getConfigs(sources, dashboardId);
      } else {
        if (this.activeDashboardUIStore.requiresSaving) {
          analytics.track('Dashboard: Save Dashboard', { category: 'Dashboard' });
          await this.activeDashboardUIStore.save();
        }
        if (!this.activeDashboardUIStore.savingDashboardError) {
          this.activeDashboardUIStore.stopEditingDashboard();
        }
      }
    },
    openSubscriptionWindow() {
      const { context, title: dashboardName } = this;
      this.subscriptionStore.subscriptionRequest = { context: Object.assign({}, context), dashboardName };

      analytics.track('Dashboard: Open Subscription Window', {
        category: 'Dashboard',
        version: this.version,
      });
    },
    downloadPowerpoint: async function () {
      this.requestPowerpointError = undefined;
      this.requestingPowerpoint = true;

      const { context, configLocation } = this;
      const url = configLocation.replace(/config$/, 'createDigest');

      try {
        const { data } = await Auth.fetch(url, {
          method: 'post',
          body: JSON.stringify({ context, type: 'pptx' }),
          responseType: 'arraybuffer',
        });

        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        });
        FileSaver.saveAs(blob, `${this.title} — ${this.dateString}.pptx`);

        const hasSummary = this.config.panels.some(p => p.widgets.some(w => w.config.type === DashboardWidgetType.SUMMARY));
        const additionalFields = hasSummary ? { hasSummary } : {};

        analytics.track('Export: Powerpoint', {
          category: 'Dashboard',
          version: this.version,
          ...additionalFields
        });
      } catch (e) {
        this.requestPowerpointError = e;
      } finally {
        this.requestingPowerpoint = false;
      }
    },
    downloadJSON: async function () {
      const { context, configLocation } = this;

      const url = configLocation.replace(/config$/, 'createDigest');

      try {
        const response = await Auth.fetch(url, {
          method: 'post',
          body: JSON.stringify({ context }),
        });
        const data = get(response, 'data.data', {});
        const blob = new Blob([JSON.stringify(data)], {
          type: 'application/json;charset=utf-8',
        });
        FileSaver.saveAs(blob, `${this.title} — ${this.dateString}.json`);
      } catch (e) {
        // FIXME
        // eslint-disable-next-line no-console
        console.err(e);
      }
    },
    showDashboardConfigModal() {
      this.isDashboardConfigModalVisible = true;
    },
    hideDashboardConfigModal() {
      this.isDashboardConfigModalVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../styles/element-variables.scss';
@import '../styles/filter-set-styles.scss';

.dashboard-header {
  display: flex;
  flex-direction: column;
  line-height: 22px;
  padding: 0;

  &.edit-mode {
    position: sticky;
    top: 0;
    z-index: var(--z-index-dashboard-header);
  }

  @media (max-width: $--md) {
    padding: 5px 10px;
  }

  .dashboard-title {
    margin-bottom: 1.5rem;
    padding-block: 10px;
    border-bottom: 1px solid $--neutral-300;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    .edit-mode & {
      background-color: $--primary-100;
      margin-inline: -1rem;
      padding-inline: 1rem;
    }
    h1 {
      display: inline-flex;
      align-items: baseline;
      gap: 1rem;
      font-size: 1.6rem;
      line-height: 20px;
      font-weight: 700;
      margin: 0 0 10px;
      button {
        align-self: center;
        font-size: 1rem;
        span svg {
          margin-right: 0;
        }
      }
      .fav-star {
        font-size: 1rem;
        color: $--orange-500;
      }
      span {
        font-size: 1rem;
        font-weight: 600;
      }
    }
    .cancel-dashboard-edits {
      color: $--neutral-900;
    }
    .dashboard-buttons {
      display: grid;
      grid-auto-flow: column;
      align-items: center;
      gap: 0.75rem;
      margin-left: 2.5rem;

      button {
        margin-left: unset;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .h1 {
    margin: 0;
    -webkit-print-color-adjust: exact !important;
  }
}
</style>
