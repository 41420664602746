
import { BillingDatasetUsage } from 'api/interfaces';
import { get, sortBy } from 'lodash';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Table } from 'semantic-ui-react';
import DatasetStatusLabel from './DatasetStatusLabel';
import './usage-by-dataset.scss';

enum Direction {
  ASC = 'ascending',
  DESC = 'descending'
}

export enum UsageByDatasetMetricType {
  VERBATIMS = 'verbatims',
  TRANSLATIONS = 'translations'
}

interface UsageByDatasetProps {
  datasets: BillingDatasetUsage[];
}

interface UsageByDatasetState {
  column: string;
  direction: Direction;
}

@inject('organizationsStore', 'metricsStore')
@observer
export default class UsageByDataset extends React.Component<UsageByDatasetProps, UsageByDatasetState> {

  state = {
    column: 'verbatims',
    direction: Direction.DESC,
  };

  sortBy = (value: string) => {
    const { column } = this.state;
    if (column === value) {
      const direction = this.reverseDirection();
      this.setState({ direction });
    } else {
      let direction = Direction.ASC;
      // Sort counts by desc by default
      if (value === 'verbatims' || value === 'translations') {
        direction = Direction.DESC;
      }
      this.setState({ column: value, direction });
    }
  };

  reverseDirection = () => {
    const { direction } = this.state;
    if (direction === Direction.ASC) {
      return Direction.DESC;
    } else {
      return Direction.ASC;
    }
  };

  sortedUsageData = () => {
    const { datasets } = this.props;
    const { column, direction } = this.state;

    let sortedUsageData = sortBy(datasets, (usage: BillingDatasetUsage) => {
      return get(usage, column);
    });

    if (direction === Direction.DESC) {
      return sortedUsageData.reverse();
    } else {
      return sortedUsageData;
    }
  };

  render() {
    const { column, direction } = this.state;
    return (
      <div className="usage-by-dataset">
        <Table
          fixed={true}
          sortable={true}
          unstackable={true}
        >
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                sorted={column === 'workspace.name' ? direction : undefined}
                onClick={() => this.sortBy('workspace.name')}
              >
                Workspace
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === 'survey.name' ? direction : undefined}
                onClick={() => this.sortBy('survey.name')}
              >
                Survey
              </Table.HeaderCell>
              <Table.HeaderCell
                width={2}
                sorted={column === 'status' ? direction : undefined}
                onClick={() => this.sortBy('status')}
              >
                Survey Status
              </Table.HeaderCell>
              <Table.HeaderCell
                width={2}
                style={{ textAlign: 'center' }}
                sorted={column === 'verbatims' ? direction : undefined}
                onClick={() => this.sortBy('verbatims')}
              >
                Responses
              </Table.HeaderCell>
              <Table.HeaderCell
                width={2}
                style={{ textAlign: 'center' }}
                sorted={column === 'translations' ? direction : undefined}
                onClick={() => this.sortBy('translations')}
              >
                Translations
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.sortedUsageData().map((s) => {
              return <React.Fragment key={s.survey.id}>
                <Table.Row key={s.survey.id}>
                  <Table.Cell>{s.workspace.name}</Table.Cell>
                  <Table.Cell>{s.survey.name}</Table.Cell>
                  <Table.Cell><DatasetStatusLabel status={s.status} /></Table.Cell>
                  <Table.Cell
                    className="verbatim-count"
                  >
                    {s.verbatims.toLocaleString()}
                  </Table.Cell>
                  <Table.Cell
                    className="verbatim-count"
                  >
                    {s.translations.toLocaleString()}
                  </Table.Cell>
                </Table.Row>
              </React.Fragment>;
            })}
          </Table.Body>
        </Table>
      </div>
    );
  }
}
