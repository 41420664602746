import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dashboard from 'components/Dashboard/Dashboard';
import { RecentActivityTracker } from 'lib/recent-activity';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Accordion, Grid, Header, Segment } from 'semantic-ui-react';
import { DashboardStoreInterface } from 'stores/DashboardStore';
import { OrganizationsStoreInterface } from 'stores/OrganizationsStore';
import { OrganizationStoreInterface } from 'stores/OrganizationStore';
import { UserStoreInterface } from 'stores/UserStore';
import CallToActions from './CallToActions';
import InlineOrgSwitcher from './InlineOrgList';
import NoDashboardPlaceholder from './NoDashboardPlaceholder';
import RecentActivityButton from './RecentActivityButton';
import './welcome.scss';

interface WelcomeProps {
  organizationStore?: OrganizationStoreInterface;
  organizationsStore?: OrganizationsStoreInterface;
  userStore?: UserStoreInterface;
  dashboardStore?: DashboardStoreInterface;
}

interface WelcomeState {
  showAllActivity: boolean;
  active: boolean;
}

const MAX_RECENT_ACTIVITY_TO_SHOW = 6;

@inject('organizationStore', 'organizationsStore', 'userStore', 'dashboardStore')
@observer
class Welcome extends React.Component<WelcomeProps, WelcomeState> {
  state = {
    showAllActivity: false,
    active: true,
  };

  saveAccordionState(): void {
    localStorage.setItem(
      'dashboardAccordions',
      JSON.stringify({
        ...JSON.parse(localStorage.getItem('dashboardAccordions') as string),
        Welcome: this.state.active,
      }),
    );
  }

  componentDidMount(): void {
    this.setState({
      active: JSON.parse(localStorage.getItem('dashboardAccordions') as string)?.Welcome ?? true,
    });
    this.saveAccordionState();
  }

  componentDidUpdate(): void {
    this.saveAccordionState();
  }

  showAllActivity = () => {
    this.setState({ showAllActivity: true });
  };

  render(): JSX.Element | null {
    const { orgId } = this.props.organizationStore!;
    const { organizations } = this.props.organizationsStore!;
    const { preferredName } = this.props.userStore!;
    const { defaultDashboardId: dashboardId, dashboardsLoading } = this.props.dashboardStore!;
    const { showAllActivity } = this.state;
    const recentActivity = RecentActivityTracker.getRecentActivity();
    const limitedRecentActivity = showAllActivity
      ? recentActivity
      : recentActivity.slice(0, MAX_RECENT_ACTIVITY_TO_SHOW);

    return (
      <Grid className="welcome-grid">
        <Grid.Row>
          <Grid.Column width={4} className="welcome-sidebar">
            <div className="welcome-string">
              <Header as="h2">
                Welcome back <span className="preferred-name">{preferredName ? preferredName : ''}</span>
              </Header>
            </div>
            {organizations.length > 1 && (
              <Segment className="welcome-segment">
                <InlineOrgSwitcher orgId={orgId} maxEntries={12} />
              </Segment>
            )}
            <Segment className="welcome-segment">
              <CallToActions />
            </Segment>
            {recentActivity && recentActivity.length > 0 && (
              <Segment className="welcome-segment">
                <Accordion>
                  <Accordion.Title
                    onClick={() => this.setState({ active: !this.state.active })}
                    active={this.state.active}
                  >
                    <h3>Continue where you left off</h3>
                    <FontAwesomeIcon
                      className="icon"
                      icon={this.state.active ? 'chevron-up' : 'chevron-down'}
                      fixedWidth={true}
                    />
                  </Accordion.Title>
                  <Accordion.Content active={this.state.active}>
                    <hr />
                    <div className="welcome__detail-row">
                      <div className="two-column">
                        {limitedRecentActivity.map((a, i) => {
                          return <RecentActivityButton key={i} recentActivityInfo={a} />;
                        })}
                      </div>
                      <div>
                        {limitedRecentActivity.length < recentActivity.length && (
                          <p className="show-more">
                            <a onClick={this.showAllActivity}>Show more</a>
                          </p>
                        )}
                      </div>
                    </div>
                  </Accordion.Content>
                </Accordion>
              </Segment>
            )}
          </Grid.Column>
          <Grid.Column width={12}>
            {dashboardId && !dashboardsLoading ? (
              <Segment className="welcome-segment">
                <Dashboard orgId={orgId} dashboardId={dashboardId} hasJustBeenCreated={false} />
              </Segment>
            ) : (
              <NoDashboardPlaceholder />
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default Welcome;
