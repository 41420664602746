import * as React from 'react';
import { ExportAssistantDateSelect } from './ExportAssistantDateSelect';
import { AvailableDateRange, Category, DateOptions } from './types';

interface Props {
  availableDateRange: AvailableDateRange;
  category: Category;
  updateCategory: (categoryUpdate: Category) => void;
}

export const ExportAssistantCategorySection = ({ availableDateRange, category, updateCategory }: Props) => {
  const handleDateSelectChange = (dateOptions: DateOptions) => {
    updateCategory({
      ...category,
      dateOptions,
    });
  };

  const showDateSelect = category.type === 'date';

  return (
    <div>
      {showDateSelect && (
        <ExportAssistantDateSelect
          availableDateRange={availableDateRange}
          onDateSelectChange={handleDateSelectChange}
        />
      )}
    </div>
  );
};
