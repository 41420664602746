import { Pagination } from 'components/Shared/Pagination';
import * as React from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownProps, PaginationProps, Radio } from 'semantic-ui-react';
import './examples-list.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'components/Shared/Button';
import { InputData } from 'api/interfaces';
import classNames from 'classnames';
import { Quotes } from 'components/Shared/Quotes';
import { Tooltip } from 'components/Shared/Tooltip';
import PinIcon from 'images/icons/pin.svg';
import { FeatureFlagManager, FlagKeys } from 'lib/feature-flag';
import analytics from 'lib/analytics';

const PAGE_SIZE = 6;
const TOOLTIP_OFFSET: [number, number] = [-4, 0];

interface ExamplesProps {
  content: InputData[];
  isReadOnly: boolean;
  onTextSelection: (text: string) => void;
  onToggleExamplePin: (example: InputData) => void;
  selectedComparison?: string;
  comparisonOptions?: {
    image: JSX.Element;
    key: string;
    text: string;
    value: string;
  }[];
  onSelectedComparisonChange?: (data: DropdownProps) => void;
}

export const ExamplesList = ({
  content,
  isReadOnly,
  onTextSelection,
  onToggleExamplePin,
  comparisonOptions,
  selectedComparison,
  onSelectedComparisonChange
}: ExamplesProps) => {
  const [page, setPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);
  const [activeItem, setActiveItem] = React.useState<string | null>(null);
  const [showPinnedExamples, setShowPinnedExamples] = React.useState(false);
  const prevContentRef = React.useRef<InputData[]>();

  const recordSelection = () => {
    const selection = window.getSelection();

    if (selection && selection.toString() !== '') {
      onTextSelection(selection.toString());
    }
  };

  const handleItemClick = (itemKey: string) => {
    if (itemKey !== activeItem) {
      analytics.track('Answers: Show quotes');
    }
    setActiveItem(itemKey === activeItem ? null : itemKey);
  };

  const getList = (examples: InputData[], listId: string) => {
    return (
      <ul className="answers-example-list" onMouseUp={() => recordSelection()} onMouseLeave={() => recordSelection()}>
        {examples.map((example) => {
          const isConversation: boolean = 'references' in example;
          const isReadOnlyNonConversation = isReadOnly && !isConversation;
          const itemKey = `${listId}-${example.text}`;
          const isItemActive = activeItem === itemKey;
          const isPinned = example.isPinned;
          const pinIconClasses = classNames({
            'pinned': isPinned,
            'pinned-view': showPinnedExamples,
          });
          const shouldHighlightAsPinned = isPinned && canSeePinnedExamples && !showPinnedExamples;
          const listItemClasses = classNames('answers-example-list__item', {
            'answers-example-list__item--active': isItemActive,
            'answers-example-list__item--pinned': shouldHighlightAsPinned,
            'answers-example-list__item--read-only-non-conversation': isReadOnlyNonConversation
          });
          return (
            <li
              key={itemKey}
              tabIndex={0}
              className={listItemClasses}
            >
              <div className="answers-example-list__item-content">
                <span className="answers-example-list__text">“{example.text}”</span>
                <span className="answers-example-list__source">
                  {isConversation ?
                    <FontAwesomeIcon icon="comments" />
                  :
                    <FontAwesomeIcon icon="list" />
                  }
                  {example.source}
                </span>
                {isConversation &&
                  <>
                    {isItemActive && example.references &&
                      <Quotes references={example.references} />
                    }
                    <span className="answers-example-list__action">
                      <Button
                        size="small"
                        variant="tertiary"
                        subvariant="neutral-text"
                        onClick={() => handleItemClick(itemKey)}
                      >
                        {isItemActive ? 'Hide quotes' : 'Show quotes'} <FontAwesomeIcon icon="chevron-down" />
                      </Button>
                    </span>
                  </>
                }
              </div>
              {canSeePinnedExamples &&
                <Tooltip
                  content={
                    isPinned ? "Un-pin example" : "Pin example"
                  }
                  position="top left"
                  offset={TOOLTIP_OFFSET}
                  inverted
                  trigger={
                    <span className='answers-example-list__item-pin-button'>
                      <Button
                        role="button"
                        variant="tertiary"
                        size="small"
                        ariaLabel="Pin"
                        icon={
                          <PinIcon
                            className={pinIconClasses}
                          />
                        }
                        onClick={(e) => {
                          e.stopPropagation();
                          if (!example.isPinned) {
                            analytics.track('Answers: Pin comments');
                          }
                          onToggleExamplePin(example);
                        }}
                      />
                    </span>
                  }
                />
              }
            </li>
          );
        })}
      </ul>
    );
  }

  const onPageChange = async ({ activePage }: PaginationProps) => {
    setPage(activePage as number);
  };

  const start = (page - 1) * PAGE_SIZE;
  const end = start + PAGE_SIZE;
  const isAnyExamplePinned = content.some(example => example.isPinned);
  const canSeePinnedExamples = FeatureFlagManager.checkFlag(FlagKeys.CAN_SEE_PINNED_QUOTES);
  const shouldShowPinnedExamples = (isReadOnly && isAnyExamplePinned) || showPinnedExamples;
  const contentToUse = shouldShowPinnedExamples ? content.filter(example => example.isPinned) : content;
  const slicedContent = contentToUse.slice(start, end);

  const initialize = React.useCallback(() => {
    const pages = Math.ceil(contentToUse.length / PAGE_SIZE);
    setTotalPages(pages);
  }, [contentToUse.length]);

  React.useEffect(() => {
    initialize();
  }, [initialize]);

  React.useEffect(() => {
    const previousContent = prevContentRef.current;

    if (content && (!previousContent || content.length !== previousContent.length)) {
      initialize();
    }

    prevContentRef.current = content;
  }, [content, initialize]);

  React.useEffect(() => {
    if (!isAnyExamplePinned) {
      setShowPinnedExamples(false);
    }
  }, [isAnyExamplePinned])

  const renderLists = () => {

    return (
      <div className="answers-example-lists">
        <div className="answers-example-column">
          {getList(slicedContent.filter((_, index) => index % 2 === 0), "left-column")}
        </div>
        {slicedContent.length > 1 && (
          <div className="answers-example-column">
            {getList(slicedContent.filter((_, index) => index % 2 !== 0), "right-column")}
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <header className="tree-item__header tree-item__header--examples">
        <h2 className="tree-item__title tree-item__title--examples">Examples</h2>
        {comparisonOptions &&
          <Dropdown
            icon={<FontAwesomeIcon icon="chevron-down" fixedWidth={true} className="icon" />}
            className="answers-examples__comparison-dropdown"
            onChange={(_, data) => {
              if (onSelectedComparisonChange) {
                onSelectedComparisonChange(data)
              }
            }}
            options={comparisonOptions}
            selectOnBlur={false}
            value={selectedComparison}
          />
        }
        {canSeePinnedExamples && !isReadOnly &&
          <div className="answers-examples__pinned-toggle">
            <span className="answers-examples__pinned-toggle-label">Pinned</span>
            <Tooltip
              content={isAnyExamplePinned
                ? "Pinned examples will be shown here"
                : "No examples are pinned at the moment"
              }
              position="top left"
              inverted
              trigger={
                <Radio
                  toggle={true}
                  checked={showPinnedExamples}
                  onChange={() => {
                    const currentToggleValue = !showPinnedExamples;
                    setShowPinnedExamples(currentToggleValue)
                    setPage(1)
                    analytics.track('Answers: Pin toggle', { 'Type': currentToggleValue ? 'On' : 'Off' })
                  }}
                  disabled={!isAnyExamplePinned}
                />
              }
            />
          </div>
        }
        <div className="answers-examples__pagination-bar">
          <Dropdown
            className="answers-examples__pagination-bar-dropdown"
            icon={null}
            text={`Page ${page} of ${totalPages}`}
          >
            <DropdownMenu>
              <DropdownItem
                text='First page'
                active={page === 1}
                onClick={() => {
                  setPage(1);
                }}
              />
              <DropdownItem
                text='Last page'
                active={page === totalPages}
                onClick={() => {
                  setPage(totalPages);
                }}
              />
            </DropdownMenu>
          </Dropdown>
          <Pagination
            activePage={page}
            totalPages={totalPages}
            disabled={content.length === 0}
            lastItem={null}
            firstItem={null}
            inline={true}
            showIconTooltips={true}
            boundaryRange={0}
            onPageChange={(_e, data) => onPageChange(data)}
          />
        </div>
      </header>
      {renderLists()}
    </>
  );
};
