import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { copyText } from 'lib/clipboard';
import * as React from 'react';
import './copy-to-clipboard-button.scss';
import { Tooltip } from 'components/Shared/Tooltip';
import { Button } from 'components/Shared/Button';

type Props = {
  popupText: string;
  onCopy?: () => void;
  text: string;
};

const CopyToClipboardButton = ({ popupText, onCopy, text }: Props) => {
  const [copySuccess, setCopySuccess] = React.useState(false);
  const timeoutIdRef = React.useRef<ReturnType<typeof setTimeout>>();

  const copy = () => {
    copyText(text);
    setCopySuccess(true);

    onCopy?.();

    const timeoutId = setTimeout(() => {
      setCopySuccess(false);
    }, 1000);

    timeoutIdRef.current = timeoutId;
  };

  React.useEffect(() => {
    return () => {
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }
    };
  }, []);

  return (
    <Tooltip
      position="bottom left"
      content={popupText}
      on={['hover', 'focus']}
      inverted={true}
      trigger={
        <span>
          <Button
            role="button"
            variant="tertiary"
            size="small"
            onClick={() => copy()}
            icon={copySuccess ? (
              <FontAwesomeIcon icon="check-circle" className="success clipboard-buttons" />
            ) : (
              <FontAwesomeIcon icon="copy" fixedWidth={true} />
            )}
          />
        </span>
      }
    />
  );
}

export { CopyToClipboardButton };
