import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Survey } from 'api/interfaces';
import ErrorModal from 'components/ErrorModal/ErrorModal';
import AddDatasetIcon from 'images/icons/cta-add-dataset.svg';
import InviteTeammateIcon from 'images/icons/cta-invite-teammate.svg';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Accordion, Button, Form, Modal, TextArea, TextAreaProps } from 'semantic-ui-react';
import { OrganizationStoreInterface } from 'stores/OrganizationStore';
import { SetupStoreInterface } from 'stores/SetupStore';
import { SupportStoreInterface } from 'stores/SupportStore';
import { UserStoreInterface } from 'stores/UserStore';
import './call-to-actions.scss';

interface CallToActionsProps extends RouteComponentProps {
  setupStore?: SetupStoreInterface;
  userStore?: UserStoreInterface;
  organizationStore?: OrganizationStoreInterface;
  supportStore?: SupportStoreInterface;
}

interface CallToActionsState {
  showCreateSurveyModal: boolean;
  showCreateErrorModal: boolean;
  showCreateSurveyRequestSent: boolean;
  sendingCreateSurveyRequest: boolean;
  createSurveyRequestDetail: string;
  active: boolean;
}

@inject('setupStore', 'supportStore', 'organizationStore', 'userStore')
@observer
class CallToActions extends React.Component<CallToActionsProps, CallToActionsState> {
  state = {
    showAllActivity: false,
    showCreateSurveyModal: false,
    showCreateErrorModal: false,
    showCreateSurveyRequestSent: false,
    sendingCreateSurveyRequest: false,
    createSurveyRequestDetail: '',
    active: true,
  };

  saveAccordionState(): void {
    localStorage.setItem(
      'dashboardAccordions',
      JSON.stringify({
        ...JSON.parse(localStorage.getItem('dashboardAccordions') as string),
        CallToActions: this.state.active,
      }),
    );
  }

  componentDidMount(): void {
    this.setState({
      active: JSON.parse(localStorage.getItem('dashboardAccordions') as string)?.CallToActions ?? true,
    });
    this.saveAccordionState();
  }

  componentDidUpdate(): void {
    this.saveAccordionState();
  }

  canCreateSurveys = (): boolean => {
    const { organizationStore } = this.props;
    return organizationStore!.getCanAction('manage:survey');
  };

  sendCreateSurveyRequest = async () => {
    const { supportStore, userStore, organizationStore } = this.props;
    const { user } = userStore!;
    const { createSurveyRequestDetail } = this.state;
    this.setState({ sendingCreateSurveyRequest: true });
    if (user) {
      const subject = `${user.email} Create Survey Request in ${organizationStore!.orgName}`;
      const request = `${user.email} provided the following detail:\n ${createSurveyRequestDetail}`;

      await supportStore!.requestSupport(user.email, subject, request);
    }

    this.setState({ sendingCreateSurveyRequest: false, showCreateSurveyRequestSent: true });
  };

  inviteUsers = async () => {
    const { organizationStore } = this.props;
    const { orgId } = organizationStore!;
    const url = `/c/${orgId}/users/create`;

    this.props.history.push(url);
  };

  createSurvey = async () => {
    const { setupStore, history, organizationStore } = this.props;

    // If we aren't allowed to create surveys, show the modal
    if (!this.canCreateSurveys()) {
      this.setState({
        showCreateSurveyModal: true,
        showCreateSurveyRequestSent: false,
        sendingCreateSurveyRequest: false,
      });
      return;
    }

    if (setupStore!.creatingSurvey) {
      return;
    }

    const surveyOptions = {
      name: 'Dataset',
      dataSourceIntegration: undefined,
      manualUploadAllowed: true,
    };

    const newSurvey = await setupStore!.createSurvey(surveyOptions);

    if (!setupStore!.createSurveyError && newSurvey) {
      const { orgId } = organizationStore!;
      const { id } = newSurvey as Survey;
      const url = `/c/${orgId}/s/${id}/configure-survey?step=import`;
      history.push(url);
    } else {
      this.setState({ showCreateErrorModal: true });
    }
  };

  closeCreateSurveyModal = () => {
    this.setState({ showCreateSurveyModal: false, showCreateSurveyRequestSent: false });
  };
  updateCreateDatasetRequest = ({ value }: TextAreaProps) => {
    this.setState({ createSurveyRequestDetail: '' + value });
  };

  renderCreateSurveyModal() {
    const { showCreateSurveyModal, showCreateSurveyRequestSent, sendingCreateSurveyRequest } = this.state;

    return (
      <Modal
        dimmer="blurring"
        open={showCreateSurveyModal}
        onClose={this.closeCreateSurveyModal}
        size="tiny"
        className="cta-modal"
      >
        <Modal.Header>
          <AddDatasetIcon className="cta-modal-icon" />
          <div>How to add a new dataset</div>
        </Modal.Header>
        <Modal.Content>
          <div className="modal-content">
            {showCreateSurveyRequestSent && <div>Thanks. We'll get back to you soon!</div>}

            {!showCreateSurveyRequestSent && (
              <div className="create-form-full-width">
                <p>Your workspace admin needs to enable this first.</p>
                <p>Can you tell us a little about your dataset</p>
                <Form>
                  <TextArea
                    placeholder=""
                    onChange={(_e, data) => this.updateCreateDatasetRequest(data)}
                    autoFocus={true}
                  />
                </Form>
              </div>
            )}
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.closeCreateSurveyModal} type="button">
            Cancel
          </Button>
          <Button
            type="button"
            color="blue"
            loading={sendingCreateSurveyRequest}
            onClick={showCreateSurveyRequestSent ? this.closeCreateSurveyModal : this.sendCreateSurveyRequest}
          >
            {showCreateSurveyRequestSent ? 'Done' : 'Send Request'}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }

  renderCreateErrorModal() {
    const { setupStore } = this.props;
    const { showCreateErrorModal } = this.state;

    return (
      <>
        {showCreateErrorModal && (
          <ErrorModal
            showModal={showCreateErrorModal}
            header="Error creating survey"
            onClose={() => this.setState({ showCreateErrorModal: false })}
            errorMessage={setupStore!.createSurveyError}
          />
        )}
      </>
    );
  }

  render(): JSX.Element | null {
    const { organizationStore } = this.props;

    const canManageUsers = organizationStore!.getCanAction('manage:user');

    return (
      <>
        <Accordion>
          <Accordion.Title onClick={() => this.setState({ active: !this.state.active })} active={this.state.active}>
            <h3>Suggestions</h3>
            <FontAwesomeIcon
              className="icon"
              icon={this.state.active ? 'chevron-up' : 'chevron-down'}
              fixedWidth={true}
            />
          </Accordion.Title>
          <Accordion.Content active={this.state.active}>
            <hr />
            <div>
              <div className="cta-card" onClick={this.createSurvey}>
                <AddDatasetIcon className="cta-card-icon" />
                <div className="cta-text">Add new dataset</div>
              </div>
              {canManageUsers && (
                <div className="cta-card" onClick={this.inviteUsers}>
                  <InviteTeammateIcon className="cta-card-icon" />
                  <div className="cta-text">Invite teammates</div>
                </div>
              )}
            </div>
          </Accordion.Content>
        </Accordion>

        {this.renderCreateSurveyModal()}
        {this.renderCreateErrorModal()}
      </>
    );
  }
}

export default withRouter(CallToActions);
