import * as d3 from 'd3';
import d3tip, { D3Tip } from 'd3-tip';
import colors from 'styling/variables/colors.scss';
import { Orientation } from 'types/custom';
import Vue from 'vue';
import fuzzNum from 'vue/libs/fuzz-num';
import { calculateTickInterval } from './bargraphHelpers';

export type ChartData = {
  baselineVolume: number,
  baselineCount: number,
  comparisonVolume: number,
  comparisonCount: number,
  label: string
  baselineSigDiff?: number,
  baselineTotalCommentsCount?: number,
  comparisonSigDiff?: number,
  comparisonTotalCommentsCount?: number,
};

export type RenderBarGraphDataArgs =  {
  el: Vue | Element | Vue[] | Element[] | HTMLElement,
  options: {
    orderBy: string;
    onClick: Function;
    chartLabel: string;
    labels: string[];
    showBaselineValues: boolean;
    showComparisonValues: boolean;
    data: ChartData[];
    barChartWidth: number;
    barChartHeight: number;
    chartUnit: string;
    orientation: Orientation;
    showVerticalChartLabels: boolean;
    // ^^ this only applies to vertical bar type charts for now
    showCommentsCountInTooltip: boolean;
    showSigDiffLabelInTooltip?: boolean;
  },
  tip: D3Tip,
};

const MAX_LEFT_OFFSET_FOR_HORIZONTAL_CHART = 170;
const CHART_LINE_COLOR = colors.chartLineColor;
const CHART_LABEL_OFFSET = 20;
const CHART_LABEL_WIDTH_DIVISOR = 1.4;

function getMaxLabelWidth(labels: string[]): number {

  // Create a temporary SVG element to measure text width
  const tempSvg = d3.select('body')
    .append('svg')
    .attr('width', 0)
    .attr('height', 0)
    .style('visibility', 'hidden');

  // Iterate through the labels and measure their width
  const labelWidths = labels.map(label => {
    const text = tempSvg
      .append('text')
      .text(label)
      .attr('font-family', 'Open Sans')
      .attr('font-weight', 'bold');
    const width = text.node()?.getBBox().width ?? 0;
    text.remove();
    return width;
  });

  tempSvg.remove();

  return Math.max(...labelWidths);
}

function roundToTen(value: number): number {
  // we only want to round a number when it is greater than 5 else it will return 0
  if (value > 5) {
    return Math.round(value / 10) * 10;
  }
  return value;
}

function calcSigDiffLabel(sigDiff: number, isBaseline: boolean): string {
  if (![1, -1].includes(sigDiff)) {
    return '';
  }

  const prefix = sigDiff === 1 ? '⇑' : '⇓';
  const suffix = isBaseline ? 'within filters' : 'between filters';

  return `${prefix} Statistically Significant ${suffix}`;
}

function getSigDiffLabel(option: ChartData, options: RenderBarGraphDataArgs['options']): string {
  const comparisonSigDiff = option.comparisonSigDiff || 0;
  const baselineSigDiff = option.baselineSigDiff || 0;
  if (options.showComparisonValues) {
    return calcSigDiffLabel(comparisonSigDiff, false);
  } else {
    return calcSigDiffLabel(baselineSigDiff, true);
  }
}

function getBarGraphTip(options: RenderBarGraphDataArgs['options']) {
  return d3tip()
  .attr('class', 'el-tooltip__popper is-dark el-tooltip__popper-no-mouse bar-graph')
  .attr('x-placement', 'right')
  .direction('e')
  .offset([0, -5])
  .html((option: ChartData) => {
    return `
      <div class="bar-graph-tip" style="display: flex; flex-direction: column;">
        <h3 class="bar-graph-tip__title" style="margin-bottom: 8px;">${option.label}</h3>
        <div style="display: ${options.showBaselineValues ? `inline-flex` : `none`}; padding-bottom: 4px;">
          <div style="width: 15px; height: 15px; margin-right: 6px; background: ${colors.primary500}"></div>
          <div class="bar-graph-tip__value" style="padding-right: 10px;">${fuzzNum(option.baselineVolume)}${options.chartUnit}</div>
          ${
            options.showCommentsCountInTooltip
            ? `<div class="bar-graph-tip__value">(${option.baselineCount} / ${option.baselineTotalCommentsCount})</div>`
            : ''
          }
        </div>
        <div style="display: ${options.showComparisonValues ? `inline-flex` : `none`};">
          <div style="width: 15px; height: 15px; margin-right: 6px; background: ${colors.orange500}"></div>
          <div class="bar-graph-tip__value" style="padding-right: 10px;">${fuzzNum(option.comparisonVolume)}${options.chartUnit}</div>
          ${
            options.showCommentsCountInTooltip
            ? `<div class="bar-graph-tip__value">(${option.comparisonCount} / ${option.comparisonTotalCommentsCount})</div>`
            : ''
          }
        </div>
        ${options.showSigDiffLabelInTooltip
          ? `<div class="bar-graph-tip__sig-diff-label">${getSigDiffLabel(option, options)}</div>`
          : ''
        }
      </div>
      <div x-arrow class="popper__arrow" style="top:50%;transform:translateY(-50%);"></div>
    `;
  });
}

function renderBarGraphData(
  {
    el,
    options,
    tip
  }: RenderBarGraphDataArgs
) {
    // For expediency, erase any existing chart and build a new one.
    d3.select(el).select('svg').remove();

    const allBaselineValues: number[] = options.data.map((values) => values.baselineVolume);
    const allComparisonValues: number[] = options.data.map((values) => values.comparisonVolume);
    const allValues: number[] = [...allBaselineValues, ...allComparisonValues];
    if (options.orientation === 'vertical') {
      const maxLabelWidth = getMaxLabelWidth(options.labels);
      // Set the bottom margin to be at least 50px and at most 100px if the labels are showing vertically
      const bottomMargin = options.showVerticalChartLabels ? Math.min(Math.max(maxLabelWidth / 2, 50), 100) : 50;

      const margin = { top: 20, right: 50, bottom: bottomMargin, left: 80};
      const chartWidth = options.barChartWidth - margin.left - margin.right;
      const chartHeight = options.barChartHeight - margin.top - margin.bottom;

      const svg = d3
        .select(el)
        .append('svg')
        .attr('width', chartWidth + margin.left + margin.right)
        .attr('height', chartHeight + margin.top + margin.bottom)
        .append('g')
        .attr('transform', `translate(${margin.left}, ${margin.top})`);

      // x Axis/Scale -------------------------------------------------------------

      const xScale = d3
        .scaleBand()
        .domain(options.labels)
        .range([0, chartWidth])
        .padding(0.4);

      const xAxis = d3.axisBottom(xScale).tickSize(0);

      // y Axis/Scale  ------------------------------------------------------------

      const minValue = d3.min(allValues) || 0;
      // If max value is negative, we want to end the x axis at 0
      const maxValue = d3.max([...allValues, 0]) || 0;
      const yDomain = minValue < 0 ? [minValue, maxValue] : [0, maxValue];

      const yScale = d3.scaleLinear().domain(yDomain).range([chartHeight, 0]);
      const tickInterval = roundToTen(calculateTickInterval(yScale.domain()[0], yScale.domain()[1]));

      const numberOfTicks = (yScale.domain()[1] - yScale.domain()[0]) / tickInterval;

      const yAxis = d3
        .axisLeft(yScale)
        .ticks(numberOfTicks)
        .tickFormat((t) => {
          return `${t}${options.chartUnit}`;
        })
        .tickSize(0);

      const tickValues = yScale.ticks(numberOfTicks);

      svg
        .append('g')
        .call(yAxis)
        .selectAll('text')
        .attr('font-family', '\'Open Sans\', sans-serif')
        .attr('transform', `translate(-10, 0)`)
        .style('fill', colors.primary900)
        .style('font-size', '12px');

      // Vertical lines behind the bars
      svg
        .selectAll('.tick-line')
        .data(tickValues)
        .enter()
        .append('line')
        .attr('class', 'tick-line')
        .attr('y1', (d) => yScale(d))
        .attr('x1', 0)
        .attr('y2', (d) => yScale(d))
        .attr('x2', chartWidth)
        .attr('stroke', CHART_LINE_COLOR)
        .attr('stroke-width', 1);

      // Tick text
      const xAxisTextTranslation = options.showVerticalChartLabels ? `translate(-5, 5) rotate(-45)` : `translate(0, 5)`;
      const xAxisTextAnchor = options.showVerticalChartLabels ? 'end' : 'middle';
      svg
        .append('g')
        .attr('transform', `translate(0, ${chartHeight})`)
        .call(xAxis)
        .selectAll('text')
        .attr('text-anchor', xAxisTextAnchor)
        .attr('transform', xAxisTextTranslation)
        .attr('font-family', '\'Open Sans\', sans-serif')
        .style('fill', colors.primary600)
        .style('font-size', '12px');

      // Axis label
      const chartLabelTextTranslation =  options.showVerticalChartLabels
        ? `rotate(-90), translate(0, -130)`
        : `rotate(-90), translate(0, -150)`;
      svg
        .append('text')
        .attr('y', chartHeight / 2)
        .attr('x', 0)
        .attr('text-anchor', 'end')
        .attr('transform', chartLabelTextTranslation)
        .style('fill', colors.primary400)
        .attr('font-family', '\'Open Sans\', sans-serif')
        .style('font-weight', 'bold')
        .style('font-size', '11px')
        .text(options.chartLabel.toUpperCase());

      // Set axes and ticks color
      svg.selectAll('.domain, .tick line').attr('stroke', CHART_LINE_COLOR);

      // Bars ---------------------------------------------------------------------
      const showingBothValues = options.showBaselineValues && options.showComparisonValues;
      const bars = svg.selectAll('rect').data(options.data).enter().append('g').call(tip);

      if (options.showBaselineValues) {
        bars
          .append('rect')
          .attr('y', (d: ChartData) => yScale(Math.max(0, d.baselineVolume)))
          .attr('x', (d: ChartData) => {
            return xScale(d.label);
          })
          .attr('height', (d: ChartData) => Math.abs(yScale(d.baselineVolume) - yScale(0)))
          .attr('width', showingBothValues ? xScale.bandwidth() / 2 : xScale.bandwidth())
          .style('fill', colors.primary500)
          .on('click', (d: ChartData) => {
            options.onClick(d.label);
          })
          .on('mouseover', tip.show)
          .on('mouseout', tip.hide);
      }

      if (options.showComparisonValues) {
        bars
          .append('rect')
          .attr('y', (d: ChartData) => yScale(Math.max(0, d.comparisonVolume || 0)))
          .attr('x', (d: ChartData) => {
            const additionalWidth = showingBothValues ? ((xScale.bandwidth() / 2) + 3) : 0;
            return xScale(d.label) + additionalWidth;
          })
          .attr('height', (d: ChartData) => Math.abs(yScale(d.comparisonVolume || 0) - yScale(0)))
          .attr('width', showingBothValues ? xScale.bandwidth() / 2 : xScale.bandwidth())
          .attr('font-family', '\'Open Sans\', sans-serif')
          .style('fill', colors.orange500)
          .on('click', (d: ChartData) => {
            options.onClick(d.label);
          })
          .on('mouseover', tip.show)
          .on('mouseout', tip.hide);
      }
    } else {
      const leftOffset = getMaxLabelWidth(options.labels);

      const offset = { top: 20, right: 50, bottom: 70, left: Math.min(
        leftOffset, MAX_LEFT_OFFSET_FOR_HORIZONTAL_CHART
      ) };
      const chartWidth = options.barChartWidth - offset.left - offset.right;
      const chartHeight = options.barChartHeight - offset.top - offset.bottom;

      const svg = d3
        .select(el)
        .append('svg')
        .attr('width', chartWidth + offset.left + offset.right)
        .attr('height', chartHeight + offset.top + offset.bottom)
        .append('g')
        .attr('transform', `translate(${offset.left}, ${offset.top})`);

      // y Axis/Scale -------------------------------------------------------------

      const yScale = d3
        .scaleBand()
        .domain(options.labels)
        .range([0, chartHeight])
        .padding(0.4);

      const yAxis = d3.axisLeft(yScale).tickSize(0);

      svg
        .append('g')
        .call(yAxis)
        .selectAll('text')
        .attr('font-family', '\'Open Sans\', sans-serif')
        .attr('transform', `translate(-10, 0)`)
        .style('fill', colors.primary900)
        .style('font-size', '12px');

      // x Axis/Scale  ------------------------------------------------------------

      const minValue = d3.min(allValues) || 0;
      // If max value is negative, we want to end the x axis at 0
      const maxValue = d3.max([...allValues, 0]) || 0;
      const xDomain = minValue < 0 ? [minValue, maxValue] : [0, maxValue];

      const xScale = d3.scaleLinear().domain(xDomain).range([0, chartWidth]);
      const tickInterval = roundToTen(calculateTickInterval(xScale.domain()[0], xScale.domain()[1]));

      const numberOfTicks = (xScale.domain()[1] - xScale.domain()[0]) / tickInterval;

      const xAxis = d3
        .axisBottom(xScale)
        .ticks(numberOfTicks)
        .tickFormat((t) => {
          return `${t}${options.chartUnit}`;
        })
        .tickSize(0);

      const tickValues = xScale.ticks(numberOfTicks);

      // Vertical lines behind the bars
      svg
        .selectAll('.tick-line')
        .data(tickValues)
        .enter()
        .append('line')
        .attr('class', 'tick-line')
        .attr('x1', (d) => xScale(d))
        .attr('y1', 0)
        .attr('x2', (d) => xScale(d))
        .attr('y2', chartHeight)
        .attr('stroke', CHART_LINE_COLOR)
        .attr('stroke-width', 1);

      // Tick text
      svg
        .append('g')
        .attr('transform', `translate(0, ${chartHeight})`)
        .call(xAxis)
        .selectAll('text')
        .attr('transform', `translate(0, ${5})`)
        .attr('font-family', '\'Open Sans\', sans-serif')
        .style('fill', colors.primary600)
        .style('font-size', '12px');

      // Axis label
      svg
        .append('text')
        .attr('x', (chartWidth / CHART_LABEL_WIDTH_DIVISOR) + CHART_LABEL_OFFSET)
        .attr('y', chartHeight + 45)
        .attr('text-anchor', 'end')
        .style('fill', colors.primary400)
        .attr('font-family', '\'Open Sans\', sans-serif')
        .style('font-weight', 'bold')
        .style('font-size', '12px')
        .text(options.chartLabel.toUpperCase());

      // Set axes and ticks color
      svg.selectAll('.domain, .tick line').attr('stroke', CHART_LINE_COLOR);

      // Bars ---------------------------------------------------------------------
      const showingBothValues = options.showBaselineValues && options.showComparisonValues;
      const bars = svg.selectAll('rect').data(options.data).enter().append('g').call(tip);

      if (options.showBaselineValues) {
        bars
          .append('rect')
          .attr('x', (d: ChartData) => xScale(Math.min(0, d.baselineVolume)))
          .attr('y', (d: ChartData) => {
            const additionalHeight = showingBothValues ? ((yScale.bandwidth() / 2) - 10) : 0;
            return yScale(d.label) - additionalHeight;
          })
          .attr('width', (d: ChartData) => Math.abs(xScale(d.baselineVolume) - xScale(0)))
          .attr('height', showingBothValues ? yScale.bandwidth() / 2 : yScale.bandwidth())
          .style('fill', colors.primary500)
          .on('click', (d: ChartData) => {
            options.onClick(d.label);
          })
          .on('mouseover', tip.show)
          .on('mouseout', tip.hide);
      }

      if (options.showComparisonValues) {

        bars
          .append('rect')
          .attr('x', (d: ChartData) => xScale(Math.min(0, d.comparisonVolume || 0)))
          .attr('y', (d: ChartData) => {
            const additionalHeight = showingBothValues ? ((yScale.bandwidth() / 2) + 3) : 0;
            return yScale(d.label) + additionalHeight;
          })
          .attr('width', (d: ChartData) => Math.abs(xScale(d.comparisonVolume || 0) - xScale(0)))
          .attr('height', showingBothValues ? yScale.bandwidth() / 2 : yScale.bandwidth())
          .attr('font-family', '\'Open Sans\', sans-serif')
          .style('fill', colors.orange500)
          .on('click', (d: ChartData) => {
            options.onClick(d.label);
          })
          .on('mouseover', tip.show)
          .on('mouseout', tip.hide);
      }
    }

}

export default {
  getBarGraphTip,
  renderBarGraphData,
};
