<template>
  <widget-wrapper
    :title="config.title"
    :loading="loading"
    :error="error"
    :warning="warning"
    :panel-order="panelOrder"
    :widget-order="widgetOrder"
  >
    <div
      slot="subtitle"
      :class="{ 'widget-subtitle-clickable': canNavigate }"
      class="widget-subtitle"
      @click="doNavigate()"
    >
      <div class="widget-subtitle-text">
        {{ scoreName }} over time
        <font-awesome-icon
          v-if="canNavigate"
          class="link-icon"
          icon="chart-bar"
        />
      </div>
    </div>
    <div
      :class="{ empty: empty }"
      class="score-graph-container widget-body"
    >
      <div class="legend">
        <svg
          class="key-square"
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="7"
            cy="7"
            r="7"
            width="14"
            height="14"
            :fill="colors.primary500"
          />
        </svg>
        <span class="key-text">{{ legendItems[0] }}</span>
        <svg
          v-if="legendItems[1]"
          class="key-square"
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="7"
            cy="7"
            r="7"
            width="14"
            height="14"
            :fill="colors.orange500"
          />
        </svg>
        <span class="key-text">{{ legendItems[1] }}</span>
      </div>
      <multi-line-graph
        v-if="!empty"
        :values="values"
        :labels="labels"
      />
      <div
        v-else
        class="empty"
      >
        No data found
      </div>
    </div>
  </widget-wrapper>
</template>

<script>
import { get, map, slice, trim } from 'lodash';

import CanNavigateMixin from 'vue/dashboards/Dashboard/MixIns/CanNavigateMixin';

import MultiLineGraph from './Components/MultiLineGraph.vue';

import WidgetWrapper from './WidgetWrapper';
import colors from 'vue/styles/element-variables.scss';

export default {
  name: 'SCORE_OVERTIME_COMPARISON',
  components: {
    MultiLineGraph,
    WidgetWrapper,
  },
  mixins: [CanNavigateMixin('over-time')],
  props: {
    config: { default: undefined, type: Object },
    context: { default: undefined, type: Object },
    data: { default: undefined, type: Object },
    warning: { default: undefined, type: String },
    error: { default: undefined, type: String },
    limit: { default: 5, type: Number },
    loading: { default: false, type: Boolean },
    source: { default: undefined, type: String },
    panelOrder: { type: Number },
    widgetOrder: { type: Number },
  },
  data() {
    return {
      colors: colors,
    };
  },
  computed: {
    name() {
      return get(this, 'data.name');
    },
    compareFilterName() {
      return get(this, 'data.comparisonName');
    },
    empty() {
      return this.scores.length === 0;
    },
    labels() {
      return map(this.scores, 'label');
    },
    legendItems() {
      let items = [this.name];
      if (this.showComparison) {
        items = [this.compareFilterName, this.name];
      }
      return items;
    },
    scoreConfig() {
      return get(this.data, 'scoreConfig', get(this.config, 'score'));
    },
    scoreName() {
      return get(this.config, 'score.name', get(this, 'scoreConfig.name'));
    },
    scores() {
      return slice(get(this, 'data.scores'), 0, 5);
    },
    showComparison() {
      return !!trim(get(this, 'context.compareFilter'));
    },
    values() {
      const scores = map(this.scores, (score) => get(score, 'score.score', 0));
      if (this.showComparison) {
        const comparisonScores = map(this.scores, (score) => get(score, 'comparisonScore.score', 0));
        return [comparisonScores, scores];
      } else {
        return [scores];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.empty {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
