<template>
  <div
    ref="containerEl"
    class="line-graph"
  >
    <svg ref="multiLineGraphEl" />
  </div>
</template>

<script>
import { flatten, map, max, min, slice } from 'lodash';
import { watchAll } from 'vue/libs/vueExt';
import lineGraph from 'charts/Utils/lineGraph';

export default {
  name: 'MultiLineGraph',
  props: { values: Array, labels: Array, byVolume: Boolean },
  data() {
    return {
      chart: undefined
    };
  },
  computed: {
    low() {
      const low = min(flatten(this.values));
      if (low > 0) {
        return 0;
      } else {
        return low;
      }
    },
    high() {
      const high = max(flatten(this.values));
      if (high < 0) {
        return 0;
      } else {
        return high;
      }
    },
    chartOptions() {
      const { byVolume } = this;
      let leftPadding;
      if (this.high === 0) {
        leftPadding = 10;
      } else {
        leftPadding = Math.log(Math.abs(this.high)) * 5;
      }
      const options = {
        axisX: {
          labelOffset: { x: -25, y: 6 },
          showGrid: false
        },
        axisY: {
          labelInterpolationFnc(value) {
            return byVolume ? `${value}%` : value;
          },
          labelOffset: { x: -8, y: 6 }
        },
        chartPadding: {
          top: 30,
          left: leftPadding,
          right: 30
        },
        fullWidth: true,
        high: this.high,
        low: this.low
      };
      if (byVolume) {
        options.high = 100;
      }
      return options;
    },
    series() {
      const { byVolume } = this;
      let values;
      if (byVolume) {
        values = slice(this.values, 1, 4);
      } else {
        values = slice(this.values, 0, 3);
      }
      return map(values, data => {
        if (byVolume) {
          data = map(data, (v, index) => {
            return (v / this.totalSeries[index]) * 100;
          });
        }
        return { data };
      });
    },
    totalSeries() {
      const [totalSeries] = this.values;
      return totalSeries;
    }
  },
  mounted() {
    watchAll(this, ['labels', 'values'], this.updateChart);
    this.updateChart();
    window.addEventListener('resize', () => {
      setTimeout(this.updateChart, 10);
    });
  },
  methods: {
    updateChart() {
      const { multiLineGraphEl, containerEl } = this.$refs;
      const { labels, values } = this;

      if (!multiLineGraphEl || !containerEl || !values) {
        return;
      }
      this.clientHeight = this.$refs.containerEl.clientHeight;

      const { width: containerWidth, height: containerHeight } = containerEl.parentNode.getBoundingClientRect();
      const baselineValues = this.series[0].data;
      const comparisonValues = this.series[1]?.data;
      const chartOptions = {
        containerHeight,
        containerWidth,
        labels,
        low: this.low,
        high: this.high,
        baselineValues,
        comparisonValues
      }

      lineGraph.renderLineGraph({
        el: multiLineGraphEl,
        chartOptions
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../styles/element-variables.scss';
.line-graph {
  display: flex;
  margin: 10px 20px 10px 10px;
  position: relative;
}
</style>

