<template>
  <widget-wrapper
    :title="config.title"
    :loading="loading"
    :error="error"
    :warning="warning"
    :panel-order="panelOrder"
    :widget-order="widgetOrder"
  >
    <div
      slot="subtitle"
      :class="{ 'widget-subtitle-clickable': canNavigate }"
      class="widget-subtitle"
      @click="doNavigate()"
    >
      <div class="widget-subtitle-text">
        New and resurfacing themes {{ periodLabel }}
        <font-awesome-icon
          v-if="canNavigate"
          class="link-icon"
          icon="chart-bar"
        />
      </div>
      <div class="widget-display-categories">
        Responses
      </div>
    </div>
    <div
      :class="{ empty: empty }"
      class="themes-body widget-body"
    >
      <div
        v-for="(theme, index) in newThemes"
        :key="index + theme.name"
        class="theme-row"
      >
        <div
          v-if="!isWeighted"
          :class="{ 'clickable-theme': canClickTheme }"
          class="theme-name"
          @click="doNavigate({code: theme.basethemeCode, title: theme.basetheme},
                             theme.subtheme ? {code: theme.subthemeCode, title: theme.subtheme} : undefined)"
        >
          <span>{{ theme.name }}</span>
        </div>
        <theme-name-with-sources
          v-else
          :source="source"
          tool="explore"
          :theme="theme.basetheme"
          :subtheme="theme.subtheme"
          :theme-components="theme.components"
          :theme-name="theme.name"
          :theme-sources="themeSources"
        />
        <div class="spark-line">
          <spark-line :values="[0, 0, theme.count]" />
        </div>
        <div class="theme-count">
          {{ theme.count }}
        </div>
      </div>
      <div v-if="empty">
        No new themes
      </div>
    </div>
    <summary-component
      v-if="isSummaryEnabled"
      :filters="filters"
      :theme="selectedTheme"
      :source="source"
      :comparison-themes="comparisonThemes"
      :can-navigate="canNavigate"
      @doNavigate="
        () =>
          doNavigate(
            selectedTheme.theme,
            selectedTheme.subtheme,
            selectedTheme.volumeBy,
            true
          )
      "
    />
    <comment-component
      v-if="isCommentsEnabled"
      :filter-string="filters ? filters.baseline : undefined"
      :theme="selectedTheme"
      :source="source"
      :can-navigate="canNavigate"
      @doNavigate="
        () =>
          doNavigate(
            selectedTheme.theme,
            selectedTheme.subtheme,
            selectedTheme.volumeBy,
            true
          )
      "
    />
  </widget-wrapper>
</template>

<script>
import { ReactInVue } from 'vuera';
import { get, lowerCase, orderBy, slice } from 'lodash';

import { CommentComponent as ReactCommentComponent } from 'components/Dashboard/Widgets/CommentComponent';
import SummaryComponent from './Components/SummaryComponent';
import SparkLine from './Components/SparkLine.vue';
import ThemeNameWithSources from './Components/ThemeNameWithSources';

import CanNavigateMixin from 'vue/dashboards/Dashboard/MixIns/CanNavigateMixin';

import WidgetWrapper from './WidgetWrapper';

export default {
  name: 'THEMES_NEW',
  components: {
    SparkLine,
    ThemeNameWithSources,
    WidgetWrapper,
    SummaryComponent,
    CommentComponent: ReactInVue(ReactCommentComponent),
  },
  mixins: [CanNavigateMixin('explore')],
  props: {
    config: { default: undefined, type: Object },
    filters: { default: undefined, type: Object },
    context: { default: undefined, type: Object },
    data: { default: undefined, type: Object },
    warning: { default: undefined, type: String },
    error: { default: undefined, type: String },
    limit: { default: 5, type: Number },
    loading: { default: false, type: Boolean },
    source: { default: undefined, type: String },
    panelOrder: { type: Number },
    widgetOrder: { type: Number },
  },
  computed: {
    comparisonThemes() {
      return get(this, 'data.comparison');
    },
    themeSources() {
      return get(this, 'data.metadata.sources', undefined);
    },
    periodLabel() {
      return get(this, 'data.currentPeriod', 'This Period');
    },
    empty() {
      return this.newThemes.length === 0;
    },
    newThemes() {
      const themes = get(this, 'data.themes', []);
      const sortedThemes = orderBy(themes, ['count', (theme) => lowerCase(theme.name)], ['desc', 'asc']);

      return slice(sortedThemes, 0, this.limit);
    },
    requestOptions() {
      return { dataSource: this.source };
    },
  },
  watch: {
    config: {
      handler (config) {
        this.isCommentsEnabled = config.showComments;
        this.isSummaryEnabled = config.showSummary;
      },
      immediate: true
    },
  },
};
</script>


<style lang="scss" scoped>
.theme-row {
  display: grid;
  grid:
    'name-area line-area count' 40px
    / 150px auto 75px;
  grid-gap: 10px;
  padding: 0 10px;
}

.spark-line {
  grid-area: line-area;
  height: 50px;
}
.theme-count {
  font-size: 12px;
  text-align: right;
  grid-area: count;
}
</style>
