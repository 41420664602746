import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import {
  Pagination as SemanticPagination,
  PaginationProps as SemanticPaginationProps
} from 'semantic-ui-react';
import './pagination.scss';
import classNames from 'classnames';
import { Tooltip } from './Tooltip';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface PaginationProps extends SemanticPaginationProps {
  totalPages: string | number;
  disabled: boolean;
  boundaryRange: number;
  lastItem?: null;
  firstItem?: null;
  activePage?: string | number;
  inline?: boolean;
  showIconTooltips?: boolean;
  defaultActivePage?: number;
  onPageChange: ((event: React.MouseEvent<HTMLElement, MouseEvent>, paginationProps: SemanticPaginationProps) => void);
}

const TOOLTIP_OFFSET: [number, number] = [-14, 5];

export const Pagination = (paginationProps: PaginationProps) => {
  const {
    onPageChange,
    defaultActivePage,
    totalPages,
    lastItem,
    firstItem,
    activePage,
    disabled,
    boundaryRange,
    inline,
    showIconTooltips
  } = paginationProps;
  const classes = classNames('thematic-pagination', {
    'inline': inline
  });

  const renderIconWithTooltip = (icon: IconProp, tooltipContent: string) => (
    showIconTooltips ? (
      <Tooltip
        content={tooltipContent}
        position="top left"
        offset={TOOLTIP_OFFSET}
        inverted
        trigger={
          <FontAwesomeIcon
            icon={icon}
            className="icon"
            fixedWidth={true}
          />
        }
      />
    ) : (
      <FontAwesomeIcon
        icon={icon}
        className="icon"
        fixedWidth={true}
      />
    )
  );

  return (
    <SemanticPagination
      className={classes}
      defaultActivePage={defaultActivePage}
      activePage={activePage}
      disabled={disabled}
      boundaryRange={boundaryRange}
      totalPages={totalPages}
      lastItem={lastItem === undefined ?
        {
          content: renderIconWithTooltip("chevron-double-right", "Last page"),
          icon: true
        }
      :
        lastItem
      }
      firstItem={firstItem === undefined ?
        {
          content: renderIconWithTooltip("chevron-double-left", "First page"),
          icon: true
        }
      :
        firstItem
      }
      prevItem={{
        content: renderIconWithTooltip("chevron-left", "Previous page"),
        icon: true
      }}
      nextItem={{
        content: renderIconWithTooltip("chevron-right", "Next page"),
        icon: true
      }}
      onPageChange={(e, data) => onPageChange(e, data)}
    />
  );
};