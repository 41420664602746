import { AnalysisFilter, AnalysisFilterCut } from 'api/interfaces';
import { FilterPopUpId } from 'components/Filters/types';
import analytics from 'lib/analytics';
import {
  createDateQueryFilter,
  dateRangePresets,
  fromServerDate,
  getAvailableDateRange,
  getDateRangePresetLabel,
  getDateRangePresetMoments,
  getDefaultDateSelection,
  isDateFilter,
} from 'lib/filters/date-filter-helper';
import { createHierarchicalFilterOptions, findSelectedFilter } from 'lib/filters/hierarchical-filter-helper';
import { ThemesObject, createThemeFilter, createThemeFilterOptions, createThemeQueryFilter, getThemeCuts } from 'lib/filters/theme-filter-helper';
import { cloneDeep, isArray, isEmpty, isEqual, reduce } from 'lodash';
import { action, computed, observable } from 'mobx';
import { SPECIAL_EMPTY_CODE } from 'vue/libs/consts';
import queryBuilder from 'vue/libs/queryBuilder';
import { AnalysisConfigStoreInterface } from './AnalysisConfigStore';
import { URLFilters, UrlParametersStoreInterface } from './UrlParametersStore';
import { AnalysisFilterKey, FilterMatchType, QueryFilter } from './types';
import { queryFilterToSegmentFilter, withoutFilterById } from './utils/query-filters';
import { createTagFilter, getTagCuts } from 'lib/filters/tag-filter-helper';
import { createCategoryCut, createCategoryFilter } from 'lib/filters/category-filter-helper';
import { createSentimentCut, createSentimentFilter } from 'lib/filters/sentiment-filter-helper';
import { ThemesStoreInterface } from './ThemesStore';
import { TagStoreInterface } from './TagStore';
import { getCommentColumns } from 'components/Filters/filters.service';
import { createFreeTextCut } from 'lib/filters/free-text-filter-helper';
import { DateShortcutMap } from 'lib/filters/saved-filter-helper';

interface Titles {
  baseline?: string;
  comparison?: string;
}

interface CodeSet {
  theme: {
    code: string | null;
    name: string;
  };
  subtheme: {
    code?: string | null;
    name?: string;
  };
}

export interface Filter {
  baseline?: Record<string, QueryFilter>;
  comparison?: Record<string, QueryFilter>;
}

export interface FilterSelections {
  baseline: {
    title: string;
    query: string;
    shortTitle: string;
  };
  comparison: {
    title: string;
    query: string;
    shortTitle: string;
  };
}

export interface DateRange {
  start: Date;
  end: Date;
}

function getStandardFilters(): AnalysisFilter[] {
  return [
    createTagFilter(),
    createThemeFilter(),
    {
      ...createCategoryFilter(),
      column: 0,
      cuts: [],
    },
    {
      ...createSentimentFilter(),
      column: 0,
      cuts: [],
    }
  ];
}

export interface FilterStoreInterface {
  filters: Filter;
  openFilterPopUpId: FilterPopUpId | null;
  queryStrings: { baseline?: string; comparison?: string };
  segmentQueryStrings: { baseline?: string; comparison?: string };
  selections: FilterSelections;
  queryTitles: Titles;
  enableFilterMultiSelectByDefault: boolean;
  setInitialDateFilter: boolean;
  selectedFilterNames: string[];
  themesFilter: {
    themes?: string[];
    baselineRql?: string;
    comparisonRql?: string;
  };

  dateShortcuts: (filterId: string) => Record<string, { dateRange: DateRange; }>;
  dateShortcutMaps: Record<string, DateShortcutMap>,

  setFilterConfig: (
    filters: AnalysisFilter[],
    initialFilterStates?: { [id: string]: string },
    initialCompareStates?: { [id: string]: string },
  ) => void;
  filterConfig: AnalysisFilter[],

  clearToInitialState: (key: AnalysisFilterKey) => void;
  clearFilterToInitialState: (key: AnalysisFilterKey, filter: AnalysisFilter) => void;
  closeFilterPopUp: () => void;
  forceSyncToBaseline: (key: AnalysisFilterKey) => void;

  shouldQueryFilterRemainSynced: (filterKey: AnalysisFilterKey) => boolean;

  setQueryFilter: (filter: QueryFilter) => void;
  resetFilters: () => void;
  buildQueryStringFromFilters: (filter: QueryFilter[]) => string;
  getQueryStringByKey: (key: AnalysisFilterKey, ...ignoreFilterIds: string[]) => string;
  getCurrentDateRange: (key: AnalysisFilterKey) => DateRange | undefined;
  selectFilterSet: (filterKey: AnalysisFilterKey, filters: Record<string, AnalysisFilterCut[]>) => void;

  updateSetInitialDateFilter: (value: boolean) => void;
  setThemesFilter: (newCodeSet: CodeSet, themesHierachy: ThemesObject) => void;
  removeUntaggedCommentOption: (themes: ThemesObject) => void;
  updateSelections: () => void;
  openFilterPopUp: (filterPopUpId: FilterPopUpId) => void;
}

class FilterStore implements FilterStoreInterface {

  analysisConfigStore: AnalysisConfigStoreInterface;
  urlParametersStore: UrlParametersStoreInterface;
  themesStore: ThemesStoreInterface;
  tagStore: TagStoreInterface;

  expectedFilterIds = new Set();
  initialFilterStates: Filter | undefined = {};

  @observable
  filters: Filter = {};

  @observable
  openFilterPopUpId: FilterPopUpId | null = null;

  filterConfig: AnalysisFilter[];

  @observable
  themesFilterRaw = {} as {
    themeName?: string,
    subthemeName?: string,
    themeCode?: string,
    subthemeCode?: string
  };

  @observable
  queryStrings = {};

  @observable
  queryTitles: Titles = {};

  @observable
  queryShortTitles: Titles = {};

  @observable
  cachedQueryTitles: Titles = {};

  @observable
  multiSelect = false;

  @observable
  enableFilterMultiSelectByDefault = false;

  @observable
  setInitialDateFilter = true;

  @observable
  selections = {
    baseline: {
      title: '',
      query: '',
      shortTitle: '',
    },
    comparison: {
      title: '',
      query: '',
      shortTitle: '',
    },
  };

  constructor(
    analysisConfigStore: AnalysisConfigStoreInterface,
    urlParametersStore: UrlParametersStoreInterface,
    themesStore: ThemesStoreInterface,
    tagStore: TagStoreInterface,
  ) {
    this.analysisConfigStore = analysisConfigStore;
    this.urlParametersStore = urlParametersStore;
    this.themesStore = themesStore;
    this.tagStore = tagStore
  }

  @computed
  get selectedFilterNames(): string[] {
    const filterSelections = Object.entries(this.filters.baseline || {}).filter(
      ([, value]: [string, QueryFilter]) => value.selected?.length,
    );
    return filterSelections
      .map(([, value]: [string, QueryFilter]) => {
        if (!value.filterName && value.selected && value.selected[0].startDate && value.selected[0].endDate) {
          return 'customDateRange';
        } else {
          return value.filterName?.toLowerCase() || '';
        }
      })
      .filter((filter) => filter.length);
  }

  @computed
  get dateShortcutMaps(): Record<string, DateShortcutMap> {
    return Object.entries(this.filters).reduce((result, [filterKey, queryFilters]) => {
      return {
        ...result,
        [filterKey]: Object.keys(queryFilters).reduce((map: DateShortcutMap, filterId) => ({
          ...map,
          ...this.dateShortcuts(filterId)
        }), {})
      };
    }, {});
  }

  @computed
  get segmentQueryStrings() {
    let segmentQueries = {
      baseline: queryFilterToSegmentFilter(this.filters, 'baseline'),
      comparison: queryFilterToSegmentFilter(this.filters, 'comparison')
    };

    return segmentQueries;
  }

  @computed
  get themesFilter() {
    // The themesFilter includes any segment filters that should be applied to the theme
    // for example if "negative sentiment" is selected then we only want to find themes that have negative sentiment
    // this is done through the use of a segment filter
    const { themesFilterRaw, segmentQueryStrings } = this;
    if (themesFilterRaw.themeCode) {
      const baselineSegment = queryBuilder.appendThemesToSegmentFilter(segmentQueryStrings.baseline || '',
        themesFilterRaw.themeCode,
        themesFilterRaw.subthemeCode || '');
      const comparisonSegment = queryBuilder.appendThemesToSegmentFilter(segmentQueryStrings.comparison || '',
        themesFilterRaw.themeCode,
        themesFilterRaw.subthemeCode || '');
      const themes = [] as string[];
      if (themesFilterRaw.themeName) {
        themes.push(themesFilterRaw.themeName);
      }
      if (themesFilterRaw.subthemeName) {
        themes.push(themesFilterRaw.subthemeName);
      }

      return {
        themes,
        baselineRql: `segment==${ encodeURIComponent(baselineSegment) }`,
        comparisonRql: `segment==${ encodeURIComponent(comparisonSegment) }`
      };
    }
    return {};
  }

  dateShortcuts(filterId: string): Record<string, { dateRange: DateRange }> {
    const filter = this.filterConfig.find((f) => f.id === filterId);
    if (!filter || !isDateFilter(filter)) {
      return {};
    }
    const { maxDate } = getAvailableDateRange(filter);
    return dateRangePresets.reduce((result: Record<string, { dateRange: DateRange }>, preset) => {
      const label = getDateRangePresetLabel(preset);
      const dateRange = getDateRangePresetMoments(preset, maxDate);
      result[label.toLowerCase()] = {
        dateRange: {
          start: dateRange.start.toDate(),
          end: dateRange.end.toDate(),
        },
      };
      return result;
    }, {});
  }

  @action
  resetFilters = () => {
    this.filters = {};
    this.themesFilterRaw = {};
    this.queryStrings = {};
    this.queryTitles = {};
    this.queryShortTitles = {};
    this.multiSelect = false;

    this.expectedFilterIds = new Set();
    this.initialFilterStates = {};

    this.updateSelections();
  };

  @action
  setFilterConfig(
    filters: AnalysisFilter[],
    initialFilterStates?: { [id: string]: string },
    initialCompareStates?: { [id: string]: string },
  ) {
    this.resetFilters();
    const expectedIds = filters.map(filter => filter.id);
    this.expectedFilterIds = new Set(expectedIds);
    this.setInitialFilterState(filters, initialFilterStates, initialCompareStates);
    this.filterConfig = filters;
  }

  setInitialFilterState(
    filters: AnalysisFilter[],
    initialFilterStates?: { [id: string]: string },
    initialCompareStates?: { [id: string]: string },
  ) {
    [...filters, ...getStandardFilters()].forEach((filter) => {
      const initialSelection = this.getInitialFilterSelection(filter, initialFilterStates);

      this.setQueryFilter({
        filterKey: 'baseline',
        filterId: filter.id,
        filterName: filter.name,
        filterType: filter.type,
        selected: initialSelection,
      });
      this.setQueryFilter({
        filterKey: 'comparison',
        filterId: filter.id,
        filterName: filter.name,
        filterType: filter.type,
        selected: initialSelection,
      });
      if (
        initialCompareStates &&
        (filter.id in initialCompareStates || (filter.type === 'date' && 'date_range' in initialCompareStates))
      ) {
        const initialCompareSelection = this.getInitialFilterSelection(filter, initialCompareStates);

        this.setQueryFilter({
          filterKey: 'comparison',
          filterId: filter.id,
          filterName: filter.name,
          filterType: filter.type,
          selected: initialCompareSelection,
        });
      }

      // TODO: Hierarchical filters passed through from dashboards.
      // TODO: Full testing of dashboard routing handling
      // TODO: Full testing of filter initial state handling
    });
  }

  @action
  setQueryFilter(newQueryFilter: QueryFilter) {
    const shouldRemainSynced = this.shouldQueryFilterRemainSynced(newQueryFilter.filterKey);
    const key = newQueryFilter.filterKey;
    // check we have had the initial values from all expected filters
    this.expectedFilterIds.delete(newQueryFilter.filterId);
    if (this.expectedFilterIds.size > 0 && this.initialFilterStates) {
      // cache if we are not ready yet
      const data = this.initialFilterStates[key] || {};
      data[newQueryFilter.filterId] = newQueryFilter;
      this.initialFilterStates = { ...this.initialFilterStates, [key]: { ...data } };
    } else {
      // if we have some initalized filters, populate the values now
      if (this.initialFilterStates) {
        this.filters = cloneDeep(this.initialFilterStates);
        this.initialFilterStates = undefined;
      }
      const data = this.filters[key] || {};
      data[newQueryFilter.filterId] = newQueryFilter;
      // set filters to a whole now object, with baseline/comparison replaced
      this.filters = { ...this.filters, [key]: { ...data } };

      // we were synced before change, lets keep in sync
      if (shouldRemainSynced) {
        Object.keys(this.filters).forEach((i) => {
          if (i !== 'baseline') {
            this.forceSyncToBaseline(i as AnalysisFilterKey);
          }
        });
      }
      this.updateTitleAndString();
      this.updateURLParameters();
    }
  }

  setFilterAndUpdateSelections(newQueryFilter: QueryFilter) {
    this.setQueryFilter(newQueryFilter);
    this.updateSelections();
  }

  @action
  removeUntaggedCommentOption(themes: ThemesObject) {
    // this method is to remove the untagged comment option from themes filter,
    // if that option is selected and user has switched to other tool from feedback
    // we need to do this because "untagged comment" option of themes filter is only available inside feedback tool
    const themesFilter = createThemeFilter();
    let themesQuery: QueryFilter | undefined = this.filters['baseline']?.themes;
    if (
      themesQuery &&
      themesQuery.selected &&
      themesQuery.selected.length > 0 &&
      themesQuery.selected.some(val => val.id === 'untagged_comments')
    ) {
      const isFeedbackTool = false;
      // we will only be calling this method when feedback tool is not active
      const themeFilterOptions = createThemeFilterOptions(themes, themesQuery, isFeedbackTool);
      const newThemesQuery: QueryFilter = createThemeQueryFilter(
        themesFilter,
        themesQuery.filterKey,
        themeFilterOptions,
        themesQuery.match as FilterMatchType
      );
      this.setQueryFilter(newThemesQuery);
    }
  }

  @action
  updateTitleAndString(syncToBaseline?: boolean) {
    // Switch out for string constants
    const queryStrings: Titles = {};
    const queryTitles: Titles = {};
    const queryShortTitles = {};
    Object.keys(this.filters).forEach((key) => {
      // create the relevant query
      queryStrings[key] = queryBuilder.build(this.filters[key]);

      // create the relevant title
      let filters: QueryFilter[] = [];
      // If initial filter config exists, order the filters according
      // to the config, so the labels are ordered to reflect the config
      const initFilterConfig = this.analysisConfigStore.filters;
      if (initFilterConfig.length > 0) {
        initFilterConfig.forEach((configFilter) => {
          const filterId = configFilter.id;
          const filter: QueryFilter | undefined = this.filters[key][filterId];
          if (filter) {
            filters = [...filters, filter];
          }
        });

        // adding titles from special filter types not sourced from server
        const specialFilters = ['themes', 'categories', 'tags', 'sentiment'];
        specialFilters.forEach((filterId) => {
          const filter: QueryFilter | undefined = this.filters[key][filterId];
          if (filter) {
            filters = [...filters, filter];
          }
        });
      } else {
        // default the filters to be in current state order
        filters = this.filters[key];
      }
      queryTitles[key] = queryBuilder.buildTitle(filters);
      // create the relevant 'short' title
      queryShortTitles[key] = queryBuilder.buildShortTitle(this.filters[key]);
    });

    this.queryStrings = queryStrings;
    this.queryTitles = queryTitles;
    this.queryShortTitles = queryShortTitles;
    let tagFilter = false;
    if (queryStrings.baseline?.includes('tags') || queryStrings.comparison?.includes('tags')) {
      tagFilter = true;
    }

    // Track changes to the filters
    if (!isEqual(queryTitles, this.cachedQueryTitles) && !syncToBaseline) {
      // we don't need to track when syncing comparison filter to baseline

      const key = queryTitles.baseline !== this.cachedQueryTitles.baseline ? 'baseline' : 'comparison';
      analytics.track('Analysis: Filter Selected', {
        category: 'Analysis',
        label: key,
        'Tag Filter': tagFilter,
      });

      this.cachedQueryTitles = queryTitles;
    }
  }

  @action
  updateSelections() {
    const baseTitle = this.queryTitles.baseline || '';
    const compTitle = this.queryTitles.comparison || '';
    const baseShortTitle = this.queryShortTitles.baseline || '';
    const compShortTitle = this.queryShortTitles.comparison || '';
    this.selections = {
      baseline: {
        title: baseTitle,
        query: this.getQueryStringByKey('baseline'),
        shortTitle: baseShortTitle,
      },
      comparison: {
        title: compTitle,
        query: this.getQueryStringByKey('comparison'),
        shortTitle: compShortTitle,
      },
    } as FilterSelections;
  }

  @action
  updateURLParameters() {
    const compactChildren = (parent) => {
      const retObject: { id: number; children?: object[] } = { id: parent.id };
      if (!isEmpty(parent.children)) {
        retObject.children = [];
        [...parent.children].forEach((child) => {
          const obj = compactChildren(child);
          retObject.children?.push(obj); // :facepalm: typescript
        });
      }
      return retObject;
    };
    const filters: URLFilters = {
      baseline: {},
      comparison: {},
    };
    // create a minimal representation of the filters
    Object.keys(this.filters).forEach((filterKey) => {
      Object.keys(this.filters[filterKey]).forEach((filterId) => {
        filters[filterKey][filterId] = [];

        const filterCuts: AnalysisFilterCut[] = this.filters[filterKey][filterId]?.selected ?? [];
        filterCuts.forEach((selectedItem: AnalysisFilterCut) => {
          if (this.filters[filterKey][filterId].filterType === 'hierarchical') {
            const obj = compactChildren(selectedItem);
            filters[filterKey][filterId].push(obj);
          } else if (selectedItem.id) {
            // Handle regular filter type
            filters[filterKey][filterId].push(selectedItem.id);
          } else if (selectedItem.startDate && selectedItem.endDate) {
            // Handle date type
            filters[filterKey][filterId].push({
              startDate: selectedItem.startDate,
              endDate: selectedItem.endDate,
            });
          }
        });
      });
    });

    this.urlParametersStore.setParameter('filters', filters);
  }

  @action
  clearToInitialState(key: AnalysisFilterKey) {
    let initialFilters = this.analysisConfigStore.config?.filters;
    initialFilters = initialFilters
      ? [...initialFilters, ...getStandardFilters()]
      : getStandardFilters();
    initialFilters?.forEach((filter) => {
      this.clearFilterToInitialState(key, filter);
    });
    // sending empty array to deselect all the tags when clicked on Reset button
    // this will be removed when new filters are live
    let tagsQueryFilter: QueryFilter = {
      filterKey: key,
      filterId: 'tags',
      filterName: 'Labels',
      filterType: 'tags',
      selected: [],
    };
    this.setQueryFilter(tagsQueryFilter);
    let themesQueryFilter: QueryFilter = {
      filterKey: key,
      filterId: 'themes',
      filterName: 'Themes',
      filterType: 'themes',
      selected: [],
    };
    this.setQueryFilter(themesQueryFilter);
  }

  @action
  clearFilterToInitialState(filterKey: AnalysisFilterKey, filter: AnalysisFilter) {
    const initialFilterState = filter;
    const filterId = filter.id;

    const filterName = initialFilterState.name;
    const currentFilterState: QueryFilter = this.filters[filterKey]![filterId];

    let defaultFilter: AnalysisFilterCut[] = [];
    // if default selection is "All" it won't have rql
    const hasInitialFilterCutsWithRql: boolean = !!initialFilterState.cuts
      && initialFilterState.cuts.length > 0
      && initialFilterState.cuts[0].rql.length > 0;
    if (isDateFilter(filter)) {
      // figure out the default for date
      const defaultDateSelection = getDefaultDateSelection(filter);
      defaultFilter = [{ ...defaultDateSelection[0], isDefaultSelection: true }];
    } else {
      defaultFilter = hasInitialFilterCutsWithRql
        ? [{ ...initialFilterState.cuts![0], isDefaultSelection: true }]
        : defaultFilter;
    }

    // Only clear values that are not default
    const selectedFilters = (currentFilterState || {}).selected;
    const hasSelectedFilters = !isEmpty(selectedFilters);
    const hasDefaultFilters = !!defaultFilter && defaultFilter.length > 0;
    const hasDefaultAndSelectedFilters =  hasDefaultFilters && hasSelectedFilters;

    // We allow the filters to be cleared, on the condition that
    // - we have selected filters, and
    // - the default filter is empty, or
    // - selected filters dont match default filters, based on
    //   - the rql not matching (given there is rql in default filters)
    //   - the ids not matching (only for hierarchical type filters)

    const doesRqlDiffer = hasDefaultAndSelectedFilters && selectedFilters![0].rql !== defaultFilter[0].rql;
    const doesIdDiffer = hasDefaultAndSelectedFilters && selectedFilters![0].id !== defaultFilter[0].id;

    const selectedFiltersDifferFromDefaultFilters: boolean = hasDefaultFilters && defaultFilter[0].rql
      ? doesRqlDiffer
      : doesIdDiffer;

    const canClearFilters =
      !hasDefaultFilters
      || (filter.type === 'hierarchical' && selectedFiltersDifferFromDefaultFilters)
      || doesRqlDiffer;

    if (!hasSelectedFilters || !canClearFilters) {
      return;
    }

    const queryFilter: QueryFilter = {
      filterKey,
      filterId: filterId,
      filterName: filterName,
      selected: defaultFilter,
    };
    this.setQueryFilter(queryFilter);
  }

  @action
  forceSyncToBaseline(key: AnalysisFilterKey) {
    const baselineFilter = this.filters['baseline'] || ({} as QueryFilter);
    // Lets move all states to reflect baselines
    Object.keys(baselineFilter).forEach((i) => {
      const filterId = baselineFilter[i].filterId;
      const queryFilter: QueryFilter = {
        filterKey: key,
        filterId: filterId,
        filterName: baselineFilter[i].filterName,
        filterType: baselineFilter[i].filterType,
        selected: baselineFilter[i].selected,
      };

      if (baselineFilter[i].match) {
        queryFilter.match = baselineFilter[i].match;
      }

      // Only update the current filter state when it is different to baseline
      const currentFilterState = this.filters[key] || ({} as QueryFilter);
      const selectedBaselineFilters = this.getSelectedValues(queryFilter);
      const selectedComparisonFilters = this.getSelectedValues(currentFilterState[filterId]);

      // update the current filter state if it a has match property and different to baseline
      if (
        !isEqual(selectedBaselineFilters, selectedComparisonFilters) ||
        queryFilter?.match !== currentFilterState[filterId]?.match
      ) {
        const filterKey = queryFilter.filterKey;
        const data = this.filters[filterKey] || {};
        data[queryFilter.filterId] = queryFilter;
        // set filters to a whole now object, with baseline/comparison replaced
        this.filters = { ...this.filters, [filterKey]: { ...data } };
      }
    });
    const syncToBaseline = true;
    this.updateTitleAndString(syncToBaseline);
  }

  @action
  setThemesFilter(newCodeSet: CodeSet, themesHierarchy: ThemesObject) {
    // Temp until we build a better lookup system
    let themeName = '';
    let subthemeName: string | undefined;
    let themeCode: string | undefined;
    let subthemeCode: string | undefined;
    Object.keys(themesHierarchy).forEach((i) => {
      const item = themesHierarchy[i];
      if (item.id === newCodeSet.theme.code) {
        themeName = i;
        themeCode = item.id;
        if (newCodeSet.subtheme.code && newCodeSet.subtheme.code !== SPECIAL_EMPTY_CODE) {
          Object.keys(item.subthemes).forEach((j) => {
            const subTheme = item.subthemes[j];
            if (subTheme.id === newCodeSet.subtheme.code) {
              subthemeName = j;
              subthemeCode = subTheme.id;
            }
          });
        }
      }
    });
    this.themesFilterRaw = { themeName, subthemeName, themeCode, subthemeCode };
  }

  @action
  selectFilterSet(filterKey: AnalysisFilterKey, filters: Record<string, AnalysisFilterCut[]>) {
    // Reset all filters before selecting the given values
    this.clearToInitialState(filterKey);
    Object.keys(filters).forEach((key) => {
      const selected: AnalysisFilterCut[] = filters[key];
      const queryFilters: Record<string, QueryFilter> | undefined = this.filters[filterKey];

      if (queryFilters?.[key]) {
        queryFilters[key].selected = selected;
      }
    });
    this.updateTitleAndString();
  }

  @action openFilterPopUp(filterPopUpId: FilterPopUpId) {
    this.openFilterPopUpId = filterPopUpId;
  }

  @action closeFilterPopUp() {
    this.openFilterPopUpId = null;
  }

  @action
  updateSetInitialDateFilter = (value: boolean) => {
    this.setInitialDateFilter = value;
  };

  shouldQueryFilterRemainSynced(filterKey: AnalysisFilterKey): boolean {
    if (Object.keys(this.filters).length <= 1 || filterKey !== 'baseline') {
      return false;
    }
    // If we are in sync with baseline and all comparisons
    // Then we should remain in sync when baseline changes
    let baselineSynced = filterKey === 'baseline';
    const baselineCurrentState: Filter = (this.filters as QueryFilter)['baseline'];

    Object.keys(this.filters).forEach((i) => {
      const filters: AnalysisFilterKey = this.filters[i];
      Object.keys(filters).forEach((j) => {
        const filter: QueryFilter = filters[j];
        const isCurrentBaselineFilterDiffer = baselineCurrentState
          && baselineCurrentState[j]
          && baselineCurrentState[j].selected!.length !== filter.selected!.length;
        const isCurrentBaselineFilterEmpty = !baselineCurrentState
          || !baselineCurrentState[j]
          || !baselineCurrentState[j].selected;
        if (isCurrentBaselineFilterEmpty || isCurrentBaselineFilterDiffer) {
          baselineSynced = false;
        }
        Object.keys(filter.selected!).forEach((k: string) => {
          const selectedCut: AnalysisFilterCut = filter.selected![k];

          const list: AnalysisFilterCut[] = baselineCurrentState?.[j]?.selected;
          const minLength = parseInt(k, 10) + 1; // We need to check array length to satisfy mobx
          const selection = Array.isArray(list) && list.length >= minLength && list[k];

          const rootCut = list ? selection : undefined;

          if (selectedCut === undefined || selectedCut === null) {
            return;
          }

          const isDateDiffer = rootCut
            && rootCut.startDate
            && (rootCut.startDate !== selectedCut.startDate || rootCut.endDate !== selectedCut.endDate);
          if (isDateDiffer) {
            // Is date
            baselineSynced = false;
          }

          const isRqlDiffer = !rootCut || rootCut.rql !== selectedCut.rql;
          if (isRqlDiffer) {
            // Aren't in sync do nothing
            baselineSynced = false;
          }
        });
      });
    });
    return baselineSynced;
  }

  applyIgnoreToFilters = (key: AnalysisFilterKey, ...ignoreFilterIds: string[]) => {
    const filter = withoutFilterById(this.filters, key, ignoreFilterIds) as QueryFilter[];

    const query = queryBuilder.build(filter);
    const title = queryBuilder.buildTitle(filter);
    const shortTitle = queryBuilder.buildShortTitle(filter);

    return { query, title, shortTitle };
  };

  getQueryStringByKey = (key: AnalysisFilterKey, ...ignoreFilterIds: string[]) => {
    const filter = withoutFilterById(this.filters, key, ignoreFilterIds) as QueryFilter[];
    return this.buildQueryStringFromFilters(filter);
  };

  getCurrentDateRange = (key: AnalysisFilterKey) => {
    const filtersForKey = this.filters[key];
    if (filtersForKey) {
      // collect the date filters
      const dateFilter = reduce(filtersForKey, (result, f) => {
        if (f.filterType === 'date') {
          result.push(f);
        }
        return result;
      }, [] as QueryFilter[]);
      // if we have found a date filter, use the first
      if (dateFilter.length > 0 &&
        dateFilter[0].selected &&
        dateFilter[0].selected.length > 0 &&
        dateFilter[0].selected[0].startDate &&
        dateFilter[0].selected[0].endDate) {
        return {
          start: fromServerDate(dateFilter[0].selected[0].startDate),
          end: fromServerDate(dateFilter[0].selected[0].endDate),
        };
      }
    }
    return undefined;
  };

  buildQueryStringFromFilters = (filter: QueryFilter[]) => {
    return queryBuilder.build(filter);
  };

  getSelectedValues = (filter: QueryFilter | undefined) => {
    // Get appropriate values for different types of filters
    return (
      filter?.selected?.map((s) => {
        if ('id' in s) {
          return s.id;
        } else if ('startDate' in s) {
          return (s.startDate || '') + (s.endDate || '');
        } else if ('text' in s) {
          return s.text;
        }
        return undefined;
      }) || []
    );
  };

  getInitialFilterSelection(filter: AnalysisFilter, initialStates?: { [id: string]: string }): AnalysisFilterCut[] {
    if (initialStates && filter.id in initialStates) {
      const hasMultipleOptionSelected = isArray(initialStates[filter.id]);
      const cutIds: string[] = hasMultipleOptionSelected ? [...initialStates[filter.id]] : [initialStates[filter.id]];

      if (filter.cuts && filter.type === 'hierarchical') {
        const hierarchicalFilterOptions = createHierarchicalFilterOptions(filter.cuts);
        return cutIds.map(id => findSelectedFilter(hierarchicalFilterOptions, id));
      }

      const themesHierarchy = this.themesStore.themesHierarchy;

      if (filter.type === 'themes' && themesHierarchy) {
        const ids = initialStates['themes'].split(',');
        return getThemeCuts(ids, themesHierarchy);
      }

      if (filter.type === 'sentiment') {
        const ids = initialStates['sentiment'].split(',');
        return ids.map(createSentimentCut)
      }

      if (filter.type === 'categories') {
        const ids = initialStates['categories'].split(',');
        return ids.map(createCategoryCut)
      }

      const freeTextFilterId = this.analysisConfigStore.filters.find(f => f.type === 'freetext')?.id;

      if (filter.type === 'freetext' && freeTextFilterId && initialStates[freeTextFilterId]) {
        const texts = initialStates[freeTextFilterId].split(',');
        return texts.map(text => createFreeTextCut(text, freeTextFilterId));
      }

      const sortedTags = this.tagStore.sortedTags;

      if (filter.type === 'tags') {
        const commentColumns = getCommentColumns();
        const ids = initialStates['tags'].split(',');
        return getTagCuts(ids, sortedTags, commentColumns);
      }

      if (filter.cuts) {
        return filter.cuts.filter(c => cutIds.includes(c.id || ''));
      }

    }

    if (isDateFilter(filter) && this.setInitialDateFilter) {
      if (initialStates && 'date_range' in initialStates) {
        const dateRange = initialStates['date_range'].split(':');
        const [startDate, endDate] = dateRange.map(fromServerDate);

        // it's okay to send baseline as filterKey here because we are only going to use selected property
        const queryFilter = createDateQueryFilter(filter, 'baseline', startDate, endDate);
        return queryFilter.selected ? queryFilter.selected : [];
      }

      const defaultDateSelection = getDefaultDateSelection(filter);
      return [{ ...defaultDateSelection[0], isDefaultSelection: true }];
    }

    if (filter.cuts && filter.cuts.length > 0 && filter.cuts[0].rql !== '') {
      const firstCut = [filter.cuts[0]];
      return [{ ...firstCut[0], isDefaultSelection: true }];
    }

    return [];
  }

}

export default FilterStore;
