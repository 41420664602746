<template>
  <div class="percentage-bar">
    <svg
      class="percentage-bar-indicator"
      preserveAspectRatio="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 32"
    >
      <defs>
        <linearGradient id="overall">
          <stop
            offset="0%"
            :stop-color="colors.primary400"
          />
          <stop
            offset="100%"
            :stop-color="colors.primary300"
          />
        </linearGradient>
        <linearGradient id="comparison">
          <stop
            offset="0%"
            :stop-color="colors.orange400"
          />
          <stop
            offset="100%"
            :stop-color="colors.orange200"
          />
        </linearGradient>
        <linearGradient id="negative-overall">
          <stop
            offset="0%"
            :stop-color="colors.primary300"
          />
          <stop
            offset="100%"
            :stop-color="colors.primary400"
          />
        </linearGradient>
        <linearGradient id="negative-comparison">
          <stop
            offset="0%"
            :stop-color="colors.orange200"
          />
          <stop
            offset="100%"
            :stop-color="colors.orange400"
          />
        </linearGradient>
      </defs>
      <rect
        :x="x"
        :width="width + '%'"
        :fill="`url(#${isNegative ? 'negative-': ''}overall)`"
        y="4"
        rx="0"
        ry="0"
        height="10"
      />
      <rect
        :x="x"
        :width="oldWidth + '%'"
        :fill="`url(#${isNegative ? 'negative-': ''}comparison)`"
        y="18"
        rx="0"
        ry="0"
        height="10"
      />
    </svg>
    <div class="percentage-label baseline">
      {{ baseline }}
    </div>
    <div class="percentage-label comparison">
      {{ comparison }}
    </div>
  </div>
</template>

<script>
import toFixed from 'vue/libs/to-fixed';
import colors from 'vue/styles/element-variables.scss';
function format(value, precision = 1, suffix) {
  if (precision === 0) {
    return toFixed(Math.round(value), 0) + suffix;
  }
  return toFixed(value, precision) + suffix;
}
export default {
  name: 'BarDifference',
  props: {
    value: {
      type: [String, Number],
    },
    oldValue: {
      type: [String, Number],
    },
    maxValue: {
      type: [String, Number],
    },
    suffix: {
      type: [String, Number],
    },
    dp: {
      type: [String, Number],
    }
  },
  data() {
    return {
      colors: colors
    }
  },
  computed: {
    baseline() {
      return format(this.value, this.dp, this.suffix);
    },
    comparison() {
      return format(this.oldValue, this.dp, this.suffix);
    },
    width() {
      return Math.abs(0.9 * (this.value / this.maxValue * 100));
    },
    oldWidth() {
      return Math.abs(0.9 * (this.oldValue / this.maxValue * 100));
    },
    isNegative() {
      return this.value < 0;
    },
    x() {
      if (this.value > 0) {
        return 0;
      } else {
        return 100 - this.width + '%';
      }
    }
  }
};
</script>


<style lang="scss" scoped>
.percentage-bar {
  align-items: center;
  display: grid;
  grid-gap: 0 8px;
  grid:
    'bar-inner baseline' 16px
    'bar-inner comparison' 16px
    / 1fr 60px;
}
.percentage-bar-indicator {
  height: 32px;
  display: block;
  width: 100%;
  grid-area: bar-inner;
}
.percentage-label {
  font-size: 12px;
  line-height: 16px;
  padding: 0;
  text-align: right;
}
.baseline {
  grid-area: baseline;
}
.comparison {
  grid-area: comparison;
}
</style>
