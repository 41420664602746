import { AnalysisConfig, AnalysisFilter, AnalysisScoreOptions, Dimension, Score } from 'api/interfaces';
import auth from 'Auth/Auth';
import { assign, compact, defaults, filter, find, flatMap, get, includes, isArray, isNumber, map } from 'lodash';
import { action, computed, observable } from 'mobx';

type DateResolutionType = 'weekly' | 'monthly' | 'quarterly' | 'biannually' | 'rolling90';

export interface RequestOptions {
  dataSource: string;
  dateColumn: number;
  dateOptions: object | null;
  dateResolution: DateResolutionType;
  dateMaxPast: number;
  exampleColumns: number | number[] | null;
  scoreColumn: number;
  scoreOptions?: AnalysisScoreOptions;
  scoreType: string;
  baseFilter: AnalysisFilter;
}

export interface InitConfigStoreInterface {
  config: AnalysisConfig | null;
  configError: Error | null;
  configLocation: string;
  chartDateResolution: DateResolutionType;
  scoreGraphSelector: null;
  sentimentColumn: number;

  organization: string | undefined;
  currentUserCan: string[];
  userOrg: string | undefined;
  hasScore: boolean;
  hasDate: boolean;
  excludeSignificantChanges: boolean;
  chartDefaultResolution: DateResolutionType;
  dateMaxPast: number;
  getChartDateResolution: DateResolutionType;
  dimensions: Dimension[];
  commentHighlightingType: string;
  isScoreGraphSelector: boolean;
  commentColumns: number[];
  scoreColumn: number;
  scoreColumnId: string | null;
  IdsFromColumn: (column: number) => string[] | null;
  getSentimentColumn: number;
  requestOptions: RequestOptions;
  scores: Score[] | undefined;
  visualizations: object[] | AnalysisConfig;
  title: string;
  surveyId: string;
  visId: string;
  getConfigLocation: string;
  isLoadingConfig: boolean;

  resetConfig: () => void;
  getConfig: () => Promise<AnalysisConfig | undefined>;
  setChartDateResolution: (resolution: string) => void;
  setConfigLocation: (configLocation: string) => void;
}

class InitConfigStore implements InitConfigStoreInterface {
  @observable
  config: AnalysisConfig | null;

  @observable
  configError: Error | null;

  @observable
  configLocation: string;

  @observable
  chartDateResolution: DateResolutionType;

  @observable
  scoreGraphSelector: null;

  @observable
  sentimentColumn: number;

  @observable
  isLoadingConfig: boolean;

  @computed
  get organization() {
    if (!this.config) {
      return undefined;
    }
    // this will find the organization from the path (a path being /c/:orgId?...)
    const re = /^\/c\/([^/?]*)/gm;
    const hash = (location.hash || '').substring(1);
    const matches = re.exec(hash);
    if (matches) {
      return decodeURIComponent(matches[1]); // we want a true organization string, not url encoded
    } else {
      return undefined;
    }
  }

  @computed
  get currentUserCan() {
    const currentUserCan = get(this, 'config.scopes');
    return currentUserCan;
  }

  @computed
  get userOrg() {
    if (!this.config) {
      return 'Unknown';
    }

    const { user_organization: userOrg } = this.config;
    if (userOrg) {
      return userOrg;
    }

    if (includes(this.currentUserCan, 'manage:internalResource')) {
      return 'Thematic';
    } else {
      return this.organization;
    }
  }

  @computed
  get hasScore() {
    const scoresArray = get(this, 'config.scores');
    return isArray(scoresArray);
  }

  @computed
  get hasDate() {
    const column = get(this, 'config.date_column');
    return isNumber(column);
  }

  @computed
  get excludeSignificantChanges() {
    return get(this, 'config.visualization_options.exclude_significant_changes', false);
  }

  @computed
  get chartDefaultResolution() {
    return get(this, 'config.date_resolution', 'monthly');
  }

  @computed
  get dateMaxPast() {
    const initialMax = this.requestOptions.dateMaxPast;
    const initialRes = this.chartDefaultResolution;
    const currentRes = this.getChartDateResolution;

    let initialWeeks = initialMax;
    // Get initial res in weeks
    if (initialRes === 'biannually') {
      initialWeeks *= 24; // 24 weeks to a 6 month period
    } else if (initialRes === 'quarterly') {
      initialWeeks *= 12; // 12 weeks to a quarter
    } else if (initialRes === 'monthly') {
      initialWeeks *= 4; // 4 weeks to a month
    }

    let max = initialWeeks;
    // Get current res
    if (currentRes === 'biannually') {
      max /= 23;
    } else if (currentRes === 'quarterly') {
      max /= 12;
    } else if (currentRes === 'monthly' || currentRes === 'rolling90') {
      max /= 4;
    }

    return Math.ceil(max > 26 ? 26 : max);
  }

  @computed
  get getChartDateResolution() {
    const defaultResolution = get(this, 'config.date_resolution', 'monthly');
    return this.chartDateResolution || defaultResolution;
  }

  @computed
  get dimensions() {
    return get(this, 'config.dimensions', []);
  }

  @computed
  get commentHighlightingType() {
    return get(this, 'config.comment_highlighting', 'all');
  }

  @computed
  get isScoreGraphSelector() {
    const sgs = get(this, 'config.scoregraph_selector', true);
    return this.scoreGraphSelector || sgs;
  }

  @computed
  get commentColumns() {
    const commentColumns = get(this, 'config.comment_columns');

    if (isNumber(commentColumns)) {
      return [commentColumns];
    } else if (isArray(commentColumns)) {
      return commentColumns;
    } else {
      return [];
    }
  }

  @computed
  get scoreColumn() {
    return get(this, 'config.score_column');
  }

  @computed
  get scoreColumnId() {
    const { config } = this;
    if (!config) {
      return null;
    }
    const { score_column: column, filters } = config;
    const filterByColumn = find(filters, { column });

    return get(filterByColumn, 'id', '');
  }

  @computed
  get IdsFromColumn() {
    return (column: number) => {
      const { config } = this;
      if (!config) {
        return null;
      }
      const { filters } = config;
      const columnFilter = filter(filters, { column });
      return compact(
        flatMap(columnFilter, (obj) => {
          return obj.id || '';
        }),
      );
    };
  }

  @computed
  get getSentimentColumn() {
    return get(this, 'config.sentiment_column', -1);
  }

  @computed
  get requestOptions() {
    const { config } = this;
    if (!config) {
      return {} as RequestOptions;
    }
    const chartDateResolution = this.getChartDateResolution;

    return {
      dataSource: config.dataSource,
      dateColumn: config.date_column,
      dateOptions: config.date_options || {},
      dateResolution: chartDateResolution,
      dateMaxPast: config.max_past || 12,
      exampleColumns: config.example_columns || null,
      scoreColumn: this.scoreColumn,
      scoreOptions: defaults(config.score_options, { scoreCommentsOnly: true }) || {},
      scoreType: config.score_type,
      baseFilter: config.base_filter,
    } as RequestOptions;
  }

  @computed
  get scores() {
    const scores = get(this, 'config.scores') as Score[];

    if (scores && scores.length) {
      return map(scores, (score) => {
        const { score_column, score_type, name } = score;
        return assign({}, score, {
          key: `${score_column}-${score_type}-${name}`,
        });
      });
    } else {
      const { config } = this;
      if (!config) {
        return undefined;
      }
      const { score_column, score_options, score_type } = config;
      if (!isNumber(score_column) || !score_type) {
        return [];
      }
      const score = {
        key: `${score_column}-${score_type}`,
        name: score_type,
        score_column,
        score_options,
        score_type,
      };
      return [score];
    }
  }

  @computed
  get visualizations() {
    const { config } = this;
    if (!config) {
      return [];
    }
    // EITHER we have a list of visualizations because we are an ANALYSIS OR we have a single configuration (old style)
    return config.visualizations || [config];
  }

  @computed
  get title() {
    const { config } = this;
    if (!config) {
      return '';
    }
    // EITHER we have a list of visualizations because we are an ANALYSIS OR we have a single configuration (old style)
    return config.title || '';
  }

  @computed
  get surveyId() {
    const { configLocation = '' } = this;

    const matches = configLocation.match(/\/survey\/([^/]*)/);
    return (matches && matches[1]) || '';
  }

  @computed
  get visId() {
    const { configLocation = '' } = this;

    const matches = configLocation.match(/\/([^/]+)\/config\/?/);
    return (matches && matches[1]) || '_';
  }

  @computed
  get getConfigLocation() {
    const { configLocation = '' } = this;
    return configLocation;
  }

  @action
  resetConfig() {
    this.config = null;
  }

  @action
  async getConfig() {
    this.isLoadingConfig = true;
    this.configError = null;
    let resourceUri = this.configLocation;
    if (process.env.experiments) {
      resourceUri = `/test/data/initConfig.json`;
    }

    try {
      const response = await auth.fetch<AnalysisConfig>(resourceUri, { isRaw: true });

      if (response.ok && response.data) {
        this.config = response.data;
        return response.data;
      }

      if (!response.ok) {
        this.configError = new Error(response.errorData?.message);
        throw new Error(response.errorData?.message);
      }

    } catch (e) {
      this.configError = e;
      return e;
    } finally {
      this.isLoadingConfig = false;
    }
  }

  @action
  setChartDateResolution(resolution: DateResolutionType) {
    this.chartDateResolution = resolution;
  }

  @action
  setConfigLocation(configLocation: string) {
    this.configLocation = configLocation;
  }
}

export default InitConfigStore;
