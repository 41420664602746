<template>
  <div
    :class="{ 'no-data': empty, selected: active, 'has-change': hasChange }"
    class="stack-rank-row"
    @click="select()"
  >
    <div
      :class="{ 'sorted-by': sort === 'label'}"
      class="stack-rank-label"
    >
      {{ label }}
    </div>
    <div
      v-if="!empty"
      :class="{ 'sorted-by': sort === 'score'}"
      class="score-column"
    >
      {{ scoreLabel }}
    </div>
    <div
      v-if="empty"
      class="empty"
    >
      No data
    </div>
    <div
      v-else
      class="score-column"
    >
      <segmented-bar
        :max="maxScore"
        :min="minScore"
        :segments="segments"
        :score="score"
        :show-comment-count="!isWeighted"
      />
    </div>
    <div
      v-if="!empty && hasChange"
      :class="{ 'sorted-by': sort === 'change'}"
      class="change"
    >
      <div v-if="changeLabel">
        <component
          :is="changeArrow"
          v-if="changeArrow"
        />
        <span>{{ changeLabel }}</span>
      </div>
      <div v-else>
        <el-tooltip
          :open-delay="300"
          placement="top"
        >
          <div slot="content">
            Unable to calculate change
          </div>
          <div class="no-valid-change">
            &mdash;
          </div>
        </el-tooltip>
      </div>
    </div>
    <div
      v-if="!empty"
      :class="{ 'sorted-by': sort === 'volume'}"
      class="volume"
    >
      <bar-normalized
        :count="count"
        :count-suffix="countSuffix"
        :value="volume"
        :width="volumeNormalized"
        :precision="1"
        :shaded="true"
      />
    </div>
    <div
      v-if="!empty"
      :class="{ 'sorted-by': sort === 'sentiment'}"
      class="sentiment"
    >
      <font-awesome-icon
        v-if="sentimentIcon"
        :class="`sentiment-${sentiment}`"
        :icon="sentimentIcon"
      />
    </div>
  </div>
</template>

<script>
import { includes, isNumber } from 'lodash';
import toFixed from 'vue/libs/to-fixed';
import { VALID_SENTIMENTS } from 'lib/calculate-sentiment';
import BarNormalized from './BarNormalized.vue';
import SegmentedBar from 'vue/components/Charts/SegmentedBar.vue';

export default {
  name: 'StackRankRow',
  components: { BarNormalized, SegmentedBar },
  props: {
    compareFilter: { type: String },
    count: { type: Number },
    countSuffix: {type: String },
    filterId: { type: String },
    filterName: { type: String },
    id: { type: String },
    isWeighted: { default: false, type: Boolean },
    label: { type: String },
    maxScore: { type: Number },
    minScore: { type: Number },
    previousCount: { type: Number },
    previousScore: { type: Number },
    rql: { type: String },
    score: { type: Number },
    sort: { type: String },
    segments: { type: Array },
    sentiment: { type: String },
    volume: { type: Number },
    volumeNormalized: { type: Number }
  },
  computed: {
    active() {
      return this.compareFilter === `((${this.rql}))`;
    },
    arrowUp() {
      return () => import('images/vue-icons/arrow-up.svg');
    },
    arrowDown() {
      return () => import('images/vue-icons/arrow-down.svg');
    },
    change() {
      const { count, previousCount, previousScore } = this;
      const validCounts = count > 0 && previousCount > 0;
      if (validCounts && isNumber(previousScore)) {
        return this.score - this.previousScore;
      } else {
        return undefined;
      }
    },
    changeArrow() {
      const { change } = this;

      if (change >= 0.1) {
        return this.arrowUp;
      } else if (change <= 0.1) {
        return this.arrowDown;
      } else {
        return undefined;
      }
    },
    changeLabel() {
      const { change } = this;

      const text = toFixed(change, 1);
      if (change >= 0) {
        return `+${text}`;
      } else {
        return text;
      }
    },
    empty() {
      return !this.segments;
    },
    hasChange() {
      return isNumber(this.previousScore);
    },
    scoreLabel() {
      const { empty, score } = this;
      if (!empty && isNumber(score)) {
        const label = toFixed(score, 1);
        if (score > 0) {
          return `+${label}`;
        } else {
          return label;
        }
      } else {
        return undefined;
      }
    },
    sentimentIcon() {
      if (includes(VALID_SENTIMENTS, this.sentiment)) {
        return this.sentiment;
      } else {
        return undefined;
      }
    }
  },
  methods: {
    select() {
      const { empty, id, label, rql } = this;
      if (empty) {
        return;
      }
      const queryFilter = {
        filterId: this.filterId,
        filterName: this.filterName,
        selected: [{ id, label, name: label, rql }]
      };
      this.$emit('selectionChanged', queryFilter, label);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '../../../../styles/element-variables.scss';
@import './stack-rank.scss';

.stack-rank-row {
  align-items: center;
  border-color: $--neutral-100;
  border-style: solid;
  border-width: 1px 1px 0 1px;
  &:last-child {
    border-width: 1px;
  }
  cursor: pointer;
  display: grid;
  grid: $stack-rank-grid;
  padding: 18px 0 17px;
  > div {
    padding: 0 10px;
    &:first-child {
      padding-left: 20px;
    }
    &:last-child {
      padding-right: 20px;
    }
  }
  &.has-change {
    grid: $stack-rank-grid-with-change;
  }
  &:hover {
    background-color: $--neutral-100;
  }
  .sorted-by {
    background: $--primary-100;
    height: 60px;
    line-height: 60px;
    margin: -18px 0 -17px;
    &.volume {
      align-items: center;
      display: flex;
    }
  }

  &.selected {
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.1);
    position: relative;
    &:before {
      background: $--orange-500;
      content: '';
      position: absolute;
      left: 0;
      height: 60px;
      width: 10px;
    }
  }
  .stack-rank-label {
    text-align: left;
  }

  .empty {
    min-height: unset;
    background: repeating-linear-gradient(
      -45deg,
      $--border-color,
      $--border-color 10px,
      transparent 10px,
      transparent 20px
    );
    font-size: 12px;
    font-weight: 600;
    grid-column: 2 / 6;
    line-height: 14px;
    opacity: 0.5;
    padding: 5px 0;
  }

  .change {

    div {
      display: flex;
      align-items: center;
    }
    span {
      flex: 1 1 auto;
    }
    svg {
      width: 1rem;
      height: 1rem;
      margin-right: 3px;
      flex: 0 1 auto;
    }
    .no-valid-change {
      color: $--border-color;
      display: inline-block;
      margin-left: 18px;
    }
  }
  .score-column {
    font-size: 16px;
    padding: 0 10px;
  }
  ::v-deep .percentage-bar-indicator {
    color: $--color-primary;
  }
  .sentiment {
    .sentiment-tired {
      color: $--sentiment-tired !important;
    }
    .sentiment-frown {
      color: $--sentiment-frown !important;
    }
    .sentiment-meh {
      color: $--sentiment-meh !important;
    }
    .sentiment-smile {
      color: $--sentiment-smile !important;
    }
    .sentiment-grin-stars {
      color: $--sentiment-grin !important;
    }
    font-size: 24px;
    text-align: center;
  }
}
</style>

