import * as React from 'react';
import { SummarySegment } from 'types/custom';
import { FilterOption } from './constants';
import './conversation-lens.scss';
import classNames from 'classnames';

const LENS_SCALE = 0.2;
const LENS_ITEM_GAP = 4;
const LINE_WIDTH = 30;
const LINE_HEIGHT_OFFSET = 1;

interface Props {
  conversationListRef: React.RefObject<HTMLUListElement | null>;
  conversationItemRefs: React.RefObject<HTMLDivElement | null>[];
  conversationScrollTop: number;
  onLensUpdate: (scrollTop: number) => void;
  selectedFilter: FilterOption;
  selectedSegments: SummarySegment[];
}

const ConversationLens = ({
  conversationListRef,
  conversationItemRefs,
  conversationScrollTop,
  onLensUpdate,
  selectedSegments,
}: Props) => {
  const [scrollerHeight, setScrollerHeight] = React.useState<number>(0);
  const [isDragging, setIsDragging] = React.useState(false);
  const lensRef = React.useRef<HTMLUListElement | null>(null);
  const scrollerRef = React.useRef<HTMLDivElement | null>(null);

  const addLineToLens = (height: number, backgroundColor: string) => {
    const line = document.createElement('li');
    line.style.height = `${height - LINE_HEIGHT_OFFSET}px`;
    line.style.marginBottom = `${LENS_ITEM_GAP}px`;
    line.style.width = `${LINE_WIDTH}px`;
    line.style.backgroundColor = backgroundColor;

    lensRef?.current?.appendChild(line);
  };

  const draw = () => {
    const conversationList = conversationListRef.current;
    const currentList = lensRef.current;

    if (!conversationList) {
      return;
    }

    if (currentList) {
      currentList.innerHTML = '';
    }

    conversationItemRefs.forEach((itemRef) => {
      const item = itemRef.current;

      if (!item) {
        return;
      }

      const elementHeight = item.offsetHeight;
      const minimapElementHeight = elementHeight * LENS_SCALE;

      const isSelected = (item?.firstChild as HTMLDivElement)?.classList?.contains('conversation-item--selected');
      const backgroundColor = isSelected ? 'var(--primary-300)' : 'var(--neutral-200)';

      addLineToLens(minimapElementHeight, backgroundColor);
    });

    const conversationListVisibleHeight = conversationList.offsetHeight;

    setScrollerHeight(conversationListVisibleHeight * LENS_SCALE);
  };

  React.useEffect(() => {
    draw();
  }, [selectedSegments]);

  React.useEffect(() => {
    const lens = lensRef.current;
    const scroller = scrollerRef.current;

    if (!lens || !scroller) {
      return;
    }
  }, []);

  const repositionScroller = (event: React.MouseEvent<HTMLUListElement>) => {
    const lens = lensRef.current;
    const scroller = scrollerRef.current;

    if (!lens || !scroller) {
      return;
    }
    const { clientY } = event;
    const lensTop = lens.getBoundingClientRect().top;
    const lensHeight = lens.offsetHeight;

    const clickPosition = clientY - lensTop;
    const newTop = clickPosition > lensHeight - scrollerHeight ? lensHeight - scrollerHeight : clickPosition;

    if (newTop >= 0 && newTop <= (lensHeight - scrollerHeight)) {
      onLensUpdate(newTop / LENS_SCALE);
    }
  };

  const onLensMouseDown = () => {
    setIsDragging(true);
  };

  const onDocumentMouseUp = React.useCallback(() => {
    setIsDragging(false);
  }, []);

  const onDocumentMouseMove = React.useCallback((event) => {
    if (!isDragging) {
      return;
    }
    repositionScroller(event);
  }, [isDragging]);

  React.useEffect(() => {
    document.addEventListener('mouseup', onDocumentMouseUp);
    document.addEventListener('mousemove', onDocumentMouseMove);

    return () => {
      document.removeEventListener('mouseup', onDocumentMouseUp);
      document.removeEventListener('mousemove', onDocumentMouseMove);
    };
  }, [isDragging]);

  const lensScrollPosition = conversationScrollTop * LENS_SCALE;

  return (
    <div className="conversation-lens">
      <ul
        onClick={repositionScroller}
        ref={lensRef}
        className="conversation-lens__list"
        data-testid="conversation-lens"
      />
      <div
        ref={scrollerRef}
        className={classNames({
          'conversation-lens__scroller': true,
          'scrolling': isDragging
        })}
        onMouseDown={onLensMouseDown}
        style={{ height: `${scrollerHeight}px`, top: `${lensScrollPosition}px` }}
      />
    </div>
  );
};

export { ConversationLens };
