import AuthenticatedRoute from 'components/AuthenticatedRoute';
import NavBar from 'components/NavBar/NavBar';
import { get } from 'lodash';
import { inject, observer } from 'mobx-react';
import { parse } from 'query-string';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import {
  Header, Segment, Tab
} from 'semantic-ui-react';
import { UserStoreInterface } from 'stores/UserStore';
import AuditThematicAdmins from './AuditThematicAdmins';
import './manage-thematic-admins.scss';
import ManageGlobalThematicAdmins from './ManageGlobalThematicAdmins';
import ManageLocalThematicAdmins from './ManageLocalThematicAdmins';

export interface ManageThematicAdminsParams {
  orgId?: string;
}
interface ManageThematicAdminsProps extends RouteComponentProps<ManageThematicAdminsParams> {
  userStore?: UserStoreInterface;
}

interface ManageThematicAdminState {
  activeIndex?: number | string;
}

@inject('userStore')
@observer
export default class ManageThematicAdmins extends React.Component<ManageThematicAdminsProps, ManageThematicAdminState> {
  state = {
    activeIndex: 0
  };

  componentDidMount() {
    let search = parse(this.props.location.search);
    const view = search.view;
    if (view === 'global') {
      this.setState( {activeIndex: 1});
    } else if (view === 'audit') {
      this.setState( {activeIndex: 2});
    }
  }

  render(): JSX.Element | null {
    const { activeIndex } = this.state;
    const { orgId } = this.props.match.params;

    const isThematicAdmin = get(this.props, 'userStore.user.currentUserCan', []).includes('manage:internalResource');
    const panes = [
      { menuItem: 'Limited Admins',
        render: () => <Tab.Pane>
                        {orgId ? <ManageLocalThematicAdmins orgId={orgId}/> : (
                        <div>No organization selected</div>)}
                      </Tab.Pane> },
      { menuItem: 'Global Admins',
        render: () => <Tab.Pane>
                        {isThematicAdmin ? <ManageGlobalThematicAdmins/> : (
                        <div>You do not have permissions to see this</div>)}
                      </Tab.Pane> },
      { menuItem: 'Audit', render: () => <Tab.Pane>
                      {isThematicAdmin ? <AuditThematicAdmins/> : (
                      <div>You do not have permissions to see this</div>)}
                    </Tab.Pane> },
    ];

    return (
      <>
        <AuthenticatedRoute exact={true} path="/thematic-admin" component={NavBar} />
        <Segment className="fullscreen manage-users">
          <Header className="header-two-row">
            <div>
              Manage Thematic Administrators
            </div>
          </Header>

          <Tab
            panes={panes}
            activeIndex={activeIndex}
            onTabChange={(e, { activeIndex: newActiveIndex }) => this.setState({ activeIndex: newActiveIndex })}
          />
        </Segment>
      </>
    );
  }
}
