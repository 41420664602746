<template>
  <widget-wrapper
    :title="config.title"
    :loading="loading"
    :error="error"
    :warning="warning"
    :panel-order="panelOrder"
    :widget-order="widgetOrder"
  >
    <div
      slot="subtitle"
      class="widget-subtitle"
    >
      <div class="widget-subtitle-text">
        {{ scoreName }}
      </div>
    </div>
    <div
      v-if="showScore"
      class="widget-body"
    >
      <div class="scores">
        <div class="score overall">
          <h2>
            {{ scoreLabel }}
            <div
              v-if="scoreDifferenceLabel"
              :title="previousPeriod && `${scoreDifferenceLabel} compared to ${previousPeriod}`"
              class="old-score"
            >
              <component
                :is="scoreChangeIndicator"
                v-if="scoreChangeIndicator"
                class="arrowIndicator"
              />
              {{ scoreDifferenceLabel }}
            </div>
            <div class="response-count-subtext">
              {{ responseCount }} responses
            </div>
          </h2>
          <legend>
            {{ baselineName }}
          </legend>
        </div>
        <div
          v-if="showComparison"
          class="score comparison"
        >
          <h2
            v-if="comparisonScoreLabel"
            class="score-title"
          >
            {{ comparisonScoreLabel }}
            <div
              v-if="comparisonScoreDifferenceLabel"
              :title="previousPeriod && `${comparisonScoreDifferenceLabel} compared to ${previousPeriod}`"
              class="old-score"
            >
              <component
                :is="comparisonScoreChangeIndicator"
                v-if="comparisonScoreChangeIndicator"
                class="arrowIndicator"
              />
              {{ comparisonScoreDifferenceLabel }}
            </div>
            <div class="response-count-subtext">
              {{ comparisionResponseCount }} responses
            </div>
          </h2>
          <div
            v-else
            class="score-title small-text"
          >
            Not enough response to calculate
          </div>
          <legend>
            {{ comparisonName }}
          </legend>
        </div>
      </div>
    </div>
    <div
      v-else
      class="empty widget-body"
    >
      Not enough responses to calculate
    </div>
  </widget-wrapper>
</template>

<script>
import { get, isNaN, isNumber } from 'lodash';
import toFixed from 'vue/libs/to-fixed';

import WidgetWrapper from './WidgetWrapper';

function calculatePrecision(precision, difference) {
  const absDifference = Math.abs(difference);

  if (isNumber(precision)) {
    return precision;
  } else if (absDifference < 10) {
    return 1;
  } else {
    return 0;
  }
}

function differenceLabel(difference, precision) {
  if (isNaN(difference)) {
    return null;
  }
  const absDifference = Math.abs(difference);
  const label = toFixed(difference, precision);
  if (absDifference < 0.1) {
    return 'No change';
  } else if (difference > 0) {
    return `+${label}`;
  } else {
    return label;
  }
}

export default {
  name: 'SCORE_COMPARISON',
  components: {
    WidgetWrapper
  },
  props: {
    config: { default: undefined, type: Object },
    context: { default: undefined, type: Object },
    warning: { default: undefined, type: String },
    error: { default: undefined, type: String },
    data: { default: undefined, type: Object },
    limit: { default: 5, type: Number },
    loading: { default: false, type: Boolean },
    source: { default: undefined, type: String },
    panelOrder: { type: Number },
    widgetOrder: { type: Number }
  },
  computed: {
    arrowUpComparison() {
      return () => import('images/vue-icons/arrow-up-comparison.svg');
    },
    arrowUpOverall() {
      return () => import('images/vue-icons/arrow-up-overall.svg');
    },
    arrowDownOverall() {
      return () => import('images/vue-icons/arrow-down-overall.svg');
    },
    arrowDownComparison() {
      return () => import('images/vue-icons/arrow-down-comparison.svg');
    },
    baselineName() {
      return 'Overall';
    },
    comparisonName() {
      return get(this, 'context.compareFilterName');
    },
    comparisonPrecision() {
      const precision = get(this,'scoreConfig.options.precision', this.config.precision);
      return calculatePrecision(precision, this.comparisonScoreDifference);
    },
    currentComparisonScore() {
      return get(this, 'data.currentComparisonScore.score.score');
    },
    currentScore() {
      return get(this, 'data.currentScore.score.score');
    },
    responseCount() {
      return get(this, 'data.currentScore.count', 0);
    },
    comparisionResponseCount() {
      return get(this, 'data.currentComparisonScore.count', 0);
    },
    previousResponseCount() {
      return get(this, 'data.previousScore.count', 0);
    },
    previousComparisionResponseCount() {
      return get(this, 'data.previousComparisonScore.count', 0);
    },
    precision() {
      const precision = get(this,'scoreConfig.options.precision', this.config.precision);
      return calculatePrecision(precision, this.scoreDifference);
    },
    previousComparisonScore() {
      return get(this, 'data.previousComparisonScore.score.score');
    },
    previousScore() {
      return get(this, 'data.previousScore.score.score');
    },
    previousPeriod() {
      return get(this, 'data.previousPeriod');
    },
    showComparison() {
      return !!get(this, 'data.currentComparisonScore');
    },
    showScore() {
      return this.responseCount > 0;
    },
    scoreName() {
      return get(this.config, 'score.name', get(this, 'scoreConfig.name'));
    },
    scoreDifference() {
      const { currentScore, previousScore } = this;
      return this.calculateDifference(currentScore, previousScore);
    },
    comparisonScoreDifference() {
      const {
        currentComparisonScore: current,
        previousComparisonScore: previous
      } = this;
      return this.calculateDifference(current, previous);
    },
    comparisonScoreDifferenceLabel() {
      const { comparisonScoreDifference, comparisonPrecision, previousComparisionResponseCount } = this;
      if (previousComparisionResponseCount) {
        return differenceLabel(comparisonScoreDifference, comparisonPrecision);
      }
      return '';
    },
    comparisonScoreLabel() {
      return toFixed(this.currentComparisonScore, 1);
    },
    scoreDifferenceLabel() {
      const { scoreDifference, precision, previousResponseCount } = this;
      if (previousResponseCount) {
        return differenceLabel(scoreDifference, precision);
      }
      return '';
    },
    scoreConfig() {
      return get(this.data, 'scoreConfig', get(this.config, 'score'));
    },
    scoreLabel() {
      return toFixed(this.currentScore, 1);
    },
    comparisonScoreChangeIndicator() {
      return this.calculateArrow(
        this.previousComparisonScore,
        this.comparisonScoreDifference,
        false
      );
    },
    scoreChangeIndicator() {
      return this.calculateArrow(
        this.previousScore,
        this.scoreDifference,
        true
      );
    }
  },
  methods: {
    calculateArrow(previousScore, scoreDifference, overall) {
      if (previousScore) {
        const absDifference = Math.abs(scoreDifference);
        if (absDifference < 0.1) {
          return undefined;
        } else if (scoreDifference < 0) {
          return overall ? this.arrowDownOverall : this.arrowDownComparison;
        } else if (scoreDifference > 0) {
          return overall ? this.arrowUpOverall : this.arrowUpComparison;
        }
      }
      return undefined;
    },
    calculateDifference(score, previous) {
      if (isNumber(score) && isNumber(previous)) {
        return score - previous;
      } else {
        return Number.NaN;
      }
    }
  }
};
</script>


<style lang="scss" scoped>
@import '../../../styles/element-variables';

.old-score {
  align-items: center;
  display: inline-flex;
  font-size: 18px;
  font-weight: 400;
  line-height: 1;
  padding: 5px 0;
  .arrowIndicator {
    color: inherit;
    height: 14px;
    width: 14px;
    margin: 0 3px 0 0;
  }
}
.response-count-subtext {
  font-size: 12px;
  font-weight: 400;
  color: $--neutral-500;
  padding-bottom: 10px;

}
</style>
