import { get } from "lodash";
import { VolumeType } from "stores/ui/ExploreToolUIStore";

export default {
  data() {
    return {
      volumeBy: undefined
    };
  },
  computed: {
    datasetName() {
      return get(this, 'data.metadata.title');
    },
    widgetSubtitle() {
      let title;
      if (this.volumeBy === VolumeType.Positive) {
        title = 'Top positive themes';
      } else if (this.volumeBy === VolumeType.Negative) {
        this.volumeBy = VolumeType.Negative;
        title = 'Top negative themes';
      } else {
        title = 'Top themes';
      }

      if (this.datasetName) {
        title += ` in ${this.datasetName}`;
      }

      return title;
    }
  },
  mounted() {
    const volumeBy = get(this.config, 'volumeBy', undefined);
    if (volumeBy === 'countPosSentiment') {
      this.volumeBy = VolumeType.Positive;
      this.widgetSubtitle = 'Most common positive themes';
    } else if (volumeBy === 'countNegSentiment') {
      this.volumeBy = VolumeType.Negative;
      this.widgetSubtitle = 'Most common negative themes';
    } else {
      this.volumeBy = VolumeType.All;
    }
  }
};