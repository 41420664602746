<template>
  <div
    ref="containerEl"
    class="line-graph-container"
  >
    <svg ref="lineGraphEl" />
  </div>
</template>

<script>
import { watchAll } from 'vue/libs/vueExt';
import { max, min } from 'lodash';
import lineGraph from 'charts/Utils/lineGraph';

export default {
  name: 'LineGraph',
  props: {
    partialValues: { default: undefined, type: Array },
    values: { default: undefined, type: Array },
    labels: { default: undefined, type: Array }
  },
  data() {
    return {
      clientHeight: 0,
      interval: undefined,
      d3Tip: undefined
    };
  },
  computed: {
    high() {
      const high = max(this.values);
      if (high < 0) {
        return 0;
      } else {
        return high;
      }
    },
    low() {
      const low = min(this.values);
      if (low > 0) {
        return 0;
      } else {
        return low;
      }
    },
  },
  mounted() {
    watchAll(this, ['labels', 'values', 'partialValues'], this.updateChart);
    this.updateChart();
    window.addEventListener('resize', () => {
      setTimeout(this.updateChart, 10);
    });
    // clientHeight changes if the neighbouring widget loads after this one
    // But its not a reactive property, so we listen to it manually
    this.listenToHeightChanges();
  },
  beforeDestroy() {
    clearInterval(this.interval);
    this.d3Tip?.destroy();
  },
  methods: {
    updateChart() {
      const { lineGraphEl, containerEl } = this.$refs;
      const { labels, values } = this;

      if (!lineGraphEl || !containerEl) {
        return;
      }
      this.clientHeight = this.$refs.containerEl.clientHeight;

      const { width: containerWidth, height: containerHeight } = containerEl.parentNode.getBoundingClientRect();
      const chartOptions = {
        containerHeight,
        containerWidth,
        labels,
        baselineValues: values,
        low: this.low,
        high: this.high
      }

      this.d3Tip?.destroy();
      this.d3Tip = lineGraph.getLineGraphTip();

      lineGraph.renderLineGraph({
        el: lineGraphEl,
        chartOptions,
        tip: this.d3Tip
      });
    },
    listenToHeightChanges() {
      // Check every 200ms to see if the height of the container has grown in
      // size due to neighbouring widgets like ORIGINALSCORECOMPARISON
      this.interval = setInterval(() => {
        if (this.$refs && this.$refs.containerEl && this.$refs.containerEl.clientHeight !== this.clientHeight) {
          this.updateChart();
          clearInterval(this.interval);
        }
      }, 200);

      //  6s should be enough time for widgets to load with data from API
      setTimeout(() => {
        clearInterval(this.interval);
      }, 6000)
    },
  }
};
</script>
