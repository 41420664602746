import { User } from 'api/interfaces';
import { Auth0DecodedHash } from 'auth0-js';
import Auth0Lock from 'auth0-lock';
import { EventEmitter } from 'events';
import jwt_decode from 'jwt-decode';
import { onLogout, onProfileReady } from 'lib/analytics';
import authedFetch from 'lib/authed-fetch';
import { Fetchable } from 'lib/fetch';
import { compact, includes, isEmpty, isString, reduce, trim } from 'lodash';
import * as moment from 'moment';
import { parse, stringify } from 'query-string';
import fetchPageResources from 'routing/fetch-page-resources';
import config from 'runtime-config';
import { getOrganizationStore, getUserStore } from 'stores/RootStore';
import Cookies from 'universal-cookie';

export interface Authable {
  fetch<T>(path: string): Promise<Fetchable<T>>;
  isAuthenticated(): boolean;
  login(): void;
  logout(): void;
}

function getLocation(href: string) {
  const el = document.createElement('a');
  el.href = href;
  return el;
}

function toUserObject(profile: User) {
  const {
    email,
    emailHash,
    firstName,
    id,
    authId,
    lastName,
    preferences,
    firstAccess,
    currentUserCan,
    picture,
    subscriptions,
    seatType
  } = profile;
  let { organization } = profile;
  if (!organization) {
    if (includes(currentUserCan, 'manage:internalResource')) {
      organization = 'Thematic';
    } else {
      organization = 'Unknown';
    }
  }
  if (id && email && isString(firstName) && isString(lastName)) {
    return {
      email,
      emailHash,
      firstName,
      id,
      authId,
      lastName,
      organization,
      preferences,
      firstAccess,
      currentUserCan,
      picture,
      subscriptions,
      seatType
    };
  } else {
    return undefined;
  }
}

class Auth extends EventEmitter implements Authable {
  lock = new Auth0Lock(
    config.auth0ClientID,
    config.auth0Domain,
    config.auth0Config
  );
  constructor() {
    super();
    this.handleAuthentication();
    // binds functions to keep this context
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    if (typeof localStorage !== 'undefined') {
      this.checkTokenTimeout(true);
    }
  }

  constructLoginState() {
    localStorage.setItem('auth_redirect', window.location.hash);
  }
  destructLoginState() {
    const hash = localStorage.getItem('auth_redirect');
    if (hash) {
      window.location.hash = hash;
    }
  }

  login(): void {

    // Call the show method to display the widget.
    this.constructLoginState();
    this.lock.show();
    getUserStore().suggestLogout = false;
  }
  showReset(): void {
    this.lock.show({
      initialScreen: 'forgotPassword'
    });
  }

  getToken(): string {
    return localStorage.getItem('access_token') || '';
  }

  handleAuthentication(): void {
    // Add callback Lock's `authenticated` event
    this.lock.on('authenticated', this.setSession.bind(this));
    // Add callback for Lock's `authorization_error` event
    this.lock.on('authorization_error', () => {
      // console.log('Authorization Error', error)
    });
    this.lock.on('unrecoverable_error', error => {
      getUserStore().fatalLoginError = error.errorDescription || 'Unable to configure login';
    });
  }

  assumedUser(): string | null {
    return localStorage.getItem('assumed_user_id');
  }

  assumeUser = async (userId, token): Promise<void> => {
    const expiresAt = this.calculateExpires(token);
    const originalToken = localStorage.getItem('access_token') || '';
    localStorage.setItem('assumed_user_original_token', originalToken);
    localStorage.setItem('assumed_user_id', userId);
    localStorage.setItem('access_token', token);
    localStorage.setItem('expires_at', expiresAt);
    // refresh page to get new info
    window.location.href = '/#/';
    window.location.reload();

  }

  stopAssumingUser = async (): Promise<void> => {
    const originalUserToken = localStorage.getItem('assumed_user_original_token');
    if (originalUserToken) {
      const expiresAt = this.calculateExpires(originalUserToken);
      localStorage.setItem('access_token', originalUserToken);
      localStorage.setItem('expires_at', expiresAt);
    }
    localStorage.removeItem('assumed_user_id');
    localStorage.removeItem('assumed_user_original_token');
    window.location.href = '/#/';
    window.location.reload();
  }

  calculateExpires = (token: string, expiresIn?: number): string => {
    if (!expiresIn) {
      // try and parse from token
      const jwtParts = token.split('.');
      try {
        const payload = JSON.parse(atob(jwtParts[1]));
        const { exp } = payload;
        if (exp) {
          // exp is in seconds
          return String(exp * 1000);
        }
      } catch (e) {
        // do nothing, fall through
      }
    }
    // default expiresIn to 2 hours
    // expiresIn is in seconds
    expiresIn = expiresIn || 2 * 60 * 60;
    return String(expiresIn * 1000 + Date.now());
  };

  setSession = async (authResult: Auth0DecodedHash): Promise<void> => {
    const { accessToken, idToken, expiresIn } = authResult;
    if (accessToken && idToken) {
      const expiresAt = this.calculateExpires(accessToken, expiresIn);
      localStorage.setItem('access_token', accessToken);
      localStorage.setItem('id_token', idToken);
      localStorage.setItem('expires_at', expiresAt);
      localStorage.removeItem('assumed_user_original_token'); // on login stop assuming a user
      localStorage.removeItem('assumed_user_id'); // on login stop assuming a user

      const idTokenDecoded = jwt_decode(idToken) as object;
      if (idTokenDecoded['https://client.getthematic.com/client_url']) {
        this.checkClientUrl(idTokenDecoded['https://client.getthematic.com/client_url']);
      }

      this.destructLoginState();

      await fetchPageResources();
    }

    this.lock.hide();
  };

  checkTokenTimeout = (continueCheck?: boolean) => {
    const token = localStorage.getItem('access_token');
    const decoded: { authMethod?: string } | null = token ? jwt_decode(token) : null;

    const expiryTime = Number(localStorage.getItem('expires_at'));
    const isAboutToExpire: boolean = moment().isSameOrAfter(moment(expiryTime).subtract(10, 'minutes'));

    let tokenOk = true;

    if (decoded && isAboutToExpire) {
      tokenOk = false;

      const authMethod: string = decoded?.authMethod ?? 'sso';

      if (authMethod === 'sso') {
        analytics.track('logout-suggested', {
          action: 'Logout suggested due to expiry',
          authType: 'sso',
          visibility: document.visibilityState
        });
        this.clearTokens();
        getUserStore().suggestLogout = true;
      }

      if (authMethod === 'password') {

        this.lock.checkSession({}, (err, authResult) => {
          if (err || !authResult) {
            analytics.track('logout-suggested', {
              action: 'Logout suggested due to expiry',
              authType: 'password',
              errorCode: err?.code,
              visibility: document.visibilityState
            });
            this.clearTokens();
            getUserStore().suggestLogout = true;
          } else {
            const { accessToken, idToken, expiresIn } = authResult;
            const expiresAt = this.calculateExpires(accessToken, expiresIn);
            localStorage.setItem('access_token', accessToken);
            localStorage.setItem('id_token', idToken);
            localStorage.setItem('expires_at', expiresAt);
            getUserStore().suggestLogout = false;
          }
        });

      }

    }

    if (continueCheck) {
      setTimeout(() => {
        this.checkTokenTimeout(true);
      }, 60000);
    }
    return tokenOk;
  };

  fetchProfile = async (): Promise<User | undefined> => {
    getUserStore().unsetUser();

    try {
      const { ok, status, data, errorData } = await getUserStore().fetchCurrentUser();

      if (ok && data) {
        this.identify(data);
      } else {
        if (Math.floor(status / 100) === 5) {
          getUserStore().fatalLoginError = 'Unable to fetch user details.';
        }

        // we might be able to redirect to the right domain! Check in the errorData
        if (errorData && errorData.redirect) {
          const { redirect } = errorData;
          this.checkClientUrl(redirect);
        }

        return data;
      }
    } catch (e) {
      getUserStore().fatalLoginError = e.message;
      throw new Error('Could not retrieve current user');
    }
    return;

  };

  refreshProfile = async (userData?: User): Promise<void> => {
    if (!userData) {
      const { ok, data } = await getUserStore().fetchCurrentUser();
      if (!ok || !data) {
        return;
      }
      userData = data;
    }
    // @FIXME this should receive a profile directly here
    const user = toUserObject(userData);
    if (user) {
      getUserStore().setUser(user);
    }
  };

  checkClientUrl(clientUrls: string | string[]): void {
    // Always make it an array
    if (!(clientUrls instanceof Array)) {
      clientUrls = [clientUrls];
    }

    // check all possible prefixes
    const prefixMismatch = reduce(clientUrls, (result, clientUrl) => {
      return result && config.auth0Redirect.indexOf(clientUrl) !== 0;
    }, true);

    if (clientUrls && prefixMismatch && !config.appUrlBase.startsWith("http://local")) {
      const destination = getLocation(window.location.href);
      let { hash, pathname, search } = destination;

      const params = parse(search);

      const token = this.getToken();
      if (token) {
        params.sso_token = token;
      }

      search = stringify(params);
      const clientUrl = clientUrls[0]; // redirect to the first valid url
      const href = `${ clientUrl }${ pathname }${ hash || '#/' }?${ search }`;
      this.clearTokens();
      window.location.href = href;
    }
  }
  clearTokens() {
    // Clear access token and ID token from local storage
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('profile');
    localStorage.removeItem('auth_redirect');
    onLogout();
  }

  deleteAllCookies() {
    const cookies = new Cookies();

    let cookieList = document.cookie.split(';');

    if (isEmpty(compact(cookieList))) {
      return;
    }

    for (let i = 0; i < cookieList.length; i++) {
      const cookie = cookieList[i];
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      // Clear cookies with client.getthematic.com domain
      cookies.remove(name, { path: '/' });
      // Clear cookies with .getthematic.com & .client.getthematic.com domain
      cookies.remove(name, { path: '/', domain: '.getthematic.com' });
      cookies.remove(name, { path: '/', domain: '.client.getthematic.com' });
    }
  }

  logout(): void {
    this.clearTokens();
    this.deleteAllCookies();

    getUserStore().unsetUser();
    getUserStore().suggestLogout = false;
    getOrganizationStore().setOrg('');

    this.lock.logout({
      returnTo: `${ window.location.origin }/#/`
    });
  }
  identify = async (profile: User) => {
    const {
      email,
      emailHash,
      firstName,
      id,
      lastName,
      preferredName,
      cohort,
      orgType,
      activatedAt,
      firstAccess,
      currentUserCan
    } = profile;
    let { organization } = profile;
    if (!organization) {
      if (includes(currentUserCan, 'manage:internalResource')) {
        organization = 'Thematic';
      } else {
        organization = 'Unknown';
      }
    }
    if (id && email && emailHash && isString(firstName) && isString(lastName)) {
      onProfileReady({
        userID: id,
        email,
        emailHash,
        firstName,
        lastName,
        preferredName,
        organization,
        cohort,
        activatedAt,
        orgType,
        firstAccess,
        currentUserCan
      });
      const user = toUserObject(profile);
      if (user) {
        getUserStore().setUser(user);
      }
    }
  };
  isAuthenticated(): boolean {
    // Check whether the current time is past the
    // access token's expiry time
    const expiresAt = JSON.parse(localStorage.getItem('expires_at') || '{}');
    return new Date().getTime() < expiresAt;
  }
  fetch = async <T>(
    path: string,
    opts: RequestInit & { isRaw?: boolean, isText?: boolean } = {}
  ): Promise<Fetchable<T>> => authedFetch<T>(path, opts);
  hasToken = () => {
    const token = localStorage.getItem('access_token') || '';
    return !!trim(token);
  };
}

export default new Auth();
