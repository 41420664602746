import * as React from 'react';
import { RemoveThemeParams, ThemeCodes } from 'stores/ui/AnalysisToolsUIStore';
import { PhraseSegment, PhraseSegmentTheme, PlainComment, Summary } from 'types/custom';
import classNames from 'classnames';
import toSentimentClassname from 'vue/libs/to-sentiment-classname';
import { Button } from 'components/Shared/Button';
import { ThemeList } from 'components/ThemeList/ThemeList';
import './vertical-comment.scss';
import segmentsToOrderedThemes from 'lib/segments-to-ordered-themes';
import { createThemeTitle, doesThemeAlreadyExist, isNewlyAddedTheme, isThemeRemoved, themesAdded } from 'vue/libs/edited-comment-helpers';
import toCommentThemeItem from 'vue/libs/to-comment-theme-item';
import getCommentHighlightLocations from 'lib/get-comment-highlight-locations';
import segmentsToBlocks, { Block, ContentBlock } from 'lib/segments-to-blocks';
import { useSimilarSentencesContext } from './similar-sentences.context';
import { QuotesList } from 'components/Shared/QuotesList';
import { VueInReact } from 'vuera';
import CommentThemePopup from 'vue/components/Comments/CommentThemePopup.vue';
import { useHoveredLocation } from './useHoveredLocation';
import { findPopupThemeItems } from './findPopUpThemeItems';
import { ValidSimpleSentiments } from 'lib/calculate-sentiment';
import { NextSegments, selectSentiment } from './selectSentiment';
import { getRemovePlainCommentParams } from './getRemoveThemePlainCommentParams';
import { useAddThemeToSelection } from './useAddThemeToSelection';
import AddThemeToComment from 'vue/components/Themes/AddThemeToComment.vue';
import RemoveTheme from 'vue/components/Themes/RemoveTheme.vue';
import { MAX_SELECTED_WORDS, SELECTION_STATUS } from './types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AddTheme from 'vue/components/Themes/AddTheme.vue';
import segmentsToCategories from 'lib/segments-to-categories';
import CommentCategories from 'vue/components/Categories/CommentCategories.vue';
import CommentTags from 'vue/components/Tags/CommentTags.vue';
import { Tooltip } from 'components/Shared/Tooltip';
import FlagKeys from 'Auth/flag-keys';
import { copyText } from 'lib/clipboard';
import { FeatureFlagManager } from 'lib/feature-flag';
import ExportableComment from 'vue/components/Comments/ExportableComment.vue';

const AddThemeVue = VueInReact(AddTheme);
const AddThemeToCommentVue = VueInReact(AddThemeToComment);
const CommentCategoriesVue = VueInReact(CommentCategories);
const CommentTagsVue = VueInReact(CommentTags);
const CommentThemePopUpVue = VueInReact(CommentThemePopup);
const ExportableCommentVue = VueInReact(ExportableComment);
const RemoveThemeVue = VueInReact(RemoveTheme);

const commentIsThreadSummary = (comment: PlainComment | Summary): comment is Summary => {
  return comment.column === 'threadSummary';
}

const getSentimentClassName = (block: Block | ContentBlock, hasSentiment: boolean) => {
  if (!('sentiment' in block)) {
    return '';
  }

  return toSentimentClassname(block.sentiment, hasSentiment);
};

interface Props {
  alwaysExpanded: boolean;
  comment: PlainComment | Summary;
  highlightType: string;
  hoverOnThemes: boolean;
  isCommentEdited?: boolean;
  isDisabled: boolean;
  isNarrow: boolean;
  onSegmentSelect?: (nextSegments: NextSegments) => void;
  selectedThemeCodes: ThemeCodes | undefined;
  sentenceSnippet: string;
  showTagComment: boolean;
}

const VerticalComment = ({
  alwaysExpanded,
  comment,
  highlightType,
  hoverOnThemes,
  isCommentEdited,
  isDisabled,
  isNarrow,
  onSegmentSelect,
  selectedThemeCodes,
  sentenceSnippet,
  showTagComment,
}: Props) => {
  const commentRef = React.useRef<HTMLDivElement>(null);

  const {
    hoveredLocation,
    hoverBlock,
    leaveBlock,
    persistHoveredBlock
  } = useHoveredLocation();

  const {
    canManageThemes,
    isApplyingThemes,
    hasSentiment,
    orgId,
    shouldShowFullMessages,
    surveyId
  } = useSimilarSentencesContext();

  const {
    onClickOutside,
    onEnterBlock,
    onSelectionChange,
    onTextSelectionEnd,
    selectedPhrase,
    selectedPhrasePosition,
    selectedStatus
  } = useAddThemeToSelection();

  React.useEffect(() => {
    if (selectedStatus !== SELECTION_STATUS.None) {
      const isThreadSummary = commentIsThreadSummary(comment);
      const commentType = isThreadSummary ? 'Summary' : 'Comment';
      analytics.track('Analysis: Select Comment', { 'Type': commentType });
    }
  }, [comment, selectedStatus]);

  const [copySuccess, setCopySuccess] = React.useState(false);
  const [exportingComment, setExportingComment] = React.useState<boolean>(false);
  const [showMetadata, setShowMetadata] = React.useState<boolean>(false);
  const [hoveredThemeCodes] = React.useState<ThemeCodes | undefined>(undefined);
  const [removeThemeParams, setRemoveThemeParams] = React.useState<RemoveThemeParams | undefined>(undefined);
  const [tags, setTags] = React.useState<string[]>(comment.tags || []);
  const [visibleBlocks, setVisibleBlocks] = React.useState<string[]>([]);

  const getThemeLocations = (theme: PhraseSegmentTheme, segments: PhraseSegment[]) => {
    return segments.reduce((result, segment) => {
      const index = segment.themes.findIndex((t) => {
        return t.base === theme.base && t.sub === theme.sub;
      });

      const segmentHasTheme = index > -1;

      return segmentHasTheme ? [...result, segment.location] : result;
    }, []);
  };

  const getCommentThemeItems = () => {
    if (!selectedThemeCodes) {
      return [];
    }

    const orderedThemes = segmentsToOrderedThemes(comment.segments, selectedThemeCodes);

    return orderedThemes.map((theme) =>
      toCommentThemeItem({
        theme,
        segments: comment.segments,
        isNew: isNewlyAddedTheme(comment.id, theme),
        isRemoved: isThemeRemoved(comment.id, theme, getThemeLocations(theme, comment.segments)),
        hoveredLocation,
        hoveredThemeCodes,
        selectedThemeCodes: selectedThemeCodes,
      }),
    );
  };

  const highlightedLocations = getCommentHighlightLocations(
    comment.segments,
    selectedThemeCodes,
    hoveredLocation,
    hoveredThemeCodes,
  );

  const mapConversationToExcerpt = (summary: Summary) => {
    const snippetIndex = summary.comment.indexOf(sentenceSnippet);
    const matchingSegment = summary.segments.find(({ location }) => location[0] === snippetIndex);
    const matchingIndexes = matchingSegment?.conversationReferences.map(({ index }) => index) || [];

    return summary.conversation.parts
      .filter((_, index) => matchingIndexes.includes(index))
      .map(({ author, text }) => ({ author, excerpts: [text] }));
  };

  const shouldShowBlock = (block: Block) => {
    if (shouldShowFullMessages) {
      return true;
    }

    if (block.content.trim().length === 0) {
      return true;
    }

    if (visibleBlocks.includes(block.key)) {
      return true;
    }

    return ('hasThemes' in block) && block.hasThemes;
  }

  const handleBlockClick = (block: Block) => {
    if (shouldShowBlock(block)) {
      return;
    }

    setVisibleBlocks([...visibleBlocks, block.key]);
  }

  const blocks = segmentsToBlocks(comment.comment, comment.segments, -1, highlightedLocations || []);
  const themeItems = getCommentThemeItems();

  const handleSentimentSelection = (sentiment: ValidSimpleSentiments, block: Block) => {
    const nextSegments = selectSentiment({
      block,
      comment,
      orgId,
      segments: comment.segments,
      sentiment,
      surveyId,
    });

    if (onSegmentSelect && nextSegments) {
      onSegmentSelect(nextSegments);
    }
  }

  const handleRemoveTheme = (theme: PhraseSegmentTheme, block: Block) => {
    if (isApplyingThemes) {
      return;
    }

    const removeThemeParams = getRemovePlainCommentParams({ block, comment, theme });

    setRemoveThemeParams(removeThemeParams);

    analytics.track('Analysis: Remove Theme Started', { Location: 'Metadata' });
  };

  const handleRemoveThemeClose = () => {
    setRemoveThemeParams(undefined);
  };

  const getThemesAdded = () => {
    return themesAdded(comment.id);
  };


  const orderedThemes = React.useMemo(() => {
    if (!selectedThemeCodes) {
      return [];
    }

    return segmentsToOrderedThemes(comment.segments, selectedThemeCodes);
  }, [comment.segments, selectedThemeCodes]);

  const isThreadSummary = commentIsThreadSummary(comment);

  React.useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (commentRef.current && !commentRef.current.contains(e.target as Node)) {
        onClickOutside();
      }
    }

    document.addEventListener('click', handleClickOutside);
    commentRef.current?.addEventListener('selectionchange', onSelectionChange);

    return () => {
      document.removeEventListener('click', handleClickOutside);
      commentRef.current?.removeEventListener('selectionchange', onSelectionChange);
    };
  }, [onClickOutside, onSelectionChange]);

  const handleTextSelectionEnd = () => {
    onTextSelectionEnd();
  };

  const commentCategories = segmentsToCategories(comment.segments);

  const updateTags = (newTags: string[]) => {
    setTags(tags.splice(0, tags.length, ...newTags));
  };

  const copyComment = () => {
    const commentType = isThreadSummary ? 'Summary' : 'Comment';
    analytics.track('Analysis: Copy Comment', { 'Type': commentType });

    if (FeatureFlagManager.checkFlag(FlagKeys.CAN_USE_PROTOTYPE_EXPORT) ) {
      setExportingComment(true);
    } else {
      copyText(comment.comment);

      setCopySuccess(true);
      setTimeout(() => {
        setCopySuccess(false);
      }, 2000);
    }
  };

  const isBlockContentSnippet = (content: string) => {
    return content.replace(/\W/g, '') === sentenceSnippet.replace(/\W/g, '');
  };

  return (
    <div>
      <div
        className={classNames('react-vertical-comment', {
          'is-edited': isCommentEdited,
          'is-expanded': alwaysExpanded,
          'is-narrow': isNarrow,
        })}
      >
        <div className="react-vertical-comment__body">
          <div className="react-vertical-comment__content">
            <h3 className="react-vertical-comment__heading">Summary</h3>
            {isThreadSummary ? (
              <div className="react-vertical-comment__text react-vertical-comment__section">
                {blocks.map((block) => {
                  const sentimentClass = getSentimentClassName(block, true);

                  return (
                    (
                      <React.Fragment key={block.key}>
                        <span
                          key={block.key}
                          className={classNames(`react-vertical-comment__block ${sentimentClass}`, {
                            'is-highlighted': isBlockContentSnippet(block.content),
                          })}
                        >
                          {block.content}
                        </span>
                      </React.Fragment>
                    )
                  );
                })}
              </div>
            ) : (
              <div className="react-vertical-comment__text react-vertical-comment__section" ref={commentRef}>
                {blocks.map((block, index) => (
                  <React.Fragment key={block.key}>
                    <span
                      key={block.key}
                      className={classNames(`react-vertical-comment__block ${getSentimentClassName(block, true)}`, {
                        'is-highlighted': block.isHighlighted,
                        'is-empty-block': block.content.trim().length === 0,
                        'is-grey': 'hasThemes' in block && block.hasThemes && hoverOnThemes && !block.isHighlighted,
                      })}
                      onClick={() => handleBlockClick(block)}
                      onMouseEnter={() => onEnterBlock(block)}
                      onMouseLeave={leaveBlock}
                      onMouseOver={() => hoverBlock(block)}
                      onMouseUp={handleTextSelectionEnd}
                    >
                      <CommentThemePopUpVue
                        areThemesInApplyingState={isApplyingThemes}
                        blockIndex={index}
                        block={block}
                        canManageThemes={canManageThemes}
                        hoverOnThemes={hoverOnThemes}
                        isHiddenBlock={!shouldShowBlock(block)}
                        isPopUpDisabled={isDisabled}
                        isSentimentEnabled={hasSentiment}
                        popupThemes={findPopupThemeItems(comment, block)}
                        showTruncation={true}
                        on={{
                          'handleSentimentSelection': (sentiment: ValidSimpleSentiments) => handleSentimentSelection(sentiment, block),
                          'persistHoveredBlock': (block: Block) => persistHoveredBlock(block),
                          'removeTheme': (theme: PhraseSegmentTheme) => handleRemoveTheme(theme, block),
                        }}
                      />
                    </span>
                  </React.Fragment>
                ))}
              </div>
            )}
            {showMetadata && (
              <div>
                <div className="react-vertical-comment__themes react-vertical-comment__section">
                  {themeItems.length > 0 && (
                    <div className="react-vertical-comment__theme-list">
                      <h4 className="react-vertical-comment__theme-title">Themes</h4>
                      <ThemeList items={themeItems} showSentiment={hasSentiment} />
                      {getThemesAdded()?.map((theme, index) => (
                        <React.Fragment key={`${index}-${JSON.stringify(theme)}`}>
                          {!doesThemeAlreadyExist(comment.id, theme, orderedThemes) && (
                            <div className="react-themes-added">
                              <FontAwesomeIcon icon="sparkles" />
                              <span className="react-added-theme-title">{createThemeTitle(theme)}</span>
                            </div>
                          )}
                        </React.Fragment>
                      ))}
                    </div>
                  )}
                  {canManageThemes && !isDisabled && (
                    <AddThemeVue
                      areThemesInApplyingState={isApplyingThemes}
                      commentId={comment.id}
                      plainBlocks={blocks}
                    />
                  )}
                </div>
                <div className="react-vertical-comment__categories react-vertical-comment__section">
                  <h4 className="react-vertical-comment__theme-title">Categories</h4>
                  <CommentCategoriesVue
                    categories={commentCategories}
                    commentColumnId={comment.column}
                    commentId={comment.id}
                    location="explore"
                  />
                </div>
                {showTagComment && !isDisabled && (
                  <div className={classNames('react-vertical-comment__categories react-vertical-comment__section', {
                    'no-export-output': tags.length === 0,
                  })}>
                    <h4 className="react-vertical-comment__theme-title">Labels</h4>
                    <CommentTagsVue
                      commentColumnId={comment.column}
                      commentId={comment.id}
                      commentTags={comment.tags}
                      location="explore"
                      on={{
                        'updateTags': (newTags) => updateTags(newTags),
                      }}
                    />
                  </div>
                )}
              </div>
            )}
            <div className="react-vertical-comment__footer">
              {isNarrow && !alwaysExpanded && (
                <div className="react-vertical-comment__meta-data-button">
                  <Button onClick={() => setShowMetadata(!showMetadata)} inline={true} size="small" variant="tertiary">
                    {showMetadata ? 'Hide details' : 'Show details'}
                  </Button>
                </div>
              )}
              <div className="react-vertical-comment__actions">
                <Tooltip
                  position="bottom center"
                  content={isThreadSummary ? 'Copy summary' : 'Copy comment'}
                  on={['hover', 'focus']}
                  inverted={true}
                  trigger={
                    <span>
                      <Button
                        role="button"
                        variant="tertiary"
                        size="small"
                        onClick={() => copyComment()}
                        icon={copySuccess ? (
                          <FontAwesomeIcon icon="check-circle" className="success clipboard-buttons" />
                        ) : (
                          <FontAwesomeIcon icon="copy" fixedWidth={true} />
                        )}
                      />
                    </span>
                  }
                />
              </div>
            </div>
          </div>
          {isThreadSummary && (
            <div className="react-vertical-comment__references">
              <h3 className="react-vertical-comment__heading">Conversation</h3>
              <ul className="react-vertical-comment__conversation">
                <QuotesList
                  references={mapConversationToExcerpt(comment)}
                />
              </ul>
            </div>
          )}
        </div>
        {canManageThemes && (
          <AddThemeToCommentVue
            areThemesInApplyingState={isApplyingThemes}
            commentId={comment.id}
            horizontalPosition={selectedPhrasePosition.x}
            plainBlocks={blocks}
            preSelectedPhrase={selectedPhrase}
            maxWords={MAX_SELECTED_WORDS}
            selectionStatus={selectedStatus}
            verticalPosition={selectedPhrasePosition.y}
          />
        )}
      </div>
      {exportingComment && (
        <div>
          <ExportableCommentVue
            comment={comment.comment}
            commentTags={tags}
            highlightType={highlightType}
            info={comment.data}
            segments={comment.segments}
            selectedThemeCodes={selectedThemeCodes}
            on={{
              'onExported': () => setExportingComment(false)
            }}
          />
        </div>
      )}
      {removeThemeParams && (
        <RemoveThemeVue
          removeThemeParams={removeThemeParams}
          on={{
            'onClose': () => handleRemoveThemeClose(),
          }}
        />
      )}
    </div>
  );
};

export { VerticalComment };
