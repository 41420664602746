<template>
  <div
    v-if="config"
    class="selection-widget"
  >
    <filter-dropdown-hierarchical
      v-if="hasHierarchical"
      :filter-data="widget"
      filter-icon="exchange-alt"
      query-param="compare"
      class="filter-item"
      popover-cls="selection-widget-popover"
      @selectionChanged="selectionChanged"
    />
    <filter-dropdown-list
      v-else-if="hasDropdown"
      :filter-data="widget"
      filter-icon="exchange-alt"
      query-param="compare"
      class="filter-item"
      popover-cls="selection-widget-popover"
      @selectionChanged="selectionChanged"
    />
    <div
      v-else
      v-loading="true"
      class="loader"
    />
    <div class="settings">
      <settings-menu
        v-if="activeDashboardUIStore.isEditing"
        :show-delete="true"
        @onDelete="deleteSelectionWidget()"
      />
    </div>
  </div>
</template>

<script>
import { get, some } from 'lodash';
import { getActiveDashboardUIStore, getReportUIStore } from 'stores/RootStore';

import FilterDropdownList from 'vue/components/Filters/FilterDropdownList.vue';
import FilterDropdownHierarchical from 'vue/components/Filters/FilterDropdownHierarchical.vue';

import queryBuilder from 'vue/libs/queryBuilder';
import analytics from 'lib/analytics';
import SettingsMenu from 'vue/dashboards/DashboardEditable/SettingsMenu.vue';

export default {
  name: 'SELECTION_WIDGET',
  components: {
    FilterDropdownList,
    FilterDropdownHierarchical,
    SettingsMenu,
  },
  props: {
    context: { default: undefined, type: Object },
    source: { default: undefined, type: String },
    dashboardId: { type: String },
  },
  data() {
    return {
      loading: true,
      widget: undefined,
      widgetError: undefined,
      activeDashboardUIStore: getActiveDashboardUIStore(),
      reportUIStore: getReportUIStore(),
    };
  },
  computed: {
    hasHierarchical() {
      const cuts = get(this, 'widget.cuts');
      return some(cuts, (c) => !!c.children);
    },
    hasDropdown() {
      return this.widget;
    },
    config() {
      return this.activeDashboardUIStore.selectionWidget;
    },
  },
  mounted() {
    const unwatchConfig = this.$watch(
      'config',
      () => {
        const { context, config } = this;
        if (config && context) {
          this.fetchData();
          setTimeout(unwatchConfig); // immediate is synchronous, so this isn't assigned yet
        }
      },
      { immediate: true },
    );
    const unwatchContext = this.$watch('context', () => {
      const { context, config } = this;
      if (config && context) {
        this.fetchData();
        unwatchContext();
      }
    });
  },
  methods: {
    fetchData: async function () {
      const { context, config, source, dashboardId } = this;
      this.loading = true;
      try {
        this.widget = await this.reportUIStore.getWidget({ context, config, source, dashboardId });
        this.$nextTick(() => this.$emit('onReady'));
      } catch (e) {
        this.widgetError = get(e, 'response.data.message', e.message) || 'Could not fetch data.';
      } finally {
        this.loading = false;
      }
    },
    selectionChanged(queryFilter, title) {
      const filter = { compareTo: queryFilter };
      const compareRql = queryBuilder.build(filter);
      const compareParam = queryBuilder.getSelectedIds(filter);
      this.$emit('updateCompareTo', compareRql, title, compareParam);

      analytics.track('Dashboard: Update CompareTo', {
        category: 'Dashboard',
        compareRql,
      });
    },
    deleteSelectionWidget() {
      this.activeDashboardUIStore.deleteSelectionWidget();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../styles/element-variables.scss';
@import '../../../styles/filter-set-styles.scss';
.selection-widget {
  display: flex;
  margin-right: 10px;
  position: relative;
  ::v-deep .filter-item {
    display: inline-block;
    margin: 0;
    .header {
      font-size: 14px;
      line-height: 28px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 200px;
    }
  }
  .filter {
    min-width: 200px;
    max-width: 200px;
  }
  .loader {
    align-items: center;
    display: flex;
    width: 30px;
    ::v-deep .el-loading-mask {
      background: none;
      display: flex;
      align-items: center;
      justify-content: center;
      .el-loading-spinner {
        line-height: 1;
        margin-top: 0;
        position: relative;
        top: inherit;
      }
      .circular {
        height: 21px;
        width: 21px;
      }
    }
  }
  @media print {
    display: inline-block;
  }

  .settings {
    position: absolute;
    top: 5px;
    right: 10px;
    padding: 0;

    .settings-dropdown.el-dropdown {
      padding: 0;
    }
  }
}
</style>

