import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { compact, isEmpty, map } from 'lodash';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Accordion, Button, Header, Loader, Message, Segment } from 'semantic-ui-react';
import { ManageThematicAdminsStoreInterface } from 'stores/ManageThematicAdminsStore';
import './manage-thematic-admins.scss';

export interface AuditThematicAdminsProps {
  manageThematicAdminsStore?: ManageThematicAdminsStoreInterface;
}

@inject('manageThematicAdminsStore')
@observer
export default class AuditThematicAdmins extends React.Component<AuditThematicAdminsProps> {

  componentDidMount() {
    this.initialize();
  }
  initialize = async () => {
    const { manageThematicAdminsStore } = this.props;

    manageThematicAdminsStore!.auditAdmins();
  }

  downloadAudit = async () => {
    const { manageThematicAdminsStore } = this.props;
    await manageThematicAdminsStore!.downloadAudit();
  }

  revokeThematicAdmin = async (orgId, email) => {
    const { manageThematicAdminsStore } = this.props;
    await manageThematicAdminsStore!.revokeAdmin(orgId, email);
  }

  render(): JSX.Element | null {

    const {
      audit,
      loadingAudit,
      loadingAuditError,
      revokingAdmin,
      revokingAdminError,
      downloadingAudit
    } = this.props.manageThematicAdminsStore!;

    const errors = compact([
      loadingAuditError,
      revokingAdminError
    ]);

    let body;

    if (loadingAudit) {
      body = (
        <Loader inline={true} size="small" active={loadingAudit}>
          Fetching audit... This takes a long time&hellip;
        </Loader>
      );
    } else if (!audit || isEmpty(audit.limited)) {
      body = (
        <div>
          No audit found
        </div>
      );
    } else {
      const panels = map(audit.limited, (value, user) => {
        return {
          key: user,
          title: user,
          content: map(value, org => {
            return (
              <div className="organization-row" key={org.id}>
                {org.name}
                <span className="revoke-button">
                  <Button
                    type="button"
                    color="blue"
                    size="small"
                    loading={revokingAdmin}
                    onClick={() => { this.revokeThematicAdmin(org.id, user); }}
                  >
                    Revoke
                  </Button>
                </span>
              </div>
            );
          })
        };
      });
      body = (<div>
        <Accordion
          panels={panels}
          styled={true}
          fluid={true}
          exclusive={false}
        />
        <Button
          className="refresh-button"
          type="button"
          icon={true}
          onClick={() => { this.initialize(); }}>
          <FontAwesomeIcon
            icon="redo"
            className="icon"
            fixedWidth={true} />
        </Button>
      </div>);
    }

    return (
      <Segment className="fullscreen audit-thematic-admins">
        <Header className="header-two-row">
          <div>
            Audit Thematic Administrators
          </div>
        </Header>
        <Segment className="white">

          {errors.map(error => {
            return (
              <Message
                key={error}
                className="error"
                negative={true}
                header={error}
              />
            );
          })}

          {body}

        </Segment>
        <Segment className="white">
          <Button
            type="button"
            color="blue"
            loading={downloadingAudit}
            onClick={() => { this.downloadAudit(); }}
          >
            Download Regional Audit
          </Button>
        </Segment>
      </Segment>
    );
  }
}
