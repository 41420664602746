import * as React from 'react';
import { inject, observer } from 'mobx-react';

import { Button, Header, Modal, Select, FormInputProps, Message } from 'semantic-ui-react';

import { ThemesStoreInterface } from 'stores/ThemesStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { when } from 'mobx';

export interface ApplyTaxonomyProps {
  cancel: () => void;
  open: boolean;
  surveyId: string;
}
export interface ApplyTaxonomyState {
    applying: boolean;
    taxonomy: string;
    taxonomies: {
      key: number;
      value: string;
      text: string;
    }[];
}

export interface InjectedProps {
  themesStore: ThemesStoreInterface;
}

@inject('themesStore')
@observer
export default class ApplyTaxonomy extends React.Component<
  ApplyTaxonomyProps,
  ApplyTaxonomyState
> {
  constructor(props: ApplyTaxonomyProps) {
    super(props);

    this.state = {
        applying: false,
        taxonomy: '',
        taxonomies: []
    };
  }

  get injected(): InjectedProps {
    return this.props as ApplyTaxonomyProps & InjectedProps;
  }

  get themesStore(): ThemesStoreInterface {
    return this.injected.themesStore;
  }

  componentDidMount() {
    this.getAvailableTaxonomies();
  }

  getAvailableTaxonomies = async () => {
    await this.themesStore.getAvailableTaxonomies(this.props.surveyId);
    const taxonomies = this.themesStore.taxonomies.map((taxonomy, index) => {
      return {
        key: index,
        value: taxonomy,
        text: taxonomy
      };
    });
    this.setState({ taxonomies });
  }

  close = () => {
    this.props.cancel();
  };

  apply = () => {
    this.setState({ applying: true });
    // Wait until any draft themes to be saved
    when(
      () => !this.themesStore.working,
      this.applyTaxonomy
    );
  };

  applyTaxonomy = async () => {
    const  { surveyId } = this.props;
    const  { taxonomy } = this.state;
    const  { applyingTaxonomy, taxonomyApplyError } = this.themesStore;

    await this.themesStore.applyTaxonomy(surveyId, taxonomy);
    if (!applyingTaxonomy && !taxonomyApplyError) {
      this.setState({ taxonomy: '', applying: false });
      this.close();
    }
  };

  updateTaxonomy = ({ value: taxonomy }: FormInputProps) => {
    this.setState({ taxonomy });
  };

  render() {
    const { open } = this.props;
    const { taxonomy, taxonomies, applying } = this.state;
    const {
      taxonomyLoading,
      taxonomyLoadError,
      taxonomyApplyError
    } = this.themesStore;
    const applyDisabled = taxonomyLoadError ||
          taxonomyLoading ||
          applying ||
          !taxonomy;

    return (
      <div>
        <Modal
          open={open}
          size="small"
          dimmer="blurring"
          className="theme-parameter-modal"
        >
          <Modal.Header as={Header}>Apply Taxonomy</Modal.Header>
          <Modal.Content style={{ minHeight: '150px' }}>
            {taxonomyLoadError &&
              <Message negative={true}>
                <Message.Content>
                  <FontAwesomeIcon className="icon" icon="exclamation-triangle" />
                    Error fetching available taxonomies, please try again.
                  </Message.Content>
              </Message>
            }
            {taxonomyApplyError &&
              <Message negative={true}>
                <Message.Content>
                  <FontAwesomeIcon className="icon" icon="exclamation-triangle" />
                    Error applying taxonomies, please try again.
                  </Message.Content>
              </Message>
            }
            {!taxonomyLoadError && !taxonomyLoading &&
              <>
                <span>
                  Taxonomy to apply
                </span>
                <Select
                  style={{ marginLeft: '15px', height: '35px' }}
                  placeholder="Select"
                  onChange={(_e, data) => this.updateTaxonomy(data)}
                  options={taxonomies}
                  value={taxonomy} />
              </>
            }
            {taxonomyLoading &&
              <FontAwesomeIcon
                className="icon"
                icon="spinner"
                spin={true}
                fixedWidth={true}
              />
            }
          </Modal.Content>
          <Modal.Actions>
            <Button
              disabled={applying}
              onClick={this.close}
            >
              Close
            </Button>
            <Button
              disabled={applyDisabled}
              loading={applying}
              color="blue"
              onClick={this.apply}
            >
              Apply Taxonomy
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}
