import { AnswersDataSet, ErrorMessage } from 'api/interfaces';
import FeatureFeedbackButton from 'components/FeatureFeedback/FeatureFeedbackButton';
import { OpenFilterButton } from 'components/Filters/OpenFilterButton';
import { filterPopUpId } from 'components/Filters/types';
import * as React from 'react';
import { Consumer } from '../utils/context';
import { focusAskFormInput } from '../utils/focusAskFormInput';
import { getErrorTitle } from '../utils/getErrorTitle';
import { getSavedAnswerId } from '../utils/getSavedAnswerId';
import { Warning } from './Warning';
import './no-data.scss';
import { ContextNoData } from './ContextNoData';
import NoDataIcon from 'images/icons/no-data.svg';

interface Props {
  question: string | null;
  error: Extract<ErrorMessage, { errorCode: 'NO_DATA' }>;
  datasets: AnswersDataSet[];
}

export const NoData = (props: Props) => {
  const data = props.error.data;
  const title = getErrorTitle(props.question);
  const feedbackData = {
    error: props.error,
    timestamp: new Date().toISOString(),
    question: props.question,
  };
  const savedAnswerId = getSavedAnswerId();
  const locationName = `answers:${savedAnswerId || 'new'}`;

  return (
    <Consumer>
      {({ askParams }) => (
        <section className="answers__no-data">
          <h4 className="answers__no-data-title">{title}</h4>

          <Warning
            title="We were unable to find data matching your query."
            icon={<NoDataIcon className="no-data-icon" />}
          >
            <p
              className="answers-warning__description"
              data-testid="advice"
            >
              It's possible that this information hasn't been collected yet or falls outside the scope of our current datasets.
            </p>
          </Warning>
          <ContextNoData
            answerDataSets={props.datasets}
            answerFilters={data?.filters}
          />
          <div className="answers__no-data-toolbar" role="toolbar">
            {askParams.dataSets.length === 1 && (
              <OpenFilterButton
                basic={true}
                color="blue"
                size="medium"
                filterPopUpId={filterPopUpId.Answers}
              >
                Change filters
              </OpenFilterButton>
            )}
            <FeatureFeedbackButton
              featureId="answers"
              location={locationName}
              rating={1}
              modalTextConfig={{
                title: 'Give feedback',
                subTitle: 'Your feedback helps us to continuously improve our answers.',
                textAreaPlaceholder: 'Tell us what is missing in your data, or give any other feedback.'
              }}
              data={feedbackData}
              onModalUnmount={() => focusAskFormInput()}
            />
          </div>
        </section>
      )}
    </Consumer>
  );
};
