import { AnalysisViewSource, AnswersDataSet } from 'api/interfaces';
import classNames from 'classnames';
import SingleFilter from 'components/Filters/SingleFilter';
import { filterPopUpId } from 'components/Filters/types';
import { PickerItem } from 'components/Shared/Picker';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Button, Form, FormInputProps, Popup } from 'semantic-ui-react';
import { Ask } from 'stores/ui/AnswersUIStore';
import { DatasetPicker } from './DatasetPicker';
import './ask-form.scss';
import { initializeFilters } from 'components/Filters/filters.service';
import { QueryFilter } from 'stores/types';
import { queryFiltersToSegmentFilters } from 'lib/query-filters-to-segment-filters';
import { FilterStoreInterface } from 'stores/FilterStore';
import { IReactionDisposer, reaction } from 'mobx';
import { getIdFromAnswersDataset } from 'lib/dataset-picker-helper';

interface AskFormState {
  showDataSetToolTip: boolean;
  submitButtonWrapperRef: React.RefObject<HTMLDivElement>;
}

interface AskFormProps {
  filterStore?: FilterStoreInterface,
  placeholder: string;
  dataSets: AnswersDataSet[];
  datasetPickerItems: PickerItem[];
  askParams: Ask;
  onChange: (askParams: Ask) => void;
  onSubmit: () => void;
}

@inject('filterStore')
@observer
export class AskForm extends React.Component<AskFormProps, AskFormState> {
  disposer: IReactionDisposer | undefined;
  state = {
    showDataSetToolTip: false,
    submitButtonWrapperRef: React.createRef<HTMLDivElement>()
  };

  componentDidMount(): void {

    let previousDatasetIds: string[] = [];

    this.disposer = reaction(
      () => this.props.askParams.dataSets,
      (datasets: AnswersDataSet[]) => {
        const datasetIds = datasets.map(getIdFromAnswersDataset);

        if (datasets.length === 1 && datasetIds[0] !== previousDatasetIds[0]) {
          const dataset = datasets[0];
          this.initializeFilters(dataset);
        }

        previousDatasetIds = datasetIds;
      },
      { fireImmediately: true }
    );

  }

  componentWillUnmount(): void {
      this.disposer?.();
  }

  initializeFilters(dataset: AnswersDataSet) {
    const surveyId = dataset.surveyId;
    const viewId = dataset.viewId || undefined;
    const visId = dataset.visId || '_';

    const source: AnalysisViewSource = {
      survey: surveyId,
      view: viewId,
      visualization: visId
    };

    initializeFilters(
      source,
      [],
    );
  }

  hasFilledPrompt(): boolean {
    return this.props.askParams.question.trim().length > 0;
  }

  hasSelectedDatasets() {
    return this.props.askParams.dataSets.length > 0;
  }

  handleKeyDown(e: React.KeyboardEvent): void {
    if (e.key === 'Enter' && !this.hasSelectedDatasets()) {
      this.setState({ showDataSetToolTip: true });

      setTimeout(() => {
        this.setState({ showDataSetToolTip: false });
      }, 5000);
    }
  }

  handleMouseOver(): void {
    if (!this.hasSelectedDatasets()) {
      this.setState({ showDataSetToolTip: true });
    }
  }

  handleMouseLeave(): void {
    this.setState({ showDataSetToolTip: false });
  }

  onInputChange(question: string): void {
    this.props.onChange({
      ...this.props.askParams,
      question
    });
  }

  onDataSetsChange(dataSets: AnswersDataSet[]): void {

    this.props.onChange({
      ...this.props.askParams,
      dataSets
    });

  }

  onFilterChange(filters: Record<string, QueryFilter>): void {
    const dateShortcuts = this.props.filterStore?.dateShortcutMaps['baseline'] ?? {};
    const segmentFilters = queryFiltersToSegmentFilters(filters, dateShortcuts);

    this.props.onChange({
      ...this.props.askParams,
      filters: segmentFilters
    });
  }

  render() {
    const { askParams, placeholder, dataSets } = this.props;

    const datasetLabel = askParams.dataSets.length === 1 ? 'Data set:' : 'Data sets:';

    const availableDatasets = this.props.dataSets;
    const selectedDatasets = this.props.askParams.dataSets;

    const className = classNames('ask-form', {
      'ask-form--hidden': availableDatasets.length === 0
    });

    const canSubmit = this.hasSelectedDatasets() && this.hasFilledPrompt();

    return (
      <div className={className}>
        <div className="ask-form__content">
          <header className="ob-form-title" aria-label="question-options">
            <div className="data-set-selection">
              <label className="label">
                {datasetLabel}
              </label>
              <DatasetPicker
                datasets={dataSets}
                pickerItems={this.props.datasetPickerItems}
                selectedDatasets={askParams.dataSets}
                onChange={dataset => this.onDataSetsChange(dataset)}
              />
            </div>
            <div className="data-set-filters">
              {selectedDatasets.length === 1 ? (
                <div className="filters-block">
                  <label className="label">
                    Filters:
                  </label>
                  <span className="ask-form__filter">
                    <SingleFilter
                      testId="ask-form-filter"
                      selectedFilters={askParams.filters}
                      filterPopUpId={filterPopUpId.Answers}
                      surveyId={askParams.dataSets[0].surveyId}
                      defaultDateToAll={true}
                      onChange={() => {/*no-op*/ }}
                      onClose={(filters) => this.onFilterChange(filters)}
                    />
                  </span>
                </div>)
              :
                'To use filters, please select only one data source.'
              }
            </div>
          </header>
          <Form className="ob-form" aria-label="question-form">
            <Form.Input
              id="ask-form-input"
              placeholder={placeholder}
              className="question-input nw-answers-question-input"
              value={askParams.question}
              onChange={
                (_e: React.SyntheticEvent<HTMLInputElement>, { value }: FormInputProps) =>
                  this.onInputChange(value)
              }
              onKeyDown={(e: React.KeyboardEvent) => this.handleKeyDown(e)}
              autoFocus={true}
              action={
                <span
                  ref={this.state.submitButtonWrapperRef}
                  onMouseOver={() => this.handleMouseOver()}
                  onMouseLeave={() => this.handleMouseLeave()}
                >
                  <Button
                    data-testid="ask-button"
                    disabled={!canSubmit}
                    primary={true}
                    onClick={this.props.onSubmit}
                  >
                    Answer
                  </Button>
                </span>
              }
            />
          </Form>
        </div>
        <Popup
          content="Please select a data set"
          context={this.state.submitButtonWrapperRef}
          open={this.state.showDataSetToolTip}
          position="top center"
        />
      </div>
    );
  }
}
