import Auth from 'Auth/Auth';
import { RoleJson, SubscriptionJson, User, UserRefreshToken, UserSeatType, UserToken } from 'api/interfaces';
import { Fetchable } from 'lib/fetch';
import { action, computed, observable } from 'mobx';
import WyeSwitch from 'wye-switch';
import { OrganizationStoreInterface } from './OrganizationStore';


export type SeatErrorCode = "NO_SEATS_AVAILABLE" | "ALREADY_HAS_BETTER_SEAT";

export interface UserObject {
  authId: string;
  email: string;
  id: string;
  firstName: string;
  lastName: string;
  picture?: string;
  preferredName?: string;
  organization: string;
  preferences?: object;
  firstAccess: boolean;
  currentUserCan?: string[];
  subscriptions?: SubscriptionJson[];
  seatType: UserSeatType | null;
}

export interface CreateUserJson {
  email: string;
  firstName: string;
  lastName: string;
  passwordAccessAllowed: boolean;
  preferredName?: string;
  roles?: RoleJson[];
  seatType?: UserSeatType;

  seatError?: SeatErrorCode;
}

export interface UserPreferences {
  [key: string]: any;
}

export interface UserStoreInterface {
  abortController: AbortController | null;
  preferredName: string | undefined;
  defaultDashboard: string | undefined;
  suggestLogout: boolean;
  flagUser?: WyeSwitch.UserObject;
  flags: WyeSwitch;
  preferences?: UserPreferences;
  user?: UserObject;
  fatalLoginError?: string;
  fetchingCurrentUser: boolean;
  currentUserIsThematicStaff: boolean;

  getFlagJson: () => Promise<void>;
  fetchCurrentUser: () => Promise<Fetchable<User>>;
  setPreferences: (preferences: UserPreferences) => Promise<void>;
  setUser: (user: UserObject) => void;
  unsetUser: () => void;

  createRefreshToken: (username: string, password: string, integrationName: string) =>
    Promise<UserRefreshToken | undefined>;
  fetchTokens: () => Promise<UserToken[]>;
  revokeToken: (token: UserToken) => Promise<UserToken[]>;
}

export interface InjectedUserStore {
  userStore: UserStoreInterface;
}

class UserStore implements UserStoreInterface {
  currentOrg: OrganizationStoreInterface;
  abortController: AbortController | null = null;

  @observable
  suggestLogout = false;
  @observable
  user?: UserObject;
  @observable
  fatalLoginError?: string;
  @observable
  fetchingCurrentUser: boolean;
  @observable
  flagJson: WyeSwitch.Flags = {};

  @computed
  get preferredName() {
    const { user } = this;
    if (!user) {
      return undefined;
    }
    const preferredName = user.preferredName ? user.preferredName : user.firstName;
    return preferredName;
  }
  @computed
  get defaultDashboard() {
    const { preferences } = this;
    if (preferences) {
      return preferences['defaultDashboard'];
    } else {
      return undefined;
    }
  }
  @computed
  get preferences() {
    const { user } = this;
    if (user) {
      return user.preferences;
    } else {
      return undefined;
    }
  }
  @computed
  get flagUser(): WyeSwitch.UserObject | undefined {
    const { user } = this;
    if (user) {
      const { authId: userId, currentUserCan } = user;
      let { orgId: groupId } = this.currentOrg;

      if (currentUserCan && currentUserCan.includes('manage:internalResource') || this.currentUserIsThematicStaff) {
        groupId = 'Thematic';
      }
      return { groupId, userId };
    } else {
      return undefined;
    }
  }
  @computed
  get flags() {
    return new WyeSwitch(this.flagJson);
  }
  @computed
  get currentUserIsThematicStaff() {
    const { user } = this;
    return !!user && user.email.endsWith('@getthematic.com') && !user.email.includes('+');
  }

  constructor(currentOrg: OrganizationStoreInterface) {
    this.currentOrg = currentOrg;
  }

  @action
  setUser = (user: UserObject) => {
    this.user = user;
  };
  @action
  unsetUser = () => {
    this.abortController?.abort();
    this.user = undefined;
    this.fatalLoginError = undefined;
  };

  @action
  fetchCurrentUser = async () => {
    this.fetchingCurrentUser = true;

    const response = await Auth.fetch<User>('/current-user');

    this.fetchingCurrentUser = false;
    return response;
  };

  getFlagJson = async () => {
    let flagsUrl;
    if (process.env.NODE_ENV === 'test') {
      return;
    } else if (process.env.NODE_ENV === 'development') {
      flagsUrl = require('feature-flags.json').default;
    } else {
      flagsUrl =
        'https://s3.amazonaws.com/thematic-client-cloudfront-flags.us/feature-flags.json';
    }
    try {
      const result = await fetch(flagsUrl);
      const { ok } = result;
      if (ok) {
        // replace flagJson
        this.flagJson = await result.json();
      }
    } catch (e) {
      // do nothing
    }
  };

  @action
  setPreferences = async (preferences: object) => {
    if (!this.user) {
      return;
    }

    this.abortController = new AbortController();

    const { data, ok, error, errorData }: Fetchable<User> = await Auth.fetch<User>('/current-user/preferences', {
      method: 'PUT',
      body: JSON.stringify({ preferences }),
      signal: this.abortController.signal
    });

    if (data && ok) {
      this.user = data;
      await Auth.refreshProfile(data);
    } else if (window.rg4js) {

      if (errorData?.message === 'Gateway Timeout') {
        return;
      }

      window.rg4js('send', {
        error: new Error('Failed to update preferences'),
        customData: { error, errorData }
      });
    }
  };

  @action
  createRefreshToken = async (username: string, password: string, integrationName: string) => {
    if (!this.user) {
      return;
    }

    const { data, ok, error } = await Auth.fetch<UserRefreshToken>('/current-user/token',
      {
        method: 'POST',
        body: JSON.stringify({
          username,
          password,
          deviceName: integrationName
        })
      });
    if (data && ok) {
      return data;
    } else if (error) {
      throw new Error(error);
    }

    return;
  }

  @action
  fetchTokens = async () => {
    if (!this.user) {
      return [];
    }
    const { data, ok }: Fetchable<UserToken[]> = await Auth.fetch<UserToken[]>(`/user/${ this.user.id }/tokens`);
    let tokens = [] as UserToken[];
    if (data && ok) {
      tokens = data;
    }
    return tokens;

  };

  @action
  revokeToken = async (token: UserToken) => {
    if (!this.user) {
      return [];
    }

    await Auth.fetch<UserToken[]>(`/user/${ this.user.id }/token/${ token.id }`, { method: 'DELETE' });

    return this.fetchTokens();
  };
}

export default UserStore;
