import * as React from 'react';
import { CommentThemeItem, PhraseSegmentTheme, Summary, SummarySegment } from 'types/custom';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getAllReferences, getRefinedThemeItems, getSelectableSegments } from './conversational-analytics.util';
import { CONVERSATION_DRAWER_SLIDE_DURATION } from './constants';
import { ConversationList } from './ConversationList';
import './conversational-analytics.scss';
import { ThemeList } from 'components/ThemeList/ThemeList';
import { MetadataList } from 'components/MetadataList/MetadataList';
import { MetadataColumn } from 'api/interfaces';
import { ConversationSummary } from './ConversationSummary';
import segmentsToCategories from 'lib/segments-to-categories';
import { TagList } from 'components/TagList/TagList';
import { TagInfo } from 'stores/TagStore';
import { ThemeCodes } from 'stores/ui/AnalysisToolsUIStore';
import { FeatureFlagManager, FlagKeys } from 'lib/feature-flag';
import analytics from 'lib/analytics';

const getSelectedSegments = (summary: Summary, selectedThemeCodes: ThemeCodes[]): SummarySegment[] => {
  const selectableSegments = getSelectableSegments(summary);

  if (selectableSegments.length === 0) {
    return [];
  }

  if (selectedThemeCodes.length === 0) {
    return [selectableSegments[0]];
  }

  return selectableSegments.filter(segment => {
    return segment.themes.some((theme: PhraseSegmentTheme):boolean => {
      return selectedThemeCodes.some((code: ThemeCodes) => {
        if (code.sub) {
          return theme.base === code.base && theme.sub === code.sub
        }
        return theme.base === code.base;
      });
    });
  });
}

interface Props {
  close: () => void;
  initialselectedthemecodes: ThemeCodes | null,
  columns?: MetadataColumn[],
  tags: Record<string, TagInfo>,
  summary: Summary;
}

const ConversationalAnalytics = ({ close, columns = [], initialselectedthemecodes, summary, tags }: Props) => {
  const canClickDrawerThemes = FeatureFlagManager.checkPHFlag(FlagKeys.CAN_CLICK_DRAWER_THEMES);

  const conversationDrawerRef = React.useRef<HTMLDivElement>(null);
  const initialSelectedThemeCodes = initialselectedthemecodes?.base ? [initialselectedthemecodes]: [];
  const initialSelectedSegments = getSelectedSegments(summary, initialSelectedThemeCodes);
  const [selectedSegments, setSelectedSegments] = React.useState<SummarySegment[]>(canClickDrawerThemes ? initialSelectedSegments : [initialSelectedSegments[0]]);
  const [preSelectedSegments, setPreselectedSegments] = React.useState<SummarySegment[] | null>(null);
  const [selectedThemeItemIndex, setSelectedThemeItemIndex] = React.useState<number>(-1);
  const [hoveredSegment, setHoveredSegment] = React.useState<SummarySegment | null>(null);
  const [slideOut, setSlideOut] = React.useState(false);

  const closeDrawer = () => {
    setSlideOut(true);
    setTimeout(() => close(), CONVERSATION_DRAWER_SLIDE_DURATION);
  };

  const handleClickOutside = (e: MouseEvent) => {
    const conversationDrawer = conversationDrawerRef.current;

    if (conversationDrawer && !conversationDrawer.contains(e.target as Node)) {
      closeDrawer();
    }
  };

  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      closeDrawer();
    }
  };

  const handleSegmentClick = (index: number) => {
    analytics.track('Analysis: Selected Summary Segment');
    setHoveredSegment(null);
    setSelectedThemeItemIndex(-1);
    setSelectedSegments([summary.segments[index]]);
  };

  const handleThemeItemClick = (item: CommentThemeItem, index: number) => {
    if (!canClickDrawerThemes) {
      return;
    }
    analytics.track('Analysis: Clicked Theme', {
      Location: 'Conversation Drawer'
    });
    const themeCodes: ThemeCodes = {
      base: item.theme.base,
      sub: item.theme.sub
    };

    setSelectedThemeItemIndex(index);
    setSelectedSegments(getSelectedSegments(summary, [themeCodes]));
  }

  const handleThemeItemEnter = (item: CommentThemeItem) => {
    if (!canClickDrawerThemes) {
      return;
    }
    const themeCodes: ThemeCodes = {
      base: item.theme.base,
      sub: item.theme.sub
    };
    setPreselectedSegments(getSelectedSegments(summary, [themeCodes]));
  }

  const handleThemeItemLeave = () => {
    setPreselectedSegments(null);
  }

  const handleSegmentMouseEnter = (index: number | null) => {
    if (index !== null) {
      const hoveredSegment = summary.segments[index];
      setHoveredSegment(hoveredSegment);
    }
  }

  const handleSegmentMouseLeave = () => {
    setHoveredSegment(null);
  }

  React.useEffect(() => {
    setTimeout(() => {
      document.addEventListener('click', handleClickOutside);
      document.addEventListener('keydown', handleKeyPress);
      document.body.classList.add('no-scroll');
    }, CONVERSATION_DRAWER_SLIDE_DURATION);

    return () => {
      document.removeEventListener('click', handleClickOutside);
      document.removeEventListener('keydown', handleKeyPress);
      document.body.classList.remove('no-scroll');
    };
  }, []);

  const allReferences = getAllReferences(summary);

  const refinedThemeItems: CommentThemeItem[] = getRefinedThemeItems(
    [selectedSegments[0]],
    summary.segments,
    initialselectedthemecodes,
    hoveredSegment,
  );

  const selectedSegmentIndexes = selectedSegments.map(segment => segment.index);
  const preSelectedSegmentIndexes: number[] = preSelectedSegments ? preSelectedSegments.map(segment => segment.index) : [];

  const categoryNames:string[] = Object.keys(segmentsToCategories(summary.segments));
  const tagNames:string[] = summary.tags.reduce((acc, tag:string) => {
    const fullName = tags[tag]?.fullName;
    return fullName ? [...acc, fullName] : acc;
  }, []);

  React.useEffect(() => {
    analytics.track("Analysis: Opened Conversation Drawer");
  }, []);

  const hasReferences = allReferences.length > 0;

  return (
    <>
      <div
        className={classNames('conversation-drawer', {
          'conversation-drawer--with-mini-map': true,
          'slide-out': slideOut,
        })}
        ref={conversationDrawerRef}
      >
        <header className="conversation-drawer__header">
          <button className="conversation-drawer__close" onClick={closeDrawer}>
            <FontAwesomeIcon icon="times" />
          </button>
          <h2 className="conversation-drawer__title">Conversation #ID {summary.conversation.id}</h2>
        </header>
        <div className="conversation-drawer__content">
          <div className="conversation-drawer__summary">
            <h2 className="conversation-drawer__column-title">Summary</h2>
            <ConversationSummary
              hasReferences={hasReferences}
              summary={summary}
              onSegmentClick={handleSegmentClick}
              selectedSegmentIndexes={selectedSegmentIndexes}
              preSelectedSegmentIndexes={preSelectedSegmentIndexes}
              onSegmentMouseEnter={handleSegmentMouseEnter}
              onSegmentMouseLeave={handleSegmentMouseLeave}
            />
            <h3 className="conversation-drawer__section-title">
              Themes
            </h3>
            <ThemeList
              items={refinedThemeItems}
              selectedIndex={selectedThemeItemIndex}
              onClick={handleThemeItemClick}
              onMouseEnter={handleThemeItemEnter}
              onMouseLeave={handleThemeItemLeave}
              showSentiment={false}
              disabled={!hasReferences}
            />
            {categoryNames.length > 0 && (
              <>
                <h3 className="conversation-drawer__section-title">
                  Categories
                </h3>
                <TagList items={categoryNames}/>
              </>
            )}
            {tagNames.length > 0 && (
              <>
                <h3 className="conversation-drawer__section-title">
                  Labels
                </h3>
                <TagList items={tagNames}/>
              </>
            )}
            <MetadataList
              summaryData={summary.data}
              metadataColumns={columns}
            />
          </div>
          <div className="conversation-drawer__conversation-list">
            <ConversationList
              allReferences={allReferences}
              conversation={summary.conversation}
              selectedSegments={selectedSegments}
            />
          </div>
        </div>
      </div>
      <div className="conversation-drawer__overlay" onClick={closeDrawer} />
    </>
  );
};

export { ConversationalAnalytics };
