import { useState } from "react";

const useAsyncFunction = <T, P extends any[]>(asyncFunction: (...args: P) => Promise<T>) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<any>(null);

  const fetch = async (...args: P) => {
    setError(null);
    setLoading(true);
    try {
      const result = await asyncFunction(...args);
      setData(result);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  return { fetch, loading, data, error };
}

export { useAsyncFunction }
