import { stringify } from 'query-string';
import { getAnalysisToolsStore } from 'stores/RootStore';

interface VisParams {
  surveyID: string;
  viewID?: string;
  visID: string;
}
interface NavigateParams extends VisParams {
  params: string;
}
export default (source: string, params: object): NavigateParams => {
  const { surveyID, viewID, visID } = matchUrl(source);

  return { params: stringify(params), surveyID, viewID, visID };
};

export function matchUrl(source: string): VisParams {
  let surveyID = '';
  let viewID = '';
  let visID = '';

  const matches = source.match(
    /survey\/([^/]+)(\/view\/([^/]+))?\/visualization\/([^/]+)\/?/
  );
  if (matches) {
    surveyID = matches[1];
    viewID = matches[3];
    visID = matches[4];
  }
  return { surveyID, viewID, visID };
}

export function canNavigate(
  source: string
): boolean {
  const { surveyID, viewID } = matchUrl(source);
  return isSourceValid(surveyID, viewID);
}

export function isSourceValid (
  surveyID: string,
  viewID: string | undefined
) {
  const surveys = getAnalysisToolsStore().flat;
  if (!surveys) {
    return false;
  }

  const found = surveys.find(k => {
    if (k.viewId && k.viewId.toString() !== viewID) {
      return false;
    }
    return k.surveyId.toString() === surveyID;
  });

  return !!found;
}
