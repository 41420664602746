import { AnswersDataSet } from 'api/interfaces';
import { PickerItem } from 'components/Shared/Picker';
import { compact } from 'lodash';
import { DatasetVis } from 'stores/data-structure/datasets';

function isItemSelected(item: PickerItem, selectedDatasets: AnswersDataSet[]): boolean {
  const isParentOption: boolean = !!item.children && item.children.length > 0;
  // If it's a parent option, we want to check if all of its children are selected
  if (isParentOption) {
    return item.children!.every(childItem => childItem.isSelected);
  } else {
    return selectedDatasets.some(selectedDataset => {
      const selectedDatasetId = getIdFromAnswersDataset(selectedDataset);
      return selectedDatasetId === item.id;
    });
  }
}

export function countPickerItems(pickerItems: PickerItem[]): number {
  return pickerItems.reduce((result: number, item: PickerItem): number => {
    const childCount = countPickerItems(item.children || []);
    return result + 1 + childCount;
  }, 0);
}

export function updateSelectionState(item: PickerItem, selectedDatasets: AnswersDataSet[]): PickerItem {
  const isParentOption: boolean = !!item.children && item.children.length > 0;
  if (isParentOption) {
    item.children = item.children!.map(childItem => updateSelectionState(childItem, selectedDatasets)) || [];
  }
  item.isSelected = isItemSelected(item, selectedDatasets);
  return item;
}

function getOriginalStructuredDataset(item: PickerItem, datasets: AnswersDataSet[]): AnswersDataSet | undefined {
  return datasets.find(dataset => {
    const datasetId = getIdFromAnswersDataset(dataset);
    return datasetId === item.id;
  });
}

function onParentClick(
  item: PickerItem,
  datasets: AnswersDataSet[],
  selectedDataSets: AnswersDataSet[]
): AnswersDataSet[] {
  const originalStructuredChildItems: AnswersDataSet[] = compact(item.children!.map(child =>
    getOriginalStructuredDataset(child, datasets)
  ));
  const isParentSelected = item.isSelected;
  if (isParentSelected) {
    // If the parent is already selected, we want to remove all of its children from the selected datasets
    const childItemsToRemove: AnswersDataSet[] = originalStructuredChildItems.filter(childItem => {
      const isChildItemAlreadySelected = selectedDataSets.some(dataset =>
        areDatasetsMatching(dataset, childItem)
      );
      return isChildItemAlreadySelected;
    });
    return selectedDataSets.filter(dataset => !childItemsToRemove.some(child => areDatasetsMatching(child, dataset)));
  } else {
    // If the parent is not selected, we want to add all of its non selected children to the selected datasets
    const childItemsToAdd: AnswersDataSet[] = originalStructuredChildItems.filter(childItem => {
      const isChildItemAlreadySelected = selectedDataSets.some(dataset =>
        areDatasetsMatching(dataset, childItem)
      );
      return !isChildItemAlreadySelected;
    });
    return [...selectedDataSets, ...childItemsToAdd];
  }
}

export function getIdFromAnswersDataset(item: AnswersDataSet): string {
  const surveyId = item.surveyId;
  const viewId = item.viewId || '';
  const visId = item.visId || '';
  return `${surveyId}-${viewId}-${visId}`;
}

export function getIdFromDatasetVis(item: DatasetVis): string {
  const surveyId = item.surveyId;
  const viewId = item.viewId || '';
  const visId = item.visualizationId || '';
  return `${surveyId}-${viewId}-${visId}`;
}

export function areDatasetsMatching(a: AnswersDataSet, b: AnswersDataSet): boolean {
  return a.visId === b.visId && a.viewId === b.viewId && a.surveyId === b.surveyId;
}

export function handleDatasetOptionClick(
  item: PickerItem,
  datasets: AnswersDataSet[],
  selectedDataSets: AnswersDataSet[]
): AnswersDataSet[] {
  const isParentOptionClicked: boolean = !!item.children && item.children.length > 0;
  if (isParentOptionClicked) {
    return onParentClick(item, datasets, selectedDataSets);
  } else {
    // on non parent option click
    const originalStructuredDataset = getOriginalStructuredDataset(item, datasets);
    if (!originalStructuredDataset) {
      return selectedDataSets;
    }
    const isSelected = selectedDataSets.some(dataset => areDatasetsMatching(dataset, originalStructuredDataset));
    return isSelected
      ? selectedDataSets.filter(dataset => !areDatasetsMatching(originalStructuredDataset, dataset))
      : [...selectedDataSets, originalStructuredDataset];
  }
}
