<template>
  <div
    ref="containerEl"
    class="spark-line-container"
  >
    <svg ref="sparkLineEl" />
  </div>
</template>

<script>
import { min, max } from 'lodash';
import * as d3 from 'd3';
import colors from 'styling/variables/colors.scss';

const MARGIN_TOP = 5;
const MARGIN_BOTTOM = 5;
const MARGIN_LEFT = 5;
const MARGIN_RIGHT = 5;

export default {
  name: 'SparkLine',
  props: { values: Array },
  computed: {
    low() {
      const low = min(this.values);
      return low;
    },
    high() {
      const high = max(this.values);
      return high;
    }
  },
  watch: {
    values: {
      handler() {
        this.updateChart();
      }
    }
  },
  mounted() {
    this.updateChart();
    window.addEventListener('resize', () => {
      setTimeout(this.updateChart, 10);
    });
  },
  methods: {
    updateChart() {
      const { containerEl, sparkLineEl } = this.$refs;
      const { values } = this;
      if (!containerEl || !sparkLineEl || !this.values) {
        return null;
      }

      d3.select(sparkLineEl).selectAll("*").remove();
      const { width: containerWidth, height: containerHeight } = containerEl.parentNode.getBoundingClientRect();

      // Dimensions
      const margin = { top: MARGIN_TOP, right: MARGIN_RIGHT, bottom: MARGIN_BOTTOM, left: MARGIN_LEFT };
      const svgWidth = containerWidth - margin.left - margin.right;
      const svgHeight = containerHeight - margin.top - margin.bottom;
      // Create SVG
      const svg = d3.select(sparkLineEl)
        .attr('width', svgWidth + margin.left + margin.right)
        .attr('height', svgHeight + margin.top + margin.bottom)
        .append('g')
        .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

      // X Scale
      const x = d3.scaleLinear()
        .domain([0, values.length - 1])
        .range([0, svgWidth]);

      // Y Scale
      const y = d3.scaleLinear()
        .domain([this.low, this.high])
        .range([svgHeight, 0]);

      // Line
      const line = d3.line()
        .x((d, i) => x(i))
        .y(d => y(d))
        .curve(d3.curveMonotoneX); // curve line

      // Draw Line
      svg.append('path')
        .datum(values)
        .attr('fill', 'none')
        .attr('stroke', colors.primary500)
        .attr('stroke-width', 2)
        .attr('d', line);
    }
  }
};
</script>
