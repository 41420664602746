import { each, some } from 'lodash';
import { action, observable } from 'mobx';

interface DescriptorErrors {
  isDuplicate?: boolean;
  isEmpty?: boolean;
  hasMoreThanTwoWords?: boolean;
  existsAsNonDescriptor?: boolean;
}

interface NonDescriptorErrors {
  isDuplicate?: boolean;
  isEmpty?: boolean;
  existsAsDescriptor?: boolean;
}

export enum ConceptType {
  DISCOVERED_CONCEPTS = 'discoveredConcepts',
  PREDEFINED_CONCEPTS = 'predefinedConcepts'
}

export interface ConceptsEditorUIStoreInterface {
  concepts: {
    learned: {[key: string]: { nonDescriptors: string[]}};
    exclude: {[key: string]: { nonDescriptors: string[]}};
    include: {[key: string]: { nonDescriptors: string[]}};
    phrases: {[key: string]: { nonDescriptors: string[]}};
  };
  conceptType: ConceptType;
  isConceptsChanged: boolean;
  descriptorInEdit: string;
  createDescriptorErrors: DescriptorErrors;
  createNonDescriptorErrors: NonDescriptorErrors;
  showCreateDescriptorOptions: boolean;
  showCreateNonDescriptorOptions: boolean;
  descriptorErrors: DescriptorErrors;
  nonDescriptorErrors: NonDescriptorErrors;

  initializeConcepts: (concepts: {
    learned: {[key: string]: { nonDescriptors: string[]}};
    exclude: {[key: string]: { nonDescriptors: string[]}};
    include: {[key: string]: { nonDescriptors: string[]}};
    phrases: {[key: string]: { nonDescriptors: string[]}};
  }) => void;
  updateDescriptorInEdit: (key: string) => void;

  switchConcepts: (content: ConceptType) => void;

  createDescriptor: (newDescriptor: string) => void;
  validateCreateDescriptor: (newDescriptor: string) => void;
  toggleCreateDescriptorOptions: (toggle: boolean) => void;

  toggleCreateNonDescriptorOptions: (toggle: boolean) => void;
  createNonDescriptor: (newValue: string, key: string) => void;
  validateCreateNonDescriptor: (newValue: string) => void;

  deleteConcept: (key: string) => void;
  deleteNonDescriptor: (key: string, value: string) => void;

  updateDescriptor: (newValue: string, oldValue: string) => void;
  validateDescriptor: (newValue: string, oldValue: string) => void;

  updateNonDescriptor: (updatedValue: string, key: string, oldValue: string) => void;
  validateNonDescriptor: (updatedValue: string, oldValue: string) => void;

  swapDescriptor: (key: String, value: string) => void;

  reset: () => void;
}

export default class ConceptsEditorUIStore {

  @observable
  concepts = {
    learned: {},
    exclude: {},
    include: {},
    phrases: {}
  };

  @observable
  conceptType = ConceptType.DISCOVERED_CONCEPTS;

  @observable
  isConceptsChanged = false;

  @observable
  showCreateDescriptorOptions = false;

  @observable
  showCreateNonDescriptorOptions = false;

  @observable
  createDescriptorErrors: DescriptorErrors = {};

  @observable
  descriptorErrors = {};

  @observable
  nonDescriptorErrors = {};

  @observable
  createNonDescriptorErrors: NonDescriptorErrors = {};

  @observable
  descriptorInEdit = '';

  @action
  initializeConcepts = (concepts: {
    learned: {[key: string]: { nonDescriptors: string[]}};
    exclude: {[key: string]: { nonDescriptors: string[]}};
    include: {[key: string]: { nonDescriptors: string[]}};
    phrases: {[key: string]: { nonDescriptors: string[]}};
    }) => {
    let sortedConcepts = {
      learned: {},
      exclude: {},
      include: {},
      phrases: {}
    };
    each(concepts, (contents: {[key: string]: { nonDescriptors: string[]}}, contentsKey: string) => {
      sortedConcepts[contentsKey] = Object.keys(contents).sort().reduce(
        (obj, key) => {
          obj[key] = concepts[contentsKey][key];
          return obj;
        }, {});
    });
    this.concepts = sortedConcepts;
  }

  // to make sure that all the inputs can only open one at a time
  @action
  updateDescriptorInEdit = (key: string) => {
    this.descriptorInEdit = key;
  }

  @action
  switchConcepts = (value) => {
    switch (value) {
      case ConceptType.DISCOVERED_CONCEPTS:
        this.conceptType = ConceptType.DISCOVERED_CONCEPTS;
        break;
      case ConceptType.PREDEFINED_CONCEPTS:
        this.conceptType = ConceptType.PREDEFINED_CONCEPTS;
        break;
      default:
        this.conceptType = ConceptType.DISCOVERED_CONCEPTS;
    }
  };

  // These are the actions for handling of creating a new descriptor
  @action
  toggleCreateDescriptorOptions = (toggle: boolean) => {
    this.createDescriptorErrors = {};
    this.showCreateDescriptorOptions = toggle;
  }

  @action
  validateCreateDescriptor = ( newDescriptor: string ) => {
    const trimmedValue = newDescriptor.trim().toLowerCase();
    const isDuplicate = some(this.concepts, (value) => trimmedValue in value);
    const hasMoreThanTwoWords = trimmedValue.split(' ').length > 2;
    const isEmpty =  trimmedValue.length < 1;
    const existsAsNonDescriptor = this.existsAsNonDescriptor(trimmedValue);
    this.createDescriptorErrors = {isDuplicate, isEmpty, hasMoreThanTwoWords, existsAsNonDescriptor};
  }

  @action
  createDescriptor = (newDescriptor: string) => {
    const trimmedValue = newDescriptor.trim().toLowerCase();
    if (this.conceptType === ConceptType.DISCOVERED_CONCEPTS) {
      this.concepts.learned[trimmedValue] = { nonDescriptors : [] };
    } else {
      const hasMoreThanOneWord = trimmedValue.split(' ').length > 1;
      if (hasMoreThanOneWord) {
        this.concepts.phrases[trimmedValue] = { nonDescriptors : [] };
      } else {
        this.concepts.include[trimmedValue] = { nonDescriptors : [] };
      }
    }
    this.toggleCreateDescriptorOptions(false);
    this.isConceptsChanged = true;
  }

  // These are the actions for handling of creating a new non descriptor
  @action
  toggleCreateNonDescriptorOptions = (toggle: boolean) => {
    this.showCreateNonDescriptorOptions = toggle;
    this.createNonDescriptorErrors = {};
  }

  @action
  validateCreateNonDescriptor = (newValue: string) => {
    const trimmedValue = newValue.trim().toLowerCase();
    const isDuplicate = this.existsAsNonDescriptor(trimmedValue);
    const isEmpty =  trimmedValue.length < 1;
    const existsAsDescriptor = this.existsAsDescriptor(trimmedValue);
    this.createNonDescriptorErrors = {isDuplicate, isEmpty, existsAsDescriptor};
  }

  @action
  createNonDescriptor = (newValue: string, key: string) => {
    const trimmedValue = newValue.trim().toLowerCase();
    const concept = this.findConceptType(key);
    if (concept) {
      this.concepts[concept][key].nonDescriptors.push(trimmedValue);
      this.toggleCreateNonDescriptorOptions(false);
      this.isConceptsChanged = true;
    }
  }

  // These are the actions for handling of updating descriptor
  @action
  validateDescriptor = (newValue: string, oldValue: string) => {
    const trimmedValue = newValue.trim().toLowerCase();
    // this condition is making sure that if user reverts value back to original it won't show an error
    if (trimmedValue === oldValue) {
      this.descriptorErrors = {};
    } else {
      const isDuplicate = some(this.concepts, (value) => trimmedValue in value);
      const isEmpty =  trimmedValue.length < 1;
      const hasMoreThanTwoWords = trimmedValue.split(' ').length > 2;
      const existsAsNonDescriptor = this.existsAsNonDescriptor(trimmedValue);
      this.descriptorErrors = {isDuplicate, isEmpty, hasMoreThanTwoWords, existsAsNonDescriptor};
    }
  }

  @action
  updateDescriptor = (newValue: string, oldValue: string) => {
    const trimmedValue = newValue.trim().toLowerCase();
    if (trimmedValue !== oldValue) {
      const concept = this.findConceptType(oldValue);
      if (concept) {
        const newObj = this.renameDescriptor(oldValue, newValue);
        this.concepts[concept] = newObj;
        this.isConceptsChanged = true;
      }
    }
  }

  // These are the actions for handling of updating non descriptor
  @action
  validateNonDescriptor = (newValue: string, oldValue: string) => {
    const trimmedValue = newValue.trim().toLowerCase();
    // this condition is making sure that if user reverts value back to original it won't show an error
    if (trimmedValue === oldValue) {
      this.nonDescriptorErrors = {};
    } else {
      const isDuplicate = this.existsAsNonDescriptor(trimmedValue);
      const isEmpty =  trimmedValue.length < 1;
      const existsAsDescriptor = this.existsAsDescriptor(trimmedValue);
      this.nonDescriptorErrors = {isDuplicate, isEmpty, existsAsDescriptor};
    }
  }

  @action
  updateNonDescriptor = (newValue: string, key: string, oldValue: string) => {
    const trimmedValue = newValue.trim().toLowerCase();
    if (trimmedValue !== oldValue) {
      const concept = this.findConceptType(key);
      if (concept) {
        const index = this.concepts[concept][key].nonDescriptors.findIndex(e => e === oldValue);
        if (index > -1) {
          this.concepts[concept][key].nonDescriptors[index] = trimmedValue;
          this.isConceptsChanged = true;
        }
      }
    }
  }

  @action
  swapDescriptor = (key, value) => {
    const concept = this.findConceptType(key);
    if (concept) {
      const index = this.concepts[concept][key].nonDescriptors.findIndex(e => e === value);
      if (index > -1) {
        this.concepts[concept][key].nonDescriptors[index] = key;
        const newObj = this.renameDescriptor(key, value);
        this.concepts[concept] = newObj;
        this.isConceptsChanged = true;
      }
    }
  }

  @action
  deleteConcept = (key: string) => {
    const concept = this.findConceptType(key);
    if (concept) {
      delete this.concepts[concept][key];
      this.isConceptsChanged = true;
    }
  }

  @action
  deleteNonDescriptor = (key: string, value: string) => {
    const concept = this.findConceptType(key);
    if (concept) {
      const index = this.concepts[concept][key].nonDescriptors.findIndex(e => e === value);
      if (index > -1) {
        this.concepts[concept][key].nonDescriptors.splice(index, 1);
        this.isConceptsChanged = true;
      }
    }
  }

  @action
  reset = () => {
    this.isConceptsChanged = false;
    this.descriptorInEdit = '';
    this.createDescriptorErrors = {};
    this.createNonDescriptorErrors = {};
    this.showCreateDescriptorOptions = false;
    this.showCreateNonDescriptorOptions = false;
    this.descriptorErrors = {};
    this.nonDescriptorErrors = {};
    this.conceptType = ConceptType.DISCOVERED_CONCEPTS;
  }

  existsAsNonDescriptor = (newValue: string) => {
    return some(this.concepts, (contents) => {
      return Object.keys(contents).some(val => {
        return contents[val].nonDescriptors.some(value =>
          value === newValue
        );
      });
    });
  }

  existsAsDescriptor = (newValue: string) => {
    return some(this.concepts, (contents) => {
      return newValue in contents;
    });
  }

  findConceptType = (key: string) => {
    return Object.keys(this.concepts).find(value => key in this.concepts[value]);
  }

  renameDescriptor = (oldKey, newKey) => {
    const conceptType = this.findConceptType(oldKey);
    let newObj;
    if (conceptType) {
      const keys = Object.keys(this.concepts[conceptType]);
      newObj = keys.reduce((acc, key) => {
        if (key === oldKey) {
            acc[newKey] = this.concepts[conceptType][oldKey];
        } else {
            acc[key] = this.concepts[conceptType][key];
        }
        return acc;
      }, {});
    }
    return newObj;
  };
}