import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import * as React from 'react';
import './picker.scss';

export type PickerItem = {
  isSelected: boolean,
  label: string,
  id: string,
  children?: PickerItem[]
};

type Props = {
  items: PickerItem[],
  onItemClicked: (item: PickerItem) => void
};

export const Picker = (props: Props) => {
  const pickerRef = React.useRef<HTMLElement>(null);
  const [expandedItems, setExpandedItems] = React.useState<string[]>([]);

  function onItemClick(item: PickerItem) {
    props.onItemClicked(item);
  }

  function navigateWithArrowKeys(event: React.KeyboardEvent<HTMLButtonElement>) {
    const items: HTMLButtonElement[] = Array.from(pickerRef.current?.querySelectorAll('[tabindex="0"]') ?? []);
    const currentItem = event.target as HTMLButtonElement;
    const currentIndex = items.indexOf(currentItem);

    if (event.key === 'ArrowUp' && currentIndex > 0) {
      items[currentIndex - 1].focus();
    } else if (event.key === 'ArrowDown' && currentIndex < items.length - 1) {
      items[currentIndex + 1].focus();
    }
  }

  function getSelectionIcon(item: PickerItem): IconProp {
    if (item.children) {
      const areAllChildrenSelected: boolean = item.children.every(child => child.isSelected) ?? false;
      return areAllChildrenSelected ? 'check' : 'minus';
    }
    return 'check';
  }

  function renderItem(item: PickerItem) {
    const icon: IconProp = getSelectionIcon(item);
    const isItemSelected = item.children?.some(child => child.isSelected) || item.isSelected;
    return (
      <button
        className={classNames('picker__label', {
          'picker__label--is-selected': isItemSelected
        })}
        aria-label={item.label}
        tabIndex={0}
        onClick={() => onItemClick(item)}
        onKeyDown={(e) => navigateWithArrowKeys(e)}
      >
        <FontAwesomeIcon
          className={classNames('picker__selection-icon', `${icon}`, {
            'picker__selection-icon--hidden': !isItemSelected
          })}
          icon={icon}
        />
        {item.label}
      </button>
    );
  }

  function toggleExpand(id: string) {
    if (expandedItems.includes(id)) {
      setExpandedItems(expandedItems.filter(item => item !== id));
    } else {
      setExpandedItems([...expandedItems, id]);
    }
  }

  function getSelectedItemsCount(item: PickerItem, level: number): ({showCount: boolean, count: number}) {
    // Only show the count for the top level items
    if (level !== 0) {
      return {
        showCount: false,
        count: 0
      };
    }
    if (item.children) {
      const areAnyChildrenSelected = item.children.some(child => child.isSelected);
      return {
        showCount: areAnyChildrenSelected,
        count: item.children.filter(child => child.isSelected).length
      };
    }
    return {
      showCount: item.isSelected,
      count: item.isSelected ? 1 : 0
    };
  }

  function renderList(items: PickerItem[], level: number) {

    return (
      <ul className="picker__list">
        {items.map(item => {
          const isExpanded: boolean = expandedItems.includes(item.id);
          const icon = isExpanded ? 'chevron-up' : 'chevron-down';
          const isParent: boolean = !!item.children && item.children.length > 0;
          const itemsCount = getSelectedItemsCount(item, level);

          return (
            <li
              aria-label={item.label}
              key={item.id}
              className="picker__list-item"
            >
              <span
                className="picker__list-item-content"
                tabIndex={-1}
              >
                {renderItem(item)}
                <span
                  data-testid="picker-list-item-count"
                  className={classNames('picker__list-item-count', {
                    'picker__list-item-count--hidden': !itemsCount.showCount
                  })}
                >
                  {itemsCount.count}
                </span>
                {isParent &&
                  <button
                    className="picker__expand-button"
                    role="button"
                    aria-label="expand-button"
                    data-testid="picker-expand-icon"
                    tabIndex={0}
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleExpand(item.id);
                    }}
                    onKeyDown={(e) => navigateWithArrowKeys(e)}
                  >
                    <FontAwesomeIcon icon={icon} />
                  </button>
                }
              </span>
              {isExpanded &&
                item.children && renderList(item.children, level + 1)
              }
            </li>
          );
        })}
      </ul>
    );
  }

  return (
    <section
      className="picker"
      ref={pickerRef}
    >
      {renderList(props.items, 0)}
    </section>
  );
};
