import * as React from 'react';
import { Button, ButtonProps } from 'semantic-ui-react';
import { getFilterStore } from 'stores/RootStore';
import { FilterPopUpId } from './types';

interface Props extends ButtonProps {
  filterPopUpId: FilterPopUpId;
}

export const OpenFilterButton = (props: Props) => {
  const { children, filterPopUpId, size, ...remainingProps } = props;

  const filterStore = getFilterStore();
  const filterIsOpen = filterStore.openFilterPopUpId === filterPopUpId;

  const handleClose = () => {
    filterStore.closeFilterPopUp();
  };

  const handleOpen = () => {
    filterStore.openFilterPopUp(filterPopUpId);
  };

  const toggleFilterPopUp = () => {
    if (filterIsOpen) {
      handleClose();
    } else {
      handleOpen();
    }
  };

  return (
    <Button
      active={filterIsOpen}
      onClick={() => toggleFilterPopUp()}
      size={size || 'small'}
      type="button"
      {...remainingProps}
    >
      {children}
    </Button>
  );
};
