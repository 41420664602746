import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PageRequestParams } from 'api/interfaces';
import analytics from 'lib/analytics';
import {
  compact, debounce, isEmpty
} from 'lodash';
import { inject, observer } from 'mobx-react';
import { parse, stringify } from 'query-string';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
  Button,
  FormInputProps,
  Header,
  Input,
  Loader,
  Menu,
  Message,
  Pagination,
  PaginationProps,
  Segment,
  Table
} from 'semantic-ui-react';
import { ManageUsersStoreInterface } from 'stores/ManageUsersStore';
import { OrganizationStoreInterface } from 'stores/OrganizationStore';
import { RoleStoreInterface } from 'stores/RoleStore';
import { SurveyStoreInterface } from 'stores/SurveyStore';
import User from './User';
import './users.scss';

export interface UsersParams {
  orgId: string;
}
export interface UsersProps extends RouteComponentProps<UsersParams> {
  manageUsersStore?: ManageUsersStoreInterface;
  surveyStore?: SurveyStoreInterface;
  organizationStore?: OrganizationStoreInterface;
  roleStore?: RoleStoreInterface;
}

export enum Direction {
  ASC = 'ascending',
  DESC = 'descending'
}

@inject('surveyStore', 'organizationStore', 'roleStore', 'manageUsersStore')
@observer
export default class Users extends React.Component<UsersProps> {
  fetchUsers: Function;

  constructor(props: UsersProps) {
    super(props);
    this.fetchUsers = debounce(this.props.manageUsersStore!.fetchUsers, 400);
  }

  get surveyStore() {
    return this.props.surveyStore!;
  }
  get roleStore() {
    return this.props.roleStore!;
  }
  get organizationStore() {
    return this.props.organizationStore!;
  }
  get manageUsersStore() {
    return this.props.manageUsersStore!;
  }
  get orgId(): string {
    const { orgId } = this.props.match.params;
    return orgId;
  }
  componentDidMount() {
    analytics.track('User Mgmt: User list view', { category: 'User Mgmt' });
    this.initialize();
  }
  componentDidUpdate(prevProps: UsersProps) {
    if (prevProps.match.params.orgId !== this.orgId) {
      this.initialize();
    } else if (prevProps.location.search !== this.props.location.search) {
      this.fetchUsers();
    }
  }
  initialize = async () => {
    const { search } = this.props.location;
    this.manageUsersStore.resetPageParams();
    if (isEmpty(search)) {
      this.updateParams({ sort_by: 'name' });
    } else {
      const searchParams = parse(search, { parseBooleans: true, parseNumbers: true });
      this.manageUsersStore.setPageParams(searchParams);
    }
    await this.manageUsersStore.fetchUsers();
  }
  createUser = () => {
    const url = `/c/${ this.orgId }/users/create`;

    this.props.history.push(url);
  };
  manageRoles = () => {
    const url = `/c/${ this.orgId }/roles`;

    this.props.history.push(url);
  };
  updateParams = (params: PageRequestParams) => {
    const { location, history } = this.props;
    const search = parse(location.search);
    const searchParams = { ...(search ? search : {}), ...params };

    history.push({
      search: stringify(searchParams)
    });
    this.manageUsersStore.setPageParams(params);
  }
  sortBy = (sortBy: string) => {
    const { sort_by: currentSortBy, reverse_sort } = this.manageUsersStore.pageParams;
    const reverseSort = sortBy === currentSortBy ? !reverse_sort : false;
    const params = {
      page: 1,
      sort_by: sortBy,
      reverse_sort: reverseSort
    };
    this.updateParams(params);
  };
  clearSearch = () => {
    this.updateParams({ page: 1, search_term: '' });
  };
  updateSearch = ({ value }: FormInputProps) => {
    const search = value.toLowerCase();
    const params = {
      page: 1,
      search_term: search
    };
    this.updateParams(params);
  };
  onPageChange = async ({ activePage }: PaginationProps) => {
    const params = {
      page: activePage as number
    };
    this.updateParams(params);
  }
  render(): JSX.Element | null {
    const surveyStore = this.surveyStore;
    const organizationStore = this.organizationStore;

    const { fetchingSurveys } = surveyStore;
    const { orgId } = organizationStore;
    const { reinviteError,
      users,
      pageParams,
      totalPages,
      fetchingUsers,
      fetchingUsersError
    } = this.manageUsersStore;
    const { sort_by, search_term, reverse_sort, page } = pageParams;

    const canManageRoles = this.organizationStore.getCanAction('manage:role');

    const errors = compact([
      fetchingUsersError,
      reinviteError
    ]);

    let body;

    if (fetchingUsers) {
      body = (
        <Table.Row className="loading">
          <Table.Cell textAlign="center">
            <Loader inline={true} size="small" active={fetchingUsers}>
              Fetching users&hellip;
            </Loader>
          </Table.Cell>
        </Table.Row>
      );
    } else if (isEmpty(users)) {
      body = (
        <Table.Row className="loading">
          <Table.Cell textAlign="center">
            {search_term ? 'No matching users found' : 'No users found'}
          </Table.Cell>
        </Table.Row>
      );
    } else {
      body = users!.map(user => {
        return (<User key={user.id} orgId={orgId} user={user} />);
      });
    }

    return (
      <Segment className="fullscreen manage-users">
        <Header className="header-two-row">
          <div>
            Manage Users
          </div>
          {canManageRoles &&
            <Button
              className="nw--manage-roles"
              disabled={!!fetchingSurveys}
              onClick={this.manageRoles}
            >
              Manage roles
            </Button>
          }
        </Header>
        <Segment className="white">
          <Menu secondary={true}>
            <Menu.Item fitted={true}>
              <Button
                color="blue"
                className="nw--add-user"
                disabled={!!fetchingSurveys || fetchingUsers}
                onClick={this.createUser}
              >
                <FontAwesomeIcon
                  className="icon"
                  fixedWidth={true}
                  icon="plus"
                />
                Add new user
              </Button>
            </Menu.Item>
            <Menu.Menu position="right">
              <Menu.Item fitted={true}>
                <Input
                  action={{
                    className: 'basic',
                    icon: search_term ? 'close' : 'search',
                    onClick: this.clearSearch
                  }}
                  placeholder="Search..."
                  name="filter"
                  onChange={(_e, data) => this.updateSearch(data)}
                  value={search_term || ''}
                />
              </Menu.Item>
            </Menu.Menu>
          </Menu>

          {errors.map(error => {
            return (
              <Message
                key={error}
                className="error"
                negative={true}
                header={error}
              />
            );
          })}

          <Table
            unstackable={true}
            fixed={true}
            sortable={true}
            className="users-grid"
          >
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  sorted={sort_by === 'name' ? reverse_sort ? Direction.DESC : Direction.ASC : undefined}
                  onClick={() => this.sortBy('name')}
                >
                  Name
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={sort_by === 'email' ? reverse_sort ? Direction.DESC : Direction.ASC : undefined}
                  onClick={() => this.sortBy('email')}
                >
                  Email
                </Table.HeaderCell>

                <Table.HeaderCell
                  sorted={sort_by === 'seatType' ? reverse_sort ? Direction.DESC : Direction.ASC : undefined}
                  onClick={() => this.sortBy('seatType')}
                >
                  Seat Type
                </Table.HeaderCell>
                <Table.HeaderCell>
                  Role
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={sort_by === 'lastLoginDate' ? reverse_sort ? Direction.DESC : Direction.ASC : undefined}
                  onClick={() => this.sortBy('lastLoginDate')}
                >
                  Last login
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>{body}</Table.Body>
          </Table>
          <div className="pagination-block">
            <Pagination
              activePage={page}
              totalPages={totalPages}
              disabled={fetchingUsers}
              onPageChange={(_e, data) => this.onPageChange(data)}
            />
          </div>
        </Segment>
      </Segment>
    );
  }
}
