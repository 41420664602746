<template>
  <widget-wrapper
    :title="config.title"
    :loading="loading"
    :error="error"
    :warning="warning"
    class="widget-width-2"
    :panel-order="panelOrder"
    :widget-order="widgetOrder"
  >
    <div
      slot="subtitle"
      class="widget-subtitle"
    >
      <div class="widget-subtitle-text">
        {{ scoreName }} breakdown
      </div>
    </div>
    <div
      v-if="showScore"
      class="widget-body"
    >
      <stack-rank-headline v-bind="cut" />
    </div>
    <div
      v-else
      class="empty widget-body"
    >
      Not enough responses to calculate
    </div>
  </widget-wrapper>
</template>

<script>
import { get, map } from 'lodash';

import { mapCut } from 'vue/libs/score-to-segments';
import StackRankHeadline from './Components/StackRankHeadline.vue';
import WidgetWrapper from './WidgetWrapper.vue';

export default {
  name: 'SCORE_DETAIL',
  components: {
    StackRankHeadline,
    WidgetWrapper
  },
  props: {
    config: { default: undefined, type: Object },
    data: { default: undefined, type: Object },
    warning: { default: undefined, type: String },
    error: { default: undefined, type: String },
    limit: { default: 5, type: Number },
    loading: { default: false, type: Boolean },
    panelOrder: { type: Number },
    widgetOrder: { type: Number }
  },
  computed: {
    cut() {
      if (!this.data) {
        return undefined;
      }

      const type = get(this, 'scoreConfig.type');
      const name = get(this, 'scoreConfig.name', 'Score');
      const count = get(this, 'data.currentScore.count');

      let segmentNames = get(this, 'scoreConfig.options.range_thresholds');
      segmentNames = map(segmentNames, s => get(s, 'name'));

      const cut = {
        count,
        name,
        score: get(this, 'data.currentScore.score'),
        previousScore: get(this, 'data.previousScore.score')
      };

      return mapCut(type, cut, count, segmentNames);
    },
    responseCount() {
      return get(this, 'data.currentScore.count', 0);
    },
    scoreConfig() {
      return get(this.data, 'scoreConfig', get(this.config, 'score'));
    },
    scoreName() {
      return get(this, 'scoreConfig.name', 'Score');
    },
    showScore() {
      return this.responseCount > 0;
    }
  }
};
</script>

<style lang="scss" scoped>
.empty {
  align-items: center;
  display: flex;
  justify-content: center;
}
</style>
