import * as React from 'react';
import {
  find,
  findIndex,
  forEach,
  isEmpty,
  reduce,
  reject,
  throttle
} from 'lodash';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { observer, inject } from 'mobx-react';

import './theme-tree.scss';
import ThemeCard from './ThemeCard';
import HintCancel from './HintCancel';
import { ThemeGroup } from 'lib/theme-file-parser';
import { ThemeTreeItem, ThemesStoreInterface } from 'stores/ThemesStore';
import { ThemeEditorSessionStoreInterface } from 'stores/ThemeEditorSessionStore';
import { compose } from 'lib/composeHOCs';

export interface ThemeCardInterface {
  id: string;
  parentId?: string;
}

interface ThemeHintPosition {
  child?: boolean;
  topHalf?: boolean;
  promote?: boolean;
  overMerge?: boolean;
  merge?: boolean;
}

export interface HoverHint extends ThemeHintPosition, ThemeCardInterface {}

interface ThemeTreeStoreProps {
  themesStore: ThemesStoreInterface;
  themeEditorSessionStore: ThemeEditorSessionStoreInterface;
}

interface ThemeTreeProps extends ThemeTreeStoreProps {
  group: ThemeGroup;
}
interface ThemeTreeState {
  hoverHint?: HoverHint;
}

type TopLevelPath = [number];
type ChildPath = [number, number];
type Path = TopLevelPath | ChildPath;

function pathDistance(p1: Path, p2: Path): number {
  // If both paths are top-level
  if (p1.length === 1 && p2.length === 1) {
    return Math.abs(p1[0] - p2[0]);
  }

  // If both paths are child-level
  if (p1.length === 2 && p2.length === 2) {
    // If they're in the same top-level group
    if (p1[0] === p2[0]) {
      return Math.abs(p1[1] - p2[1]);
    }
    // If they're in different top-level groups
    return Math.abs(p1[0] - p2[0]);
  }

  // If one path is top-level and the other is child-level
  if (p1.length !== p2.length) {
    const topLevel = p1.length === 1 ? p1[0] : p2[0];
    const childLevel = p1.length === 2 ? p1 : p2;
    return Math.abs(topLevel - childLevel[0]);
  }

  // This should never happen if the input is correct
  throw new Error("Invalid path combination");
}

const withHocs = compose(
  inject('themesStore', 'themeEditorSessionStore'),
  observer,
);

export default withHocs(class ThemeTree extends React.Component<
  ThemeTreeProps,
  ThemeTreeState
> {
  state: Readonly<ThemeTreeState> = {
    hoverHint: undefined
  };
  componentDidMount = () => {
    this.moveHint = throttle(this.moveHint, 50, { trailing: true });
  };
  findParent = (id?: string) => {
    const { nodes: themes } = this.props.group;
    return find(themes, { id });
  };
  findTheme = (item: ThemeCardInterface) => {
    const parent = this.findParent(item.parentId);

    if (parent) {
      return find(parent.children, { id: item.id });
    } else {
      return this.findParent(item.id);
    }
  };
  promoteCard = (dragItem: ThemeCardInterface, dropHint: HoverHint) => {
    const { group, themesStore } = this.props;
    // item we're moving
    const promoted = this.findTheme(dragItem);
    if (!promoted) {
      return;
    }
    const { nodes: themes } = group;

    if (dragItem.parentId) {
      // move subtheme
      const movedParent = this.findParent(dragItem.parentId);
      if (movedParent) {
        movedParent.children = reject(
          movedParent.children,
          c => c.id === dragItem.id
        );
      }
      // we're moving a theme
    } else {
      const removeIndex = findIndex(themes, { id: dragItem.id });
      themes.splice(removeIndex, 1);
    }
    let targetIndex;
    if (dropHint.parentId) {
      targetIndex =
        findIndex(themes, { id: dropHint.parentId }) +
        (dropHint.topHalf ? 0 : 1);
    } else {
      targetIndex =
        findIndex(themes, { id: dropHint.id }) + (dropHint.topHalf ? 0 : 1);
    }
    themes.splice(targetIndex, 0, {
      ...promoted,
      children: promoted.children || [],
      expanded: false
    });

    // udpate data
    themesStore.moveTheme(group, dragItem.id, undefined);
  };
  mergeCard = (dragItem: ThemeCardInterface, dropHint: HoverHint) => {
    const { themesStore, group } = this.props;
    const { nodes: themes } = group;
    // if merging with self, abort
    if (dragItem.id === dropHint.id) {
      return;
    }
    // item we're moving
    const merged = this.findTheme(dragItem);
    if (!merged) {
      return;
    }
    // we're merging a subtheme
    if (dragItem.parentId) {
      const movedParent = this.findParent(dragItem.parentId);
      if (movedParent) {
        movedParent.children = reject(
          movedParent.children,
          c => c.id === dragItem.id
        );
      }
      // we're merging a theme
    } else {
      const removeIndex = findIndex(themes, { id: dragItem.id });
      themes.splice(removeIndex, 1);
    }

    const target = this.findTheme(dropHint);
    if (target) {
      target.merged.push(...themesStore!.toMergeItems(merged));
    }

    // update data
    themesStore!.mergeTheme(group, dragItem.id, dropHint.id);
  };
  moveCard = (dragItem: ThemeCardInterface, dropHint: HoverHint) => {
    const {
      themesStore,
      themeEditorSessionStore,
      group
    } = this.props;
    const { nodes: themes } = group;

    const parent: ThemeTreeItem | undefined = dragItem.parentId ? this.findParent(dragItem.parentId) : undefined;
    const parentIndex = parent ? findIndex(themes, { id: parent.id }) : -1;

    const initialPath: Path = parent
      ? [parentIndex, findIndex(parent.children, { id: dragItem.id })]
      : [findIndex(themes, { id: dragItem.id })]

    if (dragItem.id === dropHint.id) {
      // if moving to self, abort
      return;
    } else if (dragItem.id === dropHint.parentId) {
      // if moving parent into children, abort
      return;
    }
    // item we're moving
    const moved = this.findTheme(dragItem);
    if (!moved) {
      return;
    }
    // we're moving a subtheme
    if (dragItem.parentId) {
      const movedParent = this.findParent(dragItem.parentId);
      if (movedParent) {
        movedParent.children = reject(
          movedParent.children,
          c => c.id === dragItem.id
        );
      }
      // we're moving a theme
    } else {
      const removeIndex = findIndex(themes, { id: dragItem.id });
      themes.splice(removeIndex, 1);
    }
    let targetParent;

    // it's dropping onto a subtheme
    if (dropHint.parentId) {
      targetParent = this.findParent(dropHint.parentId);
      if (targetParent) {
        const targetIndex =
          findIndex(targetParent.children, {
            id: dropHint.id
          }) + (dropHint.topHalf ? 0 : 1);
        const newChildren = [moved];
        if (moved.children) {
          newChildren.push(...moved.children);
          delete moved.expanded;
          delete moved.children;
        }

        if (targetParent.children) {
          targetParent.children.splice(targetIndex, 0, ...newChildren);
        }
      }
      // it's dropping onto a theme
    } else {
      if (dropHint.child) {
        targetParent = this.findParent(dropHint.id);
        if (targetParent) {
          const targetIndex =
            findIndex(targetParent.children, {
              id: dropHint.id
            }) + (dropHint.topHalf ? 0 : 1);
          const newChildren = [moved];
          if (moved.children) {
            newChildren.push(...moved.children);
            delete moved.expanded;
            delete moved.children;
          }

          if (targetParent.children) {
            targetParent.children.splice(targetIndex, 0, ...newChildren);
          }
        }
      } else {
        const targetIndex =
          findIndex(themes, { id: dropHint.id }) + (dropHint.topHalf ? 0 : 1);
        themes.splice(targetIndex, 0, {
          ...moved,
          children: moved.children || [],
          expanded: false
        });
      }
    }

    let finalPath:Path = [-1];

    if (dropHint.parentId) {
      const parent = this.findParent(dropHint.parentId);
      if (parent) {
        const parentIndex = findIndex(themes, { id: parent.id });
        const childIndex = findIndex(parent.children, { id: dragItem.id });
        finalPath = [parentIndex, childIndex];
      }
    } else if (dropHint.child) {
      const parent = this.findParent(dropHint.id);
      if (parent) {
        const parentIndex = findIndex(themes, { id: parent.id });
        const childIndex = findIndex(parent.children, { id: dragItem.id });
        finalPath = [parentIndex, childIndex];
      }
    } else {
      finalPath = [findIndex(themes, { id: dragItem.id })];
    }

    // update data
    themesStore.moveTheme(group, moved.id, targetParent && targetParent.id);
    themeEditorSessionStore.addEvent({
      type: 'Modify',
      subType: 'MoveTheme',
      timestamp: Date.now(),
      data: pathDistance(initialPath, finalPath)
    });
  };
  moveHint = (hoverHint?: ThemeCardInterface & ThemeHintPosition) => {
    this.setState({ hoverHint });
  };
  executeHint = (item: ThemeCardInterface) => {
    const { group } = this.props;
    const { hoverHint } = this.state;
    if (!hoverHint) {
      return;
    }
    const { child, merge, promote } = hoverHint;
    if (merge) {
      this.mergeCard(item, hoverHint);
      group.activeNodeId = hoverHint.id;
    } else if (promote) {
      this.promoteCard(item, hoverHint);
      group.activeNodeId = item.id;
    } else {
      this.moveCard(item, hoverHint);
      const target = this.findTheme(hoverHint);
      if (child) {
        if (target && target.expanded) {
          group.activeNodeId = item.id;
        } else {
          group.activeNodeId = hoverHint.id;
        }
      } else {
        group.activeNodeId = item.id;
      }
    }
  };
  addSubtheme = (title: string, parentId: string) => {
    const {
      group,
      themesStore,
      themeEditorSessionStore: sessionStore
    } = this.props;
    themesStore.addTheme(group, title, parentId);
    if (sessionStore.currentSessionId) {
      sessionStore.addEvent({
        type: 'Addition',
        subType: 'AddSubTheme',
        timestamp: Date.now()
      });
    }
  };
  activateNode = (id: string) => {
    const { group } = this.props;
    group.activeNodeId = id;
  };
  deleteTheme = (id: string, parentId?: string) => {
    const { group, themesStore } = this.props;

    themesStore!.deleteTheme(group, id);
    const parent = this.findParent(parentId);
    if (parent && parent.children) {
      const index = findIndex(parent.children, { id });
      if (index >= 0) {
        parent.children.splice(index, 1);
      }
      group.activeNodeId = parent.id;
    } else {
      const index = findIndex(group.nodes, { id });
      if (index >= 0) {
        group.nodes.splice(index, 1);
        if (group.nodes[index]) {
          group.activeNodeId = group.nodes[index].id;
        } else if (group.nodes[0]) {
          group.activeNodeId = group.nodes[0].id;
        }
      }
    }
  };
  toggleNode = (id: string) => {
    const node = this.findParent(id);
    if (node) {
      node.expanded = !node.expanded;
    }
  };
  render() {
    const { hoverHint } = this.state;
    const { group } = this.props;

    const { nodes: themes } = group;

    return (
      <DndProvider backend={HTML5Backend}>
        <div className="theme-tree">
          <HintCancel cancelHint={this.moveHint} />
          {reduce(
            themes,
            (result, theme) => {
              const { id } = theme;
              let hint;
              if (hoverHint && hoverHint.id === id) {
                hint = hoverHint;
              }
              result.push(
                <ThemeCard
                  key={theme.id}
                  id={theme.id}
                  isNew={theme.isNew}
                  hasMerged={theme.merged.length > 0}
                  hasMergedNew={!!theme.hasMergedNew}
                  toReview={theme.toReview}
                  activateNode={this.activateNode}
                  deleteTheme={this.deleteTheme}
                  expanded={theme.expanded}
                  title={String(theme.title)}
                  hasChildren={!isEmpty(theme.children)}
                  hoverHint={hint}
                  executeHint={this.executeHint}
                  moveHint={this.moveHint}
                  selected={group.activeNodeId === theme.id}
                  addSubtheme={this.addSubtheme}
                  toggleNode={this.toggleNode}
                />
              );
              if (theme.expanded) {
                forEach(theme.children, (subtheme, i, children) => {
                  let subthemeHint;
                  if (hoverHint && hoverHint.id === subtheme.id) {
                    subthemeHint = hoverHint;
                  }
                  result.push(
                    <ThemeCard
                      key={subtheme.id}
                      id={subtheme.id}
                      isNew={subtheme.isNew}
                      hasMerged={subtheme.merged.length > 0}
                      hasMergedNew={!!subtheme.hasMergedNew}
                      toReview={subtheme.toReview}
                      activateNode={this.activateNode}
                      deleteTheme={this.deleteTheme}
                      title={String(subtheme.title)}
                      parentId={id}
                      hoverHint={subthemeHint}
                      executeHint={this.executeHint}
                      moveHint={this.moveHint}
                      selected={group.activeNodeId === subtheme.id}
                      isTail={i === children.length - 1}
                    />
                  );
                });
              }
              return result;
            },
            [] as JSX.Element[]
          )}
          <HintCancel cancelHint={this.moveHint} />
        </div>
      </DndProvider>
    );
  }
});
