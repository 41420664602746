<template>
  <div
    ref="scrollable"
    class="feedback-table ob-feedback-table"
  >
    <div class="feedback-table__contents">
      <div
        v-if="!loading && comments.length === 0"
        class="feedback-table__empty"
      >
        No {{ isSummary ? 'summaries' : 'comments' }} match the current filters
      </div>
      <el-table
        :data="comments"
        :border="true"
        :highlight-current-row="false"
        :row-class-name="editedCommentClass"
        :default-sort="{ prop: 'theme', order: 'ascending' }"
        @sort-change="onSortChange"
      >
        <el-table-column
          prop="id"
          column-key="id"
          width="50"
          :resizable="false"
          align="center"
        >
          <template slot-scope="scope">
            <div>{{ scope.$index + 1 + rowOffset }}</div>
            <el-tooltip
              v-if="isEdited(scope.row.id)"
              class="item"
              popper-class="feedback-table-edit-state-tooltip"
              effect="light"
              content="Changes pending"
              placement="top"
            >
              <font-awesome-icon
                v-if="isEdited(scope.row.id)"
                icon="hourglass-half"
                class="feedback-table__edit-state"
              />
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          v-if="includeFeedbackColumn"
          prop="comment"
          column-key="comment"
          label="Feedback"
          min-width="350"
          :sort-by="noopSort"
          :sort-orders="['ascending', 'descending']"
          sortable
          resizable
        >
          <template slot-scope="scope">
            <feedback-table-comment-cell
              v-if="!isThreadedComments || isSummary"
              :comment-column-id="scope.row.column"
              :comment-id="scope.row.id"
              :comment="scope.row.comment"
              :highlight-type="commentHighlightingType"
              :hovered-location="hoveredLocation"
              :hovered-theme-codes="scope.row.themeHover"
              :segments="scope.row.segments"
              :is-summary="isSummary"
              @hoverBlock="hoverBlock"
              @leaveBlock="leaveBlock"
              @onCommentSegmentsUpdated="segments => onCommentSegmentsUpdated(scope.row.id, segments)"
              @persistHoveredBlock="persistHoveredBlock"
              @showFullConversation="() => showFullConversationDrawer(scope)"
            />
            <feedback-table-thread-cell
              v-else
              :comment-column-id="scope.row.column"
              :comment-id="scope.row.id"
              :comment="scope.row.comment"
              :highlight-type="commentHighlightingType"
              :hovered-location="hoveredLocation"
              :hovered-theme-codes="scope.row.themeHover"
              @hoverBlock="hoverBlock"
              @leaveBlock="leaveBlock"
              @onSentimentChange="({ sentiment, block }) => onSentimentChange(scope.row.id, sentiment, block)"
            />
          </template>
        </el-table-column>
        <el-table-column
          v-if="includeThemesColumn"
          class-name="feedback-table__themes-column"
          prop="theme"
          column-key="theme"
          label="Themes"
          width="300"
          :sort-by="noopSort"
          :sort-orders="['ascending', 'descending']"
          sortable
          resizable
        >
          <template slot="header">
            <span class="feedback-table__themes-label">Themes</span>
            <el-button
              v-if="analysisConfigStore.canManageThemes"
              size="small"
              class="feedback-table__manage-themes"
              @click="manageThemes"
            >
              Manage themes
            </el-button>
          </template>
          <template slot-scope="scope">
            <feedback-table-themes-cell
              :segments="isThreadedComments ? getAllSegments(scope.row.comment) : scope.row.segments"
              :comment="!isThreadedComments ? scope.row.comment : undefined"
              :comment-id="scope.row.id"
              :parts="isThreadedComments ? scope.row.comment : undefined"
              :row-ref="scope.$index"
              :hovered-location="hoveredLocation"
              @onCommentThemeHighlighted="onCommentThemeHighlighted"
              @onCommentThemeSelected="onCommentThemeSelected"
            />
          </template>
        </el-table-column>
        <el-table-column
          v-if="includeSentimentColumn"
          prop="sentiment"
          column-key="sentiment"
          label="Overall Sentiment"
          sortable
          :sort-by="noopSort"
          :sort-orders="['ascending', 'descending']"
          resizable
          width="120"
        >
          <template slot-scope="scope">
            <feedback-table-sentiment-cell :sentiment="scope.row.sentiment" />
          </template>
        </el-table-column>
        <el-table-column
          v-if="includeCategoriesColumn"
          prop="categories"
          column-key="categories"
          label="Categories"
          sortable
          :sort-by="noopSort"
          :sort-orders="['ascending', 'descending']"
          resizable
          width="120"
        >
          <template slot-scope="scope">
            <comment-categories
              :comment-column-id="scope.row.column"
              :comment-id="scope.row.id"
              :categories="getCategories(scope.row)"
            />
          </template>
        </el-table-column>
        <el-table-column
          v-if="includeTagsColumn"
          class-name="feedback-table__tags-column"
          prop="tags"
          column-key="tags"
          label="Labels"
          width="300"
          :sort-by="noopSort"
          :sort-orders="['ascending', 'descending']"
          sortable
          resizable
        >
          <template slot="header">
            <span class="feedback-table__themes-label">Labels</span>
            <el-button
              v-if="analysisConfigStore.canEditData"
              size="small"
              class="feedback-table__manage-tags"
              @click="openManageTags"
            >
              Manage labels
            </el-button>
          </template>
          <template slot-scope="scope">
            <comment-tags
              :comment-column-id="scope.row.column"
              :comment-id="scope.row.id"
              :comment-tags="scope.row.tags"
              :tag-dropdown-placement="scope.$index + 1 === comments.length ? 'top-start' : 'bottom-start'"
              location="feedback"
              @updateTags="(newTags) => updateTags(scope.row.id, newTags)"
            />
          </template>
        </el-table-column>
        <el-table-column
          v-for="d in selectedColumns"
          :key="d"
          :column-key="d"
          :prop="`data[${d}]`"
          :label="dimensions[d].name"
          :sort-by="noopSort"
          :sort-orders="['ascending', 'descending']"
          sortable
          resizable
        />
        <template slot="empty">
          <div v-if="loading">
            Loading...
          </div>
          <div v-else>
            No data
          </div>
        </template>
      </el-table>
      <div
        v-if="hasMore && !loadingInitial"
        class="feedback-table__action"
      >
        <el-pagination
          v-if="isThreaded"
          background
          layout="prev, pager, next"
          :page-size="pageSize"
          :total="total"
          @current-change="handlePageChange"
        />
        <el-button
          v-else
          :disabled="loading"
          :loading="loading"
          :type="type"
          @click="handleLoadMoreClick()"
        >
          Load more {{ isSummary ? 'summaries' : 'comments' }}
        </el-button>
      </div>
    </div>
    <conversation-drawer
      v-if="currentFullConversation"
      :summary="currentFullConversation"
      :columns="analysisConfigStore.metadataColumns"
      :tags="tagStore.tags"
      :close="() => currentFullConversation = null"
    />
  </div>
</template>

<script>
import { assign, filter, forEach, isArray, reduce } from 'lodash';
import { ReactInVue } from 'vuera';
import analytics from 'lib/analytics';
import {
  getAnalysisConfigStore,
  getTagsUIStore,
  getInitConfigStore,
  getTagStore,
  getThemesStore,
} from 'stores/RootStore';
import CommentTags from 'vue/components/Tags/CommentTags.vue';
import CommentCategories from 'vue/components/Categories/CommentCategories.vue';
import FeedbackTableCommentCell from 'vue/feedback/components/FeedbackTableCommentCell.vue';
import FeedbackTableSentimentCell from 'vue/feedback/components/FeedbackTableSentimentCell.vue';
import FeedbackTableThemesCell from 'vue/feedback/components/FeedbackTableThemesCell.vue';
import FeedbackTableThreadCell from 'vue/feedback/components/FeedbackTableThreadCell.vue';
import thematicData from 'vue/libs/thematicData';
import segmentsToCategories from "lib/segments-to-categories";
import hoveredLocationMixin from 'vue/components/Comments/HoveredLocationMixin';
import editedCommentsMixin from 'vue/components/Comments/EditedCommentsMixin';
import updateSentimentInThreadedComment from 'lib/update-sentiment-in-threaded-comment';
import queryBuilder from 'vue/libs/queryBuilder';
import { ConversationalAnalytics } from 'components/ConversationalAnalytics/ConversationalAnalytics';

const PAGE_SIZE = 25;
const PAGE_SIZE_THREADED = 5;

let keyIndex = 0;
export default {
  name: 'FeedbackTable',
  components: {
    CommentCategories,
    FeedbackTableCommentCell,
    FeedbackTableSentimentCell,
    CommentTags,
    FeedbackTableThemesCell,
    FeedbackTableThreadCell,
    ConversationDrawer: ReactInVue(ConversationalAnalytics),
  },
  mixins: [
    hoveredLocationMixin(),
    editedCommentsMixin()
  ],
  props: {
    total: { default: 0, type: Number },
    type: { default: 'baseline', type: String },
    query: { default: '', type: String },
    dimensionsAvailable: { type: Array },
    selectedColumnCheckboxes: { type: Array },
  },
  data() {
    return {
      analysisConfigStore: getAnalysisConfigStore(),
      tagsUIStore: getTagsUIStore(),
      tagStore: getTagStore(),
      themesStore: getThemesStore(),
      initConfigStore: getInitConfigStore(),
      comments: [],
      commentMetadata: [],
      hasMore: true,
      loading: false,
      loadingInitial: false,
      page: 1,
      promise: undefined,
      sortBy: 'theme',
      sortDir: 'asc',
      screenshotData: undefined,
      screenshotDataStyle: '',
      currentFullConversation: null
    };
  },
  computed: {
    dimensions() {
      return this.initConfigStore.dimensions;
    },
    commentColumns() {
      return this.initConfigStore.commentColumns;
    },
    commentHighlightingType() {
      return this.initConfigStore.commentHighlightingType;
    },
    requestOptions() {
      return this.initConfigStore.requestOptions;
    },
    organization() {
      return this.initConfigStore.organization;
    },
    surveyId() {
      return this.initConfigStore.surveyId;
    },
    themesHierarchy() {
      return this.themesStore.themesHierarchy;
    },
    commentRequestOptions() {
      const { requestOptions, sortBy, sortDir, dimensionsAvailable } = this;
      const metadataColumns = reduce(
        dimensionsAvailable,
        (result, value) => {
          result.push(value.key);
          return result;
        },
        [],
      );
      const options = assign({}, requestOptions, { metadataColumns });
      options.sortDirection = sortDir;
      options.sortColumn = sortBy;
      return options;
    },
    selectedColumns() {
      const result = filter(this.commentMetadata, (col) => {
        return this.selectedColumnCheckboxes.indexOf(col) >= 0;
      });
      return result;
    },
    includeFeedbackColumn() {
      return this.selectedColumnCheckboxes.indexOf('comment') >= 0;
    },
    includeThemesColumn() {
      return this.selectedColumnCheckboxes.indexOf('theme') >= 0;
    },
    includeSentimentColumn() {
      return this.selectedColumnCheckboxes.indexOf('sentiment') >= 0;
    },
    includeCategoriesColumn() {
      return this.selectedColumnCheckboxes.indexOf('categories') >= 0;
    },
    includeTagsColumn() {
      return this.selectedColumnCheckboxes.indexOf('tags') >= 0;
    },
    isThreadedComments() {
      return this.comments.length > 0 && isArray(this.comments[0].comment);
    },
    tagsDeleted() {
      return this.tagsUIStore.deletingTags;
    },
    isThreaded() {
      const isThreaded = !!this.analysisConfigStore?.config?.threadConfig;
      return isThreaded;
    },
    pageSize() {
      return this.isThreaded ? PAGE_SIZE_THREADED : PAGE_SIZE;
    },
    rowOffset() {
      return this.isThreaded
        ? this.page * this.pageSize - this.pageSize
        : 0;
    },
    isSummary() {
      return !!this.analysisConfigStore?.config?.threadConfig?.summarize;
    },
  },
  watch: {
    query: {
      handler() {
        this.fetchInitialComments();
      },
      immediate: true,
    },
    themesHierarchy() {
      this.fetchInitialComments();
    },
    tagsDeleted: {
      handler(tagsDeleted) {
        if (tagsDeleted) {
          this.updateComments();
        }
      },
      immediate: true,
    },
  },
  methods: {
    getAllSegments(comment) {
      return comment.flatMap(threadedComment => threadedComment.segments);
    },
    getCategories(row) {
      const segments = row.segments || this.getAllSegments(row.comment);
      return segmentsToCategories(segments);
    },
    editedCommentClass({row}) {
      return this.isEdited(row.id) ? 'edited-comment' : '';
    },
    isEdited(commentId) {
      return this.editedCommentIds.includes(commentId);
    },
    manageThemes(event) {
      event.stopPropagation();
      const { organization, surveyId } = this;
      const manageThemesLink = `/#/c/${organization}/s/${surveyId}/themes`;
      window.location = manageThemesLink;
    },
    openManageTags(event) {
      event.stopPropagation();
      this.tagsUIStore.openManageTags();
    },
    updateComments() {
      // re-rendering commnets when tag gets deleted
      this.fetchInitialComments();
      this.tagsUIStore.toggleDeletingTags(false);
    },
    reset() {
      this.comments = [];
      this.hasMore = true;
      this.page = 1;
    },
    doFetch: async function () {
      let filterString = queryBuilder.appendToFilter(this.query, 'theme!=*!nothing!*');

      this.loading = true;

      const { commentColumns, commentRequestOptions, page, pageSize } = this;

      this.promise = thematicData.getComments(filterString, commentColumns, commentRequestOptions, { page, pageSize });

      let comments;
      let hasMore = false;
      try {
        comments = await this.promise;
        hasMore = comments.length >= pageSize;
      } catch (e) {
        this.error = e;
        return;
      }

      let commentMetadata = [];
      if (comments.length > 0) {
        commentMetadata = Object.keys(comments[0].data);
      }
      this.commentMetadata = commentMetadata;

      forEach(comments, (comment) => {
        comment.key = `${keyIndex++}-comment-key`;
        comment.themeHover = undefined;
      });

      const isPaginated = this.isThreaded;

      if (isPaginated) {
        this.comments = comments;
      } else {
        this.comments.splice((page - 1) * this.pageSize, this.pageSize, ...comments);
      }
      this.hasMore = hasMore;
      this.loading = false;
      this.loadingInitial = false;
    },
    canFetch() {
      if (typeof this.query === 'undefined') {
        return false;
      }
      if (!this.themesHierarchy) {
        return false;
      }
      return true;
    },
    fetchInitialComments() {
      if (!this.canFetch()) {
        return;
      }

      this.reset();
      this.loadingInitial = true;
      this.doFetch();
    },
    handlePageChange(page) {
      if (!this.canFetch()) {
        return;
      }

      this.$refs.scrollable.scrollIntoView({
        behavior: 'smooth'
      });
      this.page = page;
      this.doFetch();
    },
    handleLoadMoreClick() {
      if (!this.canFetch()) {
        return;
      }

      analytics.track('Comments: Load more', {
        category: 'Comments',
        label: this.type,
        value: this.page,
      });
      this.page++;
      this.doFetch();
    },
    onCommentThemeSelected(ref, theme) {
      this.$emit('onCommentThemeSelected', theme);
    },
    onCommentSegmentsUpdated(commentId, segments) {

      this.comments.forEach(comment => {
        if (comment.id === commentId) {
          comment.segments = segments;
        }
      });

    },
    onCommentThemeHighlighted(ref, theme) {
      forEach(this.comments, (comment, index) => {
        if (index === ref) {
          comment.themeHover = theme;
        } else {
          comment.themeHover = undefined;
        }
      });
    },
    /**
     * The css class for a given theme to colour it.
     */
    onSortChange(details) {
      if (details.order === 'ascending') {
        details.order = 'asc';
      }
      if (details.order === 'descending') {
        details.order = 'desc';
      }
      this.sortBy = details.column.columnKey;
      this.sortDir = details.order;
      this.fetchInitialComments();
    },
    closeScreenshotPopover() {
      this.screenshotData = undefined;
    },
    noopSort(element, index) {
      if (this.sortDir == 'desc') {
        return -index;
      }
      return index;
    },
    updateTags(commentId, newTags) {
      this.comments.find((c) => c.id === commentId).tags = newTags;
    },
    onSentimentChange(commentId, sentiment, block) {

      const comments = this.comments;

      const nextComments = updateSentimentInThreadedComment(
        comments,
        commentId,
        sentiment,
        block
      );

      this.comments = nextComments;


    },
    showFullConversationDrawer(scope) {
      const index = scope.$index;
      const comment = this.comments[index];
      this.currentFullConversation = comment;
    }
  },
};
</script>

<style lang="scss" scoped>
@import '../../styles/element-variables';
.configure-table {
  padding-left: 0px;
}

.feedback-table {
  &__action {
    text-align: center;
    padding-top: 20px;
  }

  .screenshot-data {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: var(--z-index-feedback-table-screenshot-data);
    background-color: $--tag-info-fill;

    &__content {
      width: 400px;
      margin: auto;
    }
  }
}
</style>

<style lang="scss">
@import '../../styles/element-variables';
// not scoped because the table doesn't append scoped elements
.feedback-table {
  .el-table {
    border-radius: 4px;
    border: 1px solid $--neutral-300;
    width: 100%;
    .edited-comment {
      background-color: $--orange-100;
      td {
        border-left: 1px solid $--orange-200;
        border-bottom: 1px solid $--orange-200;
        .feedback-table__edit-state {
          color: $--orange-700;
          margin-left: 3px;
        }
      }
    }
    th.is-leaf {
      padding: 3px 0px;
      background-color: $--neutral-100;
      border-left: 1px solid $--neutral-300;
      border-bottom: 1px solid $--neutral-300;
      border-right: none;
    }
    th.is-leaf:first-of-type {
      border-left: none;
    }

    th.is-leaf.ascending,
    th.is-leaf.descending {
      background-color: $--neutral-300;
      border: 1px solid $--neutral-300;
    }

    td {
      background-color: inherit !important;
      vertical-align: top;
      text-align: left;
      border-left: 1px solid $--neutral-100;
      border-bottom: 1px solid $--neutral-100;
      border-right: none;
      border-top: none;
    }

    &__row {
      .cell {
        word-break: break-word;
        vertical-align: top;
      }
    }

    .sort-caret.ascending {
      display: none;
    }

    .sort-caret.descending {
      display: none;
    }

    .ascending .sort-caret.ascending {
      border-bottom-color: $--neutral-900;
      display: block;
      top: 8px;
    }

    .descending .sort-caret.descending {
      border-top-color: $--neutral-900;
      display: block;
      bottom: 10px;
    }

    .cell {
      overflow: visible;
    }

    &__header {
      .cell {
        font-size: 14px;
        font-weight: 600;
        color: $--neutral-900;
        word-break: normal;
        overflow-wrap: anywhere;
      }
    }
  }

  .el-table th.feedback-table__themes-column .cell,
  .el-table th.feedback-table__tags-column .cell {
    display: grid;
    align-items: center;
    grid-template-areas: 'label caret manage';
    grid-auto-columns: max-content min-content auto;

    .caret-wrapper {
      grid-area: caret;
    }
  }

  .el-table__body-wrapper {
    overflow: visible;
  }

  &__themes-label {
    grid-area: label;
  }

  &__manage-themes.el-button,
  &__manage-tags.el-button {
    grid-area: manage;
    max-width: 180px;
    margin-left: auto;
  }
}
.el-tooltip__popper.feedback-table-edit-state-tooltip {
  &.is-light[x-placement^=top] {
    border: none;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    line-height: normal;
    font-size: 14px;
    .popper__arrow {
      border-top-color: transparent;
    }
  }
}
</style>
