import * as React from 'react';
import "./similar-sentences.scss";
import { PlainComment, Thread } from 'types/custom';
import { RefinedCluster } from 'lib/refine-clusters';
import { Button } from 'components/Shared/Button';
import { range, take } from 'lodash';
import { Tab } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import analytics from 'lib/analytics';
import { Cluster } from './Cluster';
import { ThemeCodes } from 'stores/ui/AnalysisToolsUIStore';
import { SimilarSentencesProvider } from './similar-sentences.context';
import { withCamelCaseProps } from 'lib/WithCamelCaseProps';
import { ThemesObject } from 'lib/filters/theme-filter-helper';
import { Transform } from 'components/ThemeEditor/theme-file-transforms';
import { useAddThemeToSelection } from './useAddThemeToSelection';
import { ThreadDisplayOptions } from 'api/interfaces';
import { EditedComments } from 'stores/ui/QuickEditUIStore';

type Props = {
  limit: number,
  hasComparisonFilter: boolean,
  baseline: RefinedCluster<PlainComment| Thread>[],
  comparison: RefinedCluster<PlainComment| Thread>[],
  selectedThemeTitle: string,
  selectedThemeCodes: ThemeCodes,
  hasSentiment: boolean,
  canManageThemes: boolean,
  isApplyingThemes: boolean,
  themesHierarchy: ThemesObject | null,
  transforms: Transform[],
  orgId: string,
  shouldShowFullMessages: boolean,
  surveyId: string,
  areSupportMessagesVisible: boolean,
  threadDisplayConfig: ThreadDisplayOptions | null,
  editedComments: EditedComments | null,
};

const SimilarSentences = withCamelCaseProps(({
  limit,
  hasComparisonFilter,
  baseline,
  comparison,
  selectedThemeTitle,
  selectedThemeCodes,
  hasSentiment,
  canManageThemes,
  isApplyingThemes,
  themesHierarchy,
  transforms,
  orgId,
  surveyId,
  shouldShowFullMessages,
  areSupportMessagesVisible,
  threadDisplayConfig,
  editedComments,
}: Props) => {
  const [activeTab, setActiveTab] = React.useState<'baseline'|'comparison'>('baseline');
  const [expandedIndexes, setExpandedIndexes] = React.useState<number[]>([]);
  const [shouldShowAll, setShouldShowAll] = React.useState<boolean>(false);
  const [sentencePath, setSentencePath] = React.useState<[] | [number,number]>([]);
  const addThemeToSelection = useAddThemeToSelection();

  const isEmpty: boolean = activeTab === 'baseline' && baseline.length === 0 ||
    activeTab === 'comparison' && comparison.length === 0;

  const isWithinLimit: boolean = activeTab === 'baseline' && baseline.length < limit ||
    activeTab === 'comparison' && comparison.length < limit;

  const refinedBaseline = take(baseline, shouldShowAll ? Infinity : limit);
  const refinedComparison = take(comparison, shouldShowAll ? Infinity : limit);

  function handleExpanderClick() {
    if (expandedIndexes.length > 0) {
      setExpandedIndexes([]);
      return;
    }
    analytics.track('Summarization: Expand all clusters', {});
    if (activeTab === 'baseline') {
      setExpandedIndexes(range(0, baseline.length));
      return;
    }
    if (activeTab === 'comparison') {
      setExpandedIndexes(range(0, comparison.length));
      return;
    }
  }

  function handleLimiterClick() {
    if (!shouldShowAll) {
      analytics.track('Summarization: Show all clusters', {});
    }
    setShouldShowAll(!shouldShowAll);
  }

  function handleTabChange(index: number) {
    setShouldShowAll(false);

    if (index === 0) {
      setActiveTab('baseline');
    }
    if (index === 1) {
      setActiveTab('comparison');
    }
  }

  function toggleItemExpansion(index: number) {
    const isExpanded = expandedIndexes.includes(index);

    if (!isExpanded) {
      analytics.track('Summarization: Expand cluster', {});
    }

    const nextIndexes = isExpanded
        ? expandedIndexes.filter(i => i !== index)
        : [...expandedIndexes, index]

    setExpandedIndexes(nextIndexes);
  }

  function handleSentenceClick(path: [number, number]) {
    if (sentencePath[0] === path[0] && sentencePath[1] === path[1]) {
      setSentencePath([]);
      return;
    }

    setSentencePath(path);
    analytics.track('Summarization: Show comments', {});
  }

  const panes = [
    { menuItem: 'baseline', },
    { menuItem: 'comparison' }
  ];

  return (
    <SimilarSentencesProvider value={{
      addThemeToSelection,
      areSupportMessagesVisible,
      canManageThemes,
      editedComments,
      hasSentiment,
      isApplyingThemes,
      orgId,
      shouldShowFullMessages,
      surveyId,
      themesHierarchy,
      transforms,
      threadDisplayConfig,
    }}>
      <section className="react-similar-sentences">
        <header className="react-similar-sentences__header">
          <h4 className="react-similar-sentences__heading">Similar sentences</h4>
          {!isEmpty && (
            <Button
              variant="tertiary"
              onClick={handleExpanderClick}
            >
              {expandedIndexes.length > 0 ? 'Collapse all' : 'Expand all'}
            </Button>
          )}
          {hasComparisonFilter && (
            <div className='react-similar-sentences__tabs'
              data-testid="similar-sentences__tabs"

            >
              <Tab
                menu={{ secondary: true, pointing: true }}
                panes={panes}
                onTabChange={(_event, data) => handleTabChange(data.activeIndex as number)}
              />
            </div>
          )}
        </header>
        {activeTab === 'baseline' && baseline.length > 0 && (
          <ul className="react-similar-sentences__cluster" data-testid="baseline-clusters">
            {refinedBaseline.map((item, index) => (
              <li key={`${item.name}--${index}`}>
                <Cluster
                  name={item.name}
                  hasSentiment={hasSentiment}
                  isExpanded={expandedIndexes.includes(index)}
                  toggleExpansion={() => toggleItemExpansion(index)}
                  onSentenceClick={(sentence, subIndex) => handleSentenceClick([index, subIndex])}
                  selectedThemeTitle={selectedThemeTitle}
                  selectedIndex={sentencePath[0] === index ? sentencePath[1] : undefined}
                  sentences={item.sentences}
                  sentiment={item.sentiment}
                  selectedThemeCodes={selectedThemeCodes}
                />
              </li>
            ))}
          </ul>
        )}
        {activeTab === 'comparison' && comparison.length > 0 && (
          <ul className="react-similar-sentences__cluster" data-testid="comparison-clusters">
            {refinedComparison.map((item, index) => (
              <li key={`${item.name}--${index}`}>
                <Cluster
                  name={item.name}
                  hasSentiment={hasSentiment}
                  isExpanded={expandedIndexes.includes(index)}
                  toggleExpansion={() => toggleItemExpansion(index)}
                  onSentenceClick={(sentence, subIndex) => handleSentenceClick([index, subIndex])}
                  selectedThemeTitle={selectedThemeTitle}
                  sentences={item.sentences}
                  sentiment={item.sentiment}
                  selectedThemeCodes={selectedThemeCodes}
                />
              </li>
            ))}
          </ul>
        )}
        {isEmpty && (
          <div className="react-similar-sentences__empty">
            <FontAwesomeIcon icon="exclamation-triangle" />
            <span className="react-similar-sentences__empty-text">No similar sentences found</span>
          </div>
        )}
        {!isEmpty && !isWithinLimit && (
          <div className="react-similar-sentences__show-more">
            <Button
              variant="tertiary"
              onClick={handleLimiterClick}
            >
              {shouldShowAll ? 'Show less' : 'Show more'}
            </Button>
          </div>
        )}
      </section>
    </SimilarSentencesProvider>
  );
});

export { SimilarSentences };
