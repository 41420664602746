import { AnalysisFilter } from 'api/interfaces';
import SingleFilter from 'components/Filters/SingleFilter';
import { filterPopUpId } from 'components/Filters/types';
import { Empty } from 'components/Shared/Empty';
import { Error } from 'components/Shared/Error';
import { ModalActions, ModalContent } from 'components/Shared/Modal';
import * as React from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import { Button } from 'components/Shared/Button';
import { Theme } from 'vue/explore/theme-data-functions';
import downloadResultsHelper from 'vue/libs/download-results';
import { ExportAssistantCategorySection } from './ExportAssistantCategorySection';
import { ExportAssistantCategorySelect } from './ExportAssistantCategorySelect';
import { ExportAssistantColumnSelect } from './ExportAssistantColumnSelect';
import { ExportAssistantConfigSection } from './ExportAssistantConfigSection';
import { ExportAssistantRowSelect } from './ExportAssistantRowSelect';
import { ExportAssistantTable } from './ExportAssistantTable';
import { ExportAssistantDimensionByCategoryTable } from './ExportAssistantTableDimensionsByCategory';
import { ExportAssistantTableThemesByCategory } from './ExportAssistantTableThemesByCategory';
import { tableDimensionByCategoryToCSV, tableThemesByCategoryToCSV, tableThemesToCSV, tableToCSV } from './export-assistant-csv.helper';
import {
  getDimensions,
  getDimensionsByDate,
  getThemes,
  getThemesByDate,
} from './export-assistant-service';
import { useAsyncFunction } from 'lib/useAsyncFunction';
import {
  mapDimensionToTableData,
  mapDimensionsByCategoryToTableData,
  mapThemesByDateToTableData,
  mapThemesToTableData,
} from './export-assistant-table.util';
import './export-assistant.scss';
import {
  AvailableDateRange,
  Category,
  Column,
  Columns,
  ConfigType,
  DateOptions,
  DefaultConfig,
  Dimension,
  DimensionsByCategoryConfig,
  FetchOptions,
  ThemesByCategoryConfig,
  ThemesByDate,
} from './types';
import { applyDateRangePreset, createDateQueryFilter, getAvailableDateRange, isDateFilter } from 'lib/filters/date-filter-helper';
import { QueryFilter } from 'stores/types';

const getDisabledColumns = (rowId: string) => {
  let disabledColumns: Column[] = [];

  if (rowId !== 'themes') {
    disabledColumns.push(Columns.impact);
    disabledColumns.push(Columns.score);
  }

  return disabledColumns;
};

type GetDimensionParams = [selectedDimension: string, fetchOptions: FetchOptions];
type GetDimensionResponse = Dimension[];
type GetDimensionByDateParams = [selectedDimension: string, category: Category, fetchOptions: FetchOptions, availableDateRange: AvailableDateRange];
type GetDimensionByDateResponse = { dateLabels: string[]; dimensions: Dimension[][] };
type GetThemesParams = [FetchOptions];
type GetThemesResponse = { baselineCount: number; themes: Theme[] };
type GetThemesByDateParams = [DateOptions, FetchOptions];
type GetThemesByDateResponse = ThemesByDate;

type TableConfig = DefaultConfig | ThemesByCategoryConfig | DimensionsByCategoryConfig;

interface Rows {
  id: string;
  name: string;
}

interface Props {
  availableDateRange: AvailableDateRange;
  dateIDs: string[];
  fetchOptions: FetchOptions;
  filters: AnalysisFilter[];
  filterTitle: string;
  onClose: () => void;
  onDateSelect: (dateCategory: QueryFilter) => void;
  surveyId: string;
  surveyTitle: string;
}

const ExportAssistant = ({
  availableDateRange,
  dateIDs,
  fetchOptions,
  filters,
  filterTitle,
  onClose,
  onDateSelect,
  surveyId,
  surveyTitle,
}: Props) => {
  const [columns, setColumns] = React.useState<Column[]>([]);
  const [category, setCategory] = React.useState<Category | null>(null);
  const [formIsDirty, setFormIsDirty] = React.useState(false);
  const [rows, setRows] = React.useState<Rows | null>(null);
  const [tableConfig, setTableConfig] = React.useState<TableConfig>();

  const {
    fetch: fetchDimensionsByDate,
    data: dimensionsByDateData,
    error: dimensionsByDateError,
    loading: dimensionsByDateLoading,
  } = useAsyncFunction<GetDimensionByDateResponse, GetDimensionByDateParams>(getDimensionsByDate);

  React.useEffect(() => {
    if (!dimensionsByDateData || !rows || !category?.dateOptions) {
      return;
    }

    const { dateLabels, dimensions } = dimensionsByDateData;

    const hasResults = dimensions.every((dimension) => !!dimension);

    const tableData = hasResults ? mapDimensionsByCategoryToTableData(dimensions) : null;

    setTableConfig({
      columnOneTitle: rows?.name,
      categoryLabels: dateLabels,
      filterTitle,
      surveyTitle,
      tableData,
      type: ConfigType.DIMENSIONS_BY_CATEGORY,
    });
  }, [category?.dateOptions, dimensionsByDateData, filterTitle, rows, surveyTitle]);

  const {
    fetch: fetchDimensions,
    data: dimensionsData,
    error: dimensionsError,
    loading: dimensionsLoading,
  } = useAsyncFunction<GetDimensionResponse, GetDimensionParams>(getDimensions);

  React.useEffect(() => {
    if (!dimensionsData) {
      return;
    }

    const hasResults = dimensionsData.every((dimension) => !!dimension);

    const tableData = hasResults ? mapDimensionToTableData(dimensionsData) : null;

    const tableConfig: DefaultConfig = {
      columnOneTitle: 'Categories',
      filterTitle,
      surveyTitle,
      tableData,
      type: ConfigType.DIMENSIONS
    };

    setTableConfig(tableConfig);
  }, [dimensionsData, filterTitle, surveyTitle]);

  const {
    fetch: fetchThemes,
    data: themesData,
    error: themesError,
    loading: themesLoading,
  } = useAsyncFunction<GetThemesResponse, GetThemesParams>(getThemes);

  React.useEffect(() => {
    if (!themesData) {
      return;
    }

    const { baselineCount, themes } = themesData;

    const hasResults = themes.every((theme) => !!theme);

    const tableData = hasResults ? mapThemesToTableData(themes) : null;

    const tableConfig: DefaultConfig = {
      columnOneTitle: 'Theme',
      columnTwoTitle: 'Subtheme',
      filterTitle,
      surveyTitle,
      baselineCount,
      tableData,
      type: ConfigType.THEMES
    };

    setTableConfig(tableConfig);
  }, [filterTitle, surveyTitle, themesData]);

  const {
    fetch: fetchThemesByDate,
    data: themesByDateData,
    error: themesByDateError,
    loading: themesByDateLoading,
  } = useAsyncFunction<GetThemesByDateResponse, GetThemesByDateParams>(getThemesByDate);

  React.useEffect(() => {
    if (!themesByDateData) {
      return;
    }

    const hasResults = themesByDateData.themes.every((theme) => !!theme);

    const { dateLabels, themes } = hasResults ? mapThemesByDateToTableData(themesByDateData) : { dateLabels: [], themes: null };

    const tableByCategoryConfig: ThemesByCategoryConfig = {
      filterTitle,
      columnOneTitle: 'Theme',
      columnTwoTitle: 'Subtheme',
      categoryLabels: dateLabels,
      tableData: themes,
      surveyTitle,
      type: ConfigType.THEMES_BY_CATEGORY,
    };

    setTableConfig(tableByCategoryConfig);
  }, [filterTitle, surveyTitle, themesByDateData]);

  const resetTables = () => {
    setTableConfig(undefined);
  };

  React.useEffect(() => {
    if (rows || category) {
      setFormIsDirty(true);
      resetTables();
    }
  }, [rows, category]);

  const handleUpdate = async () => {
    resetTables();
    setFormIsDirty(false);

    const rowsAreThemes = rows?.id === 'themes';

    if (!fetchOptions) {
      return;
    }

    if (rowsAreThemes && category?.dateOptions) {
      fetchThemesByDate(category.dateOptions, fetchOptions);

      return;
    }

    if (rowsAreThemes) {
      fetchThemes(fetchOptions);

      return;
    }

    if (rows?.id && !rowsAreThemes && category?.dateOptions) {
      fetchDimensionsByDate(rows.id, category, fetchOptions, availableDateRange);

      return;
    }

    if (rows?.id) {
      fetchDimensions(rows.id, fetchOptions);
    }
  };

  const getCSV = () => {
    try {
      if (!tableConfig) {
        return null;
      }

      const tableType = tableConfig?.type;

      if (tableType == ConfigType.THEMES) {
        return tableThemesToCSV(columns, tableConfig);
      }

      if (tableType === ConfigType.DIMENSIONS) {
        return tableToCSV(columns, tableConfig);
      }

      if (tableType === ConfigType.THEMES_BY_CATEGORY) {
        return tableThemesByCategoryToCSV(columns, tableConfig);
      }

      if (tableType === ConfigType.DIMENSIONS_BY_CATEGORY) {
        return tableDimensionByCategoryToCSV(columns, tableConfig);
      }

      return null;
    } catch {
      return null;
    }
  }

  const downloadTable = () => {
    const csv = getCSV();

    if (!csv) {
      return;
    }

    downloadResultsHelper.downloadArray(csv.csvData, csv.fileName);
  };

  const handleUpdateCategory = (categoryUpdate: Category) => {
    setCategory(categoryUpdate);

    if (categoryUpdate.type === 'date') {
      const dateFilter = filters.find(filter => filter.id === categoryUpdate.id);

      if (!dateFilter || !isDateFilter(dateFilter)) {
        return;
      }

      const { maxDate, minDate } = getAvailableDateRange(dateFilter);
      const { startDate, endDate } = applyDateRangePreset('all', minDate, maxDate);

      const dateQueryFilter = createDateQueryFilter(
        dateFilter,
        'baseline',
        startDate,
        endDate
      );

      onDateSelect(dateQueryFilter);
    }
  }

  const formIsInvalid = () => {
    return !rows || columns.length < 1;
  }

  const disabledColumns = rows ? getDisabledColumns(rows.id) : [];

  const isLoading = dimensionsLoading || themesLoading || themesByDateLoading || dimensionsByDateLoading;

  const isEmpty = !tableConfig && !isLoading;

  const hasError = dimensionsError || themesError || themesByDateError || dimensionsByDateError;

  const disableDownload = !tableConfig?.tableData || isLoading;

  const disableUpdate = formIsInvalid() || isLoading || !formIsDirty;

  const handleRowChange = (row: Rows) => {
    setColumns([]);
    setRows(row);
  }

  return (
    <>
      <ModalContent>
        <div className="export-assistant">
          <div className="export-assistant-sidebar">
            <h2 className="export-assistant-sidebar__title">Define criteria</h2>
            <ExportAssistantConfigSection title="Filter">
              <SingleFilter
                testId="export-assistant-filter"
                filterPopUpId={filterPopUpId.ExportAssistant}
                surveyId={surveyId}
                onChange={() => setFormIsDirty(true)}
              />
            </ExportAssistantConfigSection>
            <ExportAssistantConfigSection title="Rows">
              <ExportAssistantRowSelect filters={filters} onRowChange={handleRowChange} rows={rows} />
            </ExportAssistantConfigSection>
            <ExportAssistantConfigSection title="Columns">
              <ExportAssistantColumnSelect
                disabledColumns={disabledColumns}
                columns={columns}
                setColumns={setColumns}
              />
            </ExportAssistantConfigSection>
            <ExportAssistantConfigSection title="Group by">
              <div className="export-assistant__category-select">
                <ExportAssistantCategorySelect
                  enabledIDs={dateIDs}
                  filters={filters}
                  category={category}
                  setCategory={setCategory}
                />
              </div>
              {category && (
                <ExportAssistantCategorySection
                  availableDateRange={availableDateRange}
                  category={category}
                  updateCategory={handleUpdateCategory}
                />
              )}
            </ExportAssistantConfigSection>
            <div className="export-assistant-sidebar__button">
              <Button
                block={true}
                disabled={disableUpdate}
                onClick={handleUpdate}
                variant="primary"
              >
                Update
              </Button>
            </div>
          </div>
          <div className="export-assistant__content">
            {(tableConfig?.type === ConfigType.DIMENSIONS || tableConfig?.type === ConfigType.THEMES) && !isLoading && (
              <>
                <header className="export-assistant__table-header">
                  <h3>Preview</h3>
                </header>
                <ExportAssistantTable columns={columns} tableConfig={tableConfig} />
              </>
            )}
            {tableConfig?.type === ConfigType.THEMES_BY_CATEGORY && !isLoading && (
              <>
                <header className="export-assistant__table-header">
                  <h3>Preview</h3>
                </header>
                <ExportAssistantTableThemesByCategory columns={columns} tableConfig={tableConfig} />
              </>
            )}
            {tableConfig?.type === ConfigType.DIMENSIONS_BY_CATEGORY && !isLoading && (
              <>
                <header className="export-assistant__table-header">
                  <h3>Preview</h3>
                </header>
                <ExportAssistantDimensionByCategoryTable
                  columns={columns}
                  tableConfig={tableConfig}
                />
              </>
            )}
            {isLoading && (
              <div className="export-assistant__loader">
                <Dimmer active={true} inverted={true}>
                  <Loader active={true} size="large" inverted />
                </Dimmer>
              </div>
            )}
            {isEmpty && !hasError && (
              <Empty title="Define the criteria for your download">
                <p className="empty-state__description">You can create a custom table of data by selecting filters,</p>
                <p className="empty-state__description">columns, rows and groups and download it from here.</p>
              </Empty>
            )}
            {tableConfig && !tableConfig.tableData && !isLoading && !hasError && (
              <Empty title="No results" icon="open-box">
                <p className="empty-state__description">There were no results for the selected options</p>
              </Empty>
            )}
            {hasError && !isLoading && !tableConfig && (
              <Error
                title="An unknown error occurred"
                description="There is no data available for the selected criteria."
                tracking={{
                  event: "Error: Export Assistant",
                  eventOptions: {
                    "title": "An unknown error occurred",
                    "description": "There is no data available for the selected criteria."
                  }
                }}
              />
            )}
          </div>
        </div>
      </ModalContent>
      <ModalActions>
        <Button onClick={onClose} variant="secondary">
          Cancel
        </Button>
        <Button onClick={downloadTable} variant="primary" disabled={disableDownload}>
          Download
        </Button>
      </ModalActions>
    </>
  );
};

export { ExportAssistant };
