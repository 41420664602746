<template>
  <widget-wrapper
    :title="title"
    :loading="loading"
    :error="error"
    :warning="warning"
    :panel-order="panelOrder"
    :widget-order="widgetOrder"
  >
    <div
      slot="subtitle"
      :class="{ 'widget-subtitle-clickable': canNavigate }"
      class="widget-subtitle"
      @click="doNavigate()"
    >
      <div class="widget-subtitle-text">
        {{ subtitle }}
        <font-awesome-icon
          v-if="canNavigate"
          class="link-icon"
          icon="chart-bar"
        />
      </div>
    </div>
    <div
      :class="{ empty: empty }"
      class="score-graph-container widget-body"
    >
      <line-graph
        v-if="!empty"
        :values="values"
        :labels="labels"
      />
      <div v-else>
        No data found
      </div>
    </div>
  </widget-wrapper>
</template>

<script>
import LineGraph from './LineGraph.vue';
import CanNavigateMixin from 'vue/dashboards/Dashboard/MixIns/CanNavigateMixin';

import WidgetWrapper from '../WidgetWrapper';

export default {
  name: 'OVERTIMEGRAPH',
  components: {
    'line-graph': LineGraph,
    'widget-wrapper': WidgetWrapper,
  },
  mixins: [CanNavigateMixin('over-time')],
  props: {
    error: { type: String },
    warning: { type: String },
    periodResolution: { type: String },
    labels: { type: Array },
    loading: { type: Boolean },
    scoreName: { type: String },
    source: { type: String },
    title: { type: String },
    values: { type: Array },
    panelOrder: { type: Number },
    widgetOrder: { type: Number },
  },
  computed: {
    empty() {
      return !this.values || !this.values.length;
    },
    numPeriods() {
      return this.labels.length;
    },
    periodName() {
      if (this.periodResolution === 'rolling90') {
        return 'periods';
      } else if (this.periodResolution === 'quarterly') {
        return 'quarters';
      } else if (this.periodResolution === 'monthly') {
        return 'months';
      } else if (this.periodResolution === 'weekly') {
        return 'weeks';
      } else if (this.periodResolution === 'daily') {
        return 'days';
      }
      return undefined;
    },
    subtitle() {
      let title = `Change in ${this.scoreName}`;
      if (this.periodName) {
        title += ` over last ${this.numPeriods} ${this.periodName}`;
      }
      return title;
    },
  },
};
</script>

<style lang="scss" scoped>
.empty {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

