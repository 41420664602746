import { remove } from 'lodash';
import { action, computed, observable } from 'mobx';
import NOTIFICATION_ICONS from '../components/Notification/icons';

type NotificationIcon = keyof typeof NOTIFICATION_ICONS;

export type Notification = {
  id: string, // string id for this notification. Does NOT have to be unique
  preventClose?: boolean;
  icon?: NotificationIcon;
  title?: string;
  link?: boolean;
  message: string;
  action?: () => void;
  timeout?: number; // number of seconds to show the notification before dismissing
};

export interface NotificationStoreInterface {
  current: Notification | null;
  notifications: Notification[];
  clear: () => void;
  dismiss: (notificationId: string) => void;
  push: (notification: Notification) => void;
}

class NotificationStore implements NotificationStoreInterface {
  @observable
  notifications: Notification[] = [];

  @computed
  get current(): Notification | null {
    return this.notifications.length > 0 ? this.notifications[0] : null;
  }

  @action
  clear = () => {
    this.notifications = [];
  }

  @action
  dismiss = (notificationId: string) => {
    remove(this.notifications, (n) => n.id === notificationId);
  };

  @action
  push = (notification: Notification) => {
    this.notifications = [notification, ...this.notifications];
    // if the notification has a timeout, dismiss it after that time
    if (notification.timeout) {
      setTimeout(() => this.dismiss(notification.id), notification.timeout * 1000);
    }
  };
}

export default NotificationStore;
