import { includes, transform } from 'lodash';

export const hasOwn = Object.prototype.hasOwnProperty;

/*
  * Performs a deep schema comparison of the given 2 objects and returns true if
    all the keys present in the object 2 are in object 1, otherwise returns false
  * Only checks the keys and its types, not the values
*/
export function isASubset (o1: Object | undefined, o2: Object | undefined) {
  // Both nulls = true
  if (o1 === undefined && o2 === undefined) {
    return true;
  }

  // o2 is undefined, so it has nothing more than o1
  if (o2 === undefined) {
    return true;
  }

  // Get the keys of each object
  const o1keys = Object.keys(o1 || {});
  const o2keys = Object.keys(o2);

  // at this point o2 is not undefined, so if o1 is undefined we return true
  if (o1 === undefined) {
    if (o2keys.length === 0) {
      // o2 has no keys, so true
      return true;
    } else {
      // o2 has keys, so has more than o1, so false
      return false;
    }
  }

  if (o2keys.length > o1keys.length) {
    // o2 has extra keys than o1
    return false;
  }

  // At this point, one of two things is true:
  // A) `o1` and `o2` are both `!undefined`, or
  // B) One of them is `undefined` and the other has own "own" properties
  // The logic below relies on the fact we only try to use `o1` or
  // `o2` if there's at least one entry in `o2keys`, which we won't
  // given the guarantee above.

  // Check that the keys match and recurse into nested objects as necessary
  return o2keys.every(key => {
      if (!hasOwn.call(o1, key)) {
          // Different keys
          return false;
      }
      // Get the values and their types
      const v1 = o1[key];
      const v2 = o2[key];
      const t1 = typeof v1;
      const t2 = typeof v2;

      if (t1 === t2 && t1 !== 'object') {
          return true;
      }

      if (!isASubset(v1, v2)) {
        return false;
      }

      return true;
  });
}

/*
  Transforms a given object to conform the structure provided recursively
  Keys not present in the structure will be ignored in the transformed object
*/
export const transformToMatchStructure = (data: any, structure: Object) => {
  const keys = Object.keys(data);

  return transform(structure, (result: Object, _value: any, key: string) => {
    if (includes(keys, key)) {
      if (data[key] instanceof Array) {
        /* If value is an array match it to the first element in the structure */
        let transformedArray = [] as Object[];
        data[key].forEach(item => {
          transformedArray.push(transformToMatchStructure(item, structure[key][0]));
        });
        result[key] = transformedArray;
      } else if (data[key] instanceof Object) {
        result[key] = transformToMatchStructure(data[key], structure[key]);
      } else {
        result[key] = data[key];
      }
      return result;
    }
    return;
  });
};