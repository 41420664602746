<template>
  <widget-wrapper
    :title="config.title"
    :error="error"
    :loading="loading"
    class="dashboard-widget"
    :warning="warning"
    :panel-order="panelOrder"
    :widget-order="widgetOrder"
  >
    <div
      slot="subtitle"
      class="widget-subtitle"
    >
      <div class="widget-subtitle-text">
        {{ subtitle }}
      </div>
    </div>
    <div class="widget-body">
      <div class="counts">
        <div class="count overall">
          <h2>{{ overallCount }}</h2>
          <legend>{{ mainLabel }}</legend>
        </div>
        <div
          v-if="hasComparison"
          class="count comparison"
        >
          <h2>{{ comparisonCount }}</h2>
          <legend>{{ comparisonName }}</legend>
        </div>
      </div>
    </div>
  </widget-wrapper>
</template>

<script>
import { compact, get, isNumber } from 'lodash';
import toLocaleString from 'vue/libs/to-locale-string';

import WidgetWrapper from 'vue/dashboards/Dashboard/Widgets/WidgetWrapper.vue';

export default {
  name: 'METADATA',
  components: {
    WidgetWrapper,
  },
  props: {
    config: { type: Object },
    context: { type: Object },
    data: { type: Object },
    filterNames: { type: String },
    filterString: { type: String },
    warning: { default: undefined, type: String },
    error: { type: String },
    loading: { type: Boolean },
    source: { type: String },
    panelOrder: { type: Number },
    widgetOrder: { type: Number },
  },
  computed: {
    comparisonCount() {
      return toLocaleString(get(this, 'data.compareCount'));
    },
    comparisonName() {
      const filters = [];
      if (this.filterString) {
        filters.push(this.filterNames);
      }
      const compareFilter = get(this, 'context.compareFilterName');
      if (compareFilter) {
        filters.unshift(compareFilter);
      }
      return compact(filters).join(', ');
    },
    currentPeriodName() {
      return get(this, 'data.currentPeriodName');
    },
    hasComparison() {
      return isNumber(this.comparisonCount);
    },
    mainLabel() {
      if (this.hasComparison || !this.comparisonName) {
        return '';
      } else {
        return `${this.comparisonName}`;
      }
    },
    overallCount() {
      return toLocaleString(get(this, 'data.count', 0));
    },
    subtitle() {
      let title = 'Total number of responses';
      if (this.currentPeriodName) {
        title += ` in ${this.currentPeriodName}`;
      }
      return title;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
