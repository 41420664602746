import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ThemeDiscovery from 'components/ThemeDiscovery/ThemeDiscovery';
import ExampleComments from 'components/ThemeEditor/ExampleComments';
import MappedPhrases from 'components/ThemeEditor/MappedPhrases';
import MappedPhrasesSuggestionButton from 'components/ThemeEditor/MappedPhrasesSuggestionButton';
import MergedTheme from 'components/ThemeEditor/MergedTheme';
import analytics from 'lib/analytics';
import { generateExampleComments } from 'lib/generate-example-comments';
import { ThemeGroup } from 'lib/theme-file-parser';
import { map, sortBy } from 'lodash';
import { reaction } from 'mobx';
import { disposeOnUnmount, inject, observer } from 'mobx-react';
import * as React from 'react';
import { Accordion, Button, Input, List, Segment } from 'semantic-ui-react';
import { SelectedTheme } from 'stores/ThemeDiscoveryStore';
import { ThemeTreeItem, ThemesStoreInterface } from 'stores/ThemesStore';
import './theme-info.scss';
import { compose } from 'lib/composeHOCs';
import { ThemeEditorSessionStoreInterface } from 'stores/ThemeEditorSessionStore';

const MERGED_THEMES_SECTION = 'merged_themes';
const MAPPED_PHRASES_SECTION = 'mapped_phrases';
const EXAMPLE_COMMENTS_SECTION = 'example_comments';

export interface ThemeInfoProps {
  orgId: string;
  surveyId: string;
  group: ThemeGroup;
}

interface ThemeInfoState {
  exampleComments: string[];
  processing: boolean;
  processError: boolean;
  activeSections: string[];
  showThemesDiscovery: boolean;
}

interface InjectedProps {
  themesStore: ThemesStoreInterface;
  themeEditorSessionStore: ThemeEditorSessionStoreInterface;
}

const withHocs = compose(
  inject('themesStore', 'themeEditorSessionStore'),
  observer,
);

export default withHocs(class ThemeInfo extends React.Component<
  ThemeInfoProps,
  ThemeInfoState
> {
  state = {
    exampleComments: [],
    processing: false,
    processError: false,
    activeSections: [],
    showThemesDiscovery: false
  } as ThemeInfoState;

  get injected(): InjectedProps {
    return this.props as ThemeInfoProps & InjectedProps;
  }
  get activeNode(): ThemeTreeItem | undefined {
    const { themesStore: store } = this.injected;
    return store.getActiveNode(this.props.group);
  }
  componentDidMount() {
    this.setState({ activeSections: [EXAMPLE_COMMENTS_SECTION] });

    disposeOnUnmount(
      this,
      reaction(() => this.activeNode, this.updateActiveNode, {
        fireImmediately: true
      })
    );
    // find comments from the pre-loaded set
  }

  getSelectedThemeInfo = (group, activeNode) => {
    if (!activeNode) {
      return;
    }

    const { themesStore: store } = this.injected;

    let selectedTheme: SelectedTheme;

    // If active node has no children, there is a high chance its a child, so try to find its parent
    if (!activeNode.children) {
      const theme = store.findParentId(group.id, activeNode.id);
      if (theme) {
        selectedTheme = {
          theme,
          subtheme: activeNode.id
        };
        return selectedTheme;
      }
    }

    selectedTheme = {
      theme: activeNode?.id,
      subtheme: null
    };

    return selectedTheme;
  };
  updateActiveNode = async () => {
    const { activeNode } = this;
    const { group } = this.props;

    if (!activeNode) {
      return;
    }

    group.proposedNodeTitle = String(activeNode.title);
    const phrases = [...activeNode.phrases];

    this.setState({ exampleComments: [], processing: true, processError: false });
    const exampleComments = await generateExampleComments(phrases);
    this.setState({ exampleComments, processing: false });
  };
  handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { activeNode } = this;
    const { group } = this.props;
    if (!activeNode) {
      return;
    }
    if (e.key === 'Escape') {
      const title = String(activeNode.title);
      group.proposedNodeTitle = title;
    } else if (e.key === 'Enter') {
      this.confirmTitle();
    }
  };
  handleTitleBlur = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const group = this.props.group;
    const currentTitle = this.injected.themesStore.getActiveNode(group)?.title.trim() ?? '';
    const nextTitle = e.currentTarget.value.trim();

    // Only make a transformation when the title has changed
    if (nextTitle !== currentTitle) {
      this.confirmTitle();
    }

  };
  handlePhraseKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { group } = this.props;
    if (e.key === 'Escape') {
      group.proposedPhrase = '';
    } else if (e.key === 'Enter') {
      this.confirmPhrase();
    }
  };
  addPhrases = (proposedPhrases: string[]) => {
    // handle the addition of multiple phrases
    const { group } = this.props;
    const { themesStore: store } = this.injected;
    const node = store.getActiveNode(group);
    if (node && proposedPhrases.length > 0) {
      store.addPhrases(group, node, proposedPhrases);
    }
  };
  confirmPhrase = () => {
    // handle the entry of a single proposed phrase through the textbox
    const { group } = this.props;
    const { themeEditorSessionStore } = this.injected;
    const { proposedPhrase } = group;
    this.addPhrases([proposedPhrase.toLowerCase()]);

    themeEditorSessionStore.addEvent({
      type: 'Addition',
      subType: 'AddMappedPhrase',
      timestamp: Date.now()
    });

    group.proposedPhrase = '';
  };
  confirmTitle = () => {
    const { group } = this.props;
    const {
      themesStore: store,
      themeEditorSessionStore
    } = this.injected;

    const activeNode = store.getActiveNode(group);
    if (activeNode) {
      activeNode.title = group.proposedNodeTitle;
    }
    store.updateThemeTitle(group);
    themeEditorSessionStore.addEvent({
      type: 'Modify',
      subType: 'RenameTheme',
      timestamp: Date.now()
    });
  };
  updateProposedPhrase = (e: React.FormEvent<HTMLInputElement>) => {
    const { group } = this.props;
    const { value } = e.currentTarget;
    group.proposedPhrase = value;
  };
  updateProposedTitle = (e: React.FormEvent<HTMLInputElement>) => {
    const { group } = this.props;
    const { value } = e.currentTarget;
    group.proposedNodeTitle = value;
  };
  renderMerged = () => {
    const { activeNode } = this;
    const { group } = this.props;
    if (activeNode && activeNode.merged.length) {
      const merged = sortBy(activeNode.merged, node => node.title);
      return map(merged, node => {
        return (
          <MergedTheme
            key={node.id}
            group={group}
            id={node.id}
            isNew={node.isNew}
            title={node.title}
          />
        );
      });
    } else {
      return [];
    }
  };
  handleAccordionClick = (_e, titleProps) => {
    const { index } = titleProps;
    const { activeSections } = this.state;
    let newActiveSections = [...activeSections];
    const currentSectionIndex = activeSections.indexOf(index);

    if (currentSectionIndex > -1) {
      newActiveSections.splice(currentSectionIndex, 1);
    } else {
      newActiveSections.push(index);
    }
    this.setState({ activeSections: newActiveSections });
  }
  getThemeSections = () => {
    const { activeNode } = this;
    const { orgId, surveyId, group } = this.props;
    const { exampleComments, processing, processError } = this.state;
    const { proposedPhrase } = group;
    const merged = this.renderMerged();
    let sections;

    if (activeNode) {
      sections = [
        {
          key: MAPPED_PHRASES_SECTION,
          title: 'Mapped phrases',
          content: <>
            <MappedPhrases activeNode={activeNode} group={group} />
            <div className="theme-info__input">
              <Input
                action={
                  proposedPhrase.length > 0 ? (
                    <Button onClick={this.confirmPhrase}>
                      <FontAwesomeIcon icon="check" className="icon" />
                    </Button>
                  ) : (
                    <Button>
                      <FontAwesomeIcon icon="plus" className="icon" />
                    </Button>
                  )
                }
                fluid={true}
                size="small"
                value={proposedPhrase}
                onChange={this.updateProposedPhrase}
                onKeyDown={this.handlePhraseKeyDown}
                placeholder="Enter new phrase"
              />
              <MappedPhrasesSuggestionButton
                orgId={orgId}
                surveyId={surveyId}
                activeNode={activeNode}
                addPhrases={this.addPhrases} />
            </div>
          </>
        },
        {
          key: EXAMPLE_COMMENTS_SECTION,
          title: 'Example comments',
          content: <ExampleComments
            comments={exampleComments}
            phrases={activeNode.phrases}
            processing={processing}
            processerror={processError}
            istruncated={false}
          />
        }
      ];

      if (merged.length) {
        sections.unshift(
          {
            key: MERGED_THEMES_SECTION,
            title: 'Merged themes',
            content: <List className="merged-themes">{merged}</List>
          }
        );
      }
    }

    return sections;
  }

  onDiscoverSimilarThemesClick = () => {
    this.setState({ showThemesDiscovery: true });

    analytics.track('Theme Discovery: Open discover themes', {
      category: 'Theme Discovery',
      survey: this.props.surveyId
    });
  }

  render() {
    const { activeNode } = this;
    const { group, orgId, surveyId } = this.props;
    const { activeSections, showThemesDiscovery } = this.state;
    const { proposedNodeTitle } = group;
    const { toggleThemeInfo, showThemeInfo } = this.injected.themesStore;
    const selectedTheme = this.getSelectedThemeInfo(group, activeNode);

    if (!showThemeInfo || !activeNode) {
      return null;
    }
    const sections = this.getThemeSections();

    return (
      <Segment className="theme-editor__info theme-info">
        <div className="theme-info__header">
          <Input
            action={
              activeNode.title !== proposedNodeTitle ? (
                <Button onClick={this.confirmTitle}>
                  <FontAwesomeIcon icon="check" className="icon" />
                </Button>
              ) : (
                <Button onClick={this.confirmTitle}>
                  <FontAwesomeIcon icon="pencil" className="icon" />
                </Button>
              )
            }
            className="edit-in-place ob-theme-name-editable"
            onChange={this.updateProposedTitle}
            onKeyDown={this.handleKeyDown}
            onBlur={this.handleTitleBlur}
            size="small"
            value={proposedNodeTitle}
          />
          <div>
            <Button
              onClick={this.onDiscoverSimilarThemesClick}
              size="small"
            >
              Discover similar themes
            </Button>
            {showThemesDiscovery && <ThemeDiscovery
              orgId={orgId}
              surveyId={surveyId}
              initialOptions={{ selectedTheme }}
              close={() => this.setState({ showThemesDiscovery: false })}
            />}
            <Button size="small" className="hide-theme-info-cta" onClick={() => toggleThemeInfo(false)}>
              <FontAwesomeIcon className="icon" size="sm" icon="times" />
              Close
            </Button>
          </div>
        </div>
        <Accordion>
          {sections.map(section => {
            const isActive = activeSections.includes(section.key);
            return (
              <div
                key={section.key}
                className="theme-info__sub-section"
              >
                <Accordion.Title
                  active={isActive}
                  index={section.key}
                  onClick={this.handleAccordionClick}
                >
                  {isActive && <FontAwesomeIcon icon="chevron-down" />}
                  {!isActive && <FontAwesomeIcon icon="chevron-right" />}
                  {section.title}
                </Accordion.Title>
                <Accordion.Content
                  active={isActive}
                >
                  {section.content}
                </Accordion.Content>
              </div>
            );
          })}
        </Accordion>
        <div className="theme-info__theme-code">
          Theme code: <p className="theme-info__theme-code-id">{activeNode.id}</p>
        </div>
      </Segment>
    );
  }
});
