<template>
  <div
    v-if="areCommentsLoading"
    v-loading="areCommentsLoading"
    element-loading-spinner="el-icon-loading"
    class="explore-tool-selection-detail-loader"
    data-testid="selection-detail"
  />
  <div
    v-else
    class="count-tooltip"
    data-testid="selection-detail"
  >
    <div @click="toggleTooltip($event)">
      <span v-if="selectedScore"> {{ selectedScore.name }} = {{ score }} • </span>
      <span :class="{ warning: showWarning }">{{ totalCountString }}</span>
    </div>
    <el-tooltip
      v-model="showing"
      :manual="manualTooltip"
    >
      <div
        ref="tooltipIcon"
        @click="toggle($event)"
      >
        <font-awesome-icon
          v-if="!showWarning"
          icon="info-circle"
        />
        <span
          v-if="errorMessage || showWarning"
          class="error-message"
          :class="{ warning: showWarning }"
        >
          <font-awesome-icon icon="exclamation-triangle" />
          {{ errorMessage }}
        </span>
      </div>
      <div slot="content">
        <span v-if="showWarning">
          <div
            v-if="showWarning"
            class="low-data-warning"
          >
            <p class="low-data-warning-message">
              <font-awesome-icon
                class="icon"
                icon="exclamation-triangle"
              />
              Caution: small sample size
            </p>
          </div>
        </span>
        <span v-if="selectedScore">
          <div class="detail-desc">{{ scoreCountString }} with score</div>
        </span>
        <hr v-if="showWarning || selectedScore">
        <div class="detail-desc">
          {{ commentCountString }}
        </div>
        <br>
        <div class="detail-number">
          {{ meaningfulCountString }}
        </div>
        <div class="detail-desc">
          meaningful
        </div>
        <br>
        <div class="detail-number">
          {{ taggedCountString }}
        </div>
        <div class="detail-desc">
          tagged
        </div>
        <br>
      </div>
    </el-tooltip>
  </div>
</template>

<script>
import toLocaleString from 'vue/libs/to-locale-string';
import toFixed from 'vue/libs/to-fixed';
import {
  totalCountFromCountsObject,
  nonemptyCountFromCountsObject,
  meaningfulCountFromCountsObject,
  taggedCountFromCountsObject,
} from 'vue/libs/counts';

export default {
  name: 'ExploreToolSelectionDetail',
  props: {
    manualTooltip: { default: true, type: Boolean },
    selectedScore: { default: undefined, type: Object },
    score: { default: undefined, type: String },
    scoredResponses: { default: undefined, type: Number },
    counts: { default: undefined, type: Object },
    errorMessage: { default: undefined, type: String },
    areCommentsLoading: {default: false, type: Boolean}
  },
  data() {
    return {
      showing: false,
    };
  },
  computed: {
    totalCountString() {
      const { counts } = this;
      return toLocaleString(totalCountFromCountsObject(counts), 'response', 'responses');
    },
    scoreCountString() {
      return toLocaleString(this.scoredResponses, 'response', 'responses');
    },
    commentCountString() {
      const { counts } = this;
      return toLocaleString(nonemptyCountFromCountsObject(counts), 'comment', 'comments');
    },
    meaningfulCountString() {
      const { counts } = this;
      const total = nonemptyCountFromCountsObject(counts);
      const meaningful = meaningfulCountFromCountsObject(counts);
      return toFixed((meaningful / total) * 100, 1, '%');
    },
    taggedCountString() {
      const { counts } = this;
      const total = meaningfulCountFromCountsObject(counts);
      const themed = taggedCountFromCountsObject(counts);
      return toFixed((themed / total) * 100, 1, '%');
    },
    showWarning() {
      const { counts } = this;
      return nonemptyCountFromCountsObject(counts) < 100;
    },
  },
  mounted() {
    document.addEventListener('click', this.hide);
  },
  destroyed() {
    document.addEventListener('click', this.hide);
  },
  methods: {
    hide() {
      this.showing = false;
    },
    toggle(e) {
      if (this.manualTooltip) {
        e.stopPropagation();
        this.showing = !this.showing;
      }
    },
    toggleTooltip(e) {
      e.stopPropagation();
      this.$refs.tooltipIcon.click();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../styles/element-variables.scss';
.explore-tool-selection-detail-loader {
  ::v-deep .el-loading-spinner {
    margin-top: 0;
    text-align: right;
    i {
      color: $--color-text-primary;
    }
  }
}

.count-tooltip {
  display: flex;
  gap: 0.5rem;
}

.detail-number {
  font-size: 1rem;
  display: inline-block;
  text-align: right;
  min-width: 50px;
  margin-right: 5px;
  margin-top: 5px;
}

.detail-desc {
  font-size: 1rem;
  display: inline-block;
  margin-block: 0.35em;
}

.warning {
  color: $--red-700;
}

.low-data-warning {
  color: $--neutral-100;
  font-size: 1rem;
  margin-bottom: 0.35em;
  padding: 0.5em;
  border-radius: 0.3em;
  background-color: $--red-700;

  display: flex;
  justify-content: space-between;
  align-items: center;

  .icon {
    color: currentColor;
    margin: unset;
    font-size: 1rem;
  }

  p {
    color: currentColor;
    margin: unset;
    margin-right: auto;
  }
}

hr {
  border-color: white;
}
</style>
