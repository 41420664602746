import { AnswersAuditRecord } from 'api/interfaces';
import analytics from 'lib/analytics';
import { compose } from 'lib/composeHOCs';
import { debounce, isEmpty } from 'lodash';
import { inject, observer } from 'mobx-react';
import { parse, stringify } from 'query-string';
import * as React from 'react';
import { withErrorBoundary } from 'react-error-boundary';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  Checkbox,
  CheckboxProps,
  FormInputProps,
  Input, Loader,
  Menu,
  Pagination,
  PaginationProps,
  Table
} from 'semantic-ui-react';
import { AnswerAuditError, AnswersAuditStoreInterface, AuditAnswersPageRequestParams } from 'stores/AnswersAuditStore';
import { OrganizationStoreInterface } from 'stores/OrganizationStore';
import './audit-answers.scss';
import { BoundaryError } from './components/BoundaryError';
import { Button } from 'components/Shared/Button';
import { formatDateWithTime } from 'lib/date';
import { Tooltip } from 'components/Shared/Tooltip';
import { Error } from 'components/Shared/Error';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Empty } from 'components/Shared/Empty';
import Analyze from 'images/icons/icon-analyze.svg';
import classNames from 'classnames';

enum Direction {
  ASC = 'ascending',
  DESC = 'descending'
}

type Props = {
  answersAuditStore: AnswersAuditStoreInterface,
  organizationStore: OrganizationStoreInterface
} & RouteComponentProps<{ orgId: string }>;

type State = {
  selectedRowId: string | null
};

const withHocs = compose(
  (c: React.ComponentClass) => withErrorBoundary(c, { fallback: <BoundaryError /> }),
  withRouter,
  inject('answersAuditStore', 'organizationStore'),
  observer,
);

const getRowId = (data: AnswersAuditRecord): string => {
  return `${data.collectionId}--${data.recordedAt}`;
};

class AuditAnswersClass extends React.Component<Props, State> {
  state = {
    selectedRowId: null
  };

  fetchAnswers: Function;

  get isThematicAdmin():boolean {
    return this.props.organizationStore.availableActions.includes('manage:internalResource') ?? false;
  }

  constructor(props: Props) {
    super(props);
    this.fetchAnswers = debounce(() => this.props.answersAuditStore!.fetchAnswers(), 400);
  }

  componentDidMount(): void {
    this.initialize();
    analytics.track('Answers: Audit Answers', {});
  }

  componentWillUnmount(): void {
    this.props.answersAuditStore.reset();
  }
  componentDidUpdate(prevProps: Props) {
    if (prevProps.match.params.orgId !== this.props.match.params.orgId) {
      this.initialize();
    } else if (prevProps.location.search !== this.props.location.search) {
      this.fetchAnswers();
    }
  }

  initialize = async () => {
    const { search } = this.props.location;
    this.props.answersAuditStore.reset();
    if (isEmpty(search)) {
      this.updateParams({ sort_by: 'recordedAt' });
    } else {
      const searchParams = parse(search, {parseNumbers: true, parseBooleans: true });
      this.props.answersAuditStore.setPageParams(searchParams);
    }
    await this.props.answersAuditStore.fetchAnswers();
  }

  updateParams = (params: AuditAnswersPageRequestParams) => {
    const { location, history } = this.props;
    const search = parse(location.search);
    const searchParams = { ...(search ? search : {}), ...params };

    history.push({
      search: stringify(searchParams)
    });
    this.props.answersAuditStore.setPageParams(params);
  }
  sortBy = (sortBy: string) => {
    const { sort_by: currentSortBy, reverse_sort } = this.props.answersAuditStore.pageParams;
    const reverseSort = sortBy === currentSortBy ? !reverse_sort : false;
    const params = {
      page: 1,
      sort_by: sortBy,
      reverse_sort: reverseSort
    };
    this.updateParams(params);
  };
  clearSearch = () => {
    this.updateParams({ page: 1, search_term: '' });
  };
  updateSearch = ({ value }: FormInputProps) => {
    const search = value.toLowerCase();
    const params = {
      page: 1,
      search_term: search
    };
    this.updateParams(params);
  };
  onPageChange = async ({ activePage }: PaginationProps) => {
    const params = {
      page: activePage as number
    };
    this.updateParams(params);
  }
  handleShowFailedChange = async ({ checked }: CheckboxProps) => {
    const params = {
      page: 1,
      include_failed: !!checked
    };
    this.updateParams(params);
  };
  handleRowClick(record: AnswersAuditRecord) {
    const rowId = getRowId(record)
    const selectedRowId = rowId === this.state.selectedRowId ? null : rowId;

    this.setState({
      selectedRowId
    });
  }
  navigateTo(id?: string) {
    analytics.track('Answers: Audit Answers, View', {});

    const { params } = this.props.match;
    const pathname = id
      ? `/c/${ params.orgId }/answers/${ id }`
      : `/c/${ params.orgId }/answers`;
    this.props.history.push(pathname);
  }

  renderEmptyRow() {

    const numCols = this.isThematicAdmin ? 5 : 4;
    const { pageParams } = this.props.answersAuditStore;
    const { search_term: searchTerm } = pageParams;

    return (
      <Table.Row>
        <Table.Cell colSpan={numCols}>
          <div className="audit-answers__empty-filtered">
            <figure>
              <Analyze/>
            </figure>
            <h3 className="audit-answers__empty-filtered__message">No result for ‘{searchTerm}’</h3>
            <span className="audit-answers__empty-filtered__advice">Clear search field and start again</span>
          </div>
        </Table.Cell>
      </Table.Row>
    );
  }

  renderMetadata = (allMetadata: AnswersAuditRecord[]) => {
    const { pageParams } = this.props.answersAuditStore;
    const { sort_by, reverse_sort } = pageParams;
    const metadata = allMetadata;
    const numCols = this.isThematicAdmin ? 5 : 4;

    const rows = metadata.map((data: AnswersAuditRecord, index) => {

      const formattedDate = formatDateWithTime(data.recordedAt);
      const isSelected = getRowId(data) === this.state.selectedRowId;

      return (
        <React.Fragment
          key={index}
        >
          <Table.Row
            className={classNames("audit-answers__meta-row", {
              'audit-answers__meta-row--selected': isSelected
            })}
            onClick={() => this.handleRowClick(data)}
          >
          {this.isThematicAdmin && (
             <Table.Cell textAlign="center" className="audit-answers__success-cell">
                <FontAwesomeIcon
                  icon={data.success ? 'check' : 'info-circle'}
                  color={data.success ? 'var(--green-600)' : 'var(--orange-500)'}
                />
             </Table.Cell>
          )}
            <Table.Cell className='audit-answers__question-cell'>
              {data.question}
            </Table.Cell>
            <Table.Cell>
              <Tooltip
                content={data.createdBy}
                position="bottom center"
                inverted
                trigger={
                  <span>{data.createdBy}</span>
                }
              />
            </Table.Cell>
            <Table.Cell>
              {formattedDate}
            </Table.Cell>
            <Table.Cell
              className="audit-answers__view-cell"
            >
              <Tooltip
                content="Details"
                position="top center"
                inverted
                trigger={
                  <div className="audit-answers__info-button">
                    <Button
                      variant="secondary"
                      size="small"
                      disabled={!data.collectionId}
                    >
                      <FontAwesomeIcon icon="info-circle"/>
                    </Button>
                  </div>
                }
              />

              {data.success && (
                <Tooltip
                  content="View in Answers"
                  position="top center"
                  inverted
                  trigger={
                    <span>
                      <Button
                        variant="secondary"
                        size="small"
                        disabled={!data.collectionId}
                        onClick={() => this.navigateTo(data.collectionId)}
                      >
                        View
                      </Button>
                    </span>
                  }
                />
              )}

            </Table.Cell>
          </Table.Row>
          {isSelected && (
            <Table.Row
              className="audit-answers__answer-row"
            >
              <Table.Cell colSpan={numCols}>
                <div className='audit-answers__result'>
                  <h4>ANSWER</h4>
                  <span className="audit-answers__result-clamped">
                    {data.success ? data.result : 'No answer'}
                  </span>
                  <Button
                    inline={true}
                    variant="tertiary"
                    size="small"
                    onClick={() => this.navigateTo(data.collectionId)}
                  >View all <FontAwesomeIcon icon="share-square"/></Button>
                </div>
              </Table.Cell>
            </Table.Row>

          )}
        </React.Fragment>
      );
    });

    return (<Table
      fixed
      padded
      sortable
      unstackable
    >
      <Table.Header>
        <Table.Row>
          {this.isThematicAdmin && (
            <Tooltip
              content="Success"
              position="top center"
              inverted
              trigger={
                <Table.HeaderCell className="audit-answers__success-cell">
                  Success
                </Table.HeaderCell>
              }
            />
          )}
          <Table.HeaderCell
            className='audit-answers__question-cell'
          >
            Question
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={sort_by === 'email' ? reverse_sort ? Direction.DESC : Direction.ASC : undefined}
            onClick={() => this.sortBy('email')}
          >
            Creator
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={sort_by === 'recordedAt' ? reverse_sort ? Direction.DESC : Direction.ASC : undefined}
            onClick={() => this.sortBy('recordedAt')}
          >
            Time asked
          </Table.HeaderCell>
          <Table.HeaderCell
            className="audit-answers__view-cell"
          ></Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
      {rows.length > 0 ? rows : this.renderEmptyRow()}
      </Table.Body>
    </Table>
    );
  }

  getPaginationMessage():React.ReactNode {
    const { answersAuditStore } = this.props;
    const { pageParams, totalItems } = answersAuditStore;
    const totalRecords = answersAuditStore.auditRecords.length

    if (totalRecords === 0) {
      return (
        <span className="audit-answers__total">
          <strong>No answers to show</strong>
        </span>
      );
    }

    if (totalRecords === 1) {
      return (
        <span className="audit-answers__total">
          Showing: <strong>1 answer</strong>
        </span>
      );
    }

    const page = pageParams.page ?? 1;
    const perPage = pageParams.page_len ?? 1;
    const start = (page - 1) * perPage + 1;
    const end = Math.min(start + perPage -1, totalItems);

    return (
      <span className="audit-answers__total">
        Showing: <strong>{`${start}-${end} of ${totalItems} answers`}</strong>
      </span>
    );
  }

  getContent() {
    const { answersAuditStore } = this.props;

    const { pageParams, totalPages, isFetchingAnswers } = answersAuditStore;
    const { page } = pageParams;

    const paginationMessage = this.getPaginationMessage();

    if (answersAuditStore.isFetchingAnswers) {
      return (
        <section className="audit-answers__loader">
          <Loader size="large" active={true} />
          <span className="audit-answers__loader-message">Loading answers</span>
        </section>
      );
    }

    return (
      <section className="audit-answers__content">
        {paginationMessage}

        {this.renderMetadata(answersAuditStore.auditRecords)}

        <div className="pagination-block">
          <Pagination
            activePage={page}
            totalPages={totalPages}
            disabled={isFetchingAnswers}
            onPageChange={(_e, data) => this.onPageChange(data)}
          />
        </div>
      </section>
    );
  }

  getMenu() {
    const {
      pageParams,
    } = this.props.answersAuditStore;
    const { search_term: searchTerm, include_failed: includeFailed } = pageParams;
    return(
      <Menu secondary={true}>
        <Menu.Menu>
          <Menu.Item fitted={true}>
            <label htmlFor="search" className="screen-reader-only">Search</label>
            <Input
              action={{
                className: 'basic',
                icon: searchTerm ? 'close' : 'search',
                onClick: this.clearSearch
              }}
              placeholder="Search"
              name="filter"
              value={searchTerm}
              onChange={(_e, data) => this.updateSearch(data)}
            />
          </Menu.Item>
          {this.isThematicAdmin && (<Menu.Item fitted={true} title="Include Failed">
            <Checkbox
              toggle={true}
              label="Include failed"
              checked={includeFailed}
              onChange={(_e, data) => this.handleShowFailedChange(data)}
            />
          </Menu.Item>)}
        </Menu.Menu>
      </Menu>
    );
  }

  getBody() {
    const {
      isFetchingAnswers,
      pageParams,
      auditRecords,
      error
    } = this.props.answersAuditStore;
    const { search_term } = pageParams;
    const hasError = error !== AnswerAuditError.NONE;
    const hasSomeAuditRecords = auditRecords.length > 0;
    const hasSearchTerm = search_term && search_term.length > 0;

    const hasNoAnswers =
      !isFetchingAnswers &&
      !hasSomeAuditRecords &&
      !hasSearchTerm;

    if (hasError) {
      return (
        <section className="audit-answers__error">
          <Error
            title="An unknown error occurred"
            description="Something went wrong while fetching the records. Please try again."
            tracking={{
              event: "Error: Audit Answers",
              eventOptions: {
                "title": "An unknown error occurred",
                "description": "Something went wrong while fetching the records. Please try again."
              }
            }}
          >
            <Button
              variant="secondary"
              onClick={this.initialize}
            >
              Refresh
            </Button>
          </Error>
        </section>
      );
    }

    if (hasNoAnswers) {
      return (
        <section className="audit-answers__empty">
          <Empty
            icon='open-box'
            title="There are no answers yet"
            description="Your workspace's saved answers will be listed here when someone uses Answers."
          />
        </section>

      );
    }

    return (
      <main className="audit-answers__main">
        {this.getMenu()}
        {this.getContent()}
      </main>
    );

  }

  render() {
    return (
      <div className="audit-answers">
        <header className="audit-answers__header">
          Audit Answers
        </header>
        {this.getBody()}
      </div>
    );
  }

}

export default withHocs(AuditAnswersClass);
