import React from 'react';
import { Table } from 'semantic-ui-react';
import toFixed from 'vue/libs/to-fixed';
import { Column, Columns, ExportAssistantTableData } from './types';

const getCellValue = (value: number | undefined) => {
  return toFixed(value || 0, 2);
};

interface Props {
  columns: Column[];
  data: ExportAssistantTableData;
}

const ExportAssistantCategoryData = ({ columns, data }: Props) => {
  const showCount = columns.includes(Columns.count);
  const showImpact = columns.includes(Columns.impact);
  const showScore = columns.includes(Columns.score);
  const showSentimentCounts = columns.includes(Columns.sentimentCounts);
  const showVolume = columns.includes(Columns.volume);

  return (
    <>
      {showCount && (
        <Table.Cell className="export-assistant-category-table__date-cell">{getCellValue(data?.count)}</Table.Cell>
      )}
      {showVolume && (
        <Table.Cell className="export-assistant-category-table__date-cell">{getCellValue(data?.volume)}%</Table.Cell>
      )}
      {showImpact && (
        <Table.Cell className="export-assistant-category-table__date-cell">{getCellValue(data?.impact)}</Table.Cell>
      )}
      {showScore && (
        <Table.Cell className="export-assistant-category-table__date-cell">{getCellValue(data?.score)}</Table.Cell>
      )}
      {showSentimentCounts && (
        <>
          <Table.Cell className="export-assistant-category-table__date-cell">
            {getCellValue(data?.sentimentCounts?.positive)}
          </Table.Cell>
          <Table.Cell className="export-assistant-category-table__date-cell">
            {getCellValue(data?.sentimentCounts?.neutral)}
          </Table.Cell>
          <Table.Cell className="export-assistant-category-table__date-cell">
            {getCellValue(data?.sentimentCounts?.negative)}
          </Table.Cell>
        </>
      )}
    </>
  );
};

export { ExportAssistantCategoryData }
