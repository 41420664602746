import Logo from 'images/logo.svg';
import { parse } from 'query-string';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Card, Container, Header, Menu } from 'semantic-ui-react';

export default class Unsubscribed extends React.Component<
  RouteComponentProps<{}>
> {
  render() {
    const { location } = this.props;
    let digest;
    try {
      const { params } = parse(location.search);
      if (typeof params === 'string') {
        digest = JSON.parse(atob(params)).digest;
      }
    } catch (e) {
      // do nothing; it didn't work
    }
    return (
      <div>
        <Menu inverted={true} size={'massive'}>
          <Menu.Item header={true}>
            <Logo width="140" />
          </Menu.Item>
        </Menu>
        <Container text={true}>
          <Card fluid={true}>
            <Card.Content>
              <Card.Header as={Header}>Unsubscribed</Card.Header>
              {digest && (
                <p>
                  You have been removed from "{digest}". Contact your admin to
                  subscribe again.
                </p>
              )}
              {!digest && (
                <p>
                  We have unsubscribed you from the dashboard. Contact your admin
                  to be resubscribed.
                </p>
              )}
            </Card.Content>
          </Card>
        </Container>
      </div>
    );
  }
}
