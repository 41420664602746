import _ from 'lodash';
import { buildRql } from 'vue/libs/queryBuilder';

const restrictionMixin = {
  props: {
    baseFilter: { type: String },
    compareFilter: { type: String },
    compareFilterName: { type: String },
    config: { type: Object },
    dateFilter: { type: Object },
    filterString: { type: String },
    filterNames: { type: String }
  },
  data() {
    return {};
  },

  watch: {
    dateFilter: function() {
      this.fetchData();
    }
  },
  methods: {
    restrict() {
      // base filter of dashboard
      const baseFilter = this.baseFilter || null;
      // date filter
      const dateFilterString = _.get(this, 'dateFilter.selected.rql', null);
      // collected filters query
      const filterString = this.filterString || null;
      // widget level filter
      const widgetFilter = _.get(this, 'config.filter.filterString', null);

      return buildRql(baseFilter, dateFilterString, filterString, widgetFilter);
    },
    restrictWithComparison() {
      const baseRql = this.restrict();
      const comparisonFilter = this.compareFilter || null;

      return buildRql(baseRql, comparisonFilter);
    },
    restrictToPreviousPeriod() {
      const dateFilterString = _.get(this, 'dateFilter.previous.rql', null);
      // if there was no previous period, we don't allow continued query
      if (!dateFilterString) {
        return null;
      }
      // construct the 'base' filter which is constructed of base filter and date filter
      const baseFilter = this.baseFilter || null;
      // collected filters query
      const filterString = this.filterString || null;
      // widget level filter
      const widgetFilter = _.get(this, 'config.filter.filterString', null);
      return buildRql(baseFilter, dateFilterString, filterString, widgetFilter);
    },
    restrictToPreviousPeriodWithComparison() {
      const previousRql = this.restrictToPreviousPeriod();
      // if previous period rejected query, also reject query
      if (previousRql === null) {
        return null;
      }

      const comparisonFilter = this.compareFilter || null;

      return buildRql(previousRql, comparisonFilter);
    },
    restrictCustomDateRange(customDateFilterString) {
      // construct the 'base' filter which is constructed of base filter and date filter
      const baseFilter = this.baseFilter || null;
      // collected filters query
      const filterString = this.filterString || null;
      // widget level filter
      const widgetFilter = _.get(this, 'config.filter.filterString', null);
      return buildRql(
        baseFilter,
        customDateFilterString,
        filterString,
        widgetFilter
      );
    },
    restrictCustomDateRangeWithComparison(customDateFilterString) {
      const previousRql = this.restrictCustomDateRange(customDateFilterString);
      const comparisonFilter = this.compareFilter || null;

      return buildRql(previousRql, comparisonFilter);
    },
    timeRestrictionReady: function() {
      return this.dateFilter !== null;
    }
  }
};

export default restrictionMixin;
