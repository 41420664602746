<template>
  <div
    :class="{ 'clickable-theme': clickableTheme }"
    class="theme-name"
  >
    <el-popover
      v-if="components"
      placement="right"
      trigger="click"
      popper-class="source-breakdown-popover"
    >
      <table>
        <thead>
          <tr>
            <td>Source</td>
            <td>Score</td>
            <td>Count</td>
            <td>Impact</td>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(component, i) in components"
            :key="component + i"
            class="component"
          >
            <td
              class="source-name"
              @click="navigateToSource(component.source)"
            >
              {{ component.name }}
            </td>
            <td>{{ component.score }}</td>
            <td>{{ component.count }}</td>
            <td>{{ component.impact }}</td>
          </tr>
        </tbody>
      </table>
      <div
        slot="reference"
        :class="themeNameTextClass"
      >
        {{ themeName }}
      </div>
    </el-popover>
    <div
      v-else
      :class="themeNameTextClass"
    >
      {{ themeName }}
    </div>
  </div>
</template>

<script>
import toFixed from 'vue/libs/to-fixed';
import { isEmpty } from 'lodash';
import { stringify } from 'query-string';
import { toRoute } from 'lib/route-helper';
import { isSourceValid } from 'vue/dashboards/Dashboard/Widgets/Utils/navigate-params';
import { getActiveDashboardUIStore } from 'stores/RootStore';

export default {
  name: 'THEMENAMEWITHSOURCES',
  props: {
    source: { type: String },
    subtool: { type: String, default: undefined },
    subtheme: { type: String, default: undefined },
    tool: { type: String, required: true },
    theme: { type: String, required: true },
    themeComponents: { type: Array },
    themeName: { type: String },
    themeSources: { type: Array },
    truncateThemeName: { type: Boolean, default: false },
  },
  data() {
    return {
      activeDashboardUIStore: getActiveDashboardUIStore(),
    };
  },
  computed: {
    components() {
      const components = (this.themeComponents || [])
        .filter((component) => this.isValidSource(component.source))
        .map((component) => {
          const { count, impact, score, source } = component;
          const themeSource = this.themeSources[source];
          const name = themeSource.name;
          return {
            count,
            name,
            impact: toFixed(impact, 1),
            score: toFixed(score, 1),
            source: themeSource,
          };
        });
      if (components && components.length) {
        return components;
      }
      return undefined;
    },
    clickableTheme() {
      return !!this.components;
    },
    themeNameTextClass() {
      return this.truncateThemeName ? 'truncate-theme-name' : '';
    },
  },
  methods: {
    isValidSource(sourceIndex) {
      const { themeSources } = this;
      if (sourceIndex < themeSources.length) {
        const matchingSource = themeSources[sourceIndex];
        return !!matchingSource && !isEmpty(matchingSource.name);
      }
      return false;
    },
    navigateToSource(source) {
      const { survey, vis, view } = source;
      const { tool, subtool, theme, subtheme } = this;
      const { orgId } = this.activeDashboardUIStore;
      const path = `${toRoute(orgId, survey, view, vis)}/${tool}`;
      const { $route } = this;

      if (!isSourceValid(survey, view)) {
        return;
      }
      const { compare, filters } = $route.query;
      const query = {
        compare,
        expand: !!theme,
        filters,
        subtheme,
        subtool,
        theme,
      };
      window.location = `/#${path}?${stringify(query)}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../../styles/element-variables.scss';
.truncate-theme-name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.theme-row {
  .clickable-theme > span {
    width: 120px;
  }

  span:first-child {
    flex: 0 0 150px;
  }
}
.source-breakdown-popover {
  &.el-popover.el-popper {
    background: $--neutral-800;
    border: none;
    color: $--color-white;
    padding: 10px;
    font-size: 12px;
    line-height: 1.2;
    margin-left: 15px;

    .popper__arrow:after {
      border-right-color: $--neutral-800;
      border-left-color: $--neutral-800;
    }
  }

  .source-name {
    cursor: pointer;

    &:hover {
      font-weight: 600;
    }
  }

  thead td {
    font-weight: bold;
  }

  tbody {
    td:not(.source-name) {
      text-align: right;
    }
  }

  td {
    padding: 5px;
  }
}
</style>

