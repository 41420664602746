import * as React from 'react';
import { observer } from 'mobx-react';
import { debounce } from 'lodash';
import { VueInReact } from 'vuera';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FeatureFlagManager, FlagKeys } from 'lib/feature-flag';
import { Button } from 'components/Shared/Button';
import analytics from 'lib/analytics';
import { ThemeFilterSet } from 'stores/ui/AnalysisToolsUIStore';
import { FilterStoreInterface } from 'stores/FilterStore';
import queryBuilder from 'vue/libs/queryBuilder';
import { refineClusters } from 'lib/refine-clusters';
import thematicData, { UrlOptions } from 'vue/libs/thematicData';
import { RefinedCluster } from 'lib/refine-clusters';
import { PlainComment, Thread } from 'types/custom';
import { AnalysisFilterKey } from 'stores/types';
import { IReactionDisposer, reaction } from 'mobx';
import OverviewVue from 'vue/explore-detail/Overview.vue';
import {
  getAnalysisConfigStore,
  getAnalysisToolsKeyTakeawaysUIStore,
  getAnalysisToolsUIStore,
  getExploreToolUIStore,
  getFilterStore,
  getInitConfigStore,
  getQuickEditUIStore,
  getThemesStore,
} from 'stores/RootStore';

const Overview = VueInReact(OverviewVue);

const INITIAL_SENTENCE_LIMIT = 6;

type Props = {};

type State = {
  hasSummaryRequestError: boolean,
  isLoading: boolean,
  summaries: {
    baseline: string,
    comparison: string
  },
  clusters: {
    baseline: RefinedCluster<PlainComment | Thread>[],
    comparison: RefinedCluster<PlainComment | Thread>[]
  }
};

export const OverviewSection = observer(class OverviewSectionClass extends React.Component<Props, State> {
  disposer: IReactionDisposer;
  state = {
    hasSummaryRequestError: false,
    isLoading: true,
    summaries: {
      baseline: '',
      comparison: ''
    },
    clusters: {
      baseline: [] as RefinedCluster<PlainComment | Thread>[],
      comparison: [] as RefinedCluster<PlainComment | Thread>[]
    }
  };

  componentDidMount() {
        let previousThemeCode = this.analysisToolsUIStore.themeFilterSet.theme.code;
    let previousSubThemeCode = this.analysisToolsUIStore.themeFilterSet.subtheme.code;

    this.disposer = reaction(
      () => this.analysisToolsUIStore.themeFilterSet,
      (current: ThemeFilterSet) => {
        const currentThemeCode = current.theme.code;
        const currentSubThemeCode = current.subtheme.code;
        if (currentThemeCode !== previousThemeCode || currentSubThemeCode !== previousSubThemeCode) {
          this.makeSummariesRequests();
          previousThemeCode = currentThemeCode;
          previousSubThemeCode = currentSubThemeCode;
        }
      },
      { fireImmediately: true }
    );
  }

  componentWillUnmount() {
    this.disposer?.();
  }

  get analysisConfigStore() {
    return getAnalysisConfigStore();
  }

  get analysisToolsKeyTakeawaysUIStore() {
    return getAnalysisToolsKeyTakeawaysUIStore();
  }

  get analysisToolsUIStore() {
    return getAnalysisToolsUIStore();
  }

  get exploreToolUIStore() {
    return getExploreToolUIStore();
  }

  get filterStore() {
    return getFilterStore();
  }

  get initConfigStore() {
    return getInitConfigStore();
  }

  get themesStore() {
    return getThemesStore();
  }

  get quickEditUIStore() {
    return getQuickEditUIStore();
  }

  get hasSummaryFeature() {
    return FeatureFlagManager.checkFlag(FlagKeys.CAN_SEE_THEMES_SUMMARIZATION);
  }

  get hasKeyTakeaways() {
    const { frequency, impact } = this.keyTakeaways;
    return Boolean(frequency && impact);
  }

  get selectedThemeObject() {
    return this.exploreToolUIStore.selectedThemeObject;
  }

  get selectedThemeTitle() {
    const theme = this.analysisToolsUIStore.selectedThemeName;
    const subtheme = this.analysisToolsUIStore.selectedSubthemeName;
    if (subtheme) {
      return `${theme}: ${subtheme}`;
    }
    return theme;
  }

  get hasComparisonFilters() {
    const { selections } = this.filterStore;
    return selections.baseline.query !== selections.comparison.query;
  }

  get hasComparisonSummary() {
    return this.hasSummaryFeature && this.state.summaries.comparison.length > 0;
  }

  get keyTakeaways() {
    const keyTakeaways = this.analysisToolsKeyTakeawaysUIStore.takeaways || {};
    const frequency = (keyTakeaways['frequency-chart'] || []).reduce((acc, item) => item, null);
    const impact = (keyTakeaways['impact-chart'] || []).reduce((acc, item) => item, null);
    return { frequency, impact };
  }

  get baselineVolume() {
    const value = this.selectedThemeObject?.baseCount || 0;
    const total = this.exploreToolUIStore.baselineCount || 0;

    return { value, total };
  }

  get comparisonVolume() {
    const value = this.selectedThemeObject?.compCount || 0;
    const total = this.exploreToolUIStore.comparisonCount || 0;

    return { value, total };
  }

  get baselineSentiment() {
    const value = this.selectedThemeObject?.baseSentiment || 0;
    const segments = this.selectedThemeObject?.baseSentiments;
    return { value, segments };
  }

  get comparisonSentiment() {
    const value = this.selectedThemeObject?.compSentiment || 0;
    const segments = this.selectedThemeObject?.compSentiments;
    return { value, segments };
  }

  get themeName() {
    const { themeFilterSet } = this.analysisToolsUIStore;
    const { theme, subtheme } = themeFilterSet;
    return subtheme.name ? subtheme.name : theme.name;
  }

  get hasBetaClustering() {
    return FeatureFlagManager.checkFlag(FlagKeys.CAN_SEE_BETA_CLUSTERING);
  }

  get isCallCenterSummary() {
    const { config } = this.analysisConfigStore;
    return FeatureFlagManager.checkFlag(FlagKeys.USE_CLUSTERING_TEXT_TYPE) && !!config?.threadConfig?.summarize;
  }

  fetchAllSummariesDebounced = debounce(async (
    selectedTheme: ThemeFilterSet,
    segmentQueryStrings: FilterStoreInterface['segmentQueryStrings'],
    baselineQuery: string,
    comparisonQuery: string
  ) => {
    const promises = [
      this.fetchSummaries(
        'baseline',
        selectedTheme,
        segmentQueryStrings.baseline,
        baselineQuery
      )
    ];

    if (this.hasComparisonFilters) {
      promises.push(
        this.fetchSummaries(
          'comparison',
          selectedTheme,
          segmentQueryStrings.comparison,
          comparisonQuery
        )
      );
    }

    this.setState({ hasSummaryRequestError: false, isLoading: true });

    try {
      await Promise.all(promises);
    } catch (e) {
      this.setState({ hasSummaryRequestError: true });
    }

    this.setState({ isLoading: false });
  }, 10);

  fetchSummaries = async (
    filterType: AnalysisFilterKey,
    selectedThemes: ThemeFilterSet,
    queryString: string | undefined,
    filter: string
  ) => {
    if (!this.hasSummaryFeature) {
      this.setState({ isLoading: false });
      return;
    }

    const base = selectedThemes.theme.code || '';
    const sub = selectedThemes.subtheme.code || null;
    const sentiment = 'all';

    const summaryContext = this.exploreToolUIStore.selectedThemeName;

    const segmentSelection = selectedThemes
      ? queryBuilder.appendThemesToSegmentFilter(queryString || '', base, sub || undefined)
      : queryString;

    const options: UrlOptions<{}> = {
      ...this.initConfigStore.requestOptions,
      segmentSelection,
      summaryContext,
      type: 'default',
      summarize: true,
    };
    if (this.hasBetaClustering) {
      options["version"] = "5";
    }

    if (this.isCallCenterSummary) {
      options.textType = "callCenterSummary";
    }

    try {
      const data = await thematicData.getSummarization(
        filter,
        base,
        sub || undefined,
        sentiment,
        options,
        false,
        'exploreToolThemeSummary'
      );

      this.setState({
        summaries: {
          ...this.state.summaries,
          [filterType]: data.data.summary
        },
        clusters: {
          ...this.state.clusters,
          [filterType]: refineClusters(data.data.clusters)
        }
      });
    } catch (e) {
      if (e.code !== 'ERR_CANCELED') {
        throw e;
      }
    }
  };

  makeSummariesRequests = () => {
    const selectedTheme = this.analysisToolsUIStore.themeFilterSet;
    const selections = this.filterStore.selections;
    const themesFilter = this.filterStore.themesFilter;
    const segmentQueryStrings = this.filterStore.segmentQueryStrings;

    const baselineQuery = queryBuilder.appendToFilter(
      selections.baseline.query,
      themesFilter.baselineRql || ''
    );

    const comparisonQuery = queryBuilder.appendToFilter(
      selections.comparison.query,
      themesFilter.comparisonRql || '',
    );

    if (!selectedTheme.theme || !selectedTheme.theme.code || selectedTheme.theme.code === '') {
      return;
    }

    this.fetchAllSummariesDebounced(
      selectedTheme,
      segmentQueryStrings,
      baselineQuery,
      comparisonQuery
    );
  };

  retrySummaryRequests = () => {
    thematicData.clearSummarizationCache();
    this.makeSummariesRequests();
  };

  onSummaryTextSelection = () => {
    const selectedText = window.getSelection() as Selection;
    if (selectedText.toString().trim().length > 0) {
      analytics.track('Analysis: Select Summary', {
        'Location': 'Theme',
      });
    }
  };

  render() {
    const {
      hasSummaryRequestError,
      isLoading,
      summaries,
      clusters,
    } = this.state;
    const baselineSummary = summaries.baseline;
    const comparisonSummary = summaries.comparison;

    const { canManageThemes, hasSentiment, threadDisplayConfig } = this.analysisConfigStore;
    const { areSupportMessagesVisible, selectedThemeCodes } = this.analysisToolsUIStore;
    const { editedComments}  = this.quickEditUIStore;
    const { applying: isApplyingThemes, themesHierarchy, transforms, } = this.themesStore;

    const baselineClusters = clusters.baseline;
    const comparisonClusters = clusters.comparison;

    const baselineSentiment = this.baselineSentiment;
    const baselineVolume = this.baselineVolume;
    const isBaselineEmpty = this.baselineVolume.value === 0;
    const comparisonSentiment = this.comparisonSentiment;
    const comparisonVolume = this.comparisonVolume;
    const isComparisonEmpty = this.comparisonVolume.value === 0;

    const hasKeyTakeaways = this.hasKeyTakeaways;
    const hasSummary = this.hasSummaryFeature;
    const keyTakeaways = this.keyTakeaways;
    const selectedThemeTitle = this.selectedThemeTitle;
    const themeName = this.themeName;

    return (
      <div className="overview-section explore-detail__widget">
        {isLoading ? (
          <section className="overview-section__loading">
            <div className="ui active centered inline loader" />
            <h2>Summarizing data...</h2>
            <span>This may take up to 30 seconds.</span>
          </section>
        ):(
          <div>
            <Overview
              areSupportMessagesVisible={areSupportMessagesVisible}
              baselineClusters={baselineClusters}
              baselineSentiment={baselineSentiment}
              baselineSummary={baselineSummary}
              baselineVolume={baselineVolume}
              canManageThemes={canManageThemes}
              comparisonClusters={comparisonClusters}
              comparisonSentiment={comparisonSentiment}
              comparisonSummary={comparisonSummary}
              comparisonVolume={comparisonVolume}
              editedComments={editedComments}
              hasComparisonFilters={this.hasComparisonFilters}
              hasKeyTakeaways={hasKeyTakeaways}
              hasSentiment={hasSentiment}
              hasSummary={hasSummary}
              hasSummaryError={hasSummaryRequestError}
              isApplyingThemes={isApplyingThemes}
              isBaselineEmpty={isBaselineEmpty}
              isComparisonEmpty={isComparisonEmpty}
              keyTakeaways={keyTakeaways}
              selectedThemeCodes={selectedThemeCodes}
              selectedThemeTitle={selectedThemeTitle}
              sentenceLimit={INITIAL_SENTENCE_LIMIT}
              themeName={themeName}
              themesHierarchy={themesHierarchy}
              threadDisplayConfig={threadDisplayConfig}
              transforms={transforms}
              onRetrySummary={this.retrySummaryRequests}
              onSummaryTextSelection={this.onSummaryTextSelection}
            />
          </div>
        )}
        {hasSummaryRequestError && (
          <section className="sentence-section__error">
            <FontAwesomeIcon icon="exclamation-triangle" />
            <span className="text">Something went wrong while loading the sentences.</span>
            <Button
              type="text"
              onClick={this.retrySummaryRequests}
            >
            Click here to try again
            </Button>
          </section>
        )}
      </div>
    );
  }
});
