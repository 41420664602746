

































































import { get, debounce } from "lodash";
import { autorun } from 'mobx';

import {
  getAnalysisConfigStore,
  getAnalysisToolsKeyTakeawaysUIStore ,
  getAnalysisToolsUIStore,
  getExploreToolUIStore,
  getFilterStore,
  getInitConfigStore,
  getOrganizationStore,
  getQuickEditUIStore,
  getThemesStore,
} from 'stores/RootStore';
import queryBuilder from 'vue/libs/queryBuilder';
import thematicData from 'vue/libs/thematicData';
import { filterPopUpId } from "components/Filters/types";
import { refineClusters } from 'lib/refine-clusters';
import { FeatureFlagManager, FlagKeys } from 'lib/feature-flag';
import analytics from 'lib/analytics';
import Overview from "./Overview.vue";
const INITIAL_SENTENCE_LIMIT = 6;

export default {
  name: 'OverviewSection',
  components: {
    Overview
  },
  data() {
    return {
      analysisToolsKeyTakeawaysUIStore: getAnalysisToolsKeyTakeawaysUIStore(),
      analysisToolsUIStore: getAnalysisToolsUIStore(),
      analysisConfigStore: getAnalysisConfigStore(),
      exploreToolUIStore: getExploreToolUIStore(),
      filterStore: getFilterStore(),
      initConfigStore: getInitConfigStore(),
      hasSummaryRequestError: false,
      isLoading: true,
      summaries: {
        baseline: '',
        comparison: ''
      },
      clusters: {
        baseline: [],
        comparison: []
      },
      themesStore: getThemesStore(),
      organizationStore: getOrganizationStore(),
      quickEditUiStore: getQuickEditUIStore(),
    };
  },
  computed: {

    sentenceLimit() {
      return INITIAL_SENTENCE_LIMIT;
    },

    hasSummaryFeature() {
      return FeatureFlagManager.checkFlag(FlagKeys.CAN_SEE_THEMES_SUMMARIZATION);
    },

    hasSentimentFeature () {
      return this.analysisConfigStore.hasSentiment;
    },

    hasKeyTakeaways() {
      return Boolean(this.keyTakeaways.frequency && this.keyTakeaways.impact);
    },

    selectedThemeCodes() {
      return this.analysisToolsUIStore.selectedThemeCodes;
    },

    selectedThemeName() {
      return this.exploreToolUIStore.selectedThemeName;
    },

    selectedThemeObject() {
      return this.exploreToolUIStore.selectedThemeObject;
    },

    selectedThemeTitle() {
      const theme = this.analysisToolsUIStore.selectedThemeName;
      const subtheme = this.analysisToolsUIStore.selectedSubthemeName;
      if (subtheme) {
        return `${theme}: ${subtheme}`;
      }
      return theme;
    },

    hasComparisonFilters() {
      const selections = this.filterStore.selections;
      return selections.baseline.query !== selections.comparison.query;
    },

    hasComparisonSummary() {
      return this.hasSummaryFeature && this.summaries.comparison.length > 0;
    },

    keyTakeaways() {
      const keyTakeaways = this.analysisToolsKeyTakeawaysUIStore.takeaways || {};
      const frequency = (keyTakeaways['frequency-chart'] || []).reduce((acc, item) => item, null);
      const impact = (keyTakeaways['impact-chart'] || []).reduce((acc, item) => item, null);
      return { frequency, impact };
    },

    baselineVolume() {
      const value = get(this.selectedThemeObject, 'baseCount', 0);
      const total = this.exploreToolUIStore.baselineCount;
      return { value, total };
    },
    comparisonVolume() {
      const value = get(this.selectedThemeObject, 'compCount', 0);
      const total = this.exploreToolUIStore.comparisonCount;
      return { value, total };
    },
    baselineSentiment() {
      const value = get(this.selectedThemeObject, 'baseSentiment', null);
      const segments = get(this.selectedThemeObject, 'baseSentiments', null);
      return { value, segments };
    },
    comparisonSentiment() {
      const value = get(this.selectedThemeObject, 'compSentiment', null);
      const segments = get(this.selectedThemeObject, 'compSentiments', null);
      return { value, segments };
    },
    themeName() {
      const selectedTheme = this.analysisToolsUIStore.themeFilterSet;
      return selectedTheme.subtheme.name ? selectedTheme.subtheme.name : selectedTheme.theme.name;
    },
    hasBetaClustering() {
      return FeatureFlagManager.checkFlag(FlagKeys.CAN_SEE_BETA_CLUSTERING);
    },
    isCallCenterSummary() {
      return FeatureFlagManager.checkFlag(FlagKeys.USE_CLUSTERING_TEXT_TYPE) && !!this.analysisConfigStore?.config?.threadConfig?.summarize;
    },
    canManageThemes() {
      return this.analysisConfigStore.canManageThemes;
    },
    hasSentiment() {
      return this.analysisConfigStore.hasSentiment;
    },
    isApplyingThemes() {
      return this.themesStore.applying
    },
    themesHierarchy() {
      return this.themesStore.themesHierarchy;
    },
    transforms() {
      return this.themesStore.transforms;
    },
    orgId() {
      return this.organizationStore.orgId;
    },
    surveyId() {
      return this.analysisToolsUIStore.currentSurveyId;
    },
    shouldShowFullMessages() {
      return this.analysisToolsUIStore.shouldShowFullMessages;
    },
    areSupportMessagesVisible() {
      return this.analysisToolsUIStore.areSupportMessagesVisible;
    },
    threadDisplayConfig() {
      return this.analysisConfigStore.threadDisplayConfig
    },
    editedComments() {
      return this.quickEditUiStore.editedComments;
    }
  },
  mounted() {

    const analysisFilterIds = [
      filterPopUpId.AnalysisBaseLine,
      filterPopUpId.AnalysisComparison
    ];

    autorun(() => {

      const isAnyAnalyisFilterOpen = analysisFilterIds.includes(this.filterStore.openFilterPopUpId);

      if (isAnyAnalyisFilterOpen) {
        return;
      }

      // Cancel all in-flight summarization requests which were made by this component
      thematicData.cancelSummarizationRequestsById('exploreToolThemeSummary');

      this.makeSummariesRequests();

    });

  },
  methods: {

    retrySummaryRequests() {
      thematicData.clearSummarizationCache();
      this.makeSummariesRequests();
    },

    makeSummariesRequests() {

      const selectedTheme = this.analysisToolsUIStore.themeFilterSet;
      const selections = this.filterStore.selections;
      const themesFilter = this.filterStore.themesFilter;
      const segmentQueryStrings = this.filterStore.segmentQueryStrings;

      const baselineQuery = queryBuilder.appendToFilter(
        selections.baseline.query,
        themesFilter.baselineRql
      );

      const comparisonQuery = queryBuilder.appendToFilter(
        selections.comparison.query,
        themesFilter.comparisonRql,
      );

      if (!selectedTheme.theme || !selectedTheme.theme.code || selectedTheme.theme.code === '') {
        return;
      }

      this.fetchAllSummariesDebounced(
        selectedTheme,
        segmentQueryStrings,
        baselineQuery,
        comparisonQuery
      );

    },

    fetchAllSummariesDebounced: debounce(async function(
      selectedTheme,
      segmentQueryStrings,
      baselineQuery,
      comparisonQuery
    ) {

      const promises = [
        this.fetchSummaries(
          'baseline',
          selectedTheme,
          segmentQueryStrings.baseline,
          baselineQuery
        )
      ];

      if (this.hasComparisonFilters) {
        promises.push(
          this.fetchSummaries(
            'comparison',
            selectedTheme,
            segmentQueryStrings.comparison,
            comparisonQuery
          )
        );
      }

      this.hasSummaryRequestError = false;
      this.isLoading = true;

      try {
        await Promise.all(promises);
      } catch(e) {
        this.hasSummaryRequestError = true;
      }

      this.isLoading = false;

    }, 10),

    fetchSummaries: async function (
      filterType: "baseline" | "comparison",
      selectedThemes,
      queryString,
      filter
    ) {

      if (!this.hasSummaryFeature) {
        this.isLoading = false;
        return;
      }

      this.summaries[filterType] = '';
      this.clusters[filterType] = [];

      const base = selectedThemes.theme.code || '';
      const sub = selectedThemes.subtheme.code || null;
      const sentiment = 'all';


      const summaryContext = `${ this.selectedThemeName }`;

      const segmentSelection = selectedThemes
        ? queryBuilder.appendThemesToSegmentFilter(queryString, base, sub)
        : queryString

      const options = {
        ...this.initConfigStore.requestOptions,
        segmentSelection,
        summaryContext,
        type: 'default',
        summarize: true,
      };
      if (this.hasBetaClustering) {
        options["version"] = "5";
      }

      if (this.isCallCenterSummary) {
        options.textType = "callCenterSummary"
      }
      try {

        const data = await thematicData.getSummarization(
          filter,
          base,
          sub,
          sentiment,
          options,
          false, // cachedOnly
          'exploreToolThemeSummary' // location of this summary
        );

        this.summaries[filterType] = data.data.summary;
        this.clusters[filterType] = refineClusters(data.data.clusters);

      } catch(e) {

        if (e.code !== 'ERR_CANCELED') {
          throw e;
        }

      }

    },
    onSummaryTextSelection() {
      const selectedText = window.getSelection() as Selection;
      if (selectedText.toString().trim().length > 0) {
        analytics.track('Analysis: Select Summary',
          {
            'Location': 'Theme',
          }
        );
      }
    }

  },


};
