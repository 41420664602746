<template>
  <div class="change-value">
    <div class="theme__name">
      {{ valueChangeLabel }}
      <font-awesome-icon :icon="icon" />
    </div>
  </div>
</template>

<script>
import toFixed from 'vue/libs/to-fixed';

export default {
  name: 'ChangeValue',
  props: {
    value: { default: 0, type: Number },
    previousValue: { default: 0, type: Number },
    preCalculated: { default: undefined, type: Number },
  },
  computed: {
    valueChange() {
      if (this.preCalculated) {
        return this.preCalculated;
      }
      if( this.previousValue === 0 ) {
        return Infinity;
      }
      return (this.value / this.previousValue  - 1)* 100;
    },
    valueChangeLabel() {
      if(this.valueChange >= - 0.5 && this.valueChange <= 0.5) {
        return "no change";
      }
      else if(this.valueChange === Infinity) {
        return "new";
      }
      return toFixed(this.valueChange, 0, '%');
    },
    icon() {
      if(this.valueChange === Infinity) {
        return "";
      }
      else if(this.valueChange < -0.5) {
        return "arrow-alt-down";
      }
      else if(this.valueChange > 0.5) {
        return "arrow-alt-up";
      }
      return "";

    }
  }
};
</script>


<style lang="scss" scoped>
@import '../../../../styles/element-variables';
.change-value {
  line-height: 12px;
  flex: auto;
}
.theme__name {
  flex: 0 0 auto;
  padding: 0 8px;
  padding: 5px;
  float: right;
}
</style>
