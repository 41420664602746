<template>
  <component
    :is="widgetType"
    :config="config.config"
    :panel-order="panelOrder"
    :widget-order="config.order"
    :context="context"
    :data="widgetData"
    :is-weighted="isWeighted"
    :error="error"
    :warning="warning"
    :loading="loading"
    :filters="filters"
    :date-filter="dateFilter"
    :filter-names="filterNames"
    :compare-filter="compareFilter"
    :compare-filter-name="compareFilterName"
    :base-filter="baseFilter"
    :source="source"
    :source-key="sourceKey"
    class="dashboard-widget"
  />
</template>

<script>
import { find, get, isFunction } from 'lodash';
import { getActiveDashboardUIStore } from 'stores/RootStore';
import queryBuilder from 'vue/libs/queryBuilder';

import Themes1 from './Widgets/Themes1.vue';
import ThemesSimple from './Widgets/ThemesSimple.vue';
import GroupsOvertime from './Widgets/GroupsOvertime.vue';
import ImpactChanges from './Widgets/ImpactChanges.vue';
import ImpactComparison from './Widgets/ImpactComparison.vue';
import ImpactSimple from './Widgets/ImpactSimple.vue';
import ImpactWithSubthemes from './Widgets/ImpactWithSubthemes.vue';
import KeyTakeaways from './Widgets/KeyTakeaways.vue';
import OriginalScore from './Widgets/OriginalScore.vue';
import OriginalScoreComparison from './Widgets/OriginalScoreComparison.vue';
import Score from './Widgets/Score.vue';
import ScoreChanges from './Widgets/ScoreChanges.vue';
import ScoreComparison from './Widgets/ScoreComparison.vue';
import ScoreDetail from './Widgets/ScoreDetail.vue';
import ScoreOvertime from './Widgets/ScoreOvertime.vue';
import ScoreOvertimeComparison from './Widgets/ScoreOvertimeComparison.vue';
import Summary from './Widgets/Summary.vue';
import Metadata from './Widgets/Metadata.vue';
import ThemesChanges from './Widgets/ThemesChanges.vue';
import ThemesNew from './Widgets/ThemesNew.vue';
import ThemesCompareTwo from './Widgets/ThemesCompareTwo.vue';
import EmptyWidget from './Widgets/EmptyWidget.vue';
import SignificantChanges from './Widgets/SignificantChanges.vue';
import SignificantScoreChanges from './Widgets/SignificantScoreChanges.vue';

import WidgetUnknown from './Widgets/Unknown.vue';

import WidgetDataMixin from 'vue/components/mixins/WidgetDataMixin';

const widgetTypes = [
  GroupsOvertime,
  Themes1,
  ThemesSimple,
  ImpactChanges,
  ImpactSimple,
  ImpactWithSubthemes,
  KeyTakeaways,
  Metadata,
  OriginalScore,
  OriginalScoreComparison,
  Score,
  ScoreChanges,
  ScoreDetail,
  ScoreOvertime,
  SignificantChanges,
  SignificantScoreChanges,
  Summary,
  ThemesChanges,
  ThemesNew,
  ThemesCompareTwo,
  EmptyWidget
];

const COMPARISON_TYPES = {
  IMPACT_SIMPLE: ImpactComparison,
  SCORE: ScoreComparison,
  SCORE_OVERTIME: ScoreOvertimeComparison
};

const ORIGINAL_SCORE_TYPES = {
  SCORE: OriginalScore
};

const ORIGINAL_SCORE_COMPARISON_TYPES = {
  SCORE: OriginalScoreComparison
};

export default {
  name: 'WidgetChooser',
  mixins: [WidgetDataMixin],
  props: {
    compareFilter: { type: String },
    compareFilterName: { type: String },
    filterString: { type: String },
    filterNames: { type: String },
    dateFilter: { type: Object },
    baseFilter: { type: String },
    panelOrder: { type: Number }
  },
  data() {
    return {
      activeDashboardUIStore: getActiveDashboardUIStore()
    };
  },
  computed: {
    source() {
      return this.activeDashboardUIStore.getSourceUrl(this.sourceKey);
    },
    sourceKey() {
      return this.activeDashboardUIStore.getWidgetSourceKey(this.config);
    },
    isWeighted() {
      return get(this, 'widgetData.metadata.weighted', false);
    },
    filters() {
      return {
        baseline: queryBuilder.appendToFilter(get(this.dateFilter, 'selected.rql'), this.baseFilter),
        comparison: queryBuilder.appendToFilter(get(this.dateFilter, 'selected.rql'), this.compareFilter)
      };
    },
    widgetType() {
      const { widgetData } = this;
      const { config } = this.config;
      const { type: expectedType, themeLevel } = config;
      const widgetType = find(widgetTypes, { name: expectedType });

      if (widgetType) {
        const { isComparison, hasOriginalScore } = widgetType;

        // if:
        // * found widget type
        // * AND has hasOriginalScore / isComparison function
        // * AND it returns truthy
        // * AND expectedType in ORIGINAL_SCORE_TYPES / ORIGINAL_SCORE_COMPARISON_TYPES
        // THEN actually return the corresponding type

        const showOriginalScore = isFunction(hasOriginalScore) && hasOriginalScore(widgetData);
        const showComparison = isFunction(isComparison) && isComparison(config);

        if (showOriginalScore) {
          if (showComparison) {
            const comparisonType = ORIGINAL_SCORE_COMPARISON_TYPES[expectedType];
            if (comparisonType) {
              return comparisonType;
            }
          }

          const originalScoreType = ORIGINAL_SCORE_TYPES[expectedType];
          if (originalScoreType) {
            return originalScoreType;
          }
        }

        // if:
        // * found widget type
        // * AND has isComparison function
        // * AND it returns truthy
        // * AND expectedType in COMPARISON_TYPES
        // THEN actually return the comparison type
        if (showComparison) {
          const comparisonType = COMPARISON_TYPES[expectedType];
          if (comparisonType) {
            return comparisonType;
          }
        }
        if (widgetType.name === ImpactSimple.name && themeLevel !== 'subtheme') {
          return ImpactWithSubthemes;
        }
        return widgetType;
      } else {
        return WidgetUnknown;
      }
    }
  }
};
</script>


<style lang="scss">
@import './widgets';

</style>
