<template>
  <div class="explore-detail">
    <div>
      <react-overview-section
        v-if="canSeeReactOverviewSection"
      />
      <overview-section
        v-else
        class="explore-detail__widget"
      />
      <div
        v-if="hasDate"
        class="explore-detail__widget"
      >
        <over-time-chart
          :selections="selectionWithoutDates"
          :segment-query-strings="segmentQueryStrings"
          :selected-score-external="selectedScoreExternal"
          :date-label="dateLabel"
          @onSelectionChanged="onSelectionChanged"
        />
      </div>
      <dimension-widget
        v-if="!isLoadingThemes"
        class="explore-detail__widget"
        :selections="selections"
        :title="title"
        :selections-with-theme="selectionsWithTheme"
        :highlight="highlight"
        key-takeaways-key="explore-dimensions"
        @onSelectionChanged="onSelectionChanged"
        @onSelectionCleared="onSelectionCleared"
      />
      <div class="explore-detail__widget nw-related-themes">
        <div class="widget__header">
          <div>Themes related to</div>
          <div class="widget__header-theme">
            {{ selectedThemeName }}
          </div>
          <div>by</div>
          <div v-if="!hasScore">
            &nbsp;volume
          </div>
          <el-dropdown
            v-else
            trigger="click"
            @command="setViewChartBy"
          >
            <el-button
              size="small"
              class="widget__header-view-button"
            >
              {{ viewChartBy }}
              <font-awesome-icon icon="chevron-down" />
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="Volume">
                Volume
              </el-dropdown-item>
              <el-dropdown-item command="Impact">
                Impact
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="widget__body">
          <bar-graph
            v-if="viewChartBy === 'Volume' && !isLoadingThemes"
            :highlight="highlight"
            :selections="selectionsWithTheme"
            :filter-themes="filterOutThemes"
            :theme="theme"
            :subtheme="subtheme"
            title="Volume"
            @onSelectionChanged="onSelectionChangedBargraph"
          />
          <score-impact
            v-if="viewChartBy == 'Impact'"
            :highlight="highlight"
            :selections="selectionsWithTheme"
            :filtered-selections="selectionsWithTheme"
            :filter-themes="filterOutThemes"
            :theme="theme"
            :subtheme="subtheme"
            @onSelectionChanged="onSelectionChangedBargraph"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _, { get } from 'lodash';

import queryBuilder from 'vue/libs/queryBuilder';

import {
  getAnalysisToolsUIStore,
  getAnalysisToolsKeyTakeawaysUIStore,
  getExploreToolUIStore,
  getFilterStore,
  getInitConfigStore,
} from 'stores/RootStore';
import BarGraph from 'vue/explore-detail/BarGraph.vue';
import { ReactInVue } from 'vuera';
import DimensionWidget from 'vue/explore-detail/Dimension.vue';
import OverTimeChart from 'vue/explore-detail/OverTimeChart.vue';
import ScoreImpact from 'vue/explore-detail/ScoreImpact.vue';
import OverviewSection from 'vue/explore-detail/OverviewSection.vue';
import { OverviewSection as ReactOverviewSection } from 'components/AnalysisTool/OverviewSection';
import { FeatureFlagManager, FlagKeys } from 'lib/feature-flag';
import configMixin from 'vue/tools/Utils/configMixin';
import { autorun } from 'mobx';

import { SPECIAL_EMPTY_NAME } from 'vue/libs/consts';

export default {
  name: 'ExploreDetail',
  components: {
    ReactOverviewSection: ReactInVue(ReactOverviewSection),
    OverviewSection,
    BarGraph,
    DimensionWidget,
    OverTimeChart,
    ScoreImpact,
  },
  mixins: [configMixin],
  props: {
    hasComparison: { default: false, type: Boolean },
    baselineTitle: { type: String },
    comparisonTitle: { type: String },
    dateLabel: { default: undefined, type: [String, undefined] },
    isLoadingThemes: { default: true, type: Boolean }
  },
  data() {
    return {
      filterStore: getFilterStore(),
      analysisToolsKeyTakeawaysUIStore: getAnalysisToolsKeyTakeawaysUIStore(),
      analysisToolsUIStore: getAnalysisToolsUIStore(),
      initConfigStore: getInitConfigStore(),
      exploreToolUIStore: getExploreToolUIStore(),
      viewChartBy: 'Volume',
      upgradeMessage: `Upgrade to track theme volume, impact, and score over time,
        so you can see what’s trending up and what needs attention.`,
      selectionWithoutDates: {},
      themesFilter: {}
    };
  },
  computed: {
    canSeeReactOverviewSection() {
      return FeatureFlagManager.checkFlag(FlagKeys.USE_PARALLEL_SENTENCE_REQUESTS);
    },
    selectedThemeName() {
      return this.exploreToolUIStore.selectedThemeName;
    },
    hasDate() {
      return this.initConfigStore.hasDate;
    },
    hasScore() {
      return this.initConfigStore.hasScore;
    },
    IdsFromColumn() {
      return this.initConfigStore.IdsFromColumn;
    },
    stateConfig() {
      return this.initConfigStore.config;
    },
    selectedThemes() {
      return this.analysisToolsUIStore.themeFilterSet;
    },
    subtheme() {
      return this.analysisToolsUIStore.selectedSubthemeName;
    },
    theme() {
      return this.analysisToolsUIStore.selectedThemeName;
    },
    selections() {
      return this.filterStore.selections;
    },
    segmentQueryStrings() {
      const queryStrings = this.filterStore.segmentQueryStrings;
      return queryStrings;
    },
    selectionsWithTheme() {
      const selections = _.cloneDeep(this.selections);
      // if we have a theme selector to apply, do it
      selections.baseline.query = queryBuilder.appendToFilter(selections.baseline.query, this.themesFilter.baselineRql);
      selections.comparison.query = queryBuilder.appendToFilter(
        selections.comparison.query,
        this.themesFilter.comparisonRql,
      );
      return selections;
    },
    filterOutThemes() {
      const { subtheme, theme } = this;
      const filterOutThemes = { basethemes: [], subthemes: [] };
      if (subtheme && subtheme !== SPECIAL_EMPTY_NAME) {
        filterOutThemes.subthemes = [subtheme];
      } else {
        filterOutThemes.basethemes = [theme];
      }
      return filterOutThemes;
    },
    highlight() {
      const base = get(this, 'selectedThemes.theme.code', '');
      const sub = get(this, 'selectedThemes.subtheme.code', null);
      return { base, sub };
    },
    title() {
      if (this.subtheme && this.subtheme !== SPECIAL_EMPTY_NAME) {
        return this.subtheme;
      } else {
        return this.theme;
      }
    },
    validThemeSelection() {
      return get(this, 'selectedThemes.theme.code');
    },
    dateColumn() {
      return _.get(this, 'config.date_column', _.get(this, 'stateConfig.date_column', null));
    },
    baselineSelection() {
      return _.cloneDeep(this.selections.baseline);
    },
    comparisonSelection() {
      return _.cloneDeep(this.selections.comparison);
    },
  },
  watch: {
    selectedThemes: {
      handler() {
        // If the user selects new themes, clear the 'selection' that is limiting the comments
        this.$emit('onSelectionChanged', undefined);
      },
    },
    baselineSelection: {
      handler() {
        this.filteredSelectionWithoutDates();
      },
      immediate: true
    },
    comparisonSelection: {
      handler() {
        this.filteredSelectionWithoutDates();
      },
      immediate: true
    }
  },
  mounted() {
    this.analysisToolsKeyTakeawaysUIStore.reset();

    autorun(() => {
      this.themesFilter = this.filterStore.themesFilter;
    });
  },
  methods: {
    filteredSelectionWithoutDates() {
      const dateColumnIds = this.IdsFromColumn(this.dateColumn) || [];
      const baseline = this.baselineSelection;
      const queryFn = this.filterStore.getQueryStringByKey;
      baseline.query = queryFn('baseline', ...dateColumnIds);
      const comparison = this.comparisonSelection;
      comparison.query = queryFn('comparison', ...dateColumnIds);
      this.selectionWithoutDates =  { baseline, comparison };
    },
    onSelectionChanged(queries, counts, title) {
      this.$emit('onSelectionChanged', {
        selections: {
          baseline: {
            title: get(this, 'selections.baseline.title'),
            query: queries.baseline,
            count: counts.baseline.count,
            sentimentCounts: counts.baseline.sentimentCounts,
          },
          comparison: {
            title: get(this, 'selections.comparison.title'),
            query: queries.comparison,
            count: counts.comparison.count,
            sentimentCounts: counts.comparison.sentimentCounts,
          },
        },
        subTitle: title,
      });
    },
    onSelectionCleared() {
      this.$emit('onSelectionChanged', undefined);
    },
    onSelectionChangedBargraph(basetheme, subtheme, baselineSelection, comparisonSelection) {
      const currentSelections = this.selectionsWithTheme;
      const themesQuery = this.analysisToolsUIStore.rqlForThemes(basetheme, subtheme);

      // decide what the best suffix should be in the comments area
      let title = basetheme;
      if (subtheme) {
        title = subtheme;
      }

      this.$emit('onSelectionChanged', {
        subTitle: title,
        selections: {
          baseline: {
            title: get(this, 'selections.baseline.title'),
            query: queryBuilder.appendToFilter(currentSelections.baseline.query, themesQuery),
            count: get(baselineSelection, 'count'),
            sentimentCounts: get(baselineSelection, 'sentimentCounts'),
          },
          comparison: {
            title: get(this, 'selections.comparison.title'),
            query: queryBuilder.appendToFilter(currentSelections.comparison.query, themesQuery),
            count: get(comparisonSelection, 'count'),
            sentimentCounts: get(comparisonSelection, 'sentimentCounts'),
          },
        },
      });
    },
    selectTheme(theme, subtheme) {
      this.analysisToolsUIStore.setThemeState({ theme, subtheme });
    },
    setViewChartBy(value) {
      this.viewChartBy = value;
    },
  },
};
</script>

<style lang="scss">
@import '../styles/element-variables.scss';
.explore-detail {
  margin-top: 20px;
  background-color: $--color-white;

  .explore-detail__widget {
    border-radius: 4px;
    box-shadow: 0 2px 6px 0 rgba(84, 99, 119, 0.3);
    margin-bottom: 20px;
  }

  .widget__header {
    align-items: center;
    background: $--neutral-100;
    display: flex;
    padding: 10px 10px;
    height: 40px;
  }

  .widget__body {
    padding: 20px;
    background: $--color-white;
    border-radius: 4px;
  }

  .widget__header-right {
    margin-left: auto;
  }

  .widget__header-theme {
    border-radius: 15px;
    border: 1px solid $--neutral-200;
    background-color: $--color-white;
    padding: 0 10px;
    margin: 0 5px;
    height: 22px;
  }

  .widget__header-view-button {
    margin: 0 5px;
  }

  .upgrade-plan {
    max-height: 400px;
  }
}
</style>
