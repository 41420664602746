import * as React from 'react';
import { Button } from 'semantic-ui-react';
import { getFeatureFeedbackStore } from 'stores/RootStore';
import { FeatureFeedbackModal } from './FeatureFeedbackModal';
import { FeedbackModalText } from './types';

interface Props {
  featureId: string;
  location: string;
  rating: number;
  data?: object;
  modalTextConfig?: FeedbackModalText;
  onModalUnmount?: () => void;
}

export default (props: Props) => {

  const [isOpen, setIsOpen] = React.useState(false);

  function onSubmit(description = '') {

    const feedbackStore = getFeatureFeedbackStore();
    feedbackStore.sendFeedback(
      props.featureId,
      props.location,
      props.rating,
      description,
      props.data
    );

    setIsOpen(false);

  }

  function handleCancel() {
    setIsOpen(false);
  }

  return (
    <>
      <Button
        basic={true}
        color="blue"
        onClick={() => setIsOpen(true)}
      >
        Leave feedback
      </Button>
      <FeatureFeedbackModal
        onUnmount={props.onModalUnmount}
        isOpen={isOpen}
        onCancel={() => handleCancel()}
        onSubmit={onSubmit}
        textConfig={props.modalTextConfig}
      />
    </>
  );

};
