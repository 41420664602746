<template>
  <widget-wrapper
    :panel-order="panelOrder"
    :widget-order="widgetOrder"
  >
    <div class="dashboard-widget">
      <div class="widget-body" />
    </div>
  </widget-wrapper>
</template>

<script>
import WidgetWrapper from 'vue/dashboards/Dashboard/Widgets/WidgetWrapper.vue';

export default {
  name: 'EMPTY',
  components: {
    WidgetWrapper,
  },
  props: {
    panelOrder: { type: Number },
    widgetOrder: { type: Number },
  },
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.dashboard-widget.widget {
  background-color: transparent;
  box-shadow: none;
}
</style>
