import {
  AggregateViewSource,
  AnalysisFilter,
  AnalysisViewSource,
  AnswerComparison,
  AnswerMessage,
  AnswersAction,
  AnswersActionType,
  AnswersDataSet,
  AskAction,
  InputData,
  SavedAnswer,
  SavedAnswerCandidate,
  SegmentFilters,
  isComparisonResponse,
  isNonComparisonResponse,
  isQuotesAnswerResponse,
} from 'api/interfaces';
import { analysisToDataset } from 'components/Answers/utils/analysisToDataset';
import { assignFallbackDates } from 'components/Answers/utils/assignFallbackDates';
import { datasetsNestedMenuToActiveDatasets, datasetsToPickerItems } from 'components/Answers/utils/datasetToPickerItems';
import { dataSetToViewSource } from 'components/Answers/utils/datasetToSource';
import { getAnswerSocket } from 'components/Answers/utils/getAnswerSocket';
import { getFirstOutputItem } from 'components/Answers/utils/getFirstOutputItem';
import { hasAnyOutputItems } from 'components/Answers/utils/hasAnyOutputItems';
import { segmentFiltersToAnswersFilters } from 'components/Answers/utils/segmentFiltersToAnswersFilters';
import { PickerItem } from 'components/Shared/Picker';
import analytics from 'lib/analytics';
import { insertSiblingsAfter, reduceNodes, removeNodes, traverseNodes } from 'lib/node-helpers';
import { dropWhile, intersectionWith, isEqual, last, takeWhile } from 'lodash';
import { action, computed, observable, reaction } from 'mobx';
import customHistory from 'routing/history';
import config from 'runtime-config';
import AnalysisConfigStore, { AnalysisConfigStoreInterface } from 'stores/AnalysisConfigStore';
import { AnswerError, AnswersStoreInterface } from 'stores/AnswersStore';
import { OrganizationStoreInterface } from 'stores/OrganizationStore';
import { UserStoreInterface } from 'stores/UserStore';
import { Dataset, DatasetVis, DatasetsNestedMenu } from 'stores/data-structure/datasets';
import { getAnalysisId } from 'stores/utils/analysis-helper';
import {
  canBeToggledByAction,
  findNodeById,
  fromParts,
  insertChild,
  isToggleableNode,
  toParts,
  toggleableActions,
} from 'stores/utils/answers-helpers';
import {
  AnswerModal,
  AnswerModals,
  AnswerNode,
  AnswerOutput,
  AnswersEmailModalState,
  AnswersErrorMessage,
  Node,
  isAnswerResponse,
  isAskNode,
  isComparisonOutput,
  isQuotesAnswerNode,
} from 'types/custom';
import { v4 as uuid4 } from 'uuid';

export type Ask = {
  dataSets: AnswersDataSet[],
  question: string,
  filters: SegmentFilters
};

export interface AnswersUIStoreInterface {
  addItem: (item: AnswerNode['item'], originNodeId?: string) => void;
  answersStore: AnswersStoreInterface;
  dispatchAction: (
    answersAction: AnswersAction,
    dataSets: AnswersDataSet[],
    filters: SegmentFilters,
    originNodeId?: string
  ) => Promise<void>;
  selectedAnswerChanged: (savedAnswerId?: string) => Promise<void>;
  ask: () => Promise<void>;
  askParams: Ask;
  dataSets: AnswersDataSet[];
  duplicate: () => Promise<void>;
  nodes: AnswerNode[];
  handleAnswerModification: () => Promise<void>;
  removeAllErrorItems: () => void;
  removeItem: (item: AnswerNode['item']) => void;
  reset: () => void;
  savedAnswerExists: boolean;
  selectedSavedAnswerId: string;
  socketMap: Map<string, () => void>;
  title: string;
  currentAnswerUser: string | null;
  updateAsk: (askParams: Partial<Ask>) => void;
  updateItem: (item: AnswerNode['item']) => void;
  updateTitle: (newTitle: string) => void;
  lastRevealedNodeId: string | null;
  datasetPickerItems: PickerItem[];
  analysisFilters: Map<string, AnalysisFilter[]>;

  currentSharingLink: string;
  currentOrgName: string;
  isDuplicating: boolean;
  isSharingAllowed: boolean;
  isUpdatingIsSharingAllowed: boolean;
  setIsSharingAllowed: (isAllowed: boolean) => void;

  shouldShowReadOnly: boolean;
  refreshAnswer: (params: {
    dataSets: AnswersDataSet[],
    filters: SegmentFilters,
    originNodeId: string,
    question: string,
  }) => void;
  selectFirstDataSet: (flatDatasets: DatasetVis[]) => void;

  answersEmailModalState: AnswersEmailModalState;
  updateAnswersEmailModalState: (state: Partial<AnswersEmailModalState>) => void;

  menuSavedAnswerId: string | null;
  currentModal: AnswerModal;
  setCurrentModal: (modal: AnswerModal) => void;
  setMenuSavedAnswerId: (selectedSavedAnswerId: string | null) => void;

  toggleExamplePin: (nodeId: string, example: InputData) => void;
}

export default class AnswersUIStore implements AnswersUIStoreInterface {
  currentOrg: OrganizationStoreInterface;
  answersStore: AnswersStoreInterface;
  userStore: UserStoreInterface;
  analysisConfigStore: AnalysisConfigStoreInterface;
  pendingFilters: string[] = [];

  @observable
  selectedSavedAnswerId: string = uuid4();

  @observable
  savedAnswerExists = false;

  socketMap = new Map();

  @observable
  analysisFilters: Map<string, AnalysisFilter[]> = new Map();

  @observable
  dataSets = [] as AnswersDataSet[];

  @observable
  datasetPickerItems = [] as PickerItem[];

  @observable
  nodes = [] as AnswerNode[];

  @observable
  title = '';

  @observable
  currentAnswerUser: string | null = null;

  @observable
  askParams: Ask = {
    question: '',
    dataSets: [],
    filters: {}
  };

  @observable
  answersEmailModalState: AnswersEmailModalState = {
    subject: null,
    emails: []
  }

  @observable
  lastRevealedNodeId: string | null = null;

  @observable
  currentModal: AnswerModal = AnswerModals.None;

  @observable
  menuSavedAnswerId: string | null = null;

  @observable
  isSharingAllowed = false;

  @observable
  isUpdatingIsSharingAllowed = false;

  @observable
  isDuplicating = false;

  candidateSavedAnswerId: string = uuid4();

  @computed
  get currentSharingLink() {
    return `${ config.appUrlBase }#/c/${ this.currentOrg.orgId }/answers/${ this.selectedSavedAnswerId }`;
  }

  @computed
  get currentOrgName() {
    return this.currentOrg.orgName;
  }

  @computed
  get shouldShowReadOnly() {
    return !!(this.currentAnswerUser
      && this.currentAnswerUser.toLowerCase() !== this.userStore.user?.email.toLowerCase());
  }

  constructor(
    currentOrg: OrganizationStoreInterface,
    answersStore: AnswersStoreInterface,
    userStore: UserStoreInterface,
    analysisConfigStore: AnalysisConfigStore,
  ) {
    this.currentOrg = currentOrg;
    this.answersStore = answersStore;
    this.userStore = userStore;
    this.analysisConfigStore = analysisConfigStore;

    reaction(
      () => answersStore.nestedDatasets,
      (datasetsNestedMenu: DatasetsNestedMenu) => {
        const activeDatasets: Dataset[] = datasetsNestedMenuToActiveDatasets(datasetsNestedMenu);
        this.datasetPickerItems = datasetsToPickerItems(activeDatasets);
      },
      { fireImmediately: true }
    );
  }

  @action
  selectFirstDataSet(flatDatasets: DatasetVis[]) {
    this.dataSets = flatDatasets
      .filter(a => !a.isPreview && !a.isReviewing)
      .map(analysisToDataset);

    // this only runs when the flat analysis tools are first loaded
    // and that only happens when the org is loaded/changed
    this.askParams = {
      question: '',
      dataSets: [],
      filters: {}
    };

    // Select the first readable dataset by default (given none are selected)
    const firstReadableDataset = this.dataSets.find(d => d.canReadSurvey);
    if (firstReadableDataset) {
      this.askParams.dataSets[0] = firstReadableDataset;
      const source = dataSetToViewSource(firstReadableDataset);
      this.getAnalysisConfigFilters(source);
    }
  }

  @action
  async selectedAnswerChanged(savedAnswerId?: string) {

    this.answersStore.error = AnswerError.NONE;
    this.answersStore.resetEmailPreviewHtml();

    if (!savedAnswerId) {
      this.title = '';
      this.currentAnswerUser = null;
      this.savedAnswerExists = false;
      this.selectedSavedAnswerId = uuid4();
      this.nodes = [];
      this.lastRevealedNodeId = null;
      this.isSharingAllowed = false;
      return;
    }

    this.updateAnswersEmailModalState(
      {
        subject: null,
        emails: []
      }
    );

    this.selectedSavedAnswerId = savedAnswerId;
    this.savedAnswerExists = true;

    const existingSavedAnswer = this.answersStore.savedAnswers.get(savedAnswerId);

    if (existingSavedAnswer) {
      this.applySavedAnswer(existingSavedAnswer);
      return;
    }

    const savedAnswer = await this.answersStore.fetchAnswer(savedAnswerId);

    if (savedAnswer) {
      this.applySavedAnswer(savedAnswer);
    }

  }

  @action
  getAnalysisConfigFilters = async (source: AnalysisViewSource | AggregateViewSource): Promise<AnalysisFilter[] | undefined> => {
    const sourceId = getAnalysisId(source);

    if (this.pendingFilters.includes(sourceId)) {
      return;
    }

    if (this.analysisFilters.has(sourceId)) {
      return this.analysisFilters.get(sourceId);
    }

    this.pendingFilters.push(sourceId);

    try {
      const analysisConfig = await this.analysisConfigStore.getConfig(source);

      if (analysisConfig?.filters) {
        this.analysisFilters = new Map(this.analysisFilters.set(sourceId, analysisConfig.filters));
      }

      return analysisConfig?.filters;
    } finally {
      this.pendingFilters = this.pendingFilters.filter(id => id !== sourceId);
    }
  }

  traverseNodesAndGetConfig(nodes: Node<AnswerOutput>[]) {
    for (const node of nodes) {
      for (const dataSet of node.item.dataSets) {
        const source = dataSetToViewSource(dataSet);
        this.getAnalysisConfigFilters(source);
      }

      if (node.children) {
        this.traverseNodesAndGetConfig(node.children);
      }
    }
  }

  @action
  async applySavedAnswer(savedAnswer: SavedAnswer) {
    this.title = savedAnswer.title;
    this.isSharingAllowed = savedAnswer.sharingStatus.includes('internal');
    this.currentAnswerUser = savedAnswer.createdBy;
    const savedNodes = fromParts(savedAnswer.answer);
    const nextNodes = assignFallbackDates(savedNodes, savedAnswer.updatedAt);

    if (this.currentAnswerUser !== this.userStore.user?.email) {
      analytics.track('Answers: View shared answer', { seatType: this.userStore.user?.seatType });
    }

    const currentFirstOutput = getFirstOutputItem(this.nodes);
    const nextFirstOutput = getFirstOutputItem(nextNodes);

    // Warning nodes are not persisted, but should remain present if they
    // exist. It is possible to have some warnings, then get an answer that
    // will be persisted as a new saved answer set. When that occurs, we do not
    // intend to wipe out the ephemeral warnings from the local state.
    // Hence we check if the applying state contains data that is already loaded.
    if (currentFirstOutput?.id !== nextFirstOutput?.id) {
      if (!this.shouldShowReadOnly) {
        this.traverseNodesAndGetConfig(nextNodes);
      }
      this.nodes = nextNodes;
    }

    const lastNode = last(this.nodes);

    if (lastNode && lastNode.item.state === 'output') {
      const dataSets = lastNode.item.dataSets;

      // it is possible that the saved dataSets may no longer be valid
      // selections, so we check them before restoring them.

      this.askParams.dataSets = intersectionWith(
        this.dataSets,
        dataSets,
        (a: AnswersDataSet, b: AnswersDataSet): boolean => a.visId === b.visId &&
          a.viewId === b.viewId &&
          a.surveyId === b.surveyId
      );

      const sources: AnalysisViewSource[] = dataSets.map(ds => {
        return {
          survey: ds.surveyId,
          view: ds.viewId || undefined,
          visualization: ds.visId || '_'
        };
      });

      // if not read only, then get the configs of sources so we are ready for future followups
      if (!this.shouldShowReadOnly) {
        await Promise.all(sources.map(source => this.getAnalysisConfigFilters(source)));
      }

      this.askParams.filters = lastNode.item.filters ?? {};

    }

  }

  @action
  updateTitle(newTitle: string) {
    this.title = newTitle;
    analytics.track('Answers: Saved Answer', { 'Operation': 'Rename' });
    this.handleAnswerModification();
  }

  @action
  updateAsk(askParams: Partial<Ask>): void {
    const { dataSets } = askParams;

    if (dataSets?.length === 1) {
      const source = dataSetToViewSource(dataSets[0]);
      this.getAnalysisConfigFilters(source);
    }

    const lastNode = this.lastRevealedNodeId ? findNodeById(this.nodes, this.lastRevealedNodeId) : null;
    const hasChangedFilters = !isEqual(askParams.filters, { ...this.askParams.filters });

    if (hasChangedFilters && lastNode?.item.state === 'error') {
      analytics.track('Answers: Change filter', { result: lastNode.item.originalError.errorCode });
    }

    this.askParams = { ...this.askParams, ...askParams };
  }

  limitParentId(originNodeId: string): string | null {

    // We allow children of top level answers but not grandchildren.

    const isTopLevel = this.nodes.some(node => node.item.id === originNodeId);
    if (isTopLevel) {
      return originNodeId;
    }

    return this.nodes
      .find(node => findNodeById(node.children, originNodeId))
      ?.item.id ?? null;

  }

  getParentId(nodeId: string): string | null {

    return reduceNodes((result, node) => {

      return node.children.some(child => child.item.id === nodeId)
        ? result : null;

    }, this.nodes, null);

  }

  @action
  addItem(item: AnswerNode['item'], originNodeId?: string) {

    if (!originNodeId) {
      this.nodes.push({ item, children: [] });
    } else {

      const limitedParentId = this.limitParentId(originNodeId);

      this.nodes = this.nodes.map(node => {
        if (node.item.id !== limitedParentId) {
          return node;
        }

        const newChild = { item, children: [] };
        const children = insertChild(newChild, node, originNodeId);

        return {
          ...node,
          children
        };

      });

    }

    this.lastRevealedNodeId = item.id;
  }

  @action
  refreshItem(item: AnswerNode['item'], originNodeId: string) {
    const limitedParentId = this.limitParentId(originNodeId);

    this.nodes = this.nodes.map(node => {
      if (node.item.id !== limitedParentId) {
        return node;
      }

      const newNode = { item, children: node.children };

      return newNode;
    });

    this.lastRevealedNodeId = item.id;
  }

  @action
  removeItem(item: AnswerNode['item']) {

    // An item being removed might have children associated with open sockets.
    // We collect the items id and all it's descendant ids, such that we can
    // identify the sockets to close (if any).
    const idsToRemove = reduceNodes(
      (result: string[], node) => [...result, node.item.id],
      this.nodes,
      []
    );

    // The item being removed - or a child of the item - may have an open
    // socket associated with it. Here we close the sockets.
    this.closeSockets(idsToRemove);

    this.nodes = removeNodes(node => node.item.id === item.id, this.nodes);
    if (this.nodes.length === 0) {
      this.title = '';
    }
  }

  @action
  removeAllErrorItems() {

    const errors = reduceNodes((result: AnswerNode['item'][], node) => {

      // Some errors we treat as warnings; they get a yellow triangle view.
      // Other errors are considered more serious; these get a red triangle.
      // We intend on removing the red-triangle views as the user asks
      // questions, and leave the yellow-triangles views in the UI.
      // No errors are intended to be persisted.

      const removeableErrorCodes = ['CLIENT_ERROR', 'INTERNAL_SERVER_ERROR'];
      const isRemoveableError = node.item.state === 'error'
        && removeableErrorCodes.includes(node.item.originalError.errorCode);

      if (isRemoveableError) {
        return [...result, node.item];
      }

      return result;

    }, this.nodes, []);

    errors.forEach(item => this.removeItem(item));
  }

  @action
  toggleVisibility(node: AnswerNode) {
    if (node.item.isVisible) {
      this.lastRevealedNodeId = node.item.id;
    } else {
      this.lastRevealedNodeId = null;
    }

    this.updateItem({
      ...node.item,
      isVisible: !node.item.isVisible
    });
    this.handleAnswerModification();

  }

  @action
  addQuoteItem(originNode: AnswerNode) {

    // quotes come from parents, not from requests

    if (originNode.item.state === 'output') {

      const originItem = originNode.item;

      if (isAnswerResponse(originItem.content)) {
        // only real answer components can have quotes

        const createdAt = new Date().toUTCString();

        const inputData: InputData[] = isNonComparisonResponse(originItem.content)
          ? originItem.content.inputData || []
          : originItem.content.comparisons.flatMap(comparison => comparison.inputData);

        const item: AnswerNode['item'] = {
          id: uuid4(),
          state: 'output',
          actionType: AnswersActionType.selectQuotes,
          isVisible: true,
          dataSets: originItem.dataSets,
          filters: originItem.filters,
          createdAt,
          content: {
            question: originItem.content.question,
            inputData,
            type: 'quotes'
          },
        };

        if (isComparisonResponse(originItem.content)) {
          item.comparisons = originItem.content.comparisons;
        }

        this.addItem(item, originItem.id);
        this.handleAnswerModification();
      }

    }

  }

  @action
  toggleExamplePin(nodeId: string, example: InputData) {
    this.nodes = traverseNodes(
      node => node.item.id === nodeId
        ? { ...node, item: this.updateExamplePin(node, example)}
        : node,
      this.nodes
    );
    this.handleAnswerModification();
  }

  updateExamplePin(node: AnswerNode, example: InputData): AnswerNode['item'] {
    if (isQuotesAnswerNode(node)) {
      const item = node.item;
      if (isComparisonOutput(item)) {
        return this.updateExamplePinInComparison(item, example);
      } else {
        return this.updateExamplePinInNonComparison(item, example);
      }
    }
    return node.item;
  }

  updateExamplePinInComparison(item: AnswerOutput & {comparisons: AnswerComparison[]}, example: InputData): AnswerNode['item'] {
    const updatedComparisons = item.comparisons.map(comparison => ({
      ...comparison,
      inputData: this.toggleExamplePinStatus(comparison.inputData, example)
    }));
    return {
      ...item,
      comparisons: updatedComparisons
    };
  }

  updateExamplePinInNonComparison(item: AnswerOutput, example: InputData): AnswerNode['item'] {
    if (isQuotesAnswerResponse(item.content) && item.content.inputData) {
      const updatedInputData = this.toggleExamplePinStatus(item.content.inputData, example);
      return {
        ...item,
        content: {
          ...item.content,
          inputData: updatedInputData
        }
      };
    }

    return item;
  }

  toggleExamplePinStatus(inputData: InputData[], example: InputData): InputData[] {
    return inputData.map(inputItem =>
      inputItem.text === example.text
      ? { ...inputItem, isPinned: !inputItem.isPinned }
      : inputItem
    );
  }

  @action
  updateItem(item: AnswerNode['item']): void {
    if (item.isVisible) {
      this.lastRevealedNodeId = item.id;
    } else {
      this.lastRevealedNodeId = null;
    }
    this.nodes = traverseNodes(
      node => node.item.id === item.id
        ? { ...node, item }
        : node,
      this.nodes
    );
  }

  @action
  refreshAnswer({
    dataSets,
    filters,
    originNodeId,
    question,
  }) {
    const trimmedQuestion = question.trim();
    const questionFilters = dataSets.length === 1 ? filters : {};

    if (trimmedQuestion.length === 0) {
      return;
    }

    const currentNode = findNodeById(this.nodes, originNodeId);

    if (!currentNode || !('content' in currentNode.item)) {
      return;
    }

    const answersAction: AskAction = {
      question: trimmedQuestion,
      filters: segmentFiltersToAnswersFilters(questionFilters),
      action: AnswersActionType.ask,
      collectionId: originNodeId
    };

    const item: AnswerNode['item'] = {
      id: uuid4(),
      actionType: answersAction.action,
      state: 'loading',
      message: 'loading',
      isVisible: true,
    };

    this.refreshItem(item, originNodeId);

    this.openAnswerSocket(answersAction, dataSets, filters, item);
  }

  async getFilters(dataSet: AnswersDataSet) {
    const source = dataSetToViewSource(dataSet);
    const filters = await this.getAnalysisConfigFilters(source);

    if (!Array.isArray(filters)) {
      return {};
    }

    const defaultFilters = filters?.reduce((acc, curr) => {
      if (curr.cuts && curr.cuts.length > 0 && curr.cuts[0].rql !== '') {
        acc[curr.id] = {
          ids: ['_all'],
        };
      }

      return acc;
    }, {});

    return {
      ...this.askParams.filters,
      ...defaultFilters,
    }
  }

  @action
  async ask() {
    const { question, dataSets } = this.askParams;
    const trimmedQuestion = question.trim();

    const filters = dataSets.length === 1 ? await this.getFilters(dataSets[0]) : {};

    if (trimmedQuestion.length === 0) {
      return;
    }

    if (this.title.length === 0) {
      this.title = trimmedQuestion;
    }

    this.askParams.question = '';

    const answersAction: AnswersAction = {
      question: trimmedQuestion,
      filters: segmentFiltersToAnswersFilters(filters),
      action: AnswersActionType.ask,
      collectionId: this.selectedSavedAnswerId
    };

    // Order of operations: we want to know if the user has asked a question after receiving error feedback,
    // so we test the last created node before we create a new one (which would then become the lastRevealedNode).
    const lastRevealedNode = this.lastRevealedNodeId ? findNodeById(this.nodes, this.lastRevealedNodeId) : null;

    if (lastRevealedNode?.item.state === 'error') {
      analytics.track('Answers: Ask again', { result: lastRevealedNode.item.originalError.errorCode });
    }

    const questionNodes = this.nodes.filter(isAskNode);

    // try to provide the previous question and contents to the server as context to make for better answers
    if (questionNodes.length > 0) {
      // extract the questions
      answersAction.previousQuestions = questionNodes.map(node => node.item.content.question);

      const lastAskedNode = last(questionNodes);
      if (lastAskedNode?.item.state === 'output') {
        if (lastAskedNode.item.content.type === 'text') {
          answersAction.previousContents = lastAskedNode.item.content.contents;
        }
        else if (lastAskedNode.item.content.type === 'multipart' &&
          lastAskedNode.item.content.contents.length > 0 &&
          lastAskedNode.item.content.contents[0].type === 'text') {
          answersAction.previousContents = lastAskedNode.item.content.contents[0].contents;
        }
      }
    }


    this.dispatchAction(answersAction, dataSets, filters);

  }

  @action
  openAnswerSocket(
    answersAction: AnswersAction,
    dataSets: AnswersDataSet[],
    filters: SegmentFilters,
    item: AnswerNode['item'],
  ) {
    const orgId = this.currentOrg.orgId;
    const userId = this.userStore.user?.email || 'unknown';

    let inProgress = true;
    const closeSocket = getAnswerSocket(
      userId,
      orgId,
      answersAction,
      dataSets,
      messageEvent => {

        try {
          const data = JSON.parse(messageEvent.data);
          inProgress = !this.handleMessage(
            item,
            data,
            answersAction,
            filters,
            dataSets
          );
        } catch (e) {

          const originalError: AnswersErrorMessage = {
            message: 'error',
            error: e.message,
            errorCode: 'CLIENT_ERROR'
          };

          this.handleError(
            item,
            originalError,
            answersAction,
            dataSets
          );
        }
      },
      () => {
        if (!inProgress) {
          return;
        }

        const originalError: AnswersErrorMessage = {
          message: 'error',
          error: 'Oops, an error occurred',
          errorCode: 'CLIENT_ERROR'
        };

        this.handleError(
          item,
          originalError,
          answersAction,
          dataSets
        );
      }
    );

    this.socketMap.set(item.id, closeSocket);
  }

  @action
  loadNewAnswer(
    answersAction: AnswersAction,
    dataSets: AnswersDataSet[],
    filters: SegmentFilters,
    originNodeId?: string
  ) {

    if (dataSets.length === 0) {
      const errorItem: AnswerNode['item'] = {
        id: uuid4(),
        actionType: answersAction.action,
        state: 'loading',
        message: 'loading',
        isVisible: true,
      };

      const originalError: AnswersErrorMessage = {
        message: 'error',
        error: 'No dataset was selected! Please select a dataset.',
        errorCode: 'NO_DATASETS_SELECTED'
      };

      this.addItem(errorItem, originNodeId);

      this.handleError(
        errorItem,
        originalError,
        answersAction,
        dataSets
      );
      return;

    }

    this.removeAllErrorItems();

    const item: AnswerNode['item'] = {
      id: uuid4(),
      actionType: answersAction.action,
      state: 'loading',
      message: 'loading',
      isVisible: true,
    };

    this.addItem(item, originNodeId);

    this.openAnswerSocket(answersAction, dataSets, filters, item);
  }

  getToggleableNodesByOriginId(originNodeId: string): AnswerNode[] {

    const originNode: AnswerNode | null = originNodeId ? findNodeById(this.nodes, originNodeId) : null;

    if (!originNode) {
      return [];
    }

    const isTopLevel: boolean = this.nodes.some(node => node.item.id === originNodeId);

    if (isTopLevel) {
      return takeWhile(originNode.children, isToggleableNode);
    }

    const siblings: AnswerNode[] = this.nodes.find(
      node => node.children.some(c => c.item.id === originNodeId)
    )?.children ?? [];

    const siblingsFromOrigin = dropWhile(siblings, (n: AnswerNode) => n.item.id !== originNodeId);
    return takeWhile(siblingsFromOrigin.slice(1), isToggleableNode);

  }

  @action
  async dispatchAction(
    answersAction: AnswersAction,
    dataSets: AnswersDataSet[],
    filters: SegmentFilters,
    originNodeId?: string
  ) {

    this.removeAllErrorItems();

    if (originNodeId) {

      const isToggleableAction = toggleableActions.includes(answersAction.action);
      const isQuoteAction = answersAction.action === 'selectQuotes';
      const originNode: AnswerNode | null = findNodeById(this.nodes, originNodeId);
      const toggleableNodesForOrigin = this.getToggleableNodesByOriginId(originNodeId);
      const existingToggleableNode = toggleableNodesForOrigin.find(
        node => canBeToggledByAction(answersAction.action, node)
      );

      if (existingToggleableNode && isToggleableAction) {
        this.toggleVisibility(existingToggleableNode);
        return;
      }

      if (!existingToggleableNode && originNode && isQuoteAction) {
        this.addQuoteItem(originNode);
        return;
      }

    }

    this.loadNewAnswer(
      answersAction,
      dataSets,
      filters,
      originNodeId
    );

  }

  @action
  handleMessage(
    item: AnswerNode['item'],
    data: AnswerMessage,
    originalAction: AnswersAction,
    originalFilters: SegmentFilters,
    originalDataSets: AnswersDataSet[]
  ) {
    let socketCompleted = false;
    switch (data.message) {
      case 'interimResults': {
        this.updateItem({
          ...item,
          id: item.id,
          actionType: originalAction.action,
          state: 'loading',
          message: data.data.message,
        });
        break;
      }
      case 'output': {

        for (const dataset of originalDataSets) {
          const source = dataSetToViewSource(dataset);
          this.getAnalysisConfigFilters(source);
        }

        const [first, ...rest] = data.data;

        const createdAt = new Date().toUTCString();

        this.updateItem({
          ...item,
          actionType: originalAction.action,
          content: first,
          createdAt,
          dataSets: originalDataSets,
          filters: originalFilters,
          id: item.id,
          state: 'output',
        });

        const newSiblings: AnswerNode[] = rest.map(content => {
          return {
            item: {
              actionType: originalAction.action,
              content,
              createdAt,
              dataSets: originalDataSets,
              filters: originalFilters,
              id: uuid4(),
              isVisible: true,
              state: 'output',
              type: content.type,
            },
            children: []
          };
        });

        this.nodes = insertSiblingsAfter(
          (n: AnswerNode): boolean => n.item.id === item.id,
          newSiblings,
          this.nodes
        );

        this.closeSockets([item.id]);
        this.handleAnswerModification();

        socketCompleted = true;
        break;
      }
      case 'error': {
        this.handleError(
          item,
          data,
          originalAction,
          originalDataSets
        );
        break;
      }
      default: {

        const originalError: AnswersErrorMessage = {
          message: 'error',
          // @ts-expect-error | we get here if the message from the socket is not correctly formed
          error: data.message,
          errorCode: 'CLIENT_ERROR'
        };

        this.handleError(
          item,
          originalError,
          originalAction,
          originalDataSets
        );
      }
    }
    return socketCompleted;
  }

  @action
  handleError(
    item: AnswerNode['item'],
    originalError: AnswersErrorMessage,
    originalAction: AnswersAction,
    originalDatasets: AnswersDataSet[]
  ) {

    this.updateItem({
      id: item.id,
      state: 'error',
      originalAction,
      originalDatasets,
      originalError,
      isVisible: true,
    });
    this.closeSockets([item.id]);
  }

  @action
  closeSockets(ids: string[]) {

    ids.forEach(id => {
      if (this.socketMap.has(id)) {
        const closeSocket = this.socketMap.get(id);
        closeSocket();
        this.socketMap.delete(id);
      }
    });

  }

  async save(candidate: SavedAnswerCandidate) {
    const store = this.answersStore;
    const savedAnswerResponse = await store.saveAnswer(candidate, this.savedAnswerExists);

    if (!savedAnswerResponse) {
      return;
    }

    const { id, createdAt, createdBy, updatedAt, sharingStatus } = savedAnswerResponse;

    if (savedAnswerResponse.id) {
      const savedAnswer: SavedAnswer = {
        ...candidate,
        id,
        createdAt,
        updatedAt,
        createdBy,
        sharingStatus
      };
      store.upsert(savedAnswer);
    }
  }

  async handleAnswerModification() {
    if (!hasAnyOutputItems(this.nodes)) {
      return;
    }

    const proposedSharingStatus = [] as string[];
    if (this.isSharingAllowed) {
      proposedSharingStatus.push('internal');
    }
    const candidate: SavedAnswerCandidate = {
      title: this.title,
      answer: toParts(this.nodes),
      sharingStatus: proposedSharingStatus
    };

    candidate.id = this.selectedSavedAnswerId;

    await this.save(candidate);

    if (!this.savedAnswerExists) {
      customHistory.push(`./answers/${ candidate.id }`);
    }

    this.savedAnswerExists = true;

    if (this.answersStore.emailPreviewHtml) {
      this.answersStore.resetEmailPreviewHtml();
    }
  }

  @action
  async duplicate() {
    this.savedAnswerExists = false;
    this.isDuplicating = true;
    const duplicateId = uuid4();
    const originalId = this.selectedSavedAnswerId;

    const candidate: SavedAnswerCandidate = {
      id: duplicateId,
      duplicatedFrom: originalId,
      title: this.title,
      answer: toParts(this.nodes),
      sharingStatus: []
    };

    this.selectedSavedAnswerId = duplicateId;
    await this.save(candidate);
    this.isDuplicating = false;
    customHistory.push(`./${ duplicateId }`);
    this.savedAnswerExists = true;
  }

  @action
  reset() {
    this.socketMap.forEach((closeSocket: () => void) => {
      closeSocket();
    });
    this.socketMap.clear();

    this.nodes = [];
    this.title = '';
    this.currentAnswerUser = null;
    this.isSharingAllowed = false;
    this.isDuplicating = false;
    this.lastRevealedNodeId = null;
    this.candidateSavedAnswerId = uuid4();
  }

  @action
  setCurrentModal(modal: AnswerModal) {
    this.currentModal = modal;
  }

  @action
  setMenuSavedAnswerId(selectedSavedAnswerId: string | null) {
    this.menuSavedAnswerId = selectedSavedAnswerId;
  }

  @action
  setIsSharingAllowed(isAllowed: boolean) {
    this.isUpdatingIsSharingAllowed = true;
    this.isSharingAllowed = isAllowed;
    this.handleAnswerModification().finally(() => {
      this.isUpdatingIsSharingAllowed = false;
    });
  }

  @action
  updateAnswersEmailModalState(state: Partial<AnswersEmailModalState>) {
    this.answersEmailModalState = {
      ...this.answersEmailModalState,
      ...state
    };
  }

}
