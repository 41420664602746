import {
  AnalysisFilter,
  AnswersDataSet,
  AnswersFilters,
} from 'api/interfaces';
import * as moment from 'moment';
import { getAnalysisId } from 'stores/utils/analysis-helper';

const dataSetToViewSource = (answersDataSet: AnswersDataSet) => {
  return {
    survey: answersDataSet.surveyId,
    view: answersDataSet.viewId || undefined,
    visualization: answersDataSet.visId || '_',
  };
};

const formatFilterValues = (filterType: string, filterValues: string[]) => {
  if (filterType.toLowerCase().includes('date')) {
    const [startDate, endDate] = filterValues;

    return moment(startDate).format('D MMM YYYY') + ' - ' + moment(endDate).format('D MMM YYYY');
  }

  if (filterType.toLowerCase().includes('sentiment')) {
    return filterValues.join(' vs ');
  }

  return filterValues.join(', ');
};

const translateFilter = (
  answerFilterId: string,
  answerFilterValues: string[],
  answerDataSets: AnswersDataSet[],
  analysisFilters: Map<string, AnalysisFilter[]>
): [string, string[]] => {
  const dataset = answerDataSets.length === 1 ? answerDataSets[0] : null;

  if (!dataset) {
    return [answerFilterId, answerFilterValues];
  }

  const source = dataSetToViewSource(dataset);
  const sourceId = getAnalysisId(source);

  const filters = analysisFilters.get(sourceId);
  const configFilter = filters?.find((filter) => filter.id === answerFilterId);

  if (configFilter) {
    const translatedFilterId = configFilter.name;
    const translatedFilterValues = answerFilterValues.map((answerFilterValue) => {
      const filterValueConfig = configFilter?.cuts?.find(
        (configFilterValue) => configFilterValue.id === answerFilterValue,
      );

      return filterValueConfig ? filterValueConfig.name : answerFilterValue;
    });

    return [translatedFilterId, translatedFilterValues];
  }

  return [answerFilterId, answerFilterValues];
};

export const translateFilters = (
  answerFilters: AnswersFilters,
  dataSets: AnswersDataSet[],
  analysisFilters: Map<string, AnalysisFilter[]>
) => {
  const selectedFilters = dataSets.reduce((result, dataset) => {
    if (!dataset.globalFilters) {
      return result;
    }

    for (const [filterId, filterValues] of Object.entries(dataset.globalFilters)) {
      const [translatedId, translatedValues] = translateFilter(
        filterId,
        filterValues,
        dataSets,
        analysisFilters
      );

      result = {
        ...result,
        [translatedId]: translatedValues,
      };
    }

    return result;
  }, {});

  const foundFilters = Object.entries(answerFilters).reduce((result, [filterId, filterValues]) => {
    const [translatedId, translatedValues] = translateFilter(filterId, filterValues, dataSets, analysisFilters);

    return {
      ...result,
      [translatedId]: translatedValues,
    };
  }, {});

  const selectedEntries = Object.entries(selectedFilters);
  const foundEntries = Object.entries(foundFilters);

  return [...selectedEntries, ...foundEntries].map(([filterId, filterValues]) => {
    return {
      filterName: filterId,
      filterValues: formatFilterValues(filterId, filterValues as string[]),
    };
  });
};
