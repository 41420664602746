import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Button, Header, Modal } from 'semantic-ui-react';
import { ThemesStoreInterface } from 'stores/ThemesStore';

export interface ReorganizeThemesProps {
  themesStore?: ThemesStoreInterface;

  open: boolean;
  surveyId: string;

  cancel: () => void;
}
export interface ReorganizeThemesState {
  results?: ReorganizeThemesSuggestionsWithCheckbox;
  selectedFilterRql: string;
  largeSample: boolean;
}

interface ReorganizeThemesSuggestionsWithCheckbox {
  [themeCode: string]: {
    phrase: string;
    confidence: number;
    selected?: boolean;
  }[];
}

@inject('themesStore')
@observer
export default class ReorganizeThemes extends React.Component<
  ReorganizeThemesProps,
  ReorganizeThemesState
> {
  abortController = new AbortController();
  state = {
    results: undefined,
    selectedFilterRql: '',
    largeSample: false
  } as ReorganizeThemesState;

  componentWillUnmount() {
    this.abortController.abort();
  }

  componentDidMount() {
    this.applyAndClose();
  }

  close = () => {
    this.abortController.abort();
    this.props.cancel();
  };

  applyAndClose = async () => {
    const { themesStore, surveyId } = this.props;
    await themesStore!.reorganizeStructure(
      surveyId,
      this.abortController.signal
    );
    const { reorganizingStructureError } = themesStore!;
    if (!reorganizingStructureError) {
      this.close();
    }
  }

  render() {
    const { open, themesStore } = this.props;
    const { reorganizingStructureError } = themesStore!;

    return (
      <div>
        <Modal
          open={open}
          size="small"
          dimmer="blurring"
        >
          <Modal.Header as={Header}>Reorganizing Themes</Modal.Header>
          <Modal.Content>
            {reorganizingStructureError ? (
              <div>
                Oops, an error occured while reorganizing: {reorganizingStructureError}
              </div>
            ) : (
              <div>
                <FontAwesomeIcon
                  className="icon"
                  icon="spinner"
                  spin={true}
                  fixedWidth={true}
                />
                Please Wait
              </div>
            )}
          </Modal.Content>
          <Modal.Actions>
            <Button
              onClick={this.close}
            >
              Cancel
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}
