<template>
  <div
    :class="{ 'has-change': hasChange }"
    class="stack-rank-headline"
    @click="clearSelection()"
  >
    <div class="stack-rank-headline__label">
      <h3>Overall {{ label }}</h3>
    </div>
    <div class="stack-rank-headline__score">
      {{ scoreLabel }}
    </div>
    <div class="stack-rank-headline__nps">
      <segmented-bar
        :large="true"
        :max="maxScore"
        :min="minScore"
        :segments="segments"
        :score="score"
        :show-comment-count="!isWeighted"
      />
    </div>
    <div
      v-if="hasChange"
      class="change"
    >
      <component
        :is="changeArrow"
        v-if="changeArrow"
      />
      <span>{{ changeLabel }}</span>
    </div>
    <div class="stack-rank-headline__volume">
      {{ countLabel }}
    </div>
    <div class="stack-rank-headline__sentiment">
      <font-awesome-icon
        v-if="sentimentIcon"
        :icon="sentimentIcon"
        :class="`sentiment-${sentiment}`"
      />
    </div>
  </div>
</template>

<script>
import { includes, isNumber } from 'lodash';
import toFixed from 'vue/libs/to-fixed';
import { VALID_SENTIMENTS } from 'lib/calculate-sentiment';
import SegmentedBar from 'vue/components/Charts/SegmentedBar.vue';

export default {
  name: 'StackRankHeadline',
  components: { SegmentedBar },
  props: {
    count: { type: Number },
    label: { type: String },
    maxScore: { type: Number },
    minScore: { type: Number },
    previousScore: { type: Number },
    segments: { type: Array },
    score: { type: Number },
    sentiment: { type: String },
    countSuffix: { type: String, default: ' responses'},
    isWeighted: { default: false, type: Boolean }
  },
  computed: {
    arrowUp() {
      return () => import('images/vue-icons/arrow-up.svg');
    },
    arrowDown() {
      return () => import('images/vue-icons/arrow-down.svg');
    },
    change() {
      if (isNumber(this.previousScore)) {
        return this.score - this.previousScore;
      } else {
        return undefined;
      }
    },
    changeArrow() {
      const { change } = this;

      if (change >= 0.1) {
        return this.arrowUp;
      } else if (change <= 0.1) {
        return this.arrowDown;
      } else {
        return undefined;
      }
    },
    changeLabel() {
      const { change } = this;

      const text = toFixed(change, 1);
      if (change >= 0) {
        return `+${text}`;
      } else {
        return text;
      }
    },
    countLabel() {
      const { count, countSuffix } = this;
      return `${toFixed(count, 0, countSuffix)}`;
    },
    hasChange() {
      return isNumber(this.change);
    },
    scoreLabel() {
      const { empty, score } = this;
      if (!empty && isNumber(score)) {
        const label = toFixed(score, 1);
        if (score > 0) {
          return `+${label}`;
        } else {
          return label;
        }
      } else {
        return undefined;
      }
    },
    sentimentIcon() {
      if (includes(VALID_SENTIMENTS, this.sentiment)) {
        return this.sentiment;
      } else {
        return undefined;
      }
    }
  },
  methods: {
    clearSelection() {
      this.$emit('clearSelection');
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '../../../../styles/element-variables.scss';
@import './stack-rank.scss';
.stack-rank-headline {
  align-items: center;
  cursor: pointer;
  display: grid;
  grid: $stack-rank-grid;
  padding: 20px 0;
  position: relative;
  > div {
    padding: 0 10px;
    &:first-child {
      padding-left: 20px;
    }
    &:last-child {
      padding-right: 20px;
    }
  }
  &.selected {
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.1);
    &:before {
      background: $--orange-500;
      content: '';
      position: absolute;
      left: 0;
      height: 70px;
      width: 10px;
    }
  }
  &:hover {
    background-color: $--neutral-100;
  }
  &.has-change {
    grid: $stack-rank-grid-with-change;
  }
  .stack-rank-headline__score {
    color: $--color-primary;
    font-size: 22px;
    font-weight: 600;
    padding: 0 10px;
  }
  .stack-rank-headline__label {
    display: flex;
    flex-direction: column;
    text-transform: capitalize;
    h3 {
      font-size: 22px;
      font-weight: 600;
      margin: 0;
    }
  }
  .stack-rank-headline__volume {
    font-size: 14px;
    font-weight: 600;
    line-height: 19px;
    text-align: center;
  }

  .stack-rank-headline__sentiment {
    text-align: center;
    i,
    svg {
      color: $--color-primary;
      font-size: 30px;
      margin: 0 auto;
    }
    .sentiment-tired {
      color: $--sentiment-tired !important;
    }
    .sentiment-frown {
      color: $--sentiment-frown !important;
    }
    .sentiment-meh {
      color: $--sentiment-meh !important;
    }
    .sentiment-smile {
      color: $--sentiment-smile !important;
    }
    .sentiment-grin-stars {
      color: $--sentiment-grin !important;
    }
  }
  .change {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    span {
      flex: 1 1 auto;
    }
    svg {
      width: 1rem;
      height: 1rem;
      margin-right: 0.5rem;
      flex: 0 1 auto;
    }
  }
}
</style>

