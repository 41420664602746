import * as React from 'react';
import { AnswersDataSet, AnswersFilters } from 'api/interfaces';
import { useSingleFilters } from '../hooks/useSingleFilters';
import './context.scss';

interface Props {
  answerFilters: AnswersFilters;
  answerDataSets: AnswersDataSet[];
}

export const ContextNoData = ({ answerDataSets, answerFilters }: Props) => {
  const translatedFilters = useSingleFilters(answerFilters, answerDataSets);

  return (
    <section className="context">
      {answerDataSets.length > 0 && (
        <div>
          <span className="context__label">Datasets</span>
          {answerDataSets.length > 0 && (
            <span className="context__data-set">
              <span>{answerDataSets[0].title}</span>
              {answerDataSets.length > 1 && (
                <span className="context__data-set-count">+{answerDataSets.length - 1}</span>
              )}
            </span>
          )}
          {translatedFilters.map(({ filterName, filterValues }, index) => {
            return (
              <React.Fragment key={`${filterName}-${index}`}>
                <span className="context__bullet">•</span>
                <span className="context__label">{filterName}</span>
                <span className="context__values">{filterValues}</span>
              </React.Fragment>
            );
          })}
        </div>
      )}
    </section>
  );
};
