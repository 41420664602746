import { isArray, template } from 'lodash';
import { Orientation } from 'types/custom';
import Vue from 'vue';
import { generateScoreImpactData, sortBarGraphData } from './bargraphHelpers';
import { ThemeCountsWithImpact } from './types';

export type RenderScoreImpactDataArgs = {
  el: Vue | Element | Vue[] | Element[] | HTMLElement,
  baselineCount: number,
  comparisonCount: number,
  baseline: ThemeCountsWithImpact,
  comparison: ThemeCountsWithImpact,
  scoreType: string,
  scoreFormatter: string,
  scoreName: string | undefined,
  graphType: string,
  orderBy: string,
  range: number[] | undefined,
  showBaselineValues: boolean,
  showComparisonValues: boolean,
  barChartWidth: number,
  barChartHeight: number,
  onClick: Function
};

function getScoreImpactOptions({
  baselineCount,
  comparisonCount,
  baseline,
  comparison,
  scoreType,
  scoreFormatter,
  scoreName,
  graphType,
  orderBy,
  range,
  showBaselineValues,
  showComparisonValues,
  barChartWidth,
  barChartHeight,
  onClick
}: RenderScoreImpactDataArgs) {
  if ( graphType === 'effect' && orderBy === 'high') {
    orderBy = 'highAbs';
  } else if ( graphType === 'effect' && orderBy === 'low') {
    orderBy = 'lowAbs';
  }
  const data = generateScoreImpactData(
    baseline,
    comparison,
    graphType,
    baselineCount,
    comparisonCount
  );
  const sortedData = sortBarGraphData(data, orderBy);
  const labels = sortedData.map(item => item.label);

  let chartLabel = scoreName ? scoreName : 'score';

  if (scoreType === 'nps') {
    if (graphType === 'base') {
      chartLabel = 'NPS of theme';
    } else if (graphType === 'effect') {
      chartLabel = 'Impact on NPS';
    }
  } else {
    if (graphType === 'base') {
      chartLabel = `Average ${scoreName} of theme`;
    } else if (graphType === 'effect') {
      chartLabel = `Impact on average ${scoreName ? scoreName : 'score'}`;
    }
  }

  let chartUnit = '';
  if (isArray(range) && graphType === 'effect' && scoreType !== 'nps') {
    chartUnit = `%`;
  } else {
    let formatter = '<%= value %> points';
    if (scoreFormatter) {
      formatter = scoreFormatter;
    }
    chartUnit = template(formatter)({value: ''});
  }

  return {
    orderBy: orderBy || 'high',
    onClick,
    chartLabel,
    labels,
    showBaselineValues,
    showComparisonValues,
    data: sortedData,
    chartUnit: chartUnit.trim(),
    barChartWidth,
    barChartHeight,
    showCommentsCountInTooltip: true,
    orientation: Orientation.HORIZONTAL,
    showVerticalChartLabels: false
  };
}

export default {
  getScoreImpactOptions,
};
