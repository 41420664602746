<template>
  <div class="filter">
    <div class="header">
      <font-awesome-icon
        v-if="filterIcon"
        :icon="filterIcon"
        title="Select comparison"
      />
      {{ filterData.name }}
    </div>
    <el-popover
      ref="popover"
      :visible-arrow="false"
      :popper-class="popoverClass"
      placement="bottom-start"
      width="100%"
      trigger="click"
      @show="focusInput"
    >
      <el-input
        ref="inputElement"
        v-model="filterText"
        placeholder="Search"
        size="small"
        clearable
      />
      <el-tree
        ref="filterTree"
        :data="treeData"
        :expand-on-click-node="false"
        :default-checked-keys="defaultSelectedFilter"
        :filter-node-method="filterNode"
        show-checkbox
        class="filter-tree"
        node-key="id"
        @node-click="handleNodeClick"
        @check-change="handleCheckChange"
      />
    </el-popover>

    <el-button
      :id="buttonID"
      ref="button"
      v-popover:popover
      :type="filterKey"
      size="medium"
    >
      <span>
        {{ title }}
      </span>
      <font-awesome-icon icon="chevron-down" />
    </el-button>
  </div>
</template>


<script>
import { assign, cloneDeep, compact, debounce, forEach, includes, isEmpty, get, map, reduce } from 'lodash';

import { matchesRoute } from 'vue/libs/filter-watcher';
import { customTreeFilterFunction } from 'vue/libs/tree-functions';

const countBeforeTruncate = 1;

export default {
  name: 'FilterDropdownHierarchical',
  props: {
    filterData: { type: Object },
    filterIcon: { type: String },
    filterKey: { type: String },
    popoverCls: { type: String },
    selectedFilters: { type: Object },
    multiSelect: { type: Boolean },
  },
  data() {
    return {
      filterText: '',
      defaultSelectedFilter: [],
      title: this.filterData.cuts[0].name,
      buttonID: this._uid,
      workingData: [],
      changeSelectionDebounced: debounce(this.changeSelection, 10),
    };
  },
  computed: {
    filterId() {
      return this.filterData && this.filterData.id;
    },
    popoverClass() {
      const tokens = ['popover-no-gap'];
      if (this.popoverCls) {
        tokens.push(this.popoverCls);
      }
      return tokens.join(' ');
    },
    treeData() {
      return map(this.workingData, this.treeElementFromNode);
    },
  },
  watch: {
    filterText(val) {
      this.$refs.filterTree.filter(val);
    },
    selectedFilters: {
      handler(newFilter) {
        const { filterKey, filterId, $route } = this;
        if (!matchesRoute($route, filterKey, filterId)) {
          this.buildFromFilterData(newFilter);
        }
      },
    },
    $route: {
      handler(to) {
        if (!to) {
          return;
        }
        const { filterKey, filterId } = this;
        const selected = matchesRoute(to, filterKey, filterId);
        if (selected) {
          this.setFromIds(selected);
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.generateUniqueLocalIDs();
    const key = `selectedFilters[${this.filterKey}][${this.filterData.id}].selected`;

    // if it's not there treat the same as empty selection
    const selected = get(this, key, []);
    if (selected.length) {
      this.changeSelection();
    } else if (this.filterData) {
      // set filters to first option
      this.$refs.filterTree.setChecked(this.workingData[0].id, true, false);
      this.changeSelection();
    }

    this.$refs.filterTree.store.filter = customTreeFilterFunction;
  },
  methods: {
    onParentClick(e) {
      let { target } = e;

      while ((target = target.parentElement)) {
        if (target === this.$el) {
          return;
        }
      }
      // if we didn't find this element while walking up parents
      // it's an off-click
      this.$refs.popover.doClose();
    },
    focusInput: function () {
      // this is called in the middle of a transition so we should wait a bit
      const element = this.$refs.inputElement.$refs.input;
      setTimeout(function () {
        element.focus();
      }, 100);
    },
    buildRql(node) {
      if (node.indeterminate) {
        const childRqls = compact(map(node.childNodes, this.buildRql));

        if (childRqls.length) {
          return `(${node.data.rql};(${childRqls.join(',')}))`;
        } else {
          return node.data.rql;
        }
      } else if (node.checked) {
        return node.data.rql;
      } else {
        return;
      }
    },
    calculateRql() {
      const { root } = this.$refs.filterTree;

      const queries = reduce(
        root.childNodes,
        (result, node) => {
          const query = this.buildRql(node);
          if (query) {
            result.push(query);
          }
          return result;
        },
        [],
      );
      return queries.join(',');
    },
    calculateSelectedFilter() {
      const selections = this.getCheckedSelectionData();
      const titleTokens = [this.buildTitle(selections)];
      if (selections.length > countBeforeTruncate) {
        titleTokens.unshift(this.filterData.name);
      }
      const name = titleTokens.join(': ');

      if (selections.length) {
        const rql = this.calculateRql();

        return map(selections, (selection, i) => {
          // only the first selection gets name & rql
          if (i === 0) {
            // assign so it's a clone
            return assign({}, selection, { name, rql });
          } else {
            return assign({}, selection, { name: '', rql: '' });
          }
        });
      } else {
        return [];
      }
    },
    changeSelection() {
      const selected = this.calculateSelectedFilter();

      // Update store
      const queryFilter = {
        filterKey: this.filterKey,
        filterId: this.filterData.id,
        filterName: this.filterData.name,
        filterType: 'hierarchical',
        selected,
      };
      this.$emit('selectionChanged', queryFilter, this.title);
    },
    /**
     * Adapated from TreeStore's getCheckedNodes
     */
    getChildKeys(key) {
      const childKeys = [];

      function traverse(node) {
        const childNodes = node.root ? node.root.childNodes : node.childNodes;

        childNodes.forEach(function (child) {
          childKeys.push(child.key);

          traverse(child);
        });
      }

      traverse(this.$refs.filterTree.getNode(key));

      return childKeys;
    },
    getCheckedSelectionData() {
      const { filterTree: tree } = this.$refs;
      const keys = tree.getCheckedKeys();
      const ignore = [];
      const selected = [];

      if (!!keys && keys.length !== 0) {
        forEach(keys, (key) => {
          if (includes(ignore, key)) {
            return;
          }
          ignore.push(...this.getChildKeys(key));
          const node = tree.getNode(key);
          selected.push(node.data);
        });
      }

      return selected;
    },
    treeElementFromNode(node) {
      const { id, rql, name } = node;
      const element = { id, rql, label: name, name };

      if (node.children) {
        element.children = map(node.children.cuts, this.treeElementFromNode);
      }
      return element;
    },
    handleNodeClick(data, node) {
      const { filterTree: tree } = this.$refs;
      const { id: key } = data;

      if (this.multiSelect) {
        if (key === 'all_') {
          tree.setCheckedKeys([key]);
        } else {
          const resetNodes = tree.getCheckedNodes();
          tree.setChecked('all_', false);
          tree.setChecked(data, !node.checked, true);
          if (tree.getCheckedKeys().length === 0) {
            tree.setCheckedNodes(resetNodes);
          }
        }
      } else {
        tree.setCheckedKeys([data.id]);
      }
    },
    generateUniqueLocalIDs: function () {
      const clone = cloneDeep(this.filterData.cuts);
      for (const i in clone) {
        const cut = clone[i];
        this.buildLocalised(null, cut);
      }
      this.workingData = clone;
      this.$refs.filterTree.setChecked([this.workingData[0].id], true, false);
      //default select
      this.defaultSelectedFilter = [this.workingData[0].id];
      this.title = this.workingData[0].name;
    },
    buildLocalised: function (currentID, data) {
      const workingId = (currentID ? currentID : '') + data.id;
      //top level if has children then pass down current Id
      if (data.children) {
        for (const i in data.children.cuts) {
          const child = data.children.cuts[i];
          this.buildLocalised(workingId, child);
        }
      }
      data.id = workingId;
    },
    buildFromFilterData: function (newData) {
      var selected = get(newData, `${this.filterKey}.${this.filterData.id}.selected`);
      if (selected && !isEmpty(selected)) {
        const keys = map(selected, 'id');
        this.$refs.filterTree.setCheckedKeys(keys);
      }
    },
    setFromIds(selected) {
      // needs this to wait for `filterTree` to get instantiated
      const { filterTree: tree } = this.$refs;
      if (!tree) {
        this.$nextTick(() => {
          this.setFromIds(selected);
        });
        return;
      }
      if (selected.length) {
        tree.setCheckedKeys(selected);
      }
      const checked = tree.getCheckedKeys();
      if (!!checked && checked.length === 0) {
        tree.setCheckedKeys(['all_']);
      }
    },
    uncheckAll: function () {
      this.propagateCheck(this.$refs.filterTree.root, false);
    },
    propagateCheck: function (node, forced) {
      node.setChecked(forced === undefined || forced === null ? !node.checked : forced, true);
    },
    handleCheckChange() {
      this.setTitle();
      this.changeSelectionDebounced();
    },
    filterNode(value, data) {
      if (!value) {
        return true;
      }
      return data.label.toLowerCase().indexOf(value.toLowerCase()) !== -1;
    },
    setTitle() {
      const selections = this.getCheckedSelectionData();
      this.title = this.buildTitle(selections);
    },
    buildTitle(selections) {
      const count = selections.length;
      const { filterTree: tree } = this.$refs;
      if (count > countBeforeTruncate) {
        return `${count} selected`;
      } else {
        const labels = map(selections, (selection) => {
          const tokens = [];
          let node = tree.getNode(selection.id);
          while (node.level > 0) {
            tokens.unshift(node.data.label);
            node = node.parent;
          }

          return tokens.join(' > ');
        });
        return labels.join(', ');
      }
    },
  },
};
</script>

<style>
.popover-no-gap {
  border-width: 2px;
  margin-top: 0px !important;
  width: 300px;
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '../../styles/element-variables.scss';
@import '../../styles/filter-set-styles.scss';

.el-button {
  width: 100%;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-left: 10px;
  color: $--neutral-800;
  line-height: 22px;
  background-color: white;
  .dashboard .filter-item & {
    @extend .filter-button-base-style;
  }
  &--comparison {
    &:hover {
      color: inherit;
      background: $--red-100;
    }
  }
  ::v-deep {
    span {
      align-items: center;
      display: flex;
      span {
        flex: 1 1 auto;
      }
      svg {
        flex: 0 0 auto;
        margin: 0 3px;
      }
    }
  }
}

.el-tree ::v-deep .el-checkbox {
  margin-bottom: 0;
  width: 20px;
  height: 20px;
  margin-right: 6px;
}
.el-input {
  margin-bottom: 10px;
  .el-input--small .el-input__inner {
    height: 40px !important;
    line-height: 40px !important;
  }
}

.el-tree ::v-deep {
  max-height: 250px;
  overflow-y: auto;
}

.el-tree ::v-deep .el-tree-node__content {
  height: auto;
  min-height: 34px;
}
.el-tree ::v-deep .el-checkbox__inner {
  border: 0px;
  left: 2px;
  background-color: transparent;
  margin: auto;

  &:after {
    content: unset;
  }
}
.el-tree ::v-deep {
  .el-tree-node {
    > .el-tree-node__content {
      .is-indeterminate {
        .el-checkbox__inner {
          background-color: transparent !important;
          border-color: $--border-color-base;

          &:after {
            content: unset;
          }
          &:before {
            position: absolute;
            margin: auto;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            border-color: $--color-black;
            content: '';
            transform: initial;
            width: 14px;
            height: 14px;
            background-color: transparent;
            background-image: url('data:image/svg+xml;utf8,<svg role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><g><path fill="currentColor" d="M424 318.2c13.3 0 24-10.7 24-24v-76.4c0-13.3-10.7-24-24-24H24c-13.3 0-24 10.7-24 24v76.4c0 13.3 10.7 24 24 24h400z"></path></g></svg>');
          }
        }
      }
    }
  }
}

.el-tree ::v-deep .is-checked .el-checkbox__inner {
  background-color: transparent !important;
  border-color: $--border-color-base;
  &:after {
    content: unset;
  }
  &:before {
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-color: $--color-black;
    content: '';
    transform: initial;
    width: 14px;
    background-color: transparent;
    height: 14px;
    background-image: url('data:image/svg+xml;utf8,<svg role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><g><path fill="currentColor" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></g></svg>');
  }
}
.el-tree ::v-deep .is-checked {
  font-weight: $--font-weight-semi;
}
.el-tree ::v-deep .el-tree-node__expand-icon {
  background-color: transparent !important;
  border-color: $--border-color-base;
  margin-right: 5px;
  margin-left: 6px;

  &:before {
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-color: $--color-black;
    content: '';
    transform: initial;
    width: 14px;
    height: 14px;
    background-color: transparent;
    background-image: url('data:image/svg+xml;utf8,<svg role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><g><path fill="currentColor" d="M256 8c137 0 248 111 248 248S393 504 256 504 8 393 8 256 119 8 256 8zm113.9 231L234.4 103.5c-9.4-9.4-24.6-9.4-33.9 0l-17 17c-9.4 9.4-9.4 24.6 0 33.9L285.1 256 183.5 357.6c-9.4 9.4-9.4 24.6 0 33.9l17 17c9.4 9.4 24.6 9.4 33.9 0L369.9 273c9.4-9.4 9.4-24.6 0-34z"></path></g></svg>');
  }
  &.is-leaf {
    background-color: transparent !important;
    border-color: $--border-color-base;
    &:before {
      position: absolute;
      margin: auto;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      content: '';
      transform: initial;
      width: 14px;
      height: 14px;
      background-image: none;
    }
  }
}
.popover {
  width: 300px;
}

.el-tree ::v-deep .el-tree-node__label {
  display: block;
  position: relative;
  left: -28px;
  padding-left: 28px;
  z-index: var(--z-index-dashboard-filters);
  white-space: pre-wrap;
  text-align: left;
}
</style>
