var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('widget-wrapper',{attrs:{"title":_vm.config.title,"loading":_vm.loading,"error":_vm.error,"warning":_vm.warning,"panel-order":_vm.panelOrder,"widget-order":_vm.widgetOrder}},[_c('div',{staticClass:"widget-subtitle",class:{ 'widget-subtitle-clickable': _vm.canNavigate },attrs:{"slot":"subtitle"},on:{"click":function($event){return _vm.doNavigate()}},slot:"subtitle"},[_c('div',{staticClass:"widget-subtitle-text"},[_vm._v("\n      "+_vm._s(_vm.widgetSubtitle)+"\n      "),(_vm.canNavigate)?_c('font-awesome-icon',{staticClass:"link-icon",attrs:{"icon":"chart-bar"}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"widget-display-categories"},[_c('div',{staticClass:"category"},[_vm._v("\n        Score\n      ")]),_vm._v(" "),_c('div',{staticClass:"category large-category"},[_vm._v("\n        Change\n      ")])])]),_vm._v(" "),(_vm.themes.length)?_c('div',{staticClass:"themes-body widget-body"},_vm._l((_vm.themes),function(theme,index){return _c('div',{key:index + theme.name,staticClass:"theme-row theme-row-spacing"},[(!_vm.isWeighted)?_c('div',{staticClass:"theme-name",class:{ 'clickable-theme': _vm.canClickTheme },on:{"click":function($event){return _vm.doNavigate({code: theme.basethemeCode, title: theme.basetheme},
                           theme.subthemeCode ? {code: theme.subthemeCode, title: theme.subtheme} : undefined)}}},[_c('span',[_vm._v(_vm._s(theme.name))])]):_c('theme-name-with-sources',{attrs:{"source":_vm.source,"tool":"explore","theme":theme.name,"theme-components":theme.components,"theme-name":theme.name,"theme-sources":_vm.themeSources}}),_vm._v(" "),_c('div',{staticClass:"value"},[_vm._v("\n        "+_vm._s(theme.displayValue)+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"change-value"},[_c('change-value',{attrs:{"value":theme.value,"previous-value":theme.previousValue}})],1),_vm._v(" "),_c('div',{staticClass:"spark-line"},[_c('spark-line',{attrs:{"values":theme.history}})],1)],1)}),0):_c('div',{staticClass:"empty widget-body"},[_vm._v("\n    No changes found\n  ")]),_vm._v(" "),(_vm.isSummaryEnabled)?_c('summary-component',{attrs:{"filters":_vm.filters,"theme":_vm.selectedTheme,"source":_vm.source,"comparison-themes":_vm.comparisonThemes,"can-navigate":_vm.canNavigate},on:{"doNavigate":function () { return _vm.doNavigate(
          _vm.selectedTheme.theme,
          _vm.selectedTheme.subtheme,
          _vm.selectedTheme.volumeBy,
          true
        ); }}}):_vm._e(),_vm._v(" "),(_vm.isCommentsEnabled)?_c('comment-component',{attrs:{"filter-string":_vm.filters ? _vm.filters.baseline : undefined,"theme":_vm.selectedTheme,"source":_vm.source,"can-navigate":_vm.canNavigate},on:{"doNavigate":function () { return _vm.doNavigate(
          _vm.selectedTheme.theme,
          _vm.selectedTheme.subtheme,
          _vm.selectedTheme.volumeBy,
          true
        ); }}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }