import * as React from 'react';
import { AnswerMetadata, AnswerResponse, AnswersDataSet, AnswersFilters, InputData, TranslatedFilter, isComparisonResponse, isNonComparisonResponse } from 'api/interfaces';
import { translateFilters } from '../utils/getTranslatedFilters';
import { useAnswersContext } from '../utils/context';

function removeCommonFiltersFromComparison(obj1: AnswersFilters, obj2: AnswersFilters) {
  return Object.keys(obj1).reduce((newObj, prop) => {
    const hasDifferentProp = !(prop in obj2) || JSON.stringify(obj1[prop]) !== JSON.stringify(obj2[prop]);

    if (hasDifferentProp) {
      newObj[prop] = obj1[prop];
    }
    return newObj;
  }, {});
}

export interface TranslatedComparison {
  filters: TranslatedFilter[];
  inputData: InputData[];
  metaData: AnswerMetadata;
  name: string;
}

export const useComparisonFilters = (answer: AnswerResponse, dataSets: AnswersDataSet[]) => {
  const { analysisFilters } = useAnswersContext();
  const [translatedComparisons, setTranslatedComparisons] = React.useState<TranslatedComparison[]>([]);

  React.useEffect(() => {
    const getComparisonFilters = () => {
      if (isNonComparisonResponse(answer)) {
        return;
      }

      const deduplicatedComparisonFilters = answer.comparisons.map((comparison) => {
        const deduplicatedFilters = removeCommonFiltersFromComparison(comparison.filters, answer.filters);

        return {
          ...comparison,
          filters: deduplicatedFilters,
        };
      });

      const deduplicatedComparison = deduplicatedComparisonFilters.map(({
        filters,
        metadata,
        name,
        inputData
      }) => {
          const comparisonFilters = translateFilters(filters, dataSets, analysisFilters);

          return {
            filters: comparisonFilters,
            inputData,
            metaData: metadata,
            name,
          };
        });

      setTranslatedComparisons(deduplicatedComparison);
    };

    if (isComparisonResponse(answer) && answer.comparisons.length > 0) {
      getComparisonFilters();
    }
  }, [answer.filters, dataSets, analysisFilters, answer]);

  return translatedComparisons;
};
