<template>
  <widget-wrapper
    :title="config.title"
    :loading="loading"
    :error="error"
    :warning="warning"
  >
    <div
      slot="subtitle"
      class="widget-subtitle"
    >
      <div class="widget-subtitle-text">
        {{ widgetSubtitle }}
      </div>
    </div>
    <div
      v-if="scoreLabel"
      :class="{'has-comparison': !!compareFilter }"
      class="widget-body original-comparison-widget scores"
    >
      <div
        class="overall score"
      >
        <div class="score-header">
          <h2>
            {{ scoreLabel }}
          </h2>
          <div class="score-change">
            <font-awesome-icon
              v-if="scoreChangeIndicator"
              :icon="scoreChangeIndicator"
            />
            {{ scoreChangeLabel }}
          </div>
        </div>

        <legend>{{ scoreName }}</legend>
      </div>
      <div
        v-if="compareFilter"
        class="comparison score"
      >
        <div class="current">
          <div class="score-header">
            <h2>
              {{ comparisonScoreLabel }}
            </h2>
            <div class="score-change">
              <font-awesome-icon
                v-if="comparisonScoreChangeIndicator"
                :icon="comparisonScoreChangeIndicator"
              />
              {{ comparisonScoreChangeLabel }}
            </div>
          </div>
          <legend>{{ comparisonScoreName }}</legend>
          <legend class="goodwill">
            Goodwill
          </legend>
        </div>
        <div class="original">
          <div class="score-header">
            <h2>
              {{ originalComparisonScoreLabel }}
            </h2>
            <div class="score-change">
              <font-awesome-icon
                v-if="originalComparisonScoreChangeIndicator"
                :icon="originalComparisonScoreChangeIndicator"
              />
              {{ originalComparisonScoreChangeLabel }}
            </div>
          </div>

          <legend>{{ originalComparisonScoreName }}</legend>
        </div>
      </div>
    </div>
    <div
      v-else
      class="empty widget-body"
    >
      No data found
    </div>
  </widget-wrapper>
</template>

<script>
import { get, isNumber } from 'lodash';
import toFixed from 'vue/libs/to-fixed';
import { getScoreChange, getScoreChangeIndicator } from 'vue/libs/widget-utils';

import WidgetWrapper from './WidgetWrapper';

export default {
  name: 'ORIGINAL_SCORE_COMPARISON',
  components: {
    WidgetWrapper
  },
  props: {
    config: { type: Object },
    context: { type: Object },
    data: { type: Object },
    warning: { type: String },
    error: { type: String },
    limit: { default: 5, type: Number },
    loading: { type: Boolean },
    source: { type: String }
  },
  computed: {
    compareFilter () {
      return get(this, 'context.compareFilter', undefined);
    },
    scoreConfig() {
      return get(this, 'data.scoreConfig', get(this.config, 'score'));
    },
    currentScore() {
      return get(this, 'data.currentScore.score.score');
    },
    previousScore() {
      return get(this, 'data.previousScore.score.score');
    },
    currentComparisonScore() {
      return get(this, 'data.currentComparisonScore.score.score');
    },
    previousComparisonScore() {
      return get(this, 'data.previousComparisonScore.score.score');
    },
    currentComparisonOriginalScore() {
      return this.getOriginalScore(get(this, 'data.currentComparisonScore'));
    },
    previousComparisonOriginalScore() {
      return this.getOriginalScore(get(this, 'data.previousComparisonScore'));
    },
    widgetSubtitle() {
      const { scoreName, comparisonScoreName } = this;
      if (comparisonScoreName) {
        return `${scoreName} vs ${comparisonScoreName}`;
      }
      return scoreName;
    },
    scoreName() {
      return get(this, 'data.currentScore.name', '');
    },
    comparisonScoreName() {
      return get(this, 'data.currentComparisonScore.name', '');
    },
    originalComparisonScoreName() {
      return get(this, 'currentComparisonOriginalScore.name', '');
    },
    scoreLabel() {
      return toFixed(this.currentScore, 1);
    },
    comparisonScoreLabel() {
      return toFixed(this.currentComparisonScore, 1);
    },
    originalComparisonScoreLabel() {
      return toFixed(this.currentComparisonOriginalScore.score, 1);
    },
    scoreChange() {
      return getScoreChange(this.currentScore, this.previousScore);
    },
    scoreChangeLabel() {
      const { scoreChange } = this;
      return this.getScoreChangeLabel(scoreChange);
    },
    scoreChangeIndicator() {
      const { scoreChange } = this;
      return getScoreChangeIndicator(scoreChange);
    },
    comparisonScoreChange() {
      return getScoreChange(this.currentComparisonScore, this.previousComparisonScore);
    },
    comparisonScoreChangeLabel() {
      const { comparisonScoreChange } = this;
      return this.getScoreChangeLabel(comparisonScoreChange);
    },
    comparisonScoreChangeIndicator() {
      const { comparisonScoreChange } = this;
      return getScoreChangeIndicator(comparisonScoreChange)
    },
    originalComparisonScoreChange() {
      return getScoreChange(this.currentComparisonOriginalScore.score, this.previousComparisonOriginalScore.score);
    },
    originalComparisonScoreChangeLabel() {
      const { originalComparisonScoreChange } = this;
      return this.getScoreChangeLabel(originalComparisonScoreChange);
    },
    originalComparisonScoreChangeIndicator() {
      const { originalComparisonScoreChange } = this;
      return getScoreChangeIndicator(originalComparisonScoreChange)
    }
  },
  methods: {
    getPrecision (scoreChange) {
      let precision = get(this,'scoreConfig.options.precision', this.config.precision);
      if (isNumber(precision)) {
        return precision;
      }

      const absChange = Math.abs(scoreChange);
      if (absChange < 10) {
        return 1;
      } else {
        return 0;
      }
    },
    getScoreChangeLabel (scoreChange) {
      if (scoreChange) {
        const precision = this.getPrecision(scoreChange);
        const label = toFixed(scoreChange, precision);
        const absChange = Math.abs(label, 1);
        return absChange;
      }
      return undefined;
    },
    getOriginalScore (score) {
      if (!score) {
        return {};
      }
      return get(score, 'score.componentScores.0.originalScore');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../styles/element-variables';

.widget-body.scores.original-comparison-widget {
  padding: 10px;
  display: grid;
  grid-template-columns: 1fr 0fr;
  min-height: 200px;

  &.has-comparison {
    grid-template-columns: 1.5fr 2fr;
    grid-gap: 10px;

    .overall {
      background-color: $--primary-100;
    }
  }

  .score legend {
    color: $--neutral-900;
  }

  .score-change {
    color: $--neutral-600;
    display: inline-flex;
    font-size: 16px;
    line-height: 1;

    svg {
      font-size: 16px;
      margin-right: 5px;
    }
  }

  .overall h2, .current h2 {
    font-size: 64px;
  }

  .overall, .original, .current {
    padding: 10px;

    .score-header {
      align-items: flex-end;
      display: flex;
      flex-flow: wrap;
    }

    h2 {
      line-height: 0.8;
      padding-right: 5px;
    }

    legend {
      font-size: 16px;
      padding-top: 5px;
    }
  }

 .comparison {
    align-items: center;
    background-color: $--orange-100;
    display: grid;
    grid-column: 2 / 4;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    padding: 10px;

    .goodwill {
      font-size: 14px;
      padding-top: 0;
    }

    .original {
      padding-top: 20px;

      h2 {
        font-size: 40px;
      }
    }
  }
}
</style>
