import { Theme } from "vue/explore/theme-data-functions";
import { Counts, Dimension, ExportAssistantTableData, ThemeByDate, ThemesByDate } from "./types";
import { getBaselineCount } from "./fetch-themes.util";

const mapDimensionToTableData = (dimensions: Dimension[]): ExportAssistantTableData[] => {
  return dimensions.map((item) => ({
    rowNameOne: item.name,
    volume: (item.data / dimensions[0].data) * 100,
    sentimentCounts: {
      negative: item.sentimentCounts.neg,
      neutral: item.sentimentCounts.neut,
      positive: item.sentimentCounts.pos,
    },
    count: item.data,
  }));
}

const mapThemeToTableData = (theme: Theme): Partial<ExportAssistantTableData> => {
  return {
    impact: theme.baseImpact,
    volume: theme.baseVolume || 0,
    score: theme.baseScore,
    sentimentCounts: {
      negative: theme.baseSentiments?.neg || 0,
      neutral: theme.baseSentiments?.neut || 0,
      positive: theme.baseSentiments?.pos || 0,
    },
    count: theme.baseCount || 0,
  };
}

const mapThemesToTableData = (themes: Theme[]): ExportAssistantTableData[] => {
  return themes.flatMap((theme) => {
    const themeData = {
      rowNameOne: theme.title,
      ...mapThemeToTableData(theme),
    };
    const subThemeData =
      theme.subthemes?.map((subTheme) => ({
        rowNameOne: theme.title,
        rowNameTwo: subTheme.title,
        ...mapThemeToTableData(subTheme),
      })) || [];

    return [themeData, ...subThemeData];
  });
}

const mapThemeByDateToTableData = (value: ThemeByDate, counts: Counts) => {
  const baseCount = value.count;
  const baseTotal = getBaselineCount(counts);
  const baseVolume = (100.0 * baseCount) / baseTotal || 0;
  const impact = value.impactPercent || value.impact;
  const score = value.score.score;

  return {
    volume: baseVolume,
    impact,
    score,
    sentimentCounts: {
      negative: value.sentimentCounts.neg,
      neutral: value.sentimentCounts.neut,
      positive: value.sentimentCounts.pos,
    },
    count: value.count || 0,
  };
}

const mapDimensionsByCategoryToTableData = (dimensionsByCategory: Dimension[][]) => {
  const dimensionsByDateTableData = dimensionsByCategory.map((dimensionData) => {
    return mapDimensionToTableData(dimensionData);
  });

  return dimensionsByDateTableData.reduce((result, dateDimensions) => {
    for (const dimension of dateDimensions) {
      const rowName = dimension?.rowNameOne || 'Empty string';

      if (!result?.[rowName]) {
        result[rowName] = [];
      }

      result[rowName].push(dimension);
    }

    return result;
  }, {});
}


const mapThemesByDateToTableData = (themesByDate: ThemesByDate) => {
  const { dateLabels, themes } = themesByDate;

  const dateLabelsDescending = dateLabels.slice().reverse();
  const themesDescending = themes.slice().reverse();

  const mergedThemes = themesDescending.reduce((acc, currentDateRange, dateIndex) => {
    for (const [baseThemeKey, baseThemeValue] of Object.entries(currentDateRange.themes)) {
      const dateLabel = dateLabelsDescending[dateIndex];

      if (!acc[baseThemeKey]) {
        acc[baseThemeKey] = {
          subthemes: {},
        };
      }

      if (!acc[baseThemeKey][dateLabel]) {
        acc[baseThemeKey][dateLabel] = mapThemeByDateToTableData(baseThemeValue, currentDateRange.counts);
      }

      if (!baseThemeValue?.subthemes) {
        return acc;
      }

      for (const [subThemeKey, subThemeValue] of Object.entries(baseThemeValue.subthemes)) {
        if (!acc[baseThemeKey]?.subthemes?.[subThemeKey]) {
          acc[baseThemeKey].subthemes[subThemeKey] = {};
        }

        acc[baseThemeKey].subthemes[subThemeKey][dateLabel] = mapThemeByDateToTableData(
          subThemeValue,
          currentDateRange.counts,
        );
      }
    }

    return acc;
  }, {});

  return {
    dateLabels: dateLabelsDescending,
    themes: mergedThemes,
  };
}

export {
  mapDimensionToTableData,
  mapDimensionsByCategoryToTableData,
  mapThemesByDateToTableData,
  mapThemesToTableData,
};
