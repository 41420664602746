import { useCallback, useRef, useState } from 'react';
import { debounce } from 'lodash';
import { removeBoundaryPeriodsAndCommas } from 'lib/string-helper';
import { Block } from 'lib/segments-to-blocks';
import { SelectedPhrasePosition, SelectionStatus, SELECTION_STATUS, MAX_SELECTED_WORDS } from './types';

function expandRangeToWord(range) {
  // this function is based on https://stackoverflow.com/questions/7809319/get-selected-text-expanded-to-whole-words
  if (range.collapsed) {
    return;
  }

  while (range.startOffset > 0 && range.toString()[0].match(/\w/)) {
    range.setStart(range.startContainer, range.startOffset - 1);
  }

  while (range.endOffset < range.endContainer.length && range.toString()[range.toString().length - 1].match(/\w/)) {
    range.setEnd(range.endContainer, range.endOffset + 1);
  }
}

const defaultSelectedPhrasePosition = { x: 0, y: 0 };

export interface AddThemeToSelection {
  onClickOutside: () => void;
  onEnterBlock: (block: Block) => void;
  onTextSelectionEnd: () => void;
  onSelectionChange: () => void;
  selectedPhrase: string;
  selectedPhrasePosition: SelectedPhrasePosition;
  selectedStatus: SelectionStatus;
  setSelectedStatus: React.Dispatch<React.SetStateAction<SelectionStatus>>;
}

const useAddThemeToSelection = () => {
  const [isSelectionInProgress, setIsSelectionInProgress] = useState<boolean>(false);
  const lastEnteredBlockRef = useRef<Block | null>(null);
  const [selectedPhrase, setSelectedPhrase] = useState<string>('');
  const [selectedPhrasePosition, setSelectedPhrasePosition] = useState<SelectedPhrasePosition >(defaultSelectedPhrasePosition);
  const [selectedStatus, setSelectedStatus] = useState<SelectionStatus>(SELECTION_STATUS.None);

  const updateSelection = () => {
    const selection = window.getSelection();

    if (!selection) {
      return;
    }

    if (selection.type === 'None' || selection.toString().trim() === '') {
      setSelectedPhrase('');
      setSelectedStatus(SELECTION_STATUS.None);

      return;
    }

    const range = selection.getRangeAt(0);
    expandRangeToWord(range);

    if (!range.startContainer?.parentElement?.offsetParent) {
      return;
    }

    const rangeRect = range.getBoundingClientRect();
    const parentRect = range.startContainer.parentElement.offsetParent.getBoundingClientRect();

    // The user can drag a selection range off the bottom edge of the intended selectable region.
    const rangeGoesBelowParent = rangeRect.bottom > parentRect.bottom;

    const x = rangeRect.left - parentRect.left + rangeRect.width / 2;

    // The magic numbers are for fine tuning of the position
    const y = rangeGoesBelowParent ? parentRect.height - 10 : rangeRect.top - parentRect.top + rangeRect.height - 10;

    setSelectedPhrasePosition({ x, y });

    const selectedPhrase = removeBoundaryPeriodsAndCommas(range.toString().trim());
    setSelectedPhrase(selectedPhrase);

    if (!lastEnteredBlockRef.current) {
      return;
    }

    // The selection could be dragged across multiple segments, or even
    // triple clicked to select all segments.
    const isSelectionWithinOneSegment = lastEnteredBlockRef.current.content.includes(selectedPhrase);

    if (!isSelectionWithinOneSegment) {
      setSelectedStatus(SELECTION_STATUS.InvalidCrossesBoundary);
      return;
    }

    const selectedWords = selectedPhrase.split(/\s+/).filter((word) => word.trim().length > 0);

    if (selectedWords.length > MAX_SELECTED_WORDS) {
      setSelectedStatus(SELECTION_STATUS.InvalidTooManyWords);

      return;
    }

    setSelectedStatus(SELECTION_STATUS.Valid);
  };

  const onEnterBlock = (block: Block) => {
    lastEnteredBlockRef.current = block;
  };

  const onTextSelectionEnd = useCallback(() => {
    if (isSelectionInProgress) {
      return;
    }

    // This method is triggered by a mouse event, ideally the release of a mouse
    // that has been selecting some text. It seems that the mouse event fires before
    // the window knows the selection has changed, so we add an artificial delay to
    // allow the selection to change first.

    setTimeout(() => {
      setIsSelectionInProgress(false);
      updateSelection();
    }, 100);
  }, []);

  const onTextSelectionStart = () => {
    setIsSelectionInProgress(true);
    setSelectedPhrase('');
    setSelectedStatus(SELECTION_STATUS.None);
  };

  const onSelectionChange = useCallback(debounce(() => {
    setIsSelectionInProgress(true);

    if (selectedStatus === SELECTION_STATUS.None) {
      return;
    }

    if (window.getSelection()?.toString() === '') {
      setSelectedStatus(SELECTION_STATUS.None)
    }
  }), []);

  const onClickOutside = () => {
    setSelectedPhrase('');
    setSelectedStatus(SELECTION_STATUS.None);
  };

  return {
    onClickOutside,
    onEnterBlock,
    onSelectionChange,
    onTextSelectionEnd,
    onTextSelectionStart,
    selectedPhrase,
    selectedPhrasePosition,
    selectedStatus,
    setSelectedStatus,
  };
};

export { useAddThemeToSelection };
