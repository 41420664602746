import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import analytics from 'lib/analytics';
import { compact, debounce, isEmpty } from 'lodash';
import { inject, observer } from 'mobx-react';
import { parse, stringify } from 'query-string';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
  Button,
  Checkbox,
  CheckboxProps, FormInputProps, Header,
  Input,
  Loader,
  Menu, Message,
  Pagination,
  PaginationProps,
  Segment,
  Table
} from 'semantic-ui-react';
import { OrganizationStoreInterface } from 'stores/OrganizationStore';
import { RoleStoreInterface, RolesPageRequestParams } from 'stores/RoleStore';
import { SurveyStoreInterface } from 'stores/SurveyStore';
import './roles.scss';

export interface RolesParams {
  orgId: string;
}
export interface RolesProps extends RouteComponentProps<RolesParams> {
  surveyStore?: SurveyStoreInterface;
  roleStore?: RoleStoreInterface;
  organizationStore?: OrganizationStoreInterface;
}

enum Direction {
  ASC = 'ascending',
  DESC = 'descending'
}

@inject('surveyStore', 'roleStore', 'organizationStore')
@observer
export default class Roles extends React.Component<RolesProps> {
  fetchRoles: Function;

  constructor(props: RolesProps) {
    super(props);
    this.fetchRoles = debounce(this.props.roleStore!.fetchRoles, 400);
  }

  componentDidMount () {
    analytics.track('Role Mgmt: Role list view', {category: 'Role Mgmt'});
    this.initialize();
  }

  componentDidUpdate(prevProps: RolesProps) {
    if (prevProps.match.params.orgId !== this.props.match.params.orgId) {
      this.initialize();
    } else if (prevProps.location.search !== this.props.location.search) {
      this.fetchRoles();
    }
  }

  initialize = async () => {
    const { search } = this.props.location;
    this.props.roleStore!.resetPageParams();
    if (isEmpty(search)) {
      this.updateParams({ sort_by: 'name', is_user_specific: false });
    } else {
      let searchParams = parse(search, { parseBooleans: true, parseNumbers: true }) as RolesPageRequestParams;
      const { is_user_specific } = searchParams;
      if (is_user_specific === undefined) {
        searchParams.is_user_specific = undefined;
      } else {
        searchParams.is_user_specific = false;
      }
      this.props.roleStore!.setPageParams(searchParams);
    }
    await this.props.roleStore!.fetchRoles();
  }
  createRole = () => {
    const { orgId } = this.props.match.params;
    const url = `/c/${orgId}/roles/create`;

    this.props.history.push(url);
  };
  manageRole = (roleId: string) => {
    const { orgId } = this.props.match.params;
    const url = `/c/${orgId}/roles/${roleId}`;

    this.props.history.push(url);
  };
  updateParams = (params: RolesPageRequestParams) => {
    const { location, history } = this.props;
    const search = parse(location.search);
    const searchParams = { ...(search ? search : {}), ...params };

    history.push({
      search: stringify(searchParams)
    });
    this.props.roleStore!.setPageParams(params);
  }
  handleShowCustomPermissionsChange = async ({ checked }: CheckboxProps) => {
    const params = {
      page: 1,
      is_user_specific: checked ? undefined : false
    };
    this.updateParams(params);
  };
  sortBy = (sortBy: string) => {
    const { sort_by: currentSortBy, reverse_sort } = this.props.roleStore!.pageParams;
    const reverseSort = sortBy === currentSortBy ? !reverse_sort : false;
    const params = {
      page: 1,
      sort_by: sortBy,
      reverse_sort: reverseSort
    };
    this.updateParams(params);
  };
  clearSearch = () => {
    const { is_user_specific } = this.props.roleStore!.pageParams;
    const params = {
      search_term: '',
      is_user_specific
    };
    this.updateParams(params);
  };
  updateSearch = ({ value }: FormInputProps) => {
    const search = value.toLowerCase();
    const params = {
      search_term: search
    };
    this.updateParams(params);
  };
  onPageChange = async ({ activePage }: PaginationProps) => {
    const params = {
      page: activePage as number
    };
    this.updateParams(params);
  }
  manageUsers = () => {
    const { orgId } = this.props.match.params;
    const url = `/c/${orgId}/users`;

    this.props.history.push(url);
  };
  downloadPermissionsAudit = () => {
    this.props.roleStore!.downloadPermissionsAudit();
  };
  render(): JSX.Element | null {
    const {
      roles,
      pageParams,
      totalPages,
      fetchingRoles,
      fetchRolesError,
      auditPermissionsError,
      fetchingPermissionsAudit
    } = this.props.roleStore!;
    const { page, is_user_specific, search_term, sort_by, reverse_sort } = pageParams;
    const { fetchingSurveys } = this.props.surveyStore!;
    const canManageUsers = this.props.organizationStore!.getCanAction('manage:user');
    const rolesExist = !isEmpty(compact(roles));
    const showCustomPermissions = is_user_specific === undefined ? true : !!is_user_specific;

    return (
      <Segment className="fullscreen manage-roles">
        <Header className="header-two-row">
          <div>
            Manage Roles
          </div>
          {canManageUsers &&
            <Button
              className="nw--manage-users"
              disabled={!!fetchingSurveys}
              onClick={this.manageUsers}
            >
              Manage users
            </Button>
          }
        </Header>
        <Segment className="white">

          {fetchRolesError && <Message negative={true}>{fetchRolesError}</Message>}

          <Menu secondary={true}>
            <Menu.Item fitted={true}>
              <Button
                color="blue"
                className="nw--add-role"
                disabled={!!fetchingSurveys}
                onClick={this.createRole}
              >
                <FontAwesomeIcon
                  className="icon"
                  fixedWidth={true}
                  icon="plus"
                />
                Add new role
              </Button>
            </Menu.Item>
            <Menu.Menu position="right">
              <Menu.Item fitted={true}>
                <label htmlFor="search" className="screen-reader-only">Search</label>
                <Input
                  id="search"
                  action={{
                    className: 'basic',
                    icon: search_term ? 'close' : 'search',
                    onClick: this.clearSearch
                  }}
                  placeholder="Search..."
                  name="filter"
                  onChange={(_e, data) => this.updateSearch(data)}
                  value={search_term || ''}
                />
              </Menu.Item>
              <Menu.Item fitted={true} title="Fetching users&hellip;">
                <Checkbox
                  className="nw--show-custom-permissions"
                  toggle={true}
                  label="Show custom permissions"
                  checked={showCustomPermissions}
                  onChange={(_e, data) => this.handleShowCustomPermissionsChange(data)}
                />
              </Menu.Item>
            </Menu.Menu>
          </Menu>

          <Table
            unstackable={true}
            fixed={true}
            sortable={true}
            className="roles-grid"
          >
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  className="nw-name-header"
                  sorted={sort_by === 'name' ? reverse_sort ? Direction.DESC : Direction.ASC : undefined}
                  onClick={() => this.sortBy('name')}
                >
                  Role
                </Table.HeaderCell>
                <Table.HeaderCell
                  className="nw-description-header"
                  sorted={sort_by === 'description' ? reverse_sort ? Direction.DESC : Direction.ASC : undefined}
                  onClick={() => this.sortBy('description')}
                >
                  Description
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            {fetchingRoles && <Table.Body>
              <Table.Row className="loading">
                <Table.Cell textAlign="center">
                  <Loader inline={true} size="small" active={fetchingRoles}> Fetching roles&hellip;</Loader>
                </Table.Cell>
              </Table.Row>
            </Table.Body>}
            {!fetchingRoles && <Table.Body>
              {!rolesExist &&
                <Table.Row className="loading" textAlign="center">
                  <Table.Cell>
                    {search_term ? 'No matching roles found' : 'No roles found'}
                  </Table.Cell>
                </Table.Row>
              }
              {roles.map(role => {
                return (
                  <Table.Row
                    key={role.id}
                    className="nw--role-row"
                    onClick={() => this.manageRole(role.id)}
                  >
                    <Table.Cell className="nw--name">{role.name}</Table.Cell>
                    <Table.Cell className="nw--description">{role.description}</Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>}
          </Table>
          <div className="pagination-block">
            <Pagination
              activePage={page}
              totalPages={totalPages}
              disabled={fetchingRoles}
              onPageChange={(_e, data) => this.onPageChange(data)}
            />
          </div>
        </Segment>

        <Segment className="white">

          <h2>
            Audit Permissions
          </h2>
          <div>This will download a spreadsheet of all users and their permissions </div>
          <div>
            <Button onClick={this.downloadPermissionsAudit} loading={fetchingPermissionsAudit} >
              Download Permissions Audit
            </Button>
          </div>
          <div>
            {auditPermissionsError && <Message negative={true}>{auditPermissionsError}</Message>}
          </div>
        </Segment>
      </Segment>
    );
  }
}
