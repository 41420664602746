import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { Button, List } from 'semantic-ui-react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ThemesStoreInterface } from 'stores/ThemesStore';
import { ThemeGroup } from 'lib/theme-file-parser';

interface InjectedProps {
  themesStore: ThemesStoreInterface;
}

export interface MergedThemeProps {
  group: ThemeGroup;
  id: string;
  isNew: boolean;
  title: string;
}

@inject('themesStore')
@observer
export default class MergedTheme extends React.Component<MergedThemeProps> {
  get injected(): InjectedProps {
    return this.props as MergedThemeProps & InjectedProps;
  }

  unmerge = () => {
    const { themesStore: store } = this.injected;
    const { group, id } = this.props;
    store.unmerge(group, id);
  };

  render() {
    const { isNew, title } = this.props;
    return (
      <List.Item className="list-action">
        <List.Content content={title} />
        {isNew && <FontAwesomeIcon icon="star" className="icon" />}
        <Button className="action" size="small" onClick={this.unmerge}>
          <FontAwesomeIcon icon="undo" className="icon" />
        </Button>
      </List.Item>
    );
  }
}
