<template>
  <div class="theme-pill">
    <div class="theme__name">
      {{ theme.name }}
    </div>
    <div class="theme__baseline-percentage">
      {{ volumeBaseline }}
    </div>
    <div class="theme__comparison-percentage">
      {{ volumeComparison }}
    </div>
  </div>
</template>


<script>
import toFixed from 'vue/libs/to-fixed';
export default {
  name: 'ThemePercentageDifferencePill',
  props: { theme: { default: () => ({}), type: Object } },
  computed: {
    volumeBaseline() {
      return toFixed(this.theme.volumeBaseline, 0) + '%';
    },
    volumeComparison() {
      return toFixed(this.theme.volumeComparison, 0) + '%';
    }
  }
};
</script>


<style lang="scss" scoped>
@import '../../../../styles/element-variables';
.theme-pill {
  color: $--neutral-900;
  display: inline-flex;
  line-height: 20px;
  margin: 0 10px 4px 0;
  font-size: 12px;
  -webkit-print-color-adjust: exact !important;
  @media print {
    line-height: 15px;
    margin: 0 8px 4px 0;
    font-size: 12px;
  }
}
.theme__name {
  background: $--primary-100;
  border-radius: $--border-radius-small 0 0 $--border-radius-small;
  flex: 0 0 auto;
  padding: 0 8px;
  -webkit-print-color-adjust: exact !important;
  @media print {
    padding: 0 6px;
    background: $--primary-100 !important;
  }
}
.theme__baseline-percentage,
.theme__comparison-percentage {
  flex: 0 0 auto;
  padding: 0 8px;
  -webkit-print-color-adjust: exact !important;
  @media print {
    padding: 0 6px;
  }
}
.theme__baseline-percentage {
  background: $--primary-100;
  @media print {
    background: $--primary-100 !important;
  }
}
.theme__comparison-percentage {
  background: $--orange-200;
  @media print {
    background: $--orange-200 !important;
  }
  border-radius: 0 $--border-radius-small $--border-radius-small 0;
}
</style>
