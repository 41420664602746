import { getAnalysisConfig, getSortedTags } from 'components/Filters/filters.service';
import { Modal, ModalHeader } from 'components/Shared/Modal';
import analytics from 'lib/analytics';
import { compose } from 'lib/composeHOCs';
import { isDateFilter } from 'lib/filters/date-filter-helper';
import { getAllFilters } from 'lib/filters/get-all-filters-helper';
import { IReactionDisposer, autorun } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { getAnalysisConfigStore, getExploreToolUIStore, getFilterStore, getInitConfigStore, getSurveyStore } from 'stores/RootStore';
import { toConfigUrl } from 'stores/surveys/config-parser';
import { ExportAssistant } from './ExportAssistant';
import { AvailableDateRange } from './types';
import { QueryFilter } from 'stores/types';

const withHocs = compose(withRouter, observer);

interface ExportAssistantState {
  availableDateRange: AvailableDateRange;
}

interface Params {
  orgId: string;
  surveyId: string;
  visId: string;
  viewId: string;
}

export interface ExportAssistantModalProps extends RouteComponentProps<Params> {
  open: boolean;
  onClose: () => void;
}
class ExportAssistantModalClass extends React.Component<ExportAssistantModalProps, ExportAssistantState> {
  disposeAutoRun: IReactionDisposer;

  state = {
    availableDateRange: {
      endDate: null,
      startDate: null,
    },
  }

  constructor(props: ExportAssistantModalProps) {
    super(props);

    this.disposeAutoRun = autorun(() => {
      this.initialize();
    });
  }

  async initialize() {
    const { surveyId, visId, viewId } = this.props.match.params;
    const analysisConfigStore = getAnalysisConfigStore();
    const filterStore = getFilterStore();
    const exploreToolUIStore = getExploreToolUIStore();
    const initConfigStore = getInitConfigStore();
    const { config, scores } = initConfigStore;
    const configUrl = toConfigUrl(surveyId, visId, viewId);

    initConfigStore.setConfigLocation(configUrl);

    if (config) {
      analysisConfigStore.setConfig(config);
    }

    if (scores?.length) {
      exploreToolUIStore.setScore((scores[0])?.key || '');
    }

    if (config?.filters) {
      filterStore.setFilterConfig(config.filters);
      filterStore.updateSelections();
    }

    this.getAvailableDateRange();
  }

  async componentDidMount(): Promise<void> {
    const { surveyId, visId, viewId } = this.props.match.params;
    analytics.track('Export Assistant: Opened', { surveyId, visId, viewId });
  }

  async getAvailableDateRange() {
    const { surveyId, visId, viewId } = this.props.match.params;
    const analysisConfigStore = getAnalysisConfigStore();

    const config = await analysisConfigStore.getConfig({ survey: surveyId, visualization: visId, view: viewId });

    if (!config) {
      return;
    }

    const [dateId] = this.getDateIds();
    const dateFilter = config?.filters?.find(filter => filter.id === dateId);

    if (!dateFilter || !isDateFilter(dateFilter)) {
      return;
    }

    this.setState({
      availableDateRange: {
        endDate: new Date(dateFilter.date_end),
        startDate: new Date(dateFilter.date_start),
      }
    });
  }

  getFilters() {
    const sortedTags = getSortedTags();
    const { showSentiment } = getAnalysisConfig();
    const analysisConfigStore = getAnalysisConfigStore();
    const config = analysisConfigStore.config;

    if (!config || !config.filters) {
      return [];
    }

    return getAllFilters({
      filters: config.filters,
      showSentiment,
      sortedTags,
    });
  }

  getFetchOptions = () => {
    const exploreToolUIStore = getExploreToolUIStore();
    const filterStore = getFilterStore();
    const initConfigStore = getInitConfigStore();
    const { commentColumns } = initConfigStore;
    const { requestOptions } = exploreToolUIStore;
    const { segmentQueryStrings, selections, getQueryStringByKey } = filterStore;
    const dateIDs = this.getDateIds();
    const queryWithoutDate = getQueryStringByKey('baseline', ...dateIDs)

    const selectionQueries = {
      baseline: {
        query: selections.baseline.query,
        queryWithoutDate
      },
      comparison: {
        query: selections.comparison.query
      }
    }

    return {
      commentColumns,
      selectionQueries,
      segmentQueryStrings,
      requestOptions
    }
  }

  getFilterTitle() {
    const filterStore = getFilterStore();

    return filterStore.queryTitles.baseline || 'Last 31 days';
  }

  getSurveyTitle() {
    const surveyStore = getSurveyStore();
    const { surveyId } = this.props.match.params;

    return surveyStore.getSurveyTitle(surveyId);
  }

  getDateIds() {
    const { config, IdsFromColumn } = getInitConfigStore();

    if (!config) {
      return [];
    }

    const dateColumn = config.date_column || 0;

    return IdsFromColumn(dateColumn) || [];
  }

  handleDateSelect(dateQueryFilter: QueryFilter) {
    const filterStore = getFilterStore();
    filterStore.setQueryFilter(dateQueryFilter);
  }

  render() {
    const { open, onClose } = this.props;
    const { surveyId } = this.props.match.params;
    const { availableDateRange } = this.state;
    const dateIDs = this.getDateIds();
    const fetchOptions = this.getFetchOptions();
    const filters = this.getFilters();
    const filterTitle = this.getFilterTitle();
    const surveyTitle = this.getSurveyTitle();

    return (
      <Modal onClose={onClose} open={open} overflowVisible={true} size="fullscreen">
        <ModalHeader as="h2">Download table of data for "{surveyTitle}"</ModalHeader>
        <ExportAssistant
          availableDateRange={availableDateRange}
          dateIDs={dateIDs}
          fetchOptions={fetchOptions}
          filters={filters}
          onClose={onClose}
          onDateSelect={(updatedCategory) => this.handleDateSelect(updatedCategory)}
          filterTitle={filterTitle}
          surveyId={surveyId}
          surveyTitle={surveyTitle}
        />
      </Modal>
    );
  }
}

const ExportAssistantModal = withHocs(ExportAssistantModalClass);

export { ExportAssistantModal };
