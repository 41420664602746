import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as classnames from 'classnames';
import { UnregisterCallback } from 'history';
import { map, some } from 'lodash';
import { reaction } from 'mobx';
import { disposeOnUnmount, inject, observer } from 'mobx-react';
import * as React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Dropdown, Icon } from 'semantic-ui-react';
import { DashboardJson, DashboardStoreInterface } from 'stores/DashboardStore';
import { OrganizationStoreInterface } from 'stores/OrganizationStore';

export interface DashboardNavStoreProps {
  dashboardStore?: DashboardStoreInterface;
  organizationStore?: OrganizationStoreInterface;
}

interface DashboardNavProps extends
DashboardNavStoreProps, RouteComponentProps<object> {
  orgId: string;
  fetching: boolean;
}

interface DashboardNavState {
  isActive: boolean;
}

@inject('dashboardStore', 'organizationStore')
@observer
class DashboardNav extends React.Component<DashboardNavProps, DashboardNavState> {
  state = {
    isActive: false
  };
  unregister?: UnregisterCallback = undefined;
  componentDidMount() {
    const { history, location, dashboardStore } = this.props;

    // listen for URL changes
    this.unregister = history.listen(loc => this.matches(loc.pathname));

    disposeOnUnmount(
      this,
      reaction(
        () => dashboardStore!.dashboards,
        () => this.matches(location.pathname),
        { fireImmediately: true }
      )
    );
  }

  componentWillUnmount() {
    if (this.unregister) {
      this.unregister();
    }
  }

  canManageDashboard = (): boolean => {
    const canManageDashboard = this.props.organizationStore!.getCanAction('manage:report');
    return canManageDashboard;
  };

  matches(pathname: string) {
    const { orgId, dashboardStore } = this.props;
    const { dashboards } = dashboardStore!;

    const isActive =
      pathname === `/c/${orgId}` ||
      some(dashboards, dashboard => this.getPath(dashboard) === pathname);
    this.setState({ isActive });
  }
  getPath = (dashboard: DashboardJson): string => {
    const { orgId } = this.props;
    return `/c/${orgId}/r/${dashboard.id}`;
  };

  renderCreateDashboardOption = () => {
    const { orgId } = this.props;
    const to = `/c/${orgId}/r/create`;

    return (
      <Dropdown.Item
        as={Link}
        to={to}>
        <Icon
          size="large"
          name="plus"
        />
        Create Dashboard
      </Dropdown.Item>
    );
  }

  render(): JSX.Element | null {
    const { fetching, dashboardStore } = this.props;
    const { dashboards, dashboardsLoading } = dashboardStore!;
    const loading = fetching || dashboardsLoading;
    const canManageDashboard = this.canManageDashboard();

    const { isActive } = this.state;
    const dashboardEls = map(dashboards, dashboard => {
      const { id, isPreview, name } = dashboard;
      const to = this.getPath(dashboard);
      return (
        <Dropdown.Item key={`${id}-${name}`} as={Link} to={to}>
          {name}
          {isPreview && (
            <Icon
              size="large"
              name="hide"
              style={{ marginLeft: 5 }}
              title="[PREVIEW ONLY]"
            />
          )}
        </Dropdown.Item>
      );
    });
    const classes = classnames('normal-size', 'nw-report-menu', {
      'is-active': isActive
    });
    if (loading || dashboardEls.length) {
      if (dashboards && dashboards.length === 1) {
        const { id, name } = dashboards[0];
        const to = this.getPath(dashboards[0]);
        if (canManageDashboard) {
          return (
            <Dropdown
              item={true}
              icon={
                <FontAwesomeIcon
                  className="icon"
                  icon="angle-down"
                  fixedWidth={true}
                />
              }
              text="Dashboards"
              className={classes}
            >
              <Dropdown.Menu>
                <Dropdown.Item
                  key={`${id}-${name}`}
                  as={Link}
                  to={to}
                >
                  {name}
                </Dropdown.Item>
                {this.renderCreateDashboardOption()}
              </Dropdown.Menu>
            </Dropdown>
          );
        } else {
          return (
            <Dropdown.Item
              key={`${id}-${name}`}
              as={Link}
              to={to}
              className={classes}
            >
              Dashboard
            </Dropdown.Item>
          );
        }
      } else {
        if (loading) {
          return (
            <Dropdown
              icon={
                <FontAwesomeIcon
                  className="icon"
                  icon="spinner"
                  spin={true}
                  fixedWidth={true}
                />
              }
              text="Dashboard"
              item={true}
            />
          );
        }
        return (
          <Dropdown
            item={true}
            icon={
              <FontAwesomeIcon
                className="icon"
                icon="angle-down"
                fixedWidth={true}
              />
            }
            text="Dashboards"
            className={classes}
          >
            <Dropdown.Menu>
              {dashboardEls}
              {canManageDashboard && this.renderCreateDashboardOption()}
            </Dropdown.Menu>
          </Dropdown>
        );
      }
    } else if (canManageDashboard) {
      return (
        <Dropdown
            item={true}
            icon={
              <FontAwesomeIcon
                className="icon"
                icon="angle-down"
                fixedWidth={true}
              />
            }
            text="Dashboards"
            className={classes}
          >
            <Dropdown.Menu>
              {this.renderCreateDashboardOption()}
            </Dropdown.Menu>
          </Dropdown>
      );
    } else {
      return null;
    }
  }
}
export default withRouter(DashboardNav);
